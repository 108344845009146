import styled, { css } from 'styled-components';
import { Menu as MuiMenu, MenuItem as MuiMenuItem } from '@mui/material';

import { Button } from '../../../../components';
import { getMediaFor } from '../../../../layouts';

export const RecipientsHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const RecipientsActionsBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
`;

export const RecipientsSearchInputWrapper = styled.div`
  width: 320px;
`;

export const RecipientsButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      align-items: stretch;
      gap: 8px;
    `)};
`;

export const AddAccountButton = styled(Button)`
  min-width: 168px;
`;
export const AddRecipientButton = styled(AddAccountButton)``;

export const AddAccountMenu = styled(MuiMenu)`
  .MuiMenu-paper {
    margin-top: 8px;
    border-radius: 16px;
    background-color: ${({ theme }) => theme.colors.bg.contextMenu};
    box-shadow: ${({ theme }) => theme.shadowsCustom.contextMenu};
  }

  .MuiMenu-list {
    padding: 8px 0;
  }
`;

export const AddAccountMenuItem = styled(MuiMenuItem)`
  &.MuiMenuItem-root {
    min-width: 168px;
    padding: 0;
  }
`;

export const RecipientTypeButton = styled.button`
  width: 100%;
  padding: 8px 16px;

  border: none;
  background-color: transparent;
  cursor: pointer;

  font-weight: 400;
  font-size: 1.6rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.white};
  text-align: left;
`;
