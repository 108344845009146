import styled, { css, DefaultTheme } from 'styled-components';
import { Select as MuiSelect } from '@mui/material';
import { StandardCSSProperties } from '@mui/system';

import { Option } from './components/SelectOption/styled';

export const getSelectDropperStyles = (theme: DefaultTheme): StandardCSSProperties => ({
  marginTop: '8px',
  borderRadius: '16px',
  backgroundColor: theme.colors.bg.dropList,
});

export const Select = styled(MuiSelect)<{
  $isActive?: boolean;
  $isMultiple?: boolean;
  $disabled?: boolean;
  $nonEditable?: boolean;
}>`
  ${({ $isMultiple }) =>
    $isMultiple
      ? css`
          height: auto;
          min-height: 48px;
        `
      : css`
          height: 48px;
        `};

  // main div of Select component
  .MuiSelect-select {
    box-sizing: border-box;

    font-weight: 400;
    font-size: 1.6rem;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.typography.white};

    ${({ $isMultiple }) =>
      $isMultiple
        ? css`
            padding: 8px 16px;
          `
        : css`
            padding: 16px;
          `};

    ${({ $nonEditable }) =>
      $nonEditable &&
      css`
        color: ${({ theme }) => theme.colors.typography.gray48};
        user-select: auto;
        cursor: text;
      `};
  }

  // fieldset as OutlinedInput - visible container of select
  .MuiOutlinedInput-notchedOutline {
    border: 2px solid ${({ theme }) => theme.colors.borders.white05};
    border-radius: 8px;
    background-color: ${({ theme }) => theme.colors.bg.white05};

    ${({ $nonEditable }) =>
      $nonEditable &&
      css`
        border: none;
      `};
  }

  // when hover
  &:hover {
    && fieldset {
      border-color: ${({ theme }) => theme.colors.borders.gray80};

      ${({ $nonEditable }) =>
        $nonEditable &&
        css`
          border: none;
        `};
    }
  }

  // when select is open
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({ theme }) => theme.colors.borders.white};
  }

  // when select is disabled
  .Mui-disabled {
    cursor: not-allowed;
    -webkit-text-fill-color: ${({ theme }) => theme.colors.typography.gray32};
  }
  &:hover.Mui-disabled {
    && fieldset {
    }
  }

  &.Mui-disabled {
  }
  &.Mui-disabled .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  // when error occurred
  &:hover.Mui-error {
    && fieldset {
      border-color: ${({ theme }) => theme.colors.error};
    }
  }
  &.Mui-error .MuiOutlinedInput-notchedOutline {
  }

  // icon
  .MuiSelect-icon {
    top: auto;
    right: 16px;
    color: ${({ theme }) => theme.colors.typography.gray64};

    ${({ $isActive }) =>
      $isActive &&
      css`
        color: ${({ theme }) => theme.colors.typography.white};
      `};
    ${({ $disabled }) =>
      $disabled &&
      css`
        color: ${({ theme }) => theme.colors.typography.gray32};
      `};
    ${({ $nonEditable }) =>
      $nonEditable &&
      css`
        color: ${({ theme }) => theme.colors.typography.gray48};
      `};
  }
  &:hover .MuiSelect-icon {
    color: ${({ theme }) => theme.colors.typography.gray80};

    ${({ $disabled }) =>
      $disabled &&
      css`
        color: ${({ theme }) => theme.colors.typography.gray32};
      `};
    ${({ $nonEditable }) =>
      $nonEditable &&
      css`
        color: ${({ theme }) => theme.colors.typography.gray48};
      `};
  }
  .MuiSelect-iconOpen {
    color: ${({ theme }) => theme.colors.typography.white};
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LabelWrapper = styled.label`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.span`
  display: inline-block;
  margin-bottom: 4px;

  font-weight: 400;
  font-size: 1.6rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.gray48};
`;

export const MultipleValuesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
`;

export const NoOptions = styled(Option)`
  &.MuiMenuItem-root {
    justify-content: center;
  }
`;
