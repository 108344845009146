import styled, { css } from 'styled-components';

import { getMediaFor } from '../../../../../theming';
import { Button, IconButton } from '../../../../../../components';

export const AddWalletButton = styled(Button)`
  height: 48px;
  padding: 16px 40px;

  ${({ theme }) =>
    getMediaFor(
      'tablet-max-width',
      theme
    )(css`
      height: 56px;
      padding: 20px 32px;
      border: 2px solid ${({ theme }) => theme.colors.borders.white50};
    `)};
`;

export const AddWalletIconButton = styled(IconButton)`
  padding: 20px;
  border-radius: 16px;
  border: 2px solid ${({ theme }) => theme.colors.borders.white50};
  background-color: transparent;
  color: ${({ theme }) => theme.colors.typography.white};

  .MuiTouchRipple-child {
    border-radius: 16px;
  }
`;
