import { apiSlice } from '../../api';
import type {
  GetKycSyncProfilesDTO,
  GetKycSyncProfilesResult,
  PutKycSyncDTO,
  PutKycSyncResult,
} from './types';

export const kycSyncEndpoints = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getKycSyncProfiles: build.query<GetKycSyncProfilesResult, GetKycSyncProfilesDTO>({
      query: ({ sharingLogUid }) => ({
        url: `aml-service/v1/kyc-sync/${sharingLogUid}/profiles`,
        method: 'GET',
      }),
    }),
    putKycSync: build.mutation<PutKycSyncResult, PutKycSyncDTO>({
      query: ({ sharingLogUid, body }) => ({
        url: `aml-service/v1/kyc-sync/${sharingLogUid}`,
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const { useGetKycSyncProfilesQuery, useLazyGetKycSyncProfilesQuery, usePutKycSyncMutation } =
  kycSyncEndpoints;

export type ProfileController = typeof kycSyncEndpoints;
