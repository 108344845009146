import styled from 'styled-components';
import { Tab as MuiTab } from '@mui/material';

export const Tab = styled(MuiTab)`
  &.MuiTab-root {
    flex: 1;
    min-height: 42px;
    min-width: 110px;
    max-width: 170px;

    padding: 12px 24px;
    border-bottom: 2px solid ${({ theme }) => theme.colors.borders.white10};

    font-weight: 400;
    font-size: 1.8rem;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.typography.gray64};
    text-transform: none;
  }

  &.Mui-selected {
    border-bottom: 2px solid ${({ theme }) => theme.colors.secondary};
    color: ${({ theme }) => theme.colors.typography.white};
  }

  &.Mui-disabled {
    color: ${({ theme }) => theme.colors.typography.gray32};
  }
`;
