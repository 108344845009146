import styled from 'styled-components';
import { Tabs as MuiTabs } from '@mui/material';

export const TabsContainer = styled.div``;

export const TabsHeader = styled.div``;

export const TabsBody = styled.div`
  padding-top: 16px;
`;

export const Tabs = styled(MuiTabs)`
  min-height: 42px;

  .MuiTabScrollButton-root {
    border-bottom: 2px solid ${({ theme }) => theme.colors.borders.white10};
    color: ${({ theme }) => theme.colors.typography.white};

    svg {
      font-size: 2.4rem;
    }

    &.Mui-disabled {
      opacity: 1;

      svg {
        color: ${({ theme }) => theme.colors.typography.gray32};
      }
    }
  }
`;
