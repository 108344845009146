import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { Helmet } from 'react-helmet';
import { Tooltip } from '@mui/material';

import * as S from './styled';
import {
  Button,
  Hint,
  IconButton,
  Modal,
  ModalConfirmation,
  Title,
  useBanner,
} from '../../components';
import { IconArrowNext24, IconArrowPrevious16, IconDownload24 } from '../../assets';
import { TransferInfo } from './components/TransferInfo';
import { TransferDocuments } from './components/TransferDocuments';
import { AttachDocumentForm } from './components/AttachDocumentForm';
import { ConfirmPasswordBody } from './components/ConfirmPasswordBody';

import {
  useCancelTransferMutation,
  useGetTransferByTransferUidQuery,
  useGetTransferDocumentsQuery,
  useGetWalletsQuery,
  useLazyGetTransferStatementQuery,
  usePostTransferUploadDocumentsMutation,
  useTransferPrecheckMutation,
} from '../../api';
import { useACL } from '../../services';
import { useMedia } from '../../layouts';
import { RouterPaths } from '../../router';
import { activeProfileTypeSelector, activeProfileUidSelector, useAppSelector } from '../../store';
import { ProfileType, TransferPurpose, TransferState, TransferType } from '../../types';
import { UTCToLocal } from '../../utils';
import { SendAgain } from './SendAgain/SendAgain';
import {
  DownloadTransferStatementDto,
  useDownloadTransactionStatementPdfMutation,
} from '../../api/controllers/docgen';

type TransferProps = {
  type: 'forSend' | 'forExchange' | 'default';
};

export const Transfer: FC<TransferProps> = ({ type }) => {
  const { t } = useTranslation();
  const { transferId } = useParams<{ transferId: string }>();
  const navigate = useNavigate();
  const { isMobile } = useMedia();
  const { showBanner } = useBanner();

  const isSendPage = type === 'forSend';
  const isExchangePage = type === 'forExchange';
  const defaultPage = type === 'default';

  const hasPermission_WALLET_VIEW = useACL(['WALLET_VIEW']);

  const profileUid = useAppSelector(activeProfileUidSelector);
  const profileType = useAppSelector(activeProfileTypeSelector);

  const isLegal = profileType === ProfileType.LEGAL;

  const { data: transfer, refetch: refetchTransfer } = useGetTransferByTransferUidQuery(
    profileUid && transferId ? { transferUid: transferId, profileUid } : skipToken
  );
  const { data: documents, refetch: refetchDocuments } = useGetTransferDocumentsQuery(
    profileUid && transferId ? { transferUid: transferId, profileUid } : skipToken
  );
  const { data: wallets } = useGetWalletsQuery(
    profileUid && hasPermission_WALLET_VIEW ? { profileUid } : skipToken
  );
  const [uploadDocument] = usePostTransferUploadDocumentsMutation();
  const [transferPrecheck, { data: transferPrecheckData }] = useTransferPrecheckMutation();
  const [
    getTransferStatemetn,
    { data: transferStatement, isFetching: isFetchingTransferStatement },
  ] = useLazyGetTransferStatementQuery();
  const [downloadPdf] = useDownloadTransactionStatementPdfMutation();
  const [cancelTransfer, { isLoading: isLoadingDecline }] = useCancelTransferMutation();

  const isExchangeTransferType = transfer?.transferType === TransferType.EXCHANGE;

  // modal states
  const [isUploadModalShown, setUploadModalShown] = useState(false);
  const [tempPath, setTempPath] = useState<string | null>(null);
  const showModal = () => setUploadModalShown(true);
  const [statementLoading, setStatementLoading] = useState(false);
  const closeModal = () => setUploadModalShown(false);

  const [isPasswordModalShown, setPasswordModalShown] = useState(false);
  const showConfirmationPasswordModal = () => setPasswordModalShown(true);
  const closeConfirmationPasswordModal = () => setPasswordModalShown(false);

  const [isCloseModalShown, setCloseModalShown] = useState(false);
  const showConfirmationModal = () => setCloseModalShown(true);
  const closeConfirmationModal = () => setCloseModalShown(false);

  const [isSendAgainShown, setSendAgainShown] = useState(false);
  const showSendAgainnModal = () => setSendAgainShown(true);
  const closeSendAgainModal = () => setSendAgainShown(false);

  const backToHistory = () => {
    navigate(RouterPaths.Home);
  };

  const handleModalSave = () => {
    if (profileUid && transfer?.transferUid && tempPath) {
      uploadDocument({
        profileUid,
        transferUid: transfer.transferUid,
        transferDocumentRequestDtoWA: { tempPath },
      }).then((res: any) => {
        if (res.data) {
          closeModal();
          refetchDocuments();
        }
      });
    }
  };

  const onDownloadPdf = () => {
    if (transferId) {
      setStatementLoading(true);
      getTransferStatemetn({ transferUid: transferId }).then((data) => {
        if (data.error) {
          setStatementLoading(false);
        }
      });
    }
  };

  useEffect(() => {
    if (transferStatement && !isFetchingTransferStatement) {
      const mappedData: DownloadTransferStatementDto = {
        abfpayCompanyInfo: {
          abfCompanyName: transferStatement.abfpayCompanyInfo.companyName,
          abfCountry: transferStatement.abfpayCompanyInfo.country,
          abfZip: transferStatement.abfpayCompanyInfo.zip,
          abfCity: transferStatement.abfpayCompanyInfo.city,
          abfStreet: transferStatement.abfpayCompanyInfo.street,
          abfBuildingNumber: transferStatement.abfpayCompanyInfo.buildingNumber,
          abfOfficeNumber: transferStatement.abfpayCompanyInfo.officeNumber,
        },
        currentDate: UTCToLocal(new Date().toISOString(), 'DD/MM/YYYY, HH:mm'),
        profileAddressDto: transferStatement.profileAddressDto,
        profileName: transferStatement.profileName,
        transferStatementDto: {
          ...transferStatement.transferStatementDto,
          finishedAt: transferStatement.transferStatementDto.finishedAt
            ? UTCToLocal(transferStatement.transferStatementDto.finishedAt, 'DD/MM/YYYY, HH:mm')
            : '',
        },
      };

      downloadPdf(mappedData).then(() => {
        setStatementLoading(false);
      });
    }
  }, [transferStatement, isFetchingTransferStatement]);

  useEffect(() => {
    const walletCoinCode = wallets?.find((wallet) => wallet.uid === transfer?.walletUid)
      ?.coinResponseDto.code;
    if (transfer && wallets && walletCoinCode && transfer.transferType !== TransferType.EXCHANGE) {
      transferPrecheck({
        profileUid,
        coinCode: walletCoinCode,
        direction: transfer.transferDirection,
        transferAmount: transfer.transactionAmount,
      });
    }
  }, [transfer]);

  const onCancelTransfer = () => {
    if (profileUid && transfer?.transferUid) {
      cancelTransfer({
        profileUid: profileUid,
        transferUid: transfer?.transferUid,
      }).then(() => {
        closeConfirmationModal();
        isSendPage || isExchangePage ? navigate(RouterPaths.Home) : refetchTransfer();
      });
    }
  };

  return (
    <>
      {isSendAgainShown ? (
        transfer && <SendAgain onBack={closeSendAgainModal} transfer={transfer} />
      ) : (
        <S.TransferContainer $isSendPage={isSendPage || isExchangePage}>
          <Helmet title={t('pages.transfer', { value: transferId })} />

          {defaultPage && (
            <S.TransferHeader>
              <S.TitleWrapper>
                <IconButton icon={<IconArrowPrevious16 />} onClick={() => backToHistory()} />
                <Title text={t('transfer.title')} />
              </S.TitleWrapper>
            </S.TransferHeader>
          )}
          {/* 
          {transfer?.state === TransferState.PENDING_CONFIRMATION && (
            <Hint variant="default" icon="warning" message={t('transfers.tooltip.title')} />
          )} */}

          <S.TransferBody>
            <S.TransferInfoWithActonBody>
              <TransferInfo transfer={transfer} wallets={wallets} />
              {transfer?.transferPurpose !== TransferPurpose.CHARGES &&
                transfer?.state === TransferState.PREPARED && (
                  <S.TransferReviewButtonContainer>
                    <Button
                      body={t('send.decline')}
                      onClick={showConfirmationModal}
                      color="error"
                      style={{ flexGrow: 1 }}
                    />
                    {transferPrecheckData?.isDocumentRequired && documents?.length === 0 ? (
                      <Tooltip
                        title={
                          <Hint
                            message={t('send.execute-tooltip')}
                            variant="small"
                            icon="warning"
                            customContainerStyles={{ fontSize: '1.4rem', padding: 8 }}
                          />
                        }
                        componentsProps={{
                          tooltip: { sx: { p: 0, bgcolor: 'black', borderRadius: '16px' } },
                        }}
                        placement={isMobile ? 'top-start' : 'right'}
                      >
                        <div style={{ flexGrow: 1 }}>
                          <Button
                            body={t('send.execute')}
                            onClick={showConfirmationPasswordModal}
                            style={{ width: '100%' }}
                            disabled={documents?.length === 0}
                          />
                        </div>
                      </Tooltip>
                    ) : (
                      <Button
                        body={t('send.execute')}
                        onClick={showConfirmationPasswordModal}
                        style={{ flexGrow: 1 }}
                      />
                    )}
                  </S.TransferReviewButtonContainer>
                )}
            </S.TransferInfoWithActonBody>
            <S.LeftContainer>
              {isExchangePage || isExchangeTransferType ? null : (
                <TransferDocuments documents={documents} onAttachClick={showModal} />
              )}
              <S.DownloadButtonContainer>
                {transfer?.state === 'EXECUTED' &&
                  transfer?.transferType !== 'EXCHANGE' &&
                  (transfer.transferPurpose === 'TRANSFER' ||
                    transfer.transferPurpose === 'SERVICE_FEE' ||
                    transfer.transferPurpose === 'CHARGES') && (
                    <Button
                      style={{ width: '100%' }}
                      body={t('transfer.transaction-statement')}
                      startIcon={<IconDownload24 />}
                      onClick={onDownloadPdf}
                      loading={statementLoading}
                      disabled={statementLoading}
                    />
                  )}
              </S.DownloadButtonContainer>
              <S.DownloadButtonContainer>
                {transfer &&
                  ['EXECUTED', 'CANCELED'].includes(transfer.state) &&
                  transfer?.transferDirection === 'OUT' &&
                  transfer?.transferPurpose !== 'CHARGES' &&
                  transfer?.transferPurpose !== 'EXCHANGE' &&
                  transfer?.transferPurpose !== 'SERVICE_FEE' &&
                  defaultPage && (
                    <Button
                      style={{ justifyContent: 'flex-start' }}
                      body={t('transfer.send-button')}
                      startIcon={<IconArrowNext24 />}
                      onClick={showSendAgainnModal}
                    />
                  )}
              </S.DownloadButtonContainer>
            </S.LeftContainer>
          </S.TransferBody>
        </S.TransferContainer>
      )}

      <Modal
        modalTitle={t('transfer.attach-modal.title')}
        body={
          <AttachDocumentForm
            onLoad={(filePath) => setTempPath(filePath)}
            disabled={!tempPath}
            onClose={closeModal}
            onSave={handleModalSave}
          />
        }
        open={isUploadModalShown}
        onClose={closeModal}
      />
      <Modal
        modalTitle={t('send.confirm-password')}
        body={
          <ConfirmPasswordBody
            onCancel={closeConfirmationPasswordModal}
            transferUid={transfer?.transferUid ?? ''}
            onOpenError={() =>
              showBanner(
                'error',
                t('send.errorModal.description'),
                t('send.errorModal.title'),
                isSendPage || isExchangePage ? () => navigate(RouterPaths.Home) : refetchTransfer
              )
            }
            onOpenSuccess={() =>
              showBanner(
                'success',
                isExchangeTransferType
                  ? t('send.successModal.exchange')
                  : `${t('send.successModal.description-1')} ${transfer?.amount ?? 0} ${
                      transfer?.currencyCode ?? ''
                    } ${t('send.successModal.description-2')} ${transfer?.payee ?? ''} ${t(
                      'send.successModal.description-3'
                    )}`,
                t('send.successModal.title'),
                isSendPage || isExchangePage ? () => navigate(RouterPaths.Home) : refetchTransfer
              )
            }
          />
        }
        open={isPasswordModalShown}
        onClose={() => closeConfirmationPasswordModal()}
      />
      <ModalConfirmation
        question={t('send.declineModalTitle')}
        confirmButtonProps={{
          color: 'error',
          body: t('send.decline'),
          loading: isLoadingDecline,
        }}
        open={isCloseModalShown}
        onCancel={() => closeConfirmationModal()}
        onConfirm={() => onCancelTransfer()}
      />
    </>
  );
};
