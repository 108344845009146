import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import * as S from './styled';
import { IconArrowNext24 } from '../../../../assets';
import { InputFileUpload } from '../../../../components';

import { UTCToLocal, getItemFromStorage } from '../../../../utils';
import { commentsEndpoints, useCreateCommentInTicketMutation } from '../../../../api';
import {
  activeProfileTypeSelector,
  messageSelector,
  naturalProfileNameSelector,
  useAppSelector,
} from '../../../../store';
import { AsyncErrorMessage, getAsyncErrorMessage, Shape, yup } from '../../../../forms';
import { Comment, Pagination, ProfileType, Ticket, TicketState } from '../../../../types';

type TicketChatProps = {
  ticket: Ticket | undefined;
  addAttachment: (item: AddAttachmentProps) => void;
};
export type AddAttachmentProps = {
  tempPath: string;
  fileName: string;
};
type AddCommentFields = {
  message: string;
};

const addCommentSchema = yup.object().shape<Shape<AddCommentFields>>({
  message: yup.string().required(),
});

export const TicketChat: FC<TicketChatProps> = ({ ticket, addAttachment }) => {
  const { t } = useTranslation();

  const profileType = useAppSelector(activeProfileTypeSelector);
  const naturalName = useAppSelector(naturalProfileNameSelector);
  const messageList = useAppSelector(messageSelector);

  const userUid = getItemFromStorage('userUid');
  const isClosed = ticket?.ticketState === TicketState.CLOSED;
  const isNatural = profileType === ProfileType.NATURAL;

  // TODO: clarify - Is need here name of assignee?
  const supportName = t('ticket.chat.support-name');
  const userName = isNatural ? naturalName : t('ticket.chat.legal-name');

  const [createComment, { isLoading: isCreateCommentLoading }] = useCreateCommentInTicketMutation();

  const {
    register,
    reset,
    setError,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<AddCommentFields>({
    resolver: yupResolver(addCommentSchema),
  });

  const endOfMessagesRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (endOfMessagesRef.current) {
      const scrollHeight = endOfMessagesRef.current.scrollHeight;
      endOfMessagesRef.current.scrollTop = scrollHeight;
    }
  };

  const addComment: SubmitHandler<AddCommentFields> = async ({ message }) => {
    if (!isDirty || !ticket?.id) {
      return;
    }

    try {
      await createComment({ ticketId: String(ticket.id), body: message }).unwrap();
      reset();
    } catch (e) {
      getAsyncErrorMessage(e, setError);
    }
  };

  const commentsElements = useMemo(() => {
    return messageList.map((c) => {
      const isUser = userUid === c.userUid;

      return (
        <S.TicketChatMessage key={c.id} className={isUser ? 'ticket-chat-message-user' : 'ticket'}>
          <S.MessageContainer>
            <div className="ticket-chat-message-title">{isUser ? userName : supportName}</div>
            <S.MessageDate>{ticket?.createdAt ? UTCToLocal(ticket?.createdAt) : '—'}</S.MessageDate>
          </S.MessageContainer>
          {c.message}
        </S.TicketChatMessage>
      );
    });
  }, [messageList]);

  useEffect(() => scrollToBottom(), [commentsElements]);

  return (
    <S.TicketChatContainer>
      <S.TicketChatHeader>{t('ticket.chat.header')}</S.TicketChatHeader>

      <S.TicketChatBody ref={endOfMessagesRef}>{commentsElements}</S.TicketChatBody>

      <S.TicketChatFooter>
        <S.TicketChatForm onSubmit={handleSubmit(addComment)}>
          <InputFileUpload
            inputProps={{ name: 'attachmentUpload', disabled: isClosed }}
            fileCallback={(res) => {
              addAttachment({
                tempPath: res[0]!.path,
                fileName: res[0]!.fileName,
              });
            }}
          />

          <S.TicketChatTextField
            {...register('message')}
            errors={errors}
            placeholder={t('ticket.chat.message-form-field.placeholder')}
            disabled={isClosed}
            showErrorMessage={false}
          />

          <S.SendTicketMessageButton
            icon={<IconArrowNext24 />}
            type="submit"
            disabled={isClosed || isCreateCommentLoading}
          />
        </S.TicketChatForm>

        <AsyncErrorMessage errors={errors} />
      </S.TicketChatFooter>
    </S.TicketChatContainer>
  );
};
