import { apiSlice } from '../../api';
import type { DeleteRoleToRelatedPersonDTO, UpdateRoleToRelatedPersonDTO } from './types';

export const relatedPersonRolesEndpoints = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    updateRoleToRelatedPerson: build.mutation<void, UpdateRoleToRelatedPersonDTO>({
      query: ({ profileUid, relatedPersonId, body }) => ({
        url: `/profiles-service/v1/profiles/${profileUid}/related-persons/${relatedPersonId}/roles`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['RelatedPersonsLocal', 'RelatedPersonsExternal'],
    }),
    deleteRoleFromRelatedPerson: build.mutation<void, DeleteRoleToRelatedPersonDTO>({
      query: ({ profileUid, relatedPersonId }) => ({
        url: `/profiles-service/v1/profiles/${profileUid}/related-persons/${relatedPersonId}/roles`,
        method: 'DELETE',
      }),
      invalidatesTags: ['RelatedPersonsLocal', 'RelatedPersonsExternal'],
    }),
  }),
});

export const { useUpdateRoleToRelatedPersonMutation } = relatedPersonRolesEndpoints;
export const { useDeleteRoleFromRelatedPersonMutation } = relatedPersonRolesEndpoints;

export type RelatedPersonRolesController = typeof relatedPersonRolesEndpoints;
