import styled from 'styled-components';
import { Chip as MuiChip } from '@mui/material';

export const Chip = styled(MuiChip)`
  gap: 10px;
  min-width: 60px;
  padding: 8px;

  border-radius: 32px;
  background-color: ${({ theme }) => theme.colors.bg.white10};

  font-size: 1.6rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.gray80};

  // start icon
  .MuiChip-icon {
    margin: 0 0 0 4px;
    color: inherit;
  }

  // span with content
  .MuiChip-label {
    padding: 0;
  }

  // delete icon
  .MuiChip-deleteIcon {
    margin: 0;
    color: ${({ theme }) => theme.colors.typography.gray88};

    &:hover {
      color: ${({ theme }) => theme.colors.typography.black08};
    }
  }
`;

export const ChipDeleteIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: 16px;

  border-radius: 32px;
  background-color: ${({ theme }) => theme.colors.bg.gray32};

  &:hover {
    background-color: ${({ theme }) => theme.colors.bg.white};
  }
`;
