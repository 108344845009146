import styled, { css, keyframes } from 'styled-components';
import { Popover } from '@mui/material';

import { IconButton } from '../../../../components';
import { getMediaFor } from '../../../theming';

const blinkAnimation = keyframes`
  0%, 100% { background-color: rgba(255, 255, 255, 0.1) }
  50% { background-color: rgba(255, 255, 255, 0.3) }
`;

export const NotificationButton = styled(IconButton)<{
  $isOpen: boolean;
  $isNewNotification: boolean;
}>`
  transition: all 0.3s ease-in;

  ${({ $isOpen }) =>
    $isOpen
      ? css`
          height: 40px;
          width: 40px;
          // border: 2px solid ${({ theme }) => theme.colors.borders.white};
          color: ${({ theme }) => theme.colors.typography.white};
        `
      : css`
          &:hover {
            color: ${({ theme }) => theme.colors.typography.white};
          }
        `};

  ${({ $isNewNotification }) =>
    $isNewNotification
      ? css`
          animation: ${blinkAnimation} 2s infinite;
          color: ${({ theme }) => theme.colors.secondary};
        `
      : css`
          animation: none;
          border: 2px solid ${({ theme }) => theme.colors.borders.white};
          color: ${({ theme }) => theme.colors.typography.white};
        `};
`;

export const NotificationsPopover = styled(Popover)`
  overflow: hidden;

  .MuiPopover-paper {
    margin-top: 8px;
    border-radius: 16px;
    background-color: ${({ theme }) => theme.colors.bg.notifications};
    box-shadow: ${({ theme }) => theme.shadowsCustom.notifications};
  }
`;

export const NotificationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  // gap: 16px;
  overflow: auto;

  max-height: 500px;
  width: 342px;
  // padding: 24px;

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      padding: 16px;
    `)};
`;

export const NotificationTitleButtonContainer = styled.div`
  gap: 16px;
  display: flex;
  flex-direction: row;
`;

export const NotificationsTitle = styled.div`
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 133%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 24px;
  padding-bottom: 0;
  color: ${({ theme }) => theme.colors.typography.gray80};
`;

export const NoNotifications = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 24px;

  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.bg.white05};

  font-weight: 400;
  font-size: 1.6rem;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.typography.gray48};
`;

export const NotificationCard = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: ${({ theme }) => theme.colors.typography.gray80};

  padding-bottom: 16px;
  padding: 24px;
  &:not(:last-of-type) {
    border-bottom: 2px solid ${({ theme }) => theme.colors.borders.white10};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.bg.white05};
    cursor: pointer;
    color: #fff;
  }
`;
export const NotificationBody = styled.div`
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 120%;
  word-wrap: break-word;
`;
export const NotificationFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const NotificationDate = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  font-weight: 400;
  font-size: 1.6rem;
  line-height: 100%;
`;
export const NewNotificationBadge = styled.div`
  height: 8px;
  width: 8px;

  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.primary};
`;
export const NotificationDelete = styled(IconButton)`
  padding: 0;
  background: transparent;
  color: ${({ theme }) => theme.colors.typography.gray64};

  &:hover {
    background: transparent;
  }
`;
