import { ElementType, FC, ForwardedRef, forwardRef, TextareaHTMLAttributes } from 'react';
import { FieldErrors } from 'react-hook-form';

import * as S from './styled';
import { ErrorMessage, getErrorByFieldName } from '../../forms';

export type TextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  name: string;
  ref?: ForwardedRef<HTMLTextAreaElement>;
  errors?: FieldErrors;
  label?: string;
  nonEditable?: boolean;
  showErrorMessage?: boolean;
};

export const TextArea: FC<TextAreaProps> = forwardRef((props, ref) => {
  const {
    name,
    errors,
    label,
    nonEditable,
    showErrorMessage = true,
    className = '',
    disabled,
    ...rest
  } = props;

  const hasError = errors ? Boolean(getErrorByFieldName(errors, name)) : false;
  const Wrapper = label ? S.LabelWrapper : (S.TextAreaWrapper as ElementType);

  const getTexAreaClassName = () => {
    let textAreaClassName = className;
    if (nonEditable) {
      textAreaClassName += ' non-editable';
    }
    if (hasError) {
      textAreaClassName += ' error';
    }
    return textAreaClassName;
  };

  return (
    <Wrapper>
      {label && <S.Label>{label}</S.Label>}

      <S.TextArea
        {...rest}
        ref={ref}
        name={name}
        className={getTexAreaClassName()}
        readOnly={nonEditable}
        disabled={disabled}
      />

      {showErrorMessage && hasError && <ErrorMessage fieldName={name} errors={errors} />}
    </Wrapper>
  );
});
