export enum TransferType {
  EXCHANGE = 'EXCHANGE',
  SEPA = 'SEPA',
  INTERNAL = 'INTERNAL',
  FPS = 'FPS',
  SWIFT = 'SWIFT',
  OTHER = 'OTHER',
}

export enum TransferDirection {
  IN = 'IN',
  OUT = 'OUT',
}

export enum TransferPurpose {
  CHARGES = 'CHARGES',
  EXCHANGE = 'EXCHANGE',
  TRANSFER = 'TRANSFER',
  PAYMENT = 'PAYMENT',
  OTHER = 'OTHER',
  SERVICE_FEE = 'SERVICE_FEE',
  CANCEL = 'CANCEL',
  GATEWAY_MOVEMENT = 'GATEWAY_MOVEMENT',
}

export enum TransferState {
  PENDING = 'PENDING',
  AWAIT = 'AWAIT',
  PROCESSED = 'PROCESSED',
  CANCELED = 'CANCELED',
  PREPARED = 'PREPARED',
  PENDING_CONFIRMATION = 'PENDING_CONFIRMATION',
  IN_PROGRESS = 'IN_PROGRESS',
  EXECUTED = 'EXECUTED',
}
