import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { LineInfo } from '../../../../../components';
import { ProfileInfoBody, ProfileInfoContainer, ProfileInfoHeader } from '../../../components';
import { LegalEntity } from '../../../../../types';

type ContactInfoProps = {
  legalProfile: LegalEntity;
};

export const ContactInfo: FC<ContactInfoProps> = ({ legalProfile }) => {
  const { t } = useTranslation();

  return (
    <ProfileInfoContainer>
      <ProfileInfoHeader
        title={t('profile.legal.contact-info.header.title')}
        isActionButtonVisible={false}
      />

      <ProfileInfoBody>
        <LineInfo label={t('profile.legal.contact-info.body.phone')} value={legalProfile.phone} />

        <LineInfo label={t('profile.legal.contact-info.body.email')} value={legalProfile.email} />

        <LineInfo
          label={t('profile.legal.contact-info.body.website')}
          value={legalProfile.website}
        />
      </ProfileInfoBody>
    </ProfileInfoContainer>
  );
};
