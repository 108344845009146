import { FC } from 'react';
import { LoaderFunction, Outlet, redirect } from 'react-router-dom';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react';

import { authGuard, getRouterError, RouterPaths } from '../../router';
import { getItemFromStorage, removeItemFromStorage, setItemToStorage } from '../../utils';
import {
  businessFeatureEndpoints,
  GetProfilesResult,
  profileEndpoints,
  runEndpointInRouter,
} from '../../api';
import type { ProfileInfo } from '../../types';

export const rootLoader: LoaderFunction = async ({ request }) => {
  authGuard();

  try {
    // 1) get list of user profiles
    const { data: profileData } = await runEndpointInRouter(
      profileEndpoints,
      'getProfilesNoCache',
      undefined,
      true
    );
    const profiles = profileData as GetProfilesResult;
    const profilesCount = profiles?.length;

    // 1.1) if list is empty => redirect to Profile Create
    if (profilesCount === 0) {
      removeItemFromStorage('activeProfile');
      return redirect(RouterPaths.ProfileCreate);
    }

    // 1.2) if list have profiles => check localStorage
    const activeProfile = getItemFromStorage('activeProfile') as ProfileInfo;

    // 2) if LS have current profile => pick it

    // 2.1) if LS have not current profile and list = 1 => set as current and pick it
    if (!activeProfile && profilesCount === 1) {
      setItemToStorage('activeProfile', profiles[0]!);
    }
    // 2.2) if LS have not current profile and list > 1 => redirect to Profiles
    if (!activeProfile && profilesCount > 1) {
      return redirect(RouterPaths.Profiles);
    }
  } catch (e) {
    throw getRouterError(e as FetchBaseQueryError);
  }

  try {
    // 3) get ACL business feature list for this profile
    await runEndpointInRouter(businessFeatureEndpoints, 'getBusinessFeatures');

    // 4) redirect to Dashboard
    const isChangingProfile = new URLSearchParams(new URL(request.url).search).has('changeProfile');
    return redirect(
      isChangingProfile
        ? `${RouterPaths.DashboardLayout}?changeProfile=true`
        : RouterPaths.DashboardLayout
    );
  } catch (e) {
    throw getRouterError(e as FetchBaseQueryError);
  }
};

export const Root: FC = () => {
  return (
    <>
      <Outlet />
    </>
  );
};
