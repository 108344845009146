import { apiSlice } from '../../api';
import { ProfileType } from '../../../types';
import type {
  GetNaturalProfileDTO,
  GetNaturalProfileResult,
  SearchNaturalProfileDTO,
  SearchNaturalProfileResult,
  UpdateNaturalProfileDTO,
  UpdateNaturalProfileResult,
} from './types';

export const naturalEndpoints = apiSlice.injectEndpoints({
  endpoints: (build) => {
    return {
      getNaturalProfile: build.query<GetNaturalProfileResult, GetNaturalProfileDTO>({
        query: ({ profileUid }) => ({
          url: `/profiles-service/v1/naturals/${profileUid}`,
          method: 'GET',
        }),
        transformResponse: (response: GetNaturalProfileResult) => ({
          ...response,
          profileType: ProfileType.NATURAL,
        }),
        providesTags: ['NaturalProfile'],
      }),
      updateNaturalProfile: build.mutation<UpdateNaturalProfileResult, UpdateNaturalProfileDTO>({
        query: ({ profileUid, body }) => ({
          url: `/profiles-service/v1/naturals/${profileUid}`,
          method: 'PUT',
          body,
        }),
        async onQueryStarted({ profileUid }, { queryFulfilled, dispatch }) {
          try {
            await queryFulfilled;
            await dispatch(naturalEndpoints.endpoints.getNaturalProfile.initiate({ profileUid }));
          } catch {}
        },
        invalidatesTags: ['NaturalProfile'],
      }),
      searchNatural: build.mutation<SearchNaturalProfileResult, SearchNaturalProfileDTO>({
        query: ({ email }) => ({
          url: '/profiles-service/v1/profiles/search',
          method: 'POST',
          body: { email },
        }),
      }),
    };
  },
});

export const { useGetNaturalProfileQuery } = naturalEndpoints;
export const { useUpdateNaturalProfileMutation } = naturalEndpoints;
export const { useSearchNaturalMutation } = naturalEndpoints;

export type NaturalController = typeof naturalEndpoints;
