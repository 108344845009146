export enum Authorities {
  COUNTRY_VIEW = 'COUNTRY_VIEW',
  STORAGE_DOWNLOAD = 'STORAGE_DOWNLOAD',
  STORAGE_UPLOAD_TEMP = 'STORAGE_UPLOAD_TEMP',
  NOTIFICATIONS = 'NOTIFICATIONS',
  LEGAL_REGISTRATION = 'LEGAL_REGISTRATION',
  PROFILE_VIEW = 'PROFILE_VIEW',
  RECIPIENT_VIEW = 'RECIPIENT_VIEW',
  RECIPIENT_CREATION = 'RECIPIENT_CREATION',
  WALLET_VIEW = 'WALLET_VIEW',
  WALLET_CREATION = 'WALLET_CREATION',
  WALLET_BLOCK_AMOUNTS_VIEW = 'WALLET_BLOCK_AMOUNTS_VIEW',
  TRANSFER_CREATION = 'TRANSFER_CREATION',
  TRANSFER_EXECUTE = 'TRANSFER_EXECUTE',
  TRANSFER_CANCEL = 'TRANSFER_CANCEL',
  USER_COMMON = 'USER_COMMON',
  TICKET_ALL = 'TICKET_ALL',
  TICKET_CREATION = 'TICKET_CREATION',
  TICKET_EDIT = 'TICKET_EDIT',
  PROFILE_DOCUMENTS_VIEW = 'PROFILE_DOCUMENTS_VIEW',
  PROFILE_DOCUMENT_CREATION = 'PROFILE_DOCUMENT_CREATION',
  PROFILE_TRANSFERS_VIEW = 'PROFILE_TRANSFERS_VIEW',
  PROFILE_ADDRESSES_VIEW = 'PROFILE_ADDRESSES_VIEW',
  SELF_KYC = 'SELF_KYC',
  MP_WALLET_REFERENCE = 'MP_WALLET_REFERENCE',
  COMPANY_TYPES_VIEW = 'COMPANY_TYPES_VIEW',
  TRANSFER_DOCUMENT_VIEW = 'TRANSFER_DOCUMENT_VIEW',
  TRANSFER_DOCUMENT_EDIT = 'TRANSFER_DOCUMENT_EDIT',
  WALLET_DEPOSIT_METHODS_VIEW = 'WALLET_DEPOSIT_METHODS_VIEW',
  LEGAL_RELATED_PERSONS_VIEW = 'LEGAL_RELATED_PERSONS_VIEW',
  INDUSTRY_CODES_VIEW = 'INDUSTRY_CODES_VIEW',
  RELATED_PERSON_ROLE_MANAGEMENT = 'RELATED_PERSON_ROLE_MANAGEMENT',
  ISSUE_IBAN = 'ISSUE_IBAN',
}

export type AuthoritiesArray = `${Authorities}`[];
