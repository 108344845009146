import { FC } from 'react';
import { AvatarProps as MuiAvatarProps } from '@mui/material';

import * as S from './styled';
import { IconABF, IconFaceSmile } from '../../assets';
import type { AvatarSize } from './types';

type AvatarProps =
  | (MuiAvatarProps & { size: AvatarSize; src?: string })
  | (MuiAvatarProps & { size: AvatarSize; name?: string })
  | (MuiAvatarProps & { size: AvatarSize; abfContact?: boolean })
  | (MuiAvatarProps & { size: AvatarSize });

export const Avatar: FC<AvatarProps> = (props) => {
  const { size = 'medium', ...rest } = props;

  if ('src' in props) {
    return <S.Avatar {...rest} src={props.src} $size={size} />;
  }

  if ('name' in props) {
    return (
      <S.Avatar {...rest} $size={size}>
        <S.InitialsWrapper>{props.name}</S.InitialsWrapper>
      </S.Avatar>
    );
  }

  if ('abfContact' in props) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { abfContact, ...rest } = props;

    return (
      <S.Avatar {...rest} $size={size}>
        <S.ABFWrapper>
          <IconABF />
        </S.ABFWrapper>
      </S.Avatar>
    );
  }

  return (
    <S.Avatar {...rest} $size={size}>
      <S.DefaultWrapper>
        <IconFaceSmile />
      </S.DefaultWrapper>
    </S.Avatar>
  );
};
