import styled, { css } from 'styled-components';

import { IconButton } from '../IconButton';

const commonLineInfoStyles = css<{ $withoutBorder: boolean }>`
  display: flex;
  gap: 8px;

  width: 100%;
  padding: 7px 0 15px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.borders.white10};

  font-weight: 400;
  font-size: 1.6rem;
  line-height: 100%;

  ${({ $withoutBorder }) =>
    $withoutBorder &&
    css`
      padding: 8px 0;
      border-bottom: none;
    `};
`;

const commonLineInfoValueStyles = css`
  display: flex;
  align-items: center;
  gap: 8px;

  color: ${({ theme }) => theme.colors.typography.white};
  word-break: break-all;
`;

export const LineInfoBottom = styled.div<{ $withoutBorder: boolean }>`
  ${commonLineInfoStyles};
  flex-direction: column;
`;
export const LineInfoRight = styled.div<{ $withoutBorder: boolean }>`
  ${commonLineInfoStyles};
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

export const LineInfoLabel = styled.div`
  color: ${({ theme }) => theme.colors.typography.gray80};
  text-align: left;
  word-break: break-all;
`;

export const LineInfoBottomValue = styled.div`
  ${commonLineInfoValueStyles};
`;
export const LineInfoRightValue = styled.div`
  ${commonLineInfoValueStyles};
  justify-content: space-between;
`;

export const LineInfoCopyButton = styled(IconButton)`
  padding: 0;
  background: transparent;
  color: ${({ theme }) => theme.colors.typography.gray80};

  &:hover {
    background: transparent;
  }
`;
