import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from '../../styled';
import type { ExtendedRecipient } from '../../../../types';

type LocalProps = {
  recipient: ExtendedRecipient | undefined;
};

export const Local: FC<LocalProps> = ({ recipient }) => {
  const { t } = useTranslation();

  return (
    <S.RecipientInputContainer>
      <S.RecipientInput
        name="email"
        defaultValue={recipient?.email}
        label={t('recipient.fields.email')}
        nonEditable
      />
      <S.RecipientInput
        name="name"
        defaultValue={recipient?.name}
        label={t('recipient.fields.name')}
        nonEditable
      />
    </S.RecipientInputContainer>
  );
};
