import styled, { css } from 'styled-components';

import { Button, IconButton } from '../../../../components';
import { getMediaFor } from '../../../../layouts';

export const LegalDocumentVerificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const NeededDocuments = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const NeededDocumentsTitle = styled.div`
  max-width: 620px;

  font-size: 2.4rem;
  font-weight: 400;
  line-height: 130%;
  color: ${({ theme }) => theme.colors.typography.gray96};
`;

export const NeededDocumentsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 4px;

  padding: 0 20px;
`;

export const NeededDocumentsListItem = styled.li`
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.typography.white};
`;

export const UploadedDocuments = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const UploadedDocumentsTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  font-size: 1.8rem;
  font-weight: 400;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.gray96};

  svg {
    color: ${({ theme }) => theme.colors.primary};
  }
`;

export const UploadedDocumentsList = styled(NeededDocumentsList)``;

export const UploadedDocumentsListItem = styled.li`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.typography.white};
`;

export const LegalDocumentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const LegalDocumentsForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${({ theme }) =>
    getMediaFor(
      'tablet-max-width',
      theme
    )(css`
      gap: 16px;
    `)};

  .custom-file-input-container {
    display: flex;
    flex-direction: row;
    gap: 6px;

    height: 48px;
    min-height: auto;
    max-width: 420px;
    padding: 10px 20px;

    outline: none;
    border-radius: 8px;
    border: 2px solid ${({ theme }) => theme.colors.borders.white05};

    background-color: ${({ theme }) => theme.colors.bg.white05};
    color: ${({ theme }) => theme.colors.typography.gray56};
    white-space: pre;

    &.drag-over {
      outline: none;
      background-color: ${({ theme }) => theme.colors.bg.gray32};
    }

    &.upload-success {
      background-color: ${({ theme }) => theme.colors.bg.white05};
      border: 1px solid ${({ theme }) => theme.colors.primary};
    }

    &.upload-error {
      background-color: ${({ theme }) => theme.colors.bg.white05};
      border: 1px solid ${({ theme }) => theme.colors.error};
    }

    div {
      max-width: 100%;
      text-align: center;
    }

    ${({ theme }) =>
      getMediaFor(
        'mobile',
        theme
      )(css`
        max-width: 100%;
      `)};
  }
`;

export const LegalDocumentsFieldWrapper = styled.div<{
  $hasFieldError: boolean;
}>`
  display: flex;
  align-items: ${({ $hasFieldError }) => ($hasFieldError ? 'center' : 'flex-end')};
  flex-wrap: wrap;
  gap: 8px;

  label {
    width: 100%;
    max-width: 240px;

    ${({ theme }) =>
      getMediaFor(
        'mobile',
        theme
      )(css`
        max-width: 100%;
      `)};
  }
`;

export const LegalDocumentsFormActions = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ActionButton = styled(IconButton)`
  height: 48px;
  padding: 12px;

  &.add-btn {
    color: ${({ theme }) => theme.colors.primary};
  }

  &.remove-btn {
    color: ${({ theme }) => theme.colors.error};
  }
`;

export const UploadDocumentsButton = styled(Button)`
  margin-top: 16px;
  max-width: 320px;

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      width: 100%;
      max-width: 100%;
    `)};
`;
