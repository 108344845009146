import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { LoaderFunction, redirect, useNavigate } from 'react-router-dom';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';

import * as S from './styled';
import { Hint } from '../../components';
import { ProfileLayoutTitle } from '../../layouts';
import { AddressStep, InfoStep } from './components';

import {
  countryEndpoints,
  CreateNaturalProfileDTO,
  GetProfilesResult,
  profileEndpoints,
  runEndpointInRouter,
  useCreateNaturalProfileMutation,
  useGetActiveCountriesQuery,
  useGetCountriesQuery,
} from '../../api';
import {
  AsyncErrorMessage,
  getAsyncErrorMessage,
  nameAndSurnameRegExp,
  Shape,
  useFormSteps,
  yup,
} from '../../forms';
import { authGuard, getRouterError, RouterPaths, useAppLoading } from '../../router';
import { traitsSelector, useAppSelector } from '../../store';
import { ProfileInfo, ProfileType } from '../../types';

// appear here after redirect (cuz empty list of user profiles)
// 1) fill the form
// 2) create profile
// 3) redirect to Root

export type ProfileCreateFormFields = CreateNaturalProfileDTO;
type ProfileCreateFormSteps = 'info' | 'address';

export const profileCreateLoader: LoaderFunction = async () => {
  authGuard();

  // User can have only one natural profile
  // Code bellow check: does have user natural profile, and redirect, if it true
  try {
    const { data: profileData } = await runEndpointInRouter(
      profileEndpoints,
      'getProfiles',
      undefined,
      true
    );

    const profiles = profileData as GetProfilesResult;
    const hasNatural = profiles.some(
      (profile: ProfileInfo) => profile.profileType === ProfileType.NATURAL
    );
    if (hasNatural) return redirect(RouterPaths.DashboardLayout);

    await runEndpointInRouter(countryEndpoints, 'getCountries');
    await runEndpointInRouter(countryEndpoints, 'getActiveCountries');
  } catch (e) {
    throw getRouterError(e as FetchBaseQueryError);
  }

  return null;
};

const profileCreateSchema: Record<ProfileCreateFormSteps, any> = {
  info: yup.object().shape<Shape<ProfileCreateFormFields>>({
    firstNameEn: yup.string().matches(nameAndSurnameRegExp).required(),
    lastNameEn: yup.string().matches(nameAndSurnameRegExp).required(),
    citizenship: yup.string().required(),
    placeOfBirth: yup.string().required(),
    dateOfBirth: yup
      .date()
      .min('1900-01-01 00:00:00.000')
      .max(dayjs().subtract(18, 'year'))
      .required(),
  }),
  address: yup.object().shape<Shape<ProfileCreateFormFields>>({
    address: yup.object({
      country: yup.string().required(),
      state: yup.string().max(128),
      city: yup.string().required(),
      street: yup.string().required(),
      buildingNumber: yup.string().required(),
      flatNumber: yup.string().max(10),
      zipCode: yup.string().required(),
    }),
  }),
};

export const ProfileCreate: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAppLoading = useAppLoading();

  const traits = useAppSelector(traitsSelector);

  const { data: allCountries } = useGetCountriesQuery();
  const { data: activeCountries } = useGetActiveCountriesQuery();
  const [createNaturalProfile, { isLoading: isCreateNaturalLoading }] =
    useCreateNaturalProfileMutation();

  // form steps data
  const stepsOrder: ProfileCreateFormSteps[] = ['info', 'address'];
  const {
    activeStep: _activeStep,
    isFirstStep,
    isLastStep,
    getNextStep,
    getPrevStep,
  } = useFormSteps(stepsOrder);
  const activeStep = _activeStep as ProfileCreateFormSteps;

  // form data
  const defaultValues = {
    firstNameEn: traits?.first_name,
    lastNameEn: traits?.last_name,
    dateOfBirth: '',
  };
  const formMethods = useForm<ProfileCreateFormFields>({
    resolver: yupResolver(profileCreateSchema[activeStep]),
    defaultValues,
    shouldUnregister: false,
    mode: 'onChange',
  });
  const {
    setError,
    handleSubmit,
    trigger,
    formState: { errors },
  } = formMethods;

  const removeEmptyFields = <T extends object>(obj: T): T => {
    const result = {} as T;

    Object.keys(obj).forEach((key) => {
      if (obj[key as keyof T] !== '') {
        result[key as keyof T] = obj[key as keyof T];
      }
    });

    return result;
  };

  const createNatural: SubmitHandler<ProfileCreateFormFields> = async (values) => {
    const mappedValues: ProfileCreateFormFields = {
      ...values,
      dateOfBirth: dayjs(values.dateOfBirth).format('YYYY-MM-DD'),
      address: removeEmptyFields(values.address),
    };

    try {
      await createNaturalProfile(mappedValues).unwrap();
      navigate(RouterPaths.Root);
    } catch (e) {
      getAsyncErrorMessage(e, setError);
    }
  };

  const showNextStep = async () => {
    const isStepValid = await trigger();
    if (isStepValid) getNextStep();
  };

  const showPrevStep = () => {
    getPrevStep();
  };

  const stepsMap: Record<ProfileCreateFormSteps, ReturnType<FC>> = {
    info: <InfoStep activeCountries={activeCountries} allCountries={allCountries} />,
    address: <AddressStep activeCountries={activeCountries} />,
  };

  return (
    <S.ProfileCreateContainer>
      <Helmet title={t('pages.profile-create')} />
      <ProfileLayoutTitle text={t('profile-create.title')} />

      <FormProvider {...formMethods}>
        <S.ProfileCreateForm onSubmit={handleSubmit(createNatural)}>
          {activeStep === 'address' && (
            <Hint
              message={t('profile-create.form.address-step.hint')}
              variant="default"
              icon="warning"
            />
          )}
          {stepsMap[activeStep]}

          <AsyncErrorMessage errors={errors} />

          <S.ProfileCreateFormActions>
            <S.ProfileCreatePrevStepButton
              variant="contained"
              body={t('ui.forms.buttons.prev-step')}
              disabled={isFirstStep}
              onClick={() => showPrevStep()}
            />

            {!isLastStep && (
              <S.ProfileCreateNextStepButton
                body={t('ui.forms.buttons.next-step')}
                onClick={() => showNextStep()}
              />
            )}

            {isLastStep && (
              <S.CreateNaturalButton
                body={t('profile-create.create-natural-btn')}
                type="submit"
                loading={isAppLoading || isCreateNaturalLoading}
              />
            )}
          </S.ProfileCreateFormActions>
        </S.ProfileCreateForm>
      </FormProvider>
    </S.ProfileCreateContainer>
  );
};
