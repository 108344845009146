import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import * as S from './styled';
import { Input, Radio, RadioGroup, Table, TableCell, TableRow } from '../../../../components';

import { activeProfileUidSelector, useAppSelector } from '../../../../store';
import { AsyncErrorMessage, getAsyncErrorMessage, Shape, yup } from '../../../../forms';
import {
  useCreateLocalRecipientMutation,
  useSearchLegalMutation,
  useSearchNaturalMutation,
} from '../../../../api';
import { RecipientAccountType, RecipientType } from '../../../../types';

type AddLocalAccountFormProps = {
  onCancel: () => void;
};
type AddLocalAccountFormFields = {
  email: string;
  recipientType: string;
};

const addLocalAccountSchema = yup.object().shape<Shape<{ email: string; recipientType: string }>>({
  email: yup.string().required(),
  recipientType: yup.string().required(),
});

export const AddLocalAccountForm: FC<AddLocalAccountFormProps> = ({ onCancel }) => {
  const { t, i18n } = useTranslation();

  const profileUid = useAppSelector(activeProfileUidSelector);

  const [createLocalRecipient, { isLoading: isCreateLocalRecipientLoading }] =
    useCreateLocalRecipientMutation();
  const [getLegals, { data: legals, isLoading: isSearchLegalLoading }] = useSearchLegalMutation();
  const [getNatural, { data: natural, isLoading: isSearchNaturalLoading }] =
    useSearchNaturalMutation();

  const recipientTypeRadios: Radio[] = [
    {
      value: RecipientType.NATURAL,
      label: t(`common.recipient-type.${RecipientType.NATURAL}`),
    },
    {
      value: RecipientType.LEGAL,
      label: t(`common.recipient-type.${RecipientType.LEGAL}`),
    },
  ];

  const {
    control,
    register,
    setError,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<AddLocalAccountFormFields>({
    resolver: yupResolver(addLocalAccountSchema),
  });

  const recipientType = watch('recipientType');
  const email = watch('email');
  const closeForm = () => {
    onCancel();
  };

  const addLocalAccount: SubmitHandler<AddLocalAccountFormFields> = async (values) => {
    if (recipientType == RecipientType.LEGAL) {
      getLegals({ pattern: values.email });
    }
    if (recipientType == RecipientType.NATURAL) {
      getNatural({ email: values.email });
    }
  };

  const addAccount = async (localProfileUid: string) => {
    try {
      await createLocalRecipient({
        profileUid: profileUid,
        body: {
          recipientAccountType: RecipientAccountType.LOCAL,
          recipientProfileUid: localProfileUid,
        },
      })
        .unwrap()
        .then(() => closeForm());
    } catch (e: any) {
      getAsyncErrorMessage(e, setError);
    }
  };

  const tableRows = useMemo(() => {
    return recipientType === RecipientType.LEGAL
      ? legals?.map((legal, index) => {
          const { companyName, registrationNumber, profileUid } = legal;

          return (
            <>
              <TableRow key={index} onClick={() => addAccount(profileUid)}>
                <TableCell>
                  <S.FirstCell>
                    <S.FirstRow>{companyName}</S.FirstRow>
                    <S.SecondRow>{registrationNumber}</S.SecondRow>
                  </S.FirstCell>
                </TableCell>
              </TableRow>
            </>
          );
        })
      : natural
      ? [
          <>
            <TableRow key={natural.profileUid} onClick={() => addAccount(natural.profileUid)}>
              <TableCell>
                <S.FirstCell>
                  <S.FirstRow>{natural.name}</S.FirstRow>
                  <S.SecondRow>{email}</S.SecondRow>
                </S.FirstCell>
              </TableCell>
            </TableRow>
          </>,
        ]
      : undefined;
  }, [legals, natural, recipientType, i18n.resolvedLanguage]);

  return (
    <S.AddLocalAccountForm onSubmit={handleSubmit(addLocalAccount)}>
      <S.AddLocalAccountFormFields>
        <RadioGroup
          control={{ control, name: 'recipientType' }}
          errors={errors}
          radios={recipientTypeRadios}
          row
        />
        {(recipientType === RecipientType.LEGAL || recipientType === RecipientType.NATURAL) && (
          <>
            <Input
              {...register('email')}
              errors={errors}
              label={
                recipientType === RecipientType.NATURAL
                  ? t('recipients.header.actions.add-recipient.form.local.fields.email.label')
                  : t('recipients.header.actions.add-recipient.form.local.fields.companyName.label')
              }
            />

            <Table rows={tableRows} loading={isSearchNaturalLoading} />
          </>
        )}
        <AsyncErrorMessage errors={errors} />
      </S.AddLocalAccountFormFields>

      <S.AddLocalAccountFormActions>
        <S.CreateLocalAccountButton
          body={t('recipients.header.actions.add-recipient.form.local.create-recipient-btn')}
          type="submit"
          loading={isCreateLocalRecipientLoading}
        />
      </S.AddLocalAccountFormActions>
    </S.AddLocalAccountForm>
  );
};
