import { createSlice } from '@reduxjs/toolkit';

import { legalEndpoints, naturalEndpoints, sumsubEndpoints } from '../../api';
import { LegalEntity, NaturalEntity, ProfileType } from '../../types';

type UserState = {
  isProfileVerified: boolean | null;
  isAddressVerified: boolean | null;
  legalProfile: LegalEntity | null;
  naturalProfile: NaturalEntity | null;
  activeProfileUid: string;
  activeProfileType: ProfileType | null;
};

const initialState: UserState = {
  isProfileVerified: null,
  isAddressVerified: null,
  legalProfile: null,
  naturalProfile: null,
  activeProfileUid: '',
  activeProfileType: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetVerificationState: (state) => {
      state.isProfileVerified = null;
      state.isAddressVerified = null;
    },
  },
  extraReducers: (build) => {
    build.addMatcher(
      naturalEndpoints.endpoints.getNaturalProfile.matchFulfilled,
      (state, action) => {
        state.naturalProfile = action.payload;
        state.activeProfileUid = action.payload.profileUid;
        state.activeProfileType = action.payload.profileType;
      }
    );
    build.addMatcher(legalEndpoints.endpoints.getLegalProfile.matchFulfilled, (state, action) => {
      state.legalProfile = action.payload;
      state.activeProfileUid = action.payload.profileUid;
      state.activeProfileType = action.payload.profileType;
    });
    build.addMatcher(sumsubEndpoints.endpoints.getSumsubToken.matchFulfilled, (state, action) => {
      state.isProfileVerified = action.payload.isVerified;
      state.isAddressVerified = action.payload.isAddressVerified;
    });
  },
});

export const userActions = userSlice.actions;
export const userReducer = userSlice.reducer;
