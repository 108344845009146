import styled, { css } from 'styled-components';

import { Button, FilterButton } from '../../components';
import { getMediaFor } from '../../layouts';

export const TicketsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  height: 100%;
  padding: 0 40px 40px;

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      padding: 0 16px 40px;
    `)};
`;

export const TicketsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  width: 100%;
`;

export const TicketsActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

export const TicketsActionButtons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const AddTicketButton = styled(Button)`
  min-width: 112px;
`;

export const TicketFilterButton = styled(FilterButton)`
  min-width: 112px;
`;

export const TicketsTableContainer = styled.div`
  flex-grow: 1;
  padding-right: 16px;
  overflow: auto;

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      padding-right: 0;
    `)};
`;

export const MobileCellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (max-width: 409px) {
    max-width: 290px;
  }

  .cell-header {
    font-weight: 700;
    font-size: 1.7rem;
  }
`;
