import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import dayjs from 'dayjs';

import * as S from './styled';
import { DatePicker, Hint, Input, Select, useSelectOptions } from '../../../../components';

import { getCurrentDateLocale, sortArrayByPropAsc } from '../../../../utils';
import { GetActiveCountriesResult, GetCountriesResult } from '../../../../api';
import type { ProfileCreateFormFields } from '../../ProfileCreate';

type InfoStepProps = {
  activeCountries: GetActiveCountriesResult | undefined;
  allCountries: GetCountriesResult | undefined;
};

export const InfoStep: FC<InfoStepProps> = ({ activeCountries, allCountries }) => {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.resolvedLanguage;

  const [currentLocale, setCurrentLocale] = useState(null);

  useEffect(() => {
    getCurrentDateLocale(currentLang).then((res) => setCurrentLocale(res));
  }, [currentLang]);

  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<ProfileCreateFormFields>();

  const activeCountriesOptions = useSelectOptions(
    sortArrayByPropAsc(allCountries, 'name'),
    'alpha3Code',
    'name'
  );

  const allCountriesOptions = useSelectOptions(
    sortArrayByPropAsc(allCountries, 'name'),
    'alpha2Code',
    'name'
  );

  const dateOfBirthPlaceholder =
    currentLang === 'en'
      ? 'MM/DD/YYYY'.toLowerCase()
      : // @ts-ignore
        currentLocale?.formats?.L?.toLowerCase() ?? '';

  return (
    <>
      <S.InfoStepFieldWrapper>
        <Input
          {...register('firstNameEn')}
          errors={errors}
          label={t('profile-create.form.info-step.first-name.label')}
        />
        <Hint
          message={t('profile-create.form.info-step.first-name.hint')}
          variant="small"
          icon="none"
        />
      </S.InfoStepFieldWrapper>

      <S.InfoStepFieldWrapper>
        <Input
          {...register('lastNameEn')}
          errors={errors}
          label={t('profile-create.form.info-step.last-name.label')}
        />
        <Hint
          message={t('profile-create.form.info-step.last-name.hint')}
          variant="small"
          icon="none"
        />
      </S.InfoStepFieldWrapper>

      <Select
        control={{ control, name: 'citizenship' }}
        errors={errors}
        options={activeCountriesOptions}
        label={t('profile-create.form.info-step.citizenship.label')}
      />

      <Select
        control={{ control, name: 'placeOfBirth' }}
        errors={errors}
        options={allCountriesOptions}
        label={t('profile-create.form.info-step.place-of-birth.label')}
      />

      <S.InfoStepFieldWrapper>
        <DatePicker
          control={{ control, name: 'dateOfBirth' }}
          errors={errors}
          label={t('profile-create.form.info-step.date-of-birth.label')}
          placeholder={dateOfBirthPlaceholder}
          maxDate={dayjs().subtract(18, 'year')}
          disableFuture
        />
        <Hint
          message={t('profile-create.form.info-step.date-of-birth.hint')}
          variant="small"
          icon="none"
        />
      </S.InfoStepFieldWrapper>
    </>
  );
};
