import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import * as S from './styled';
import {
  ComingSoon,
  // Title
} from '../../components';

export const Cards: FC = () => {
  const { t } = useTranslation();

  return (
    <S.CardsContainer>
      <Helmet title={t('pages.cards')} />

      {/*<S.CardsHeader>*/}
      {/*  <Title text={t('cards.title')} />*/}
      {/*</S.CardsHeader>*/}

      <ComingSoon />
    </S.CardsContainer>
  );
};
