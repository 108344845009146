import { AnyAction, combineReducers, configureStore, Reducer } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import { apiSlice } from '../api';
import {
  authReducer,
  commonReducer,
  ticketsReducer,
  userReducer,
  transfersReducer,
  messageReducer,
} from './slices';
import { notificationReducer } from './slices/notification';
import { countryReducer } from './slices/country';
import { profilesReducer } from './slices/profiles';

const combinedReducer = combineReducers({
  auth: authReducer,
  common: commonReducer,
  notification: notificationReducer,
  tickets: ticketsReducer,
  transfers: transfersReducer,
  user: userReducer,
  message: messageReducer,
  country: countryReducer,
  profiles: profilesReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'auth/logout') {
    state = {} as RootState;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([apiSlice.middleware]),
  devTools: process.env.NODE_ENV !== 'production',
});

setupListeners(store.dispatch);

export type AppStore = typeof store;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof combinedReducer>;
