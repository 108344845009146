import styled, { css } from 'styled-components';

import { IconButton } from '../../components';
import { getMediaFor } from '../../layouts';

export const TicketContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  min-height: 100%;
  padding: 0 40px 40px;

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      padding: 0 16px 40px;
    `)};
`;

export const TicketHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  width: 100%;

  font-weight: 400;
  font-size: 3.2rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.white};
`;

export const TicketBody = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  width: 100%;
  flex-wrap: wrap;
`;

export const TicketInfoWrapper = styled.div`
  width: 432px;
`;

export const TicketChatWrapper = styled.div`
  width: 432px;
  //flex-grow: 1;
  //min-width: 432px;
  //max-width: 640px;
`;

export const TicketAttachmentsContainer = styled.div`
  margin-top: 16px;
  margin-bottom: 16px;
  gap: 12px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const AttachmentContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.bg.white10};
  width: fit-content;
  height: auto;
  max-height: 48px;
  padding: 12px 16px;
  display: flex;
  border-radius: 8px;
  gap: 12px;
  font-size: 16px;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.typography.white};
  align-items: center;

  &.show {
    max-height: none;
  }
  &:hover {
    cursor: pointer;
    background-color: ${({ theme }) => theme.colors.bg.white20};
  }
`;

export const AttachmentTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  &.show {
    flex-direction: column;
  }
`;

export const AttachmentTitle = styled.div`
  white-space: nowrap;
  line-height: 24px;
`;

export const AttachmentComment = styled.div`
  width: 0;
  height: 0;
  line-height: 0;
  overflow: hidden;
  transition: all 0.75s;

  &.show {
    height: 100%;
    line-height: 1.8rem;
    width: fit-content;
  }
`;

export const DownloadButton = styled(IconButton)`
  width: 40px;
`;
