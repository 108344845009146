import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from './store';
import { pickBy, identity } from 'lodash';

// auth
export const selectorAuthState = (state: RootState) => state.auth;
export const authStatusSelector = createSelector([selectorAuthState], (auth) => auth.authStatus);
export const traitsSelector = createSelector([selectorAuthState], (auth) => auth.traits);
export const authMethodsSelector = createSelector([selectorAuthState], (auth) => auth.authMethods);
export const logoutURLSelector = createSelector([selectorAuthState], (auth) => auth.logoutUrl);
export const businessFeaturesSelector = createSelector(
  [selectorAuthState],
  (auth) => auth.businessFeatures
);

// common
export const selectorCommonState = (state: RootState) => state.common;
export const bannerSelector = createSelector([selectorCommonState], (common) => common.banner);
export const snackbarSelector = createSelector([selectorCommonState], (common) => common.snackbar);

// tickets
export const selectorTicketsState = (state: RootState) => state.tickets;
export const ticketsFilterSelector = createSelector(
  [selectorTicketsState],
  (tickets) => tickets.filter
);
export const activeTicketsFiltersCountSelector = createSelector(
  [ticketsFilterSelector],
  (filter) => {
    return Object.values(filter).filter((f) => Array.isArray(f) && f.length).length;
  }
);

// message
export const selectorMessageState = (state: RootState) => state.message;
export const messageSelector = createSelector([selectorMessageState], (state) => state.message);
export const newMessageSelector = createSelector(
  [selectorMessageState],
  (state) => state.newMessage
);

// country
export const selectorCountryState = (state: RootState) => state.country;
export const countrySelector = createSelector([selectorCountryState], (state) => state.countries);

// country
export const selectorProfileState = (state: RootState) => state.profiles;
export const profileSelector = createSelector([selectorProfileState], (state) => state.profiles);

// transfers
export const selectorTransfersState = (state: RootState) => state.transfers;
export const transfersFilterSelector = createSelector(
  [selectorTransfersState],
  (transfers) => transfers.filter
);
export const activeTransfersFiltersCountSelector = createSelector(
  [transfersFilterSelector],
  (filter) => {
    return Object.values(filter).filter((f) => f || Array.isArray(f)).length;
  }
);

export const activeTransfersFiltersSelector = createSelector(
  [transfersFilterSelector],
  (filter) => {
    return pickBy(filter, identity);
  }
);

// user common
export const selectorUserState = (state: RootState) => state.user;
export const profileVerifyStatusSelector = createSelector(
  [selectorUserState],
  (user) => user.isProfileVerified
);
export const addressVerifyStatusSelector = createSelector(
  [selectorUserState],
  (user) => user.isAddressVerified
);
export const activeProfileUidSelector = createSelector(
  [selectorUserState],
  (user) => user.activeProfileUid
);
export const activeProfileTypeSelector = createSelector(
  [selectorUserState],
  (user) => user.activeProfileType
);

// user legal
export const legalProfileSelector = createSelector(
  [selectorUserState],
  (user) => user.legalProfile
);
export const companyNameSelector = createSelector(
  [selectorUserState],
  (user) => user.legalProfile?.companyName
);

// user natural
export const naturalProfileSelector = createSelector(
  [selectorUserState],
  (user) => user.naturalProfile
);
export const naturalProfileUidSelector = createSelector(
  [selectorUserState],
  (user) => user.naturalProfile?.profileUid
);
export const naturalProfileNameSelector = createSelector(
  [selectorUserState],
  (user) => user.naturalProfile?.firstNameEn
);
export const naturalProfileFullNameSelector = createSelector(
  [selectorUserState],
  (user) => `${user.naturalProfile?.firstNameEn} ${user.naturalProfile?.lastNameEn}`
);
