import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldErrors, FieldValues, UseFormSetError } from 'react-hook-form';

import * as S from './styled';
import { Hint } from '../../../components';
import type { StandardBackendError } from '../../../api';

export const asyncErrorName = 'root.asyncError';

type AsyncErrorMessageProps = {
  errors: FieldErrors;
};
/**
 * Component, which displays validation message from server.
 *
 * Perhaps after error is occurred, you need to run `clearErrors` method from `react-hook-form` form state
 * in your Component, for right errors displaying.
 *
 * errors - object with errors from `react-hook-form` state
 */
export const AsyncErrorMessage: FC<AsyncErrorMessageProps> = (props) => {
  const { errors } = props;
  const { t } = useTranslation();

  const error = errors?.root?.asyncError;
  const message = error?.message;

  return error ? (
    <S.AsyncErrorContainer>
      <Hint message={message || t('ui.errors.default-message')} variant="small" icon="warning" />
    </S.AsyncErrorContainer>
  ) : null;
};

/**
 * Helper function, which handle error from server after form submitting.
 * It passes error to form state, by `setError` method with `root.asyncError` name.
 *
 * @param error - error object
 * @param setError - method from `react-hook-form`
 */
export const getAsyncErrorMessage = <T extends FieldValues>(
  error: unknown,
  setError: UseFormSetError<T>
) => {
  const e = error as { status: number; data: StandardBackendError };
  setError(asyncErrorName, { message: e.data.message });
};
