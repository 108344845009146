import { FC, SVGProps } from 'react';

export const IconRightArrow: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="6 5 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_5137_963)">
        <path
          d="M15.8136 11.178C16.3876 11.5757 16.3876 12.4243 15.8136 12.822L11.0695 16.1088C10.4063 16.5683 9.5 16.0936 9.5 15.2868L9.5 8.71322C9.5 7.9064 10.4063 7.43175 11.0695 7.89123L15.8136 11.178Z"
          fill="#CCCCCC"
        />
      </g>
      <defs>
        <clipPath id="clip0_5137_963">
          <rect width="24" height="24" fill="white" transform="translate(6 6)" />
        </clipPath>
      </defs>
    </svg>
  );
};
