import { FC, ReactNode } from 'react';

import * as S from './styled';
import { IconCopy } from '../../assets';

type LineInfoAlign = 'bottom' | 'right';
type LineInfoProps = {
  label: string;
  value: ReactNode;
  onCopy?: () => void;
  align?: LineInfoAlign;
  withoutBorder?: boolean;
};

export const LineInfo: FC<LineInfoProps> = (props) => {
  const { label, value, onCopy, align = 'right', withoutBorder = false } = props;

  const alignMap: Record<LineInfoAlign, ReturnType<FC>> = {
    bottom: (
      <S.LineInfoBottom $withoutBorder={withoutBorder}>
        <S.LineInfoLabel>{label}</S.LineInfoLabel>
        <S.LineInfoBottomValue>
          {onCopy && (
            <S.LineInfoCopyButton
              //
              icon={<IconCopy />}
              onClick={() => onCopy()}
              disableRipple
            />
          )}
          {value}
        </S.LineInfoBottomValue>
      </S.LineInfoBottom>
    ),
    right: (
      <S.LineInfoRight $withoutBorder={withoutBorder}>
        <S.LineInfoLabel>{label}</S.LineInfoLabel>
        <S.LineInfoRightValue>
          {value}
          {onCopy && (
            <S.LineInfoCopyButton
              //
              icon={<IconCopy />}
              onClick={() => onCopy()}
              disableRipple
            />
          )}
        </S.LineInfoRightValue>
      </S.LineInfoRight>
    ),
  };

  return alignMap[align];
};
