import { FC, ReactNode } from 'react';

import * as S from './styled';

type DocumentButton = {
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  content?: string;
  disabled?: boolean;
  onClick: () => any;
};

// TODO: переделать с div на button (почему сразу не заюзать кнопку?!)

export const DocumentButton: FC<DocumentButton> = (props) => {
  const { startIcon, content, endIcon, disabled = false, onClick } = props;
  return (
    <S.LabelContainer onClick={onClick} $disabled={disabled}>
      {startIcon}
      {content}
      {endIcon}
    </S.LabelContainer>
  );
};
