import { FC } from 'react';
import { CircularProgress, CircularProgressProps as MuiCircularProgressProps } from '@mui/material';

import * as S from './styled';

type LoaderVariant = 'fullScreen' | 'centerOfContainer' | 'default';
type LoaderProps = {
  variant: LoaderVariant;
  circularProgressProps?: MuiCircularProgressProps;
  opaque?: boolean;
  containerClassName?: string;
};

export const Loader: FC<LoaderProps> = (props) => {
  const { variant, circularProgressProps, opaque = false, containerClassName } = props;

  const map: Record<LoaderVariant, ReturnType<FC>> = {
    fullScreen: (
      <S.FullScreen className={containerClassName} $opaque={opaque}>
        <CircularProgress color="secondary" size={46} thickness={2.2} {...circularProgressProps} />
      </S.FullScreen>
    ),
    centerOfContainer: (
      <S.CenterOfContainer className={containerClassName}>
        <CircularProgress color="secondary" size={46} thickness={2.2} {...circularProgressProps} />
      </S.CenterOfContainer>
    ),
    default: (
      <CircularProgress color="secondary" size={46} thickness={2.2} {...circularProgressProps} />
    ),
  };

  return map[variant];
};
