import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import * as S from './styled';
import { Button, InputFileUpload, Select, SelectOption } from '../../../../components';

import {
  activeProfileTypeSelector,
  activeProfileUidSelector,
  useAppSelector,
} from '../../../../store';
import { useCreateTicketMutation } from '../../../../api';
import { AsyncErrorMessage, getAsyncErrorMessage, Shape, yup } from '../../../../forms';
import { TicketType } from '../../../../types';

type AddTicketFormProps = { onCancel: () => void };
type AddTicketFormFields = {
  ticketType: `${TicketType}`;
  message: string;
  name?: string;
  tempPath?: string;
  comment?: string;
};

const addTicketSchema = yup.object().shape<Shape<AddTicketFormFields>>({
  ticketType: yup.string().required(),
  message: yup.string().required(),
  name: yup.string(),
  tempPath: yup.string(),
  comment: yup.string(),
});

// TODO: add attachments field, clarify types

export const AddTicketForm: FC<AddTicketFormProps> = ({ onCancel }) => {
  const { t } = useTranslation();

  const profileUid = useAppSelector(activeProfileUidSelector);
  const profileType = useAppSelector(activeProfileTypeSelector);

  const [createTicket, { isLoading: isCreateTicketLoading }] = useCreateTicketMutation();

  const {
    control,
    register,
    setError,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isDirty },
  } = useForm<AddTicketFormFields>({
    resolver: yupResolver(addTicketSchema),
  });

  const tempPath = watch('tempPath');

  const closeForm = () => {
    onCancel();
  };

  const addTicket: SubmitHandler<AddTicketFormFields> = async (values) => {
    if (!isDirty) {
      return;
    }

    try {
      await createTicket({
        profileUid: profileUid,
        profileType: profileType!,
        ticketType: values.ticketType,
        message: values.message,
        attachments: tempPath
          ? [
              {
                name: values.name ?? '',
                tempPath: values.tempPath ?? '',
                comment: values.comment ?? undefined,
              },
            ]
          : undefined,
      }).unwrap();
      closeForm();
    } catch (e) {
      getAsyncErrorMessage(e, setError);
    }
  };

  const ticketTypeOptions = Object.values(TicketType).map((type) => {
    return (
      <SelectOption key={type} value={type} name={t(`common.ticket-type.${type}`)}>
        {t(`common.ticket-type.${type}`)}
      </SelectOption>
    );
  });

  return (
    <S.AddTicketForm onSubmit={handleSubmit(addTicket)}>
      <S.AddTicketFormFields>
        <Select
          control={{ control, name: 'ticketType' }}
          errors={errors}
          options={ticketTypeOptions}
          label={t('tickets.actions.add-ticket.form.fields.ticket-type.label')}
        />

        <S.AddTicketFormTextArea
          {...register('message')}
          errors={errors}
          label={t('tickets.actions.add-ticket.form.fields.message.label')}
          placeholder={t('tickets.actions.add-ticket.form.fields.message.placeholder')}
        />

        <InputFileUpload
          dragAndDrop
          fileCallback={(res) => {
            setValue('name', res[0]!.fileName);
            setValue('tempPath', res[0]!.path);
          }}
        />
        {
          <S.Animated className={`${tempPath ? 'show' : null}`}>
            <S.AddTicketFormTextArea
              className="animated"
              {...register('comment')}
              errors={errors}
              label={t('tickets.actions.add-ticket.form.fields.comment.label')}
              placeholder={t('tickets.actions.add-ticket.form.fields.comment.placeholder')}
            />
          </S.Animated>
        }

        <AsyncErrorMessage errors={errors} />
      </S.AddTicketFormFields>

      <Button
        body={t('tickets.actions.add-ticket.form.create-ticket-btn')}
        type="submit"
        loading={isCreateTicketLoading}
      />
    </S.AddTicketForm>
  );
};
