import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styled';
import { ButtonProps, Modal, ModalProps } from '../../components';

type ModalConfirmationProps = Omit<ModalProps, 'body' | 'modalTitle' | 'onClose'> & {
  question: string;
  hintText?: ReactElement | string;
  confirmButtonProps: ButtonProps;
  onCancel: () => void;
  onConfirm: () => void;
};

export const ModalConfirmation: FC<ModalConfirmationProps> = (props) => {
  const {
    question,
    hintText = null,
    confirmButtonProps,
    onCancel,
    onConfirm,
    open,
    ...rest
  } = props;
  const { t } = useTranslation();

  const confirmationElement = (
    <S.ModalConfirmationContainer>
      {hintText && <S.ModalConfirmationBody>{hintText}</S.ModalConfirmationBody>}

      <S.ModalConfirmationActions>
        <S.ModalConfirmationCancelButton
          variant="contained"
          body={t('ui.confirm-modal.cancel-btn')}
          onClick={() => onCancel()}
        />
        <S.ModalConfirmationConfirmButton
          //
          {...confirmButtonProps}
          onClick={() => onConfirm()}
        />
      </S.ModalConfirmationActions>
    </S.ModalConfirmationContainer>
  );

  return (
    <Modal
      //
      {...rest}
      modalTitle={question}
      body={confirmationElement}
      open={open}
      hideCloseButton
    />
  );
};
