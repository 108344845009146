import { apiSlice } from '../../api';
import type { GetVerificationStatusDTO, GetVerificationStatusResult } from './types';

export const profileSurveyEndpoints = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getVerificationStatus: build.query<GetVerificationStatusResult, GetVerificationStatusDTO>({
      query: ({ profileUid }) => ({
        url: `/aml-service/v1/surveys/${profileUid}/verification-status`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useLazyGetVerificationStatusQuery, useGetVerificationStatusQuery } =
  profileSurveyEndpoints;

export type ProfileSurveyController = typeof profileSurveyEndpoints;
