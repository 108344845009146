import { FC, SVGProps } from 'react';

export const LogoWhiteMobile: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="122"
      height="40"
      viewBox="0 0 122 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_5739_16394)">
        <path
          d="M46.3091 18.7706H49.2567L43.3825 4.70593H41.235L35.1924 18.7706H38.1611L39.4033 15.8185H45.0669L46.3091 18.7706ZM40.2665 13.5834L42.2667 8.52259L44.2668 13.5834H40.2665Z"
          fill="white"
        />
        <path
          d="M50.8963 4.70593V18.7706H55.6125C56.8337 18.7706 58.118 18.5598 59.1707 17.632C60.1392 16.7674 60.3708 15.6077 60.3708 14.7853C60.3708 14.0473 60.2024 12.9508 59.3602 12.1495C59.0023 11.8121 58.4548 11.4114 57.5285 11.2638C57.9074 11.053 59.1075 10.3149 59.1075 8.5015C59.1075 7.32066 58.6233 6.22416 57.8022 5.59157C56.6652 4.70593 55.1493 4.70593 54.3703 4.70593H50.8963ZM53.6334 6.9411H54.4335C55.5493 6.9411 56.5389 7.42609 56.5389 8.71237C56.5389 10.5047 54.8335 10.5469 54.4124 10.5469H53.6334V6.9411ZM53.6334 12.6555H54.644C55.2335 12.6555 55.9283 12.6555 56.5178 12.9297C57.4021 13.3303 57.5495 14.0894 57.5495 14.5322C57.5495 15.0805 57.36 15.7553 56.6863 16.1559C56.0757 16.5144 55.3177 16.5355 54.8124 16.5355H53.6334V12.6555Z"
          fill="white"
        />
        <path
          d="M70.3571 4.70593H62.9039V18.7706H65.641V12.5712H70.0624V10.2095H65.641V7.06762H70.3571V4.70593Z"
          fill="white"
        />
        <path
          d="M36.7504 21.2295V35.2942H39.4875V30.0858H41.256C42.8772 30.0858 44.0352 29.6219 44.8142 28.8417C45.888 27.7663 45.9722 26.2481 45.9722 25.6998C45.9722 24.6877 45.6775 23.317 44.5405 22.3049C43.5089 21.3982 42.414 21.2295 40.835 21.2295H36.7504ZM39.4875 23.5912H40.4349C41.0876 23.5912 41.8035 23.6333 42.3719 24.034C42.772 24.3292 43.1509 24.8564 43.1509 25.6366C43.1509 26.2481 42.8983 26.8807 42.393 27.2813C41.8035 27.7241 41.1297 27.7241 40.6034 27.7241H39.4875V23.5912Z"
          fill="white"
        />
        <path
          d="M54.4027 27.3446C53.5395 25.995 52.1709 25.8685 51.4761 25.8685C48.7812 25.8685 46.9494 28.0193 46.9494 30.7184C46.9494 33.3964 48.8022 35.5894 51.6025 35.5894C52.2551 35.5894 53.4763 35.4418 54.4027 34.1133V35.2942H56.9713V26.1637H54.4027V27.3446ZM52.1288 28.1458C53.6026 28.1458 54.6553 29.2634 54.6553 30.7184C54.6553 32.1734 53.6026 33.312 52.1288 33.312C50.655 33.312 49.6023 32.1734 49.6023 30.7184C49.6023 29.2634 50.655 28.1458 52.1288 28.1458Z"
          fill="white"
        />
        <path
          d="M59.2307 40.123H62.1362L69.4842 26.1637H66.5787L64.0522 31.351L61.2309 26.1637H58.2412L62.5784 33.9235L59.2307 40.123Z"
          fill="white"
        />
        <path
          d="M70.7493 35.2942H73.3179V30.6341C73.3179 30.1491 73.339 29.411 73.6758 28.8628C73.9496 28.3778 74.3917 28.0615 75.0865 28.0615C75.3391 28.0615 75.8023 28.1037 76.1392 28.5254C76.3287 28.7574 76.5603 29.2423 76.5603 30.3178V35.2942H79.1289V30.6341C79.1289 30.1491 79.15 29.411 79.4869 28.8628C79.7606 28.3778 80.2027 28.0615 80.8975 28.0615C81.1502 28.0615 81.6133 28.1037 81.9502 28.5254C82.1397 28.7574 82.3713 29.2423 82.3713 30.3178V35.2942H84.9399V29.5586C84.9399 28.5465 84.8978 27.7663 84.3083 26.9861C83.9504 26.5222 83.2556 25.8685 81.8449 25.8685C80.2659 25.8685 79.3605 26.8174 78.9184 27.5343C78.6868 27.0704 77.9499 25.8685 76.0339 25.8685C75.4865 25.8685 74.2864 25.9739 73.3179 27.197V26.1637H70.7493V35.2942Z"
          fill="white"
        />
        <path
          d="M93.9731 32.3631C93.552 33.1012 92.8151 33.5651 91.8466 33.5651C90.857 33.5651 90.2886 33.0801 90.0149 32.7005C89.7201 32.2999 89.5727 31.7095 89.5727 31.1612H96.3943V30.9504C96.3943 29.9593 96.268 28.5254 95.2995 27.3656C94.6679 26.6065 93.5941 25.8685 91.7413 25.8685C90.6465 25.8685 89.3832 26.1005 88.3095 27.1548C87.6568 27.8085 86.9199 28.926 86.9199 30.7395C86.9199 32.1734 87.341 33.3542 88.3516 34.3031C89.2569 35.1677 90.3728 35.5894 91.8466 35.5894C95.0258 35.5894 96.0575 33.4175 96.3312 32.7427L93.9731 32.3631ZM89.6569 29.5165C89.8675 28.2935 90.857 27.6819 91.8045 27.6819C92.7519 27.6819 93.7625 28.2724 93.9731 29.5165H89.6569Z"
          fill="white"
        />
        <path
          d="M98.3832 35.2942H100.952V30.6341C100.952 30.1069 100.973 29.4532 101.352 28.8628C101.731 28.2935 102.257 28.0615 102.931 28.0615C103.184 28.0615 103.731 28.1037 104.131 28.5254C104.594 29.0104 104.615 29.8117 104.615 30.3178V35.2942H107.184V29.5586C107.184 28.673 107.1 27.7663 106.468 26.9861C105.752 26.1005 104.573 25.8685 103.773 25.8685C102.299 25.8685 101.436 26.6698 100.952 27.3446V26.1637H98.3832V35.2942Z"
          fill="white"
        />
        <path
          d="M113.967 28.3567V26.1637H112.199V23.0429H109.63V26.1637H108.619V28.3567H109.63V35.2942H112.199V28.3567H113.967Z"
          fill="white"
        />
        <path
          d="M121.537 26.8385C120.379 25.9529 119.305 25.8685 118.631 25.8685C117.894 25.8685 116.968 25.9529 116.252 26.5222C115.726 26.965 115.305 27.703 115.305 28.5887C115.305 29.3267 115.578 29.896 116 30.3388C116.547 30.9082 117.305 31.2034 117.958 31.4353L118.547 31.6462C118.884 31.7727 119.347 32.0047 119.347 32.574C119.347 33.2066 118.8 33.5651 118.084 33.5651C117.326 33.5651 116.568 33.1012 116.063 32.5108L114.715 34.0712C115.284 34.6827 116.358 35.5894 118.252 35.5894C119.368 35.5894 120.253 35.2942 120.989 34.6194C121.726 33.9446 122 33.1223 122 32.2999C122 31.7095 121.811 31.1612 121.537 30.7817C121.011 30.0647 119.937 29.7062 119.221 29.4743L118.61 29.2845C117.894 29.0526 117.789 28.6941 117.789 28.4621C117.789 28.0193 118.168 27.7241 118.779 27.7241C119.052 27.7241 119.663 27.7663 120.274 28.3356L121.537 26.8385Z"
          fill="white"
        />
        <path d="M16.4231 4.70593L4.69231 35.2941H0L11.7308 4.70593H16.4231Z" fill="white" />
        <path
          d="M21.1154 4.70593H25.8077L18.5933 23.5177H25.8077L24.0481 28.2206H16.7897L14.0769 35.2942H9.38461L21.1154 4.70593Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_5739_16394">
          <rect width="122" height="40" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
