import styled, { css } from 'styled-components';

import { getMediaFor } from '../../layouts';

export const ProfilesContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 420px;
  margin-bottom: 40px;
  padding: 40px;

  border-radius: 40px;
  background-color: ${({ theme }) => theme.colors.bg.profileLayoutContent};
  box-shadow: ${({ theme }) => theme.shadowsCustom.profileLayoutContent};

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      padding: 16px;
      border-radius: 16px;
    `)};
`;

export const ProfilesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  margin-top: 24px;
`;

export const KycSyncTitle = styled.div`
  font-weight: 400;
  font-size: 3.2rem;
  line-height: 100%;

  color: ${({ theme }) => theme.colors.typography.white};

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      font-size: 2.4rem;
      line-height: 133%;
    `)}
`;

export const KycSyncLayoutDescription = styled.div`
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 100%;
  margin-top: 24px;

  color: ${({ theme }) => theme.colors.typography.white};

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      font-size: 1.8rem;
      line-height: 133%;
    `)}
`;

export const KycSyncSubitle = styled.div`
  display: inline-block;
  margin-bottom: 4px;
  margin-top: 8px;

  font-weight: 400;
  font-size: 1.6rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.gray48};
`;
