import styled, { css } from 'styled-components';

import { getMediaFor } from '../../../../../theming';

export const WalletCardContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  min-width: 248px;
  cursor: pointer;

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      max-width: 248px;
      min-width: auto;
    `)};
`;

export const WalletLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  width: 56px;
  height: 56px;

  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.bg.white05};
`;

export const WalletLogo = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;

export const WalletCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  flex-shrink: 1;
`;

export const WalletName = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;

  font-weight: 400;
  font-size: 1.6rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.white};

  svg {
    flex-shrink: 0;
  }
`;

export const WalletAmount = styled.div`
  font-weight: 400;
  font-size: 1.7rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.white};
`;
