import { FC, SVGProps } from 'react';

export const IconCopy24: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_662_7054)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2 3C2 2.44772 2.44772 2 3 2H9C9.55229 2 10 2.44772 10 3V9C10 9.55229 9.55229 10 9 10H3C2.44772 10 2 9.55229 2 9V3ZM3 0C1.34315 0 0 1.34315 0 3V9C0 10.6569 1.34315 12 3 12H4V13C4 14.6569 5.34315 16 7 16H13C14.6569 16 16 14.6569 16 13V7C16 5.34315 14.6569 4 13 4H12V3C12 1.34315 10.6569 0 9 0H3ZM12 6V9C12 10.6569 10.6569 12 9 12H6V13C6 13.5523 6.44772 14 7 14H13C13.5523 14 14 13.5523 14 13V7C14 6.44772 13.5523 6 13 6H12Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_662_7054">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
