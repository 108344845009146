import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import { Helmet } from 'react-helmet';

import * as S from './styled';
import {
  Button,
  IconButton,
  LineInfo,
  Title,
  Select,
  Input,
  TextArea,
  SelectOption,
} from '../../../components';
import { IconArrowPrevious16, IconClose24, IconFall, IconRise } from '../../../assets';

import {
  useCreateTransferMutation,
  useGetWalletsQuery,
  useLazyGetRecipientByIdQuery,
  useLazyGetTransferFeeQuery,
  useLazyGetWalletTransferTypesQuery,
} from '../../../api';
import { useACL } from '../../../services';
import { RouterPaths } from '../../../router';
import { activeProfileUidSelector, useAppSelector } from '../../../store';
import { FullTransfer, TransferDirection, TransferType, Wallet } from '../../../types';
import { useDebounce } from '../../../utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { yup, Shape, referenceRegExp } from '../../../forms';
import {
  RHFErrorContainer,
  ValidationErrorMessage,
} from '../../../forms/components/ErrorMessage/styled';

type TransferProps = {
  transfer: FullTransfer;
  onBack: () => void;
};
type TransferInfoFields = {
  reference: string;
  transferType: `${TransferType}`;
  amount: number;
  currencyCode: string;
};
const transferInfoSchema = yup.object().shape<Shape<TransferInfoFields>>({
  reference: yup.string().matches(referenceRegExp).required().max(35),
  amount: yup.number().required(),
});
export const SendAgain: FC<TransferProps> = ({ transfer, onBack }) => {
  const { t } = useTranslation();
  const { transferId } = useParams<{ transferId: string }>();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [selectedWallet, setSelectedWallet] = useState<Wallet | undefined>();
  const [createTransfer, { data, error, isLoading: isRecipientFetching }] =
    useCreateTransferMutation();
  const [getRecipientById, { data: recipient, isFetching: isRecipientByIdFetching }] =
    useLazyGetRecipientByIdQuery();
  const {
    control,
    register,
    formState: { errors, isValid },
    watch,
    setValue,
    setError,
    clearErrors,
  } = useForm<TransferInfoFields>({
    resolver: yupResolver(transferInfoSchema),
  });

  const amount = watch('amount');
  const reference = watch('reference');
  const currencyCode = watch('currencyCode');
  const debouncedCurrencyTypeValue = useDebounce<string>(currencyCode, 500);
  const debouncedAmountValue = useDebounce<number>(amount, 500);

  const hasPermission_WALLET_VIEW = useACL(['WALLET_VIEW']);

  const profileUid = useAppSelector(activeProfileUidSelector);

  const [getFeeCalc, { data: fee, error: feeError }] = useLazyGetTransferFeeQuery();

  const [getTransferTypes, { data: transferTypes }] = useLazyGetWalletTransferTypesQuery();
  const { data: wallets } = useGetWalletsQuery(
    profileUid && hasPermission_WALLET_VIEW ? { profileUid } : skipToken
  );

  useEffect(() => {
    if (transfer?.walletUid && transfer?.recipientId) {
      setSelectedWallet(wallets?.find((wallet) => wallet.uid === transfer?.walletUid));
      getTransferTypes({ walletUid: transfer?.walletUid, recipientId: `${transfer?.recipientId}` });
      getRecipientById({ profileUid: profileUid, recipientId: `${transfer?.recipientId}` });
      setValue('amount', transfer?.amount);
      setValue('reference', transfer?.reference);
    }
  }, [transfer]);

  useEffect(() => {
    if (error) {
      setErrorMessage((error as any).data.message);
    }
  }, [error]);

  useEffect(() => {
    if (feeError) {
      setErrorMessage((feeError as any).data.message);
    }
  }, [feeError]);

  const walletName = useMemo(
    () => (
      <S.WalletNameValue>
        {transfer?.transferDirection && (
          <S.IconWrapper>
            {transfer.transferDirection === TransferDirection.OUT ? <IconFall /> : <IconRise />}
          </S.IconWrapper>
        )}
        <div>{selectedWallet?.name}</div>
      </S.WalletNameValue>
    ),
    [selectedWallet]
  );

  useEffect(() => {
    if (
      debouncedCurrencyTypeValue &&
      transfer?.transferType &&
      debouncedAmountValue &&
      transfer?.walletUid &&
      recipient
    )
      getFeeCalc({
        walletUid: transfer?.walletUid,
        body: {
          countryCode: recipient?.additionalInfo.country_code ?? null,
          amount: debouncedAmountValue,
          transferType: transfer?.transferType,
          toCurrencyCode: debouncedCurrencyTypeValue,
        },
      });
  }, [debouncedAmountValue, transfer?.transferType, debouncedCurrencyTypeValue, recipient]);

  useEffect(() => {
    if (reference?.length > 0 && !referenceRegExp.test(reference)) {
      setError('reference', {
        message: t('send.minAmount'),
      });
    } else {
      clearErrors('reference');
    }
  }, [reference]);

  useEffect(() => {
    if (amount > 0 && Number(amount) > Number(selectedWallet?.availableAmount)) {
      setError('amount', {
        message: t('send.minAmount'),
      });
    } else {
      clearErrors('amount');
    }
  }, [amount]);

  const currencyOptions = useMemo(() => {
    if (transfer?.transferType && transferTypes && selectedWallet?.name) {
      setValue('currencyCode', transfer?.currencyCode);
      return transferTypes
        ?.find((it) => it.transferType === transfer?.transferType)
        ?.currencies.map((type) => {
          return (
            <SelectOption key={type} value={type} name={type}>
              {type}
            </SelectOption>
          );
        });
    }

    return;
  }, [transfer?.transferType, transferTypes, selectedWallet]);

  const sendTransfer = () => {
    if (
      transfer?.walletUid &&
      amount &&
      currencyCode &&
      transfer?.recipientId &&
      transfer?.transferType &&
      reference
    ) {
      createTransfer({
        walletUid: transfer?.walletUid,
        body: {
          amount: amount,
          currencyCode: currencyCode,
          recipientId: transfer?.recipientId,
          reference: reference,
          transferType: transfer?.transferType,
        },
      });
    }
  };

  useEffect(() => {
    if (data?.uid) {
      navigate(`${RouterPaths.Send}/${data?.uid}`);
    }
  }, [data]);

  const closeErrorMessage = () => {
    setErrorMessage('');
  };

  return (
    <>
      <S.TransferContainer>
        <Helmet title={t('pages.transfer', { value: transferId })} />

        <S.TransferHeader>
          <S.TitleWrapper>
            <IconButton icon={<IconArrowPrevious16 />} onClick={() => onBack()} />
            <Title text={t('transfer.send-title')} />
          </S.TitleWrapper>
        </S.TransferHeader>

        {/* {transfer?.state === TransferState.PENDING_CONFIRMATION && (
          <Hint variant="default" icon="warning" message={t('transfers.tooltip.title')} />
        )} */}

        <S.TransferBody>
          <S.TransferInfoContainer>
            {errorMessage && (
              <S.ErrorMessageContainer>
                <S.ErrorMessageTextContainer>{errorMessage}</S.ErrorMessageTextContainer>
                <S.ErrorMessageCloseContainer>
                  <S.ErrorMessageClose icon={<IconClose24 />} onClick={closeErrorMessage} />
                </S.ErrorMessageCloseContainer>
              </S.ErrorMessageContainer>
            )}
            {/* <TransferInfo transfer={transfer} wallets={wallets} /> */}

            <LineInfo label={t('transfer.info.wallet')} value={walletName} />
            <LineInfo label={t('transfer.info.walletId')} value={transfer?.walletId} />
            {transfer?.transferDirection && (
              <LineInfo
                label={t('transfer.info.transferDirection')}
                value={t(`common.transfer-direction.${transfer.transferDirection}`)}
              />
            )}
            <LineInfo label={t('transfer.info.recipient')} value={transfer?.payee} />
            {transfer?.transferType && (
              <LineInfo
                label={t('transfer.info.transfer-type')}
                value={t(`common.transfer-type.${transfer.transferType}`)}
              />
            )}
            {currencyOptions && (
              <S.AmountContainer>
                <Input
                  {...register('amount')}
                  type="number"
                  min={0}
                  step={0.01}
                  errors={errors}
                  label={t('send.amount')}
                  disableNumberArrows
                  onKeyDown={(evt) =>
                    ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
                  }
                />
                <Select
                  control={{ control, name: 'currencyCode' }}
                  errors={errors}
                  options={currencyOptions}
                  disabled
                  label={t('send.currencyCode')}
                />
              </S.AmountContainer>
            )}
            <LineInfo
              label={t('send.fee')}
              value={fee ? `${fee?.transactionFee} ${fee?.transactionCurrencyCode}` : ''}
            />
            <div>
              <TextArea {...register('reference')} errors={errors} label={t('send.reference')} />
              {!isValid && reference?.length > 35 && (
                <RHFErrorContainer>
                  <ValidationErrorMessage>{t('send.maxLengthReference')}</ValidationErrorMessage>
                </RHFErrorContainer>
              )}
            </div>
            <Button
              body={t('transfer.send-next-step')}
              type="submit"
              disabled={
                !isValid ||
                Number(amount) > Number(selectedWallet?.availableAmount) ||
                Number(amount) <= 0 ||
                !fee
              }
              onClick={sendTransfer}
              loading={isRecipientFetching}
            />
          </S.TransferInfoContainer>
        </S.TransferBody>
      </S.TransferContainer>
    </>
  );
};
