import styled from 'styled-components';
import { Accordion as MuiAccordion, AccordionDetails, AccordionSummary } from '@mui/material';

export const Accordion = styled(MuiAccordion)`
  background-color: transparent;

  &:not(:last-child) {
    border-bottom: 0;
  }

  &::before {
    display: none;
  }
`;

export const AccordionHeader = styled(AccordionSummary)`
  gap: 10px;
  min-height: 32px;

  margin-bottom: 16px;
  padding: 0;

  font-weight: 400;
  font-size: 2.4rem;
  line-height: 133%;
  color: ${({ theme }) => theme.colors.typography.white};

  .MuiAccordionSummary-content {
    margin: 0;
  }
`;

export const AccordionBody = styled(AccordionDetails)`
  padding: 0;

  font-size: 1.6rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.gray64};
  overflow: auto;
`;

export const AccordionIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 32px;
  height: 32px;

  border-radius: 24px;
  background-color: ${({ theme }) => theme.colors.bg.white10};
  color: ${({ theme }) => theme.colors.typography.gray88};
`;
