import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styled';
import { IconFilter, IconFilterActive } from '../../assets';
import type { ButtonProps } from '../Button';
import type { FilterButtonSize, FilterButtonVariant } from './types';

type FilterButtonProps = Omit<ButtonProps, 'variant'> & {
  variant?: FilterButtonVariant;
  size?: FilterButtonSize;
  isFilterActive: boolean;
  activeFiltersCount?: number;
  customContent?: string;
};

export const FilterButton: FC<FilterButtonProps> = (props) => {
  const {
    variant = 'default',
    size = 'medium',
    isFilterActive,
    activeFiltersCount,
    customContent,
    ...rest
  } = props;
  const { t } = useTranslation();

  const buttonName = isFilterActive
    ? activeFiltersCount
      ? t('ui.filter-btn.name-with-count', { value: activeFiltersCount })
      : t('ui.filter-btn.name')
    : t('ui.filter-btn.name');

  const commonButtonProps: ButtonProps & { $size: FilterButtonSize } = {
    ...rest,
    color: 'secondary',
    content: customContent ?? buttonName,
    startIcon: isFilterActive ? <IconFilterActive /> : <IconFilter />,
    $size: size,
  };

  const variantMap: Record<FilterButtonVariant, ReturnType<FC>> = {
    default: <S.FilterButton {...commonButtonProps} />,
    withBackground: (
      <S.FilterButton {...commonButtonProps} variant={isFilterActive ? 'outlined' : 'contained'} />
    ),
  };

  return variantMap[variant];
};
