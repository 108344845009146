import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import * as S from './styled';
import { IconFall, IconRise } from '../../../../assets';
import { LineInfo, Table, TableCell, TableRow } from '../../../../components';

import { UTCToLocal } from '../../../../utils';
import { RouterPaths } from '../../../../router';
import { FullTransfer, TransferDirection, TransferType, Wallet } from '../../../../types';

type TransferInfoProps = {
  transfer?: FullTransfer;
  wallets?: Wallet[];
};

export const TransferInfo: FC<TransferInfoProps> = ({ transfer, wallets }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const walletName = (
    <S.WalletNameValue>
      {transfer?.transferDirection && (
        <S.IconWrapper>
          {transfer.transferDirection === TransferDirection.OUT ? <IconFall /> : <IconRise />}
        </S.IconWrapper>
      )}
      <div>{wallets?.find((wallet) => wallet.uid === transfer?.walletUid)?.name}</div>
    </S.WalletNameValue>
  );

  const linkedTransfersTableRows = useMemo(() => {
    return transfer?.feeTransfers?.map((linkedTransfer) => {
      const { transferDirection, amount, currencyCode, transferUid, reference } = linkedTransfer;

      return (
        <TableRow
          key={transferUid}
          onClick={() => navigate(`${RouterPaths.Transfers}/${transferUid}`)}
        >
          <TableCell>
            {transferDirection === TransferDirection.OUT ? <IconFall /> : <IconRise />}
          </TableCell>
          <TableCell align="left">
            {amount &&
              (transferDirection === TransferDirection.OUT
                ? `-${amount} ${currencyCode}`
                : `+${amount} ${currencyCode}`)}
          </TableCell>
          <TableCell align="right">{reference}</TableCell>
        </TableRow>
      );
    });
  }, [transfer?.feeTransfers]);

  const linkedReferenceTableRows = useMemo(() => {
    return (
      <TableRow onClick={() => navigate(`${RouterPaths.Transfers}/${transfer?.linkedTransferUid}`)}>
        <TableCell align="right">{transfer?.reference}</TableCell>
      </TableRow>
    );
  }, [transfer?.linkedTransferUid]);

  return (
    <S.TransferInfoContainer>
      {walletName && <LineInfo label={t('transfer.info.wallet')} value={walletName} />}

      {transfer?.walletId && (
        <LineInfo label={t('transfer.info.walletId')} value={transfer.walletId} />
      )}

      {transfer?.id && <LineInfo label={t('transfer.info.transferUid')} value={transfer.id} />}

      <LineInfo
        label={t('transfer.info.created-at')}
        value={transfer?.createdAt ? UTCToLocal(transfer.createdAt) : '—'}
      />

      <LineInfo
        label={t('transfer.info.finished-at')}
        value={transfer?.finishedAt ? UTCToLocal(transfer.finishedAt) : '—'}
      />

      {transfer?.transferDirection && (
        <LineInfo
          label={t('transfer.info.transferDirection')}
          value={t(`common.transfer-direction.${transfer.transferDirection}`)}
        />
      )}

      {transfer?.transferDirection && (
        <LineInfo
          label={t('transfer.info.transaction-amount')}
          value={
            transfer.transferDirection === TransferDirection.OUT
              ? `- ${transfer.transactionAmount} ${transfer.transactionCurrencyCode}`
              : `+ ${transfer.transactionAmount} ${transfer.transactionCurrencyCode}`
          }
        />
      )}

      {transfer?.amount &&
        transfer?.currencyCode &&
        transfer?.transactionCurrencyCode != transfer?.currencyCode &&
        transfer?.transferType != TransferType.EXCHANGE && (
          <LineInfo
            label={t('transfer.info.transfer-amount')}
            value={
              transfer.transferDirection === TransferDirection.OUT
                ? `- ${transfer.amount} ${transfer.currencyCode}`
                : `+ ${transfer.amount} ${transfer.currencyCode}`
            }
          />
        )}

      {transfer?.transferType == TransferType.EXCHANGE && transfer?.exchangeRate && (
        <LineInfo label={t('transfer.info.exchange-rate')} value={`${transfer.exchangeRate}`} />
      )}

      {transfer?.transferType == TransferType.EXCHANGE &&
        transfer?.exchangeConvertedAmount &&
        transfer?.recipientCurrencyCode && (
          <LineInfo
            label={t('transfer.info.converted-amount')}
            value={`${transfer.exchangeConvertedAmount} ${transfer?.recipientCurrencyCode}`}
          />
        )}

      {/* {transfer?.currencyCode && (
        <LineInfo label={t('transfer.info.currency-code')} value={transfer.currencyCode} />
      )} */}

      {transfer?.state && (
        <LineInfo
          label={t('transfer.info.state')}
          value={t(`common.transfer-state.${transfer.state}`)}
        />
      )}

      {transfer?.transferDirection === TransferDirection.OUT && transfer?.payee && (
        <LineInfo label={t('transfer.info.recipient')} value={transfer.payee} />
      )}

      {transfer?.transferDirection === TransferDirection.IN && transfer?.payer && (
        <LineInfo label={t('transfer.info.payer')} value={transfer.payer} />
      )}

      {transfer?.transferType && (
        <LineInfo
          label={t('transfer.info.transfer-type')}
          value={t(`common.transfer-type.${transfer.transferType}`)}
        />
      )}

      {transfer?.transferPurpose && (
        <LineInfo
          label={t('transfer.info.transfer-purpose')}
          value={t(`common.transfer-purpose.${transfer.transferPurpose}`)}
        />
      )}

      {transfer?.reference && (
        <div>
          {transfer?.linkedTransferUid ? (
            <div>
              <LineInfo label={t('transfer.info.reference')} value={null} withoutBorder />
              <Table rows={linkedReferenceTableRows} />
            </div>
          ) : (
            <LineInfo label={t('transfer.info.reference')} value={transfer.reference} />
          )}
        </div>
      )}

      {transfer?.feeTransfers?.length !== 0 && (
        <div>
          <LineInfo label={t('transfer.info.linked-transfers')} value={null} withoutBorder />
          <Table rows={linkedTransfersTableRows} />
        </div>
      )}
    </S.TransferInfoContainer>
  );
};
