import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldErrors } from 'react-hook-form';
import { ErrorMessage as RHFErrorMessage } from '@hookform/error-message';
import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

import * as S from './styled';
import type { LangResourceType } from '../../../types/declarations/i18next';
import { UTCToLocal } from '../../../utils';

dayjs.extend(LocalizedFormat);

type UiFormTranslationKeys =
  `ui.forms.error-messages.${keyof LangResourceType['ui']['forms']['error-messages']}`;
type ErrorMessageProps = {
  fieldName: string;
  errors: FieldErrors | undefined;
};

export const ErrorMessage: FC<ErrorMessageProps> = (props) => {
  const { fieldName, errors } = props;
  const { t } = useTranslation();

  return (
    <RHFErrorMessage
      name={fieldName}
      errors={errors}
      render={({ message }) => {
        let messageByKey;
        if (typeof message === 'string') {
          messageByKey = t(message as UiFormTranslationKeys);
        } else {
          messageByKey = t(message.key as UiFormTranslationKeys, {
            value: message.isDate ? UTCToLocal(message.value, 'DD/MM/YYYY') : message.value,
          });
        }

        return (
          <S.RHFErrorContainer>
            <S.ValidationErrorMessage>{messageByKey}</S.ValidationErrorMessage>
          </S.RHFErrorContainer>
        );
      }}
    />
  );
};
