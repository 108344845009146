import { FC, ReactElement } from 'react';

import * as S from './styled';
import { Logo } from '../../../../components';
import { VerificationBar } from '../VerificationBar';
import { AddWalletButton, WalletsBar } from '../WalletsBar';

import { useACL } from '../../../../services';

type DashboardSidebarProps = {
  getWalletsBar: (walletBar: ReactElement, verificationBar: ReactElement) => ReactElement | null;
};

export const DashboardSidebar: FC<DashboardSidebarProps> = ({ getWalletsBar }) => {
  const hasPermission_WALLET_VIEW = useACL(['WALLET_VIEW']);

  const walletBar = (
    <>
      <S.WalletsWrapper>{hasPermission_WALLET_VIEW && <WalletsBar type="list" />}</S.WalletsWrapper>
      <AddWalletButton />
    </>
  );

  return (
    <S.DashboardSideBarContainer>
      <Logo type="white" />

      {getWalletsBar(walletBar, <VerificationBar type="sidebar" />)}
    </S.DashboardSideBarContainer>
  );
};
