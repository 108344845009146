import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';

import * as S from './styled';
import { InputFileUpload, Select, SelectOption, TextArea } from '../../../../../../components';

import { useGetLegalIndustryCodesQuery } from '../../../../../../api';
import { useACL } from '../../../../../../services';
import type { AddBusinessAccountFormFields } from '../../AddBusinessAccountForm';
import { DocumentType, RelationType } from '../../../../../../types';

const allowedLegalDocumentType: Partial<Record<DocumentType, DocumentType>> = {
  [DocumentType.SHAREHOLDER_EXTRACT]: DocumentType.SHAREHOLDER_EXTRACT,
  [DocumentType.POWER_OF_ATTORNEY]: DocumentType.POWER_OF_ATTORNEY,
  [DocumentType.ARTICLES_OF_ASSOCIATION]: DocumentType.ARTICLES_OF_ASSOCIATION,
  [DocumentType.PARTNERSHIP_AGREEMENT]: DocumentType.PARTNERSHIP_AGREEMENT,
  [DocumentType.CHARTER_BY_LAWS]: DocumentType.CHARTER_BY_LAWS,
  [DocumentType.CERTIFICATE_OF_INCORPORATION]: DocumentType.CERTIFICATE_OF_INCORPORATION,
  [DocumentType.MEMORANDUM_OF_ASSOCIATION]: DocumentType.MEMORANDUM_OF_ASSOCIATION,
  [DocumentType.ACT_OF_FORMATION]: DocumentType.ACT_OF_FORMATION,
  [DocumentType.PROOF_OF_DIRECTOR_APPOINTMENT]: DocumentType.PROOF_OF_DIRECTOR_APPOINTMENT,
  [DocumentType.OTHER]: DocumentType.OTHER,
} as const;

export const DetailsStep: FC = () => {
  const { t } = useTranslation();
  const hasPermission_INDUSTRY_CODES_VIEW = useACL(['INDUSTRY_CODES_VIEW']);

  const { data: legalIndustryCodes } = useGetLegalIndustryCodesQuery(
    hasPermission_INDUSTRY_CODES_VIEW ? undefined : skipToken
  );

  const {
    control,
    register,
    setValue,
    formState: { errors },
  } = useFormContext<AddBusinessAccountFormFields>();

  const relationTypeOptions = Object.values(RelationType).map((type) => {
    return (
      <SelectOption key={type} value={type} name={t(`common.relation-type.${type}`)}>
        {t(`common.relation-type.${type}`)}
      </SelectOption>
    );
  });

  const documentTypeOptions = Object.values(allowedLegalDocumentType).map((type) => {
    return (
      <SelectOption key={type} value={type} name={t(`common.document-type.${type}`)}>
        {t(`common.document-type.${type}`)}
      </SelectOption>
    );
  });

  const industryCodeOptions = useMemo(() => {
    if (!legalIndustryCodes) return [];

    return legalIndustryCodes?.map(({ id, name }) => {
      return (
        <SelectOption key={id} value={id} name={name}>
          {name}
        </SelectOption>
      );
    });
  }, [legalIndustryCodes]);

  return (
    <>
      <Select
        control={{ control, name: 'relationType' }}
        errors={errors}
        options={relationTypeOptions}
        label={t('dashboard-layout.create-legal-form.fields.relation-type.label')}
      />

      <S.DetailsStepFieldWrapper>
        <Select
          control={{ control, name: 'documentType' }}
          errors={errors}
          options={documentTypeOptions}
          label={t('dashboard-layout.create-legal-form.fields.document-type.label')}
        />
        <InputFileUpload
          inputProps={{
            ...register('documentTempPath'),
            errors,
            label: t('dashboard-layout.create-legal-form.fields.document-type.label'),
          }}
          dragAndDrop
          fileCallback={(res) => setValue('documentTempPath', res[0]!.path)}
          dragAndDropContainerClassName="custom-file-input-container"
        />
      </S.DetailsStepFieldWrapper>

      <Select
        control={{ control, name: 'industryCodeId' }}
        errors={errors}
        options={industryCodeOptions}
        label={t('dashboard-layout.create-legal-form.fields.industry-code.label')}
      />

      <TextArea
        {...register('description')}
        errors={errors}
        label={t('dashboard-layout.create-legal-form.fields.description.label')}
      />

      <TextArea
        {...register('purpose')}
        errors={errors}
        label={t('dashboard-layout.create-legal-form.fields.purpose.label')}
      />
    </>
  );
};
