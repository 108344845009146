import { FC } from 'react';

import * as S from './styled';
import { ImageOperationError, ImageOperationSuccess } from '../../assets';

type InfoSuccessProps = { variant: 'success' | 'error'; text: string };

export const InfoCard: FC<InfoSuccessProps> = ({ variant, text }) => {
  const image = variant === 'success' ? <ImageOperationSuccess /> : <ImageOperationError />;

  return (
    <S.InfoCardContainer>
      <S.InfoCardImageWrapper>{image}</S.InfoCardImageWrapper>

      <S.InfoCardText>{text}</S.InfoCardText>
    </S.InfoCardContainer>
  );
};
