import { Navigate, RouteObject } from 'react-router-dom';

import { RouterPaths } from './paths';
import { ErrorBoundary } from './components';
import {
  Dashboard,
  DashboardErrorBoundary,
  dashboardLoader,
  DashboardPage404,
  Profile as ProfileLayout,
  ProfileErrorBoundary,
  ProfilePage404,
} from '../layouts';
import {
  Cards,
  Exchange,
  exchangeLoader,
  History,
  KycSync,
  Profile,
  ProfileCreate,
  profileCreateLoader,
  profileLoader,
  Profiles,
  profilesLoader,
  Receive,
  Recipient,
  recipientLoader,
  Recipients,
  recipientsLoader,
  Root,
  rootLoader,
  Send,
  Team,
  teamLoader,
  Ticket,
  ticketLoader,
  Tickets,
  ticketsLoader,
  Transfer,
  TransferReview,
  Verification,
  verificationLoader,
} from '../pages';

export const routes: RouteObject[] = [
  {
    path: RouterPaths.Root,
    element: <Root />,
    errorElement: <ErrorBoundary />,
    children: [
      { index: true, loader: rootLoader },
      { path: RouterPaths.KycSync, element: <KycSync /> },
      {
        path: RouterPaths.ProfileLayout,
        element: <ProfileLayout />,
        children: [
          {
            errorElement: <ProfileErrorBoundary />,
            children: [
              { index: true, element: <Navigate to={RouterPaths.Profiles} replace /> },
              { path: '*', element: <ProfilePage404 /> },

              { path: RouterPaths.Profiles, element: <Profiles />, loader: profilesLoader },
              {
                path: RouterPaths.ProfileCreate,
                element: <ProfileCreate />,
                loader: profileCreateLoader,
              },
            ],
          },
        ],
      },
      {
        path: RouterPaths.DashboardLayout,
        element: <Dashboard />,
        loader: dashboardLoader,
        shouldRevalidate: (args) => {
          const defaultRevalidate = args.defaultShouldRevalidate;
          const isChangingProfile = new URLSearchParams(args.nextUrl.search).has('changeProfile');
          return isChangingProfile ? isChangingProfile : defaultRevalidate;
        },
        children: [
          {
            errorElement: <DashboardErrorBoundary />,
            children: [
              { index: true, element: <Navigate to={RouterPaths.Home} replace /> },
              { path: '*', element: <DashboardPage404 /> },
              { path: RouterPaths.Home, element: <History /> },
              {
                path: RouterPaths.Recipients,
                element: <Recipients />,
                loader: recipientsLoader,
                children: [
                  { path: RouterPaths.Recipient, element: <Recipient />, loader: recipientLoader },
                ],
              },
              { path: RouterPaths.Cards, element: <Cards /> },
              { path: RouterPaths.Send, element: <Send /> },
              { path: RouterPaths.SendTransfer, element: <TransferReview type="forSend" /> },
              { path: RouterPaths.Receive, element: <Receive /> },
              // { path: RouterPaths.History, element: <History /> },
              { path: RouterPaths.Transfer, element: <Transfer type="default" /> },
              { path: RouterPaths.Exchange, element: <Exchange />, loader: exchangeLoader },
              {
                path: RouterPaths.ExchangeTransfer,
                element: <TransferReview type="forExchange" />,
              },
              // { path: RouterPaths.Withdraw, element: <Withdraw /> },

              { path: RouterPaths.Profile, element: <Profile />, loader: profileLoader },
              { path: RouterPaths.Tickets, element: <Tickets />, loader: ticketsLoader },
              { path: RouterPaths.Ticket, element: <Ticket />, loader: ticketLoader },
              { path: RouterPaths.Team, element: <Team />, loader: teamLoader },
              {
                path: RouterPaths.Verification,
                element: <Verification />,
                loader: verificationLoader,
              },
            ],
          },
        ],
      },
    ],
  },
];
