import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { Input, Select, useSelectOptions } from '../../../../../../components';

import { sortArrayByPropAsc } from '../../../../../../utils';
import { useGetActiveCountriesQuery, useGetLegalCompanyTypesQuery } from '../../../../../../api';
import type { AddBusinessAccountFormFields } from '../../AddBusinessAccountForm';

export const GeneralStep: FC = () => {
  const { t } = useTranslation();

  const { data: activeCountries } = useGetActiveCountriesQuery();
  const { data: legalCompanyTypes } = useGetLegalCompanyTypesQuery();

  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<AddBusinessAccountFormFields>();

  const activeCountriesOptions = useSelectOptions(
    sortArrayByPropAsc(activeCountries, 'name'),
    'alpha3Code',
    'name'
  );

  const companyTypeOptions = useSelectOptions(legalCompanyTypes, 'id', 'name');

  return (
    <>
      <Input
        {...register('companyName')}
        errors={errors}
        label={t('dashboard-layout.create-legal-form.fields.company-name.label')}
        placeholder={t('dashboard-layout.create-legal-form.fields.company-name.placeholder')}
      />

      <Select
        control={{ control, name: 'companyTypeId' }}
        errors={errors}
        options={companyTypeOptions}
        label={t('dashboard-layout.create-legal-form.fields.company-type.label')}
      />

      <Select
        control={{ control, name: 'country' }}
        errors={errors}
        options={activeCountriesOptions}
        label={t('dashboard-layout.create-legal-form.fields.country.label')}
      />

      <Input
        {...register('registrationNumber')}
        errors={errors}
        label={t('dashboard-layout.create-legal-form.fields.registration-number.label')}
      />

      <Input
        {...register('phone')}
        errors={errors}
        label={t('dashboard-layout.create-legal-form.fields.phone.label')}
      />

      <Input
        {...register('email')}
        errors={errors}
        label={t('dashboard-layout.create-legal-form.fields.email.label')}
      />

      <Input
        {...register('website')}
        errors={errors}
        label={t('dashboard-layout.create-legal-form.fields.website.label')}
      />
    </>
  );
};
