import { apiSlice } from '../../api';
import type {
  CreateBankRecipientDTO,
  CreateBankRecipientResult,
  CreateCryptoRecipientDTO,
  CreateCryptoRecipientResult,
  CreateLocalRecipientDTO,
  CreateLocalRecipientResult,
  CreateOwnBankRecipientDTO,
  CreateOwnBankRecipientResult,
  CreateOwnCryptoRecipientDTO,
  CreateOwnCryptoRecipientResult,
  DeleteRecipientByIdDTO,
  DeleteRecipientByIdResult,
  GetRecipientByIdDTO,
  GetRecipientByIdResult,
  GetRecipientsByFilterDTO,
  GetRecipientsByFilterResult,
} from './types';

export const recipientEndpoints = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getRecipientsByFilter: build.query<GetRecipientsByFilterResult, GetRecipientsByFilterDTO>({
      query: ({ profileUid, body }) => ({
        url: `/wallets-service/v1/profiles/${profileUid}/recipients/filter`,
        method: 'POST',
        body,
      }),
      providesTags: ['Recipients'],
    }),
    createBankRecipient: build.mutation<CreateBankRecipientResult, CreateBankRecipientDTO>({
      query: ({ profileUid, body }) => ({
        url: `/wallets-service/v1/profiles/${profileUid}/recipients/bank`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Recipients'],
    }),
    createOwnBankRecipient: build.mutation<CreateOwnBankRecipientResult, CreateOwnBankRecipientDTO>(
      {
        query: ({ profileUid, body }) => ({
          url: `/wallets-service/v1/profiles/${profileUid}/recipients/bank/own`,
          method: 'POST',
          body,
        }),
        invalidatesTags: ['Recipients'],
      }
    ),
    createCryptoRecipient: build.mutation<CreateCryptoRecipientResult, CreateCryptoRecipientDTO>({
      query: ({ profileUid, body }) => ({
        url: `/wallets-service/v1/profiles/${profileUid}/recipients/crypto`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Recipients'],
    }),
    createOwnCryptoRecipient: build.mutation<
      CreateOwnCryptoRecipientResult,
      CreateOwnCryptoRecipientDTO
    >({
      query: ({ profileUid, body }) => ({
        url: `/wallets-service/v1/profiles/${profileUid}/recipients/crypto/own`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Recipients'],
    }),
    createLocalRecipient: build.mutation<CreateLocalRecipientResult, CreateLocalRecipientDTO>({
      query: ({ profileUid, body }) => ({
        url: `/wallets-service/v1/profiles/${profileUid}/recipients/local`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Recipients'],
    }),
    getRecipientById: build.query<GetRecipientByIdResult, GetRecipientByIdDTO>({
      query: ({ profileUid, recipientId }) => ({
        url: `/wallets-service/v1/profiles/${profileUid}/recipients/${recipientId}`,
        method: 'GET',
      }),
    }),
    deleteRecipientById: build.mutation<DeleteRecipientByIdResult, DeleteRecipientByIdDTO>({
      query: ({ profileUid, recipientId }) => ({
        url: `/wallets-service/v1/profiles/${profileUid}/recipients/${recipientId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Recipients'],
    }),
  }),
});

export const { useLazyGetRecipientsByFilterQuery } = recipientEndpoints;
export const { useCreateBankRecipientMutation } = recipientEndpoints;
export const { useCreateOwnBankRecipientMutation } = recipientEndpoints;
export const { useCreateCryptoRecipientMutation } = recipientEndpoints;
export const { useCreateOwnCryptoRecipientMutation } = recipientEndpoints;
export const { useCreateLocalRecipientMutation } = recipientEndpoints;
export const { useGetRecipientByIdQuery } = recipientEndpoints;
export const { useLazyGetRecipientByIdQuery } = recipientEndpoints;
export const { useDeleteRecipientByIdMutation } = recipientEndpoints;

export type RecipientController = typeof recipientEndpoints;
