import styled from 'styled-components';
import {
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableHead as MuiTableHead,
} from '@mui/material';

export const Table = styled(MuiTable)``;
export const TableHead = styled(MuiTableHead)``;
export const TableBody = styled(MuiTableBody)`
  .MuiTableRow-hover {
    cursor: pointer;
  }
`;
