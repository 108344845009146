import styled from 'styled-components';
import { MenuItem } from '@mui/material';

export const Option = styled(MenuItem)`
  &.MuiMenuItem-root {
    padding: 12px 16px;

    font-weight: 400;
    font-size: 1.6rem;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.typography.gray88};

    &:hover {
      background-color: rgb(0 0 0 / 10%);
    }
  }

  // option selected
  &.Mui-selected {
  }
`;
