import styled, { css } from 'styled-components';

import { getMediaFor } from '../../../theming';

export const ProfileLayoutTitle = styled.div`
  font-weight: 400;
  font-size: 3.2rem;
  line-height: 100%;

  color: ${({ theme }) => theme.colors.typography.white};

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      font-size: 2.4rem;
      line-height: 133%;
    `)}
`;
