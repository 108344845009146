import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '../Button';

import * as S from './styled';

type ErrorProps = {
  title?: string;
  message?: string;
  actionButtonText: string;
  actionButtonCallback: () => void;
};

export const Error: FC<ErrorProps> = (props) => {
  const { title, message, actionButtonText, actionButtonCallback } = props;
  const { t } = useTranslation();

  return (
    <S.ErrorContainer>
      <S.ErrorTitle>{title ?? t('ui.errors.component.title')}</S.ErrorTitle>

      <S.ErrorMessage>{message ?? t('ui.errors.component.message')}</S.ErrorMessage>

      <Button body={actionButtonText} onClick={actionButtonCallback} />
    </S.ErrorContainer>
  );
};
