import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { Input, Select, useSelectOptions } from '../../../../../../components';

import { sortArrayByPropAsc } from '../../../../../../utils';
import { GetActiveCountriesResult } from '../../../../../../api';
import type { AddBankAccountFormFields } from '../../../../Recipients';

type AddressStepProps = {
  activeCountries: GetActiveCountriesResult | undefined;
};

export const AddressStep: FC<AddressStepProps> = ({ activeCountries }) => {
  const { t } = useTranslation();

  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<AddBankAccountFormFields>();

  const activeCountriesOptions = useSelectOptions(
    sortArrayByPropAsc(activeCountries, 'name'),
    'alpha2Code',
    'name'
  );

  return (
    <>
      <Select
        control={{ control, name: 'recipientAddress.country' }}
        errors={errors}
        options={activeCountriesOptions}
        label={t('recipients.header.actions.add-recipient.form.bank.fields.country.label')}
      />
      <Input
        {...register('recipientAddress.state')}
        errors={errors}
        label={t('recipients.header.actions.add-recipient.form.bank.fields.state.label')}
      />
      <Input
        {...register('recipientAddress.city')}
        errors={errors}
        label={t('recipients.header.actions.add-recipient.form.bank.fields.city.label')}
      />
      <Input
        {...register('recipientAddress.street')}
        errors={errors}
        label={t('recipients.header.actions.add-recipient.form.bank.fields.street.label')}
      />
      <Input
        {...register('recipientAddress.buildingNumber')}
        errors={errors}
        label={t('recipients.header.actions.add-recipient.form.bank.fields.building-number.label')}
      />
      <Input
        {...register('recipientAddress.flatNumber')}
        errors={errors}
        label={t('recipients.header.actions.add-recipient.form.bank.fields.flat-number.label')}
      />
      <Input
        {...register('recipientAddress.zipCode')}
        errors={errors}
        label={t('recipients.header.actions.add-recipient.form.bank.fields.zip-code.label')}
      />
    </>
  );
};
