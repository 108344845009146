import { FC, useCallback, useState, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styled';
import { ButtonProps } from '../../../../components';
import { IconDownload24 } from '../../../../assets';
import { Wallet } from '../../../../types';
import { PopoverProps } from '@mui/material';
import { ReportContextMenu } from '../ReportContextMenu';
import { GetRecipientsByFilterResult } from '../../../../api';

interface DownloadButtonProps {
  onClick: () => void;
  wallets?: Wallet[];
  recipientList?: GetRecipientsByFilterResult;
  disabled: boolean;
}

export const DownloadReportButton: FC<DownloadButtonProps> = ({
  onClick,
  disabled,
  wallets,
  recipientList,
}) => {
  const { t } = useTranslation();
  const [walletMenuAnchor, setWalletMenuAnchor] = useState<HTMLButtonElement | null>(null);
  const isWalletMenuOpen = Boolean(walletMenuAnchor);
  const openWalletMenu = useCallback((e: MouseEvent<HTMLButtonElement>) => {
    setWalletMenuAnchor(e.currentTarget);
  }, []);
  const closeWalletMenu = () => {
    setWalletMenuAnchor(null);
  };
  const commonButtonProps: ButtonProps = {
    variant: 'text',
    startIcon: <IconDownload24 />,
    body: t('transfers.actions.download-report-context.title'),
    onClick,
    disabled,
  };

  return (
    <>
      <S.DownloadButton {...commonButtonProps} onClick={(e) => openWalletMenu(e)} />
      <ReportContextMenu
        anchorEl={walletMenuAnchor}
        open={isWalletMenuOpen}
        closeHandler={closeWalletMenu}
        wallets={wallets}
        recipientList={recipientList}
      />
    </>
  );
};
