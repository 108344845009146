export enum RouterPaths {
  Root = '/',

  // kyc sync
  KycSync = '/kyc-sync/:sharingLogUid',

  // profile layout pages
  ProfileLayout = '/profile',
  Profiles = '/profile/list',
  ProfileCreate = '/profile/create',

  // dashboard layout pages
  DashboardLayout = '/dashboard',
  Home = '/dashboard/home',
  Recipients = '/dashboard/recipients',
  Recipient = '/dashboard/recipients/:recipientId',
  Cards = '/dashboard/cards',
  Send = '/dashboard/send',
  SendTransfer = '/dashboard/send/:transferId',
  Receive = '/dashboard/receive',
  // History = '/dashboard/history',
  Transfers = '/dashboard/transfers',
  Transfer = '/dashboard/transfers/:transferId',
  Exchange = '/dashboard/exchange',
  ExchangeTransfer = '/dashboard/exchange/:transferId',
  Withdraw = '/dashboard/withdraw',

  Profile = '/dashboard/profile',
  Tickets = '/dashboard/tickets',
  Ticket = '/dashboard/tickets/:ticketId',
  Team = '/dashboard/team',
  Verification = '/dashboard/verification',

  // account pages
  AccountSettings = '/auth/settings',
  AccountSettingsTOTP = '/auth/settings#totp',

  // other pages
}
