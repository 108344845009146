import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import * as S from './styled';
import { Input, Select, SelectOption } from '../../../../components';

import {
  CreateOwnCryptoRecipientDTO,
  useCreateOwnCryptoRecipientMutation,
  useGetCoinsByFilterQuery,
} from '../../../../api';
import { activeProfileUidSelector, useAppSelector } from '../../../../store';
import { AsyncErrorMessage, getAsyncErrorMessage, Shape, yup } from '../../../../forms';
import { getWalletNameByCoin } from '../../../../utils';

type AddCryptoAccountFormProps = {
  onCancel: () => void;
};
type AddCryptoAccountFormFields = CreateOwnCryptoRecipientDTO['body'];

const addCryptoAccountSchema = yup.object().shape<Shape<AddCryptoAccountFormFields>>({
  currencyCode: yup.string().required(),
  address: yup.string().required(),
});

export const AddCryptoAccountForm: FC<AddCryptoAccountFormProps> = ({ onCancel }) => {
  const { t } = useTranslation();

  const profileUid = useAppSelector(activeProfileUidSelector);

  const { data: coins } = useGetCoinsByFilterQuery({ active: true });
  const [createOwnCryptoRecipient, { isLoading: isCreateOwnCryptoRecipientLoading }] =
    useCreateOwnCryptoRecipientMutation();

  const coinsWithoutFiat = coins?.filter((c) => !c.currencyCode);

  const {
    control,
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<AddCryptoAccountFormFields>({
    resolver: yupResolver(addCryptoAccountSchema),
  });

  const closeForm = () => {
    onCancel();
  };

  const addCryptoAccount: SubmitHandler<AddCryptoAccountFormFields> = async (values) => {
    try {
      await createOwnCryptoRecipient({ profileUid: profileUid, body: values }).unwrap();
      closeForm();
    } catch (e) {
      getAsyncErrorMessage(e, setError);
    }
  };

  const coinsOptions = useMemo(() => {
    if (!coinsWithoutFiat) return [];

    return coinsWithoutFiat.map((coin) => {
      const name = getWalletNameByCoin(coin);
      return (
        <SelectOption key={coin.id} value={coin.name} name={name}>
          {name}
        </SelectOption>
      );
    });
  }, [coinsWithoutFiat]);

  return (
    <S.AddCryptoAccountForm onSubmit={handleSubmit(addCryptoAccount)}>
      <S.AddCryptoAccountFormFields>
        <Select
          control={{ control, name: 'currencyCode' }}
          errors={errors}
          options={coinsOptions}
          label={t('recipients.header.actions.add-account.form.crypto.fields.currency.label')}
        />
        <Input
          {...register('address')}
          errors={errors}
          label={t('recipients.header.actions.add-account.form.crypto.fields.address.label')}
        />

        <AsyncErrorMessage errors={errors} />
      </S.AddCryptoAccountFormFields>

      <S.AddCryptoAccountFormActions>
        <S.CreateCryptoAccountButton
          body={t('recipients.header.actions.add-account.form.crypto.create-account-btn')}
          type="submit"
          loading={isCreateOwnCryptoRecipientLoading}
        />
      </S.AddCryptoAccountFormActions>
    </S.AddCryptoAccountForm>
  );
};
