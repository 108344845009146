import { FC, SVGProps } from 'react';

export const IconFall: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ rotate: '180deg' }}
      {...props}
    >
      <path
        d="M7.14251 11.5708C7.53091 12.2182 8.46909 12.2182 8.85749 11.5708L13.0913 4.5145C13.4912 3.84797 13.0111 3 12.2338 3H3.76619C2.9889 3 2.50878 3.84797 2.9087 4.5145L7.14251 11.5708Z"
        stroke="#F55D58"
        strokeWidth="2"
      />
    </svg>
  );
};
