import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import * as S from './styled';
import {
  DatePicker,
  Input,
  Radio,
  RadioGroup,
  Select,
  useSelectOptions,
} from '../../../../../../components';

import { sortArrayByPropAsc } from '../../../../../../utils';
import { GetActiveCountriesResult, GetLegalCompanyTypesResult } from '../../../../../../api';
import { ExtendedRecipient, RecipientType } from '../../../../../../types';
import type { AddBankAccountFormFields } from '../../../../Recipients';

type DetailsStepProps = {
  activeCountries: GetActiveCountriesResult | undefined;
  legalCompanyTypes: GetLegalCompanyTypesResult | undefined;
  copiedAccount?: ExtendedRecipient;
  isCopyBankRecipient?: boolean;
};

export const InfoStep: FC<DetailsStepProps> = ({
  activeCountries,
  legalCompanyTypes,
  isCopyBankRecipient,
  copiedAccount,
}) => {
  const { t } = useTranslation();

  const {
    control,
    register,
    unregister,
    setValue,
    watch,
    formState: { errors },
  } = useFormContext<AddBankAccountFormFields>();

  const recipientType = watch('recipientType');
  const hasRecipientTypeError = Boolean(errors.recipientType);

  useEffect(() => {
    if (!isCopyBankRecipient) {
      unregister('infoDto');
    }
  }, [recipientType]);

  useEffect(() => {
    if (
      isCopyBankRecipient &&
      copiedAccount?.recipientType &&
      copiedAccount?.recipientType !== RecipientType.OWN
    ) {
      setValue(
        'recipientType',
        copiedAccount?.recipientType === RecipientType.NATURAL
          ? RecipientType.NATURAL
          : RecipientType.LEGAL
      );
    }
  }, []);

  const recipientTypeRadios: Radio[] = [
    {
      value: RecipientType.NATURAL,
      label: t(`common.recipient-type.${RecipientType.NATURAL}`),
      disabled: isCopyBankRecipient,
    },
    {
      value: RecipientType.LEGAL,
      label: t(`common.recipient-type.${RecipientType.LEGAL}`),
      disabled: isCopyBankRecipient,
    },
  ];

  const companyTypeOptions = useSelectOptions(legalCompanyTypes, 'id', 'name');

  const activeCountriesOptions = useSelectOptions(
    sortArrayByPropAsc(activeCountries, 'name'),
    'alpha2Code',
    'name'
  );

  return (
    <>
      <Input
        {...register('email')}
        errors={errors}
        label={t('recipients.header.actions.add-recipient.form.bank.fields.email.label')}
      />
      <S.RecipientTypeRadioGroupWrapper $hasError={hasRecipientTypeError}>
        <S.RecipientTypeRadioTitle>
          {t('recipients.header.actions.add-recipient.form.bank.fields.recipient-type.label')}
        </S.RecipientTypeRadioTitle>

        <RadioGroup
          control={{ control, name: 'recipientType' }}
          errors={errors}
          radios={recipientTypeRadios}
          row
        />
      </S.RecipientTypeRadioGroupWrapper>

      {recipientType === RecipientType.NATURAL && (
        <>
          <Input
            {...register('infoDto.firstName')}
            errors={errors}
            label={t('recipients.header.actions.add-recipient.form.bank.fields.first-name.label')}
          />
          <Input
            {...register('infoDto.lastName')}
            errors={errors}
            label={t('recipients.header.actions.add-recipient.form.bank.fields.last-name.label')}
          />
          <Input
            {...register('infoDto.middleName')}
            errors={errors}
            label={t('recipients.header.actions.add-recipient.form.bank.fields.middle-name.label')}
          />
        </>
      )}

      {recipientType === RecipientType.LEGAL && (
        <>
          <Select
            control={{ control, name: 'infoDto.companyTypeId' }}
            errors={errors}
            options={companyTypeOptions}
            label={t('recipients.header.actions.add-recipient.form.bank.fields.company-type.label')}
          />
          <Input
            {...register('infoDto.name')}
            errors={errors}
            label={t('recipients.header.actions.add-recipient.form.bank.fields.company-name.label')}
          />
          <Select
            control={{ control, name: 'infoDto.incorporationCountryCode' }}
            errors={errors}
            options={activeCountriesOptions}
            label={t(
              'recipients.header.actions.add-recipient.form.bank.fields.incorporation-country-code.label'
            )}
          />
          {/* <DatePicker
            control={{ control, name: 'infoDto.incorporationDate', defaultValue: '' }}
            errors={errors}
            label={t(
              'recipients.header.actions.add-recipient.form.bank.fields.incorporation-date.label'
            )}
            disableFuture
          />
          <Input
            {...register('infoDto.registrationNumber')}
            errors={errors}
            label={t(
              'recipients.header.actions.add-recipient.form.bank.fields.registration-number.label'
            )}
          /> */}
        </>
      )}
    </>
  );
};
