import { FC, SVGProps } from 'react';

export const IconFilterActive: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 5H3C2.44772 5 2 5.44772 2 6C2 6.55228 2.44772 7 3 7H14.416C14.1484 6.38754 14 5.7111 14 5ZM6 11C5.44772 11 5 11.4477 5 12C5 12.5523 5.44772 13 6 13H18C18.5523 13 19 12.5523 19 12C19 11.4477 18.5523 11 18 11H6ZM9 17C8.44772 17 8 17.4477 8 18C8 18.5523 8.44772 19 9 19H15C15.5523 19 16 18.5523 16 18C16 17.4477 15.5523 17 15 17H9Z"
        fill="currentColor"
      />
      <circle cx="19" cy="5" r="3" fill="#8BD300" />
    </svg>
  );
};
