import styled, { css } from 'styled-components';

import { Button } from '../../../../../../components';
import { getMediaFor } from '../../../../../theming';

export const AddWalletForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      gap: 16px;
    `)};
`;

export const AddWalletFormFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const AddWalletFormActions = styled.div`
  display: flex;
  gap: 16px;
`;

export const CancelCreateWalletButton = styled(Button)`
  min-width: 106px;
`;

export const CreateWalletButton = styled(Button)`
  flex-grow: 1;
`;
