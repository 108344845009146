import { apiSlice } from '../../api';
import type {
  GetCoinsByFilterResult,
  GetCoinsByFilterDTO,
  GetCurrenciesResult,
  GetCurrenciesTransferInResult,
  GetCurrenciesTransferInDTO,
  GetCurrenciesTransferOutDTO,
  GetCurrenciesTransferOutResult,
} from './types';

export const coinEndpoints = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCoinsByFilter: build.query<GetCoinsByFilterResult, GetCoinsByFilterDTO>({
      query: (body) => ({
        url: '/wallets-service/v1/coins/filter',
        method: 'POST',
        body,
      }),
    }),
    // TODO: move to separate controller (currency), according Swagger
    getCurrencies: build.query<GetCurrenciesResult, void>({
      query: (body) => ({
        url: '/wallets-service/v1/currencies',
        method: 'GET',
        body,
      }),
    }),
    getCurrenciesTransferIn: build.query<GetCurrenciesTransferInResult, GetCurrenciesTransferInDTO>(
      {
        query: (data) => ({
          url: `/processing-service/v1/provider-config/transfer-in/currencies${
            data.country ? `?country=${data.country}` : ''
          }`,
          method: 'GET',
        }),
      }
    ),
    getCurrenciesTransferOut: build.query<
      GetCurrenciesTransferOutResult,
      GetCurrenciesTransferOutDTO
    >({
      query: (data) => ({
        url: `/processing-service/v1/provider-config/transfer-out/currencies${
          data.country ? `?country=${data.country}` : ''
        }`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetCoinsByFilterQuery } = coinEndpoints;
export const { useGetCurrenciesQuery } = coinEndpoints;
export const { useGetCurrenciesTransferOutQuery } = coinEndpoints;
export const { useGetCurrenciesTransferInQuery, useLazyGetCurrenciesTransferInQuery } =
  coinEndpoints;

export type CoinController = typeof coinEndpoints;
