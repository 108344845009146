import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { ProfileInfo } from '../../types';

type ProfileState = {
  profiles: ProfileInfo[];
};

const initialState: ProfileState = {
  profiles: [],
};

export const profilesSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setProfiles: (state, action: PayloadAction<ProfileInfo[]>) => {
      state.profiles = action.payload;
    },
    logout: (state) => {
      return state;
    },
  },
});

export const profilesActions = profilesSlice.actions;
export const profilesReducer = profilesSlice.reducer;
