import type { Coin } from '../../types';

export const getCoinCodeForFlags = (coinResponseDto: Coin) => {
  return coinResponseDto.currencyCode.toLowerCase();
};

export const getWalletNameByCoin = (coinResponseDto: Coin | undefined) => {
  if (!coinResponseDto) return '';

  return coinResponseDto.name;
};
