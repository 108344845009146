import styled, { css } from 'styled-components';
import { Snackbar as MuiSnackbar } from '@mui/material';

import { IconButton } from '../IconButton';
import { getMediaFor } from '../../layouts';

export const Snackbar = styled(MuiSnackbar)``;

const commonSnackbarContainerStyles = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  min-height: 50px;

  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.bg.dialog};
  box-shadow: ${({ theme }) => theme.shadowsCustom.dialog};
`;

export const SnackbarDefaultContainer = styled.div`
  ${commonSnackbarContainerStyles};

  width: 359px;
  padding: 8px 8px 8px 16px;

  font-weight: 400;
  font-size: 1.4rem;
  line-height: 133%;
  color: ${({ theme }) => theme.colors.typography.gray88};
`;

export const Snackbar2faContainer = styled.div`
  ${commonSnackbarContainerStyles};

  min-width: 359px;
  max-width: 526px;
  padding: 16px 8px 16px 16px;

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      width: 359px;
    `)};
`;

export const Snackbar2faBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Snackbar2faTitle = styled.div`
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.white};
`;

export const Snackbar2faText = styled.div`
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 133%;
  color: ${({ theme }) => theme.colors.typography.gray88};
`;

export const Snackbar2faLink = styled.a`
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.primary};

  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const SnackbarCloseButton = styled(IconButton)`
  align-self: flex-start;
  padding: 4px;
  background-color: transparent;

  &:hover {
    background: transparent;
  }
`;
