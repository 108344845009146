import styled, { css } from 'styled-components';

import { Button } from '../../components';
import { getMediaFor } from '../../layouts';

export const ProfileCreateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  width: 420px;
  margin-bottom: 40px;
  padding: 40px;

  border-radius: 40px;
  background-color: ${({ theme }) => theme.colors.bg.profileLayoutContent};
  box-shadow: ${({ theme }) => theme.shadowsCustom.profileLayoutContent};

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      padding: 16px;
      border-radius: 16px;
    `)};
`;

export const ProfileCreateForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 100%;
`;

export const ProfileCreateFormActions = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 8px;
`;

export const ProfileCreatePrevStepButton = styled(Button)`
  min-width: 90px;
`;

export const ProfileCreateNextStepButton = styled(Button)`
  flex-grow: 1;
`;

export const CreateNaturalButton = styled(ProfileCreateNextStepButton)``;
