import { FC, SVGProps } from 'react';

export const IconBanknote: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 10V14M19 10V14M1 7.2L1 16.8C1 17.9201 1 18.4802 1.21799 18.908C1.40973 19.2843 1.71569 19.5903 2.09202 19.782C2.51984 20 3.07989 20 4.2 20L19.8 20C20.9201 20 21.4802 20 21.908 19.782C22.2843 19.5903 22.5903 19.2843 22.782 18.908C23 18.4802 23 17.9201 23 16.8V7.2C23 6.0799 23 5.51984 22.782 5.09202C22.5903 4.7157 22.2843 4.40974 21.908 4.21799C21.4802 4 20.9201 4 19.8 4L4.2 4C3.0799 4 2.51984 4 2.09202 4.21799C1.7157 4.40973 1.40973 4.71569 1.21799 5.09202C1 5.51984 1 6.07989 1 7.2ZM14.5 12C14.5 13.3807 13.3807 14.5 12 14.5C10.6193 14.5 9.5 13.3807 9.5 12C9.5 10.6193 10.6193 9.5 12 9.5C13.3807 9.5 14.5 10.6193 14.5 12Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
