import styled, { css } from 'styled-components';

import { getMediaFor } from '../../../theming';
import { Button } from '../../../../components';

export const VerificationBarWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
`;

export const VerificationBarInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  font-weight: 400;
  font-size: 1.8rem;
  line-height: 100%;

  color: ${({ theme }) => theme.colors.typography.white50};
`;

export const VerificationBarActions = styled.div``;

export const VerificationBarIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const VerifyButton = styled(Button)`
  width: 100%;
`;

export const VerificationBarMobileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  padding: 24px 48px 24px 40px;

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      padding: 16px;
    `)};
`;
