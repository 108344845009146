import { FC, ReactElement, ReactNode } from 'react';
import { AccordionProps as MuiAccordionProps } from '@mui/material';

import * as S from './styled';
import { IconDrop } from '../../assets';

type AccordionProps = Omit<MuiAccordionProps, 'children'> & {
  header: ReactNode;
  body: ReactNode;
  expandIcon?: ReactElement;
};

export const Accordion: FC<AccordionProps> = (props) => {
  const { header, body, expandIcon = <IconDrop />, ...rest } = props;

  const icon = <S.AccordionIconWrapper>{expandIcon}</S.AccordionIconWrapper>;

  return (
    <S.Accordion
      defaultExpanded
      disableGutters
      square
      TransitionProps={{ unmountOnExit: true }}
      elevation={0}
      {...rest}
    >
      <S.AccordionHeader expandIcon={icon}>{header}</S.AccordionHeader>
      <S.AccordionBody>{body}</S.AccordionBody>
    </S.Accordion>
  );
};
