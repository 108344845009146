import { apiSlice } from '../../api';
import type { GetAuthoritiesResult } from './types';

export const authEndpoints = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getAuthorities: build.query<GetAuthoritiesResult, void>({
      query: () => ({
        url: '/auth-service/v1/auth/authorities',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetAuthoritiesQuery } = authEndpoints;

export type AuthController = typeof authEndpoints;
