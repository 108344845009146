import styled from 'styled-components';
import { Menu as MuiMenu, MenuItem as MuiMenuItem } from '@mui/material';

import { Button } from '../Button';

export const LanguageMenuButton = styled(Button)`
  padding: 8px 10px;
  color: ${({ theme }) => theme.colors.typography.white50};
  text-transform: uppercase;
`;

export const LanguageMenu = styled(MuiMenu)`
  .MuiMenu-paper {
    margin-top: 8px;
    border-radius: 16px;
    background-color: ${({ theme }) => theme.colors.bg.contextMenu};
    box-shadow: ${({ theme }) => theme.shadowsCustom.contextMenu};
  }

  .MuiMenu-list {
    padding: 8px 0;
  }
`;

export const LanguageMenuItem = styled(MuiMenuItem)`
  &.MuiMenuItem-root {
    width: 104px;
    padding: 0;
  }
`;

export const LanguageWrapper = styled.button`
  display: flex;
  align-items: center;
  gap: 8px;

  width: 100%;
  padding: 8px 16px;

  border: none;
  background-color: transparent;
  cursor: pointer;
`;

export const LanguageImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 24px;

  border-radius: 50%;
  font-size: 2.4rem;
  overflow: hidden;
`;

export const LanguageCode = styled.div`
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.gray80};
  text-transform: uppercase;
`;

export const LanguageList = styled.div`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;

  width: 344px;
  padding: 16px;
`;

export const LanguageListButton = styled(Button)`
  width: 72px;
  padding: 8px;

  border-radius: 8px;
  text-transform: uppercase;
`;

export const LanguageListImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: 16px;

  border-radius: 50%;
  font-size: 1.6rem;
  overflow: hidden;
`;
