import { FC, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import * as S from './styled';
import { IconDrop, IconUp } from '../../assets';
import { useBanner } from '../Banner';

import { RouterPaths } from '../../router';
import { AvailableLanguages, availableLanguages, getFlagFromCode } from '../../services';

// TODO: understand, is need to run change language endpoint

type LanguageMenuType = 'menu' | 'list';
type LanguageMenuProps = { type: LanguageMenuType };

export const LanguageMenu: FC<LanguageMenuProps> = ({ type }) => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { showBanner } = useBanner();

  const currentLanguage = i18n.resolvedLanguage;
  const isVerificationPage = location.pathname === RouterPaths.Verification;

  // state and callbacks for language context menu
  const [langMenuAnchor, setLangMenuAnchor] = useState<HTMLButtonElement | null>(null);
  const isLangMenuOpen = Boolean(langMenuAnchor);
  const openLangMenu = (e: MouseEvent<HTMLButtonElement>) => setLangMenuAnchor(e.currentTarget);
  const closeLangMenu = () => setLangMenuAnchor(null);

  const changeLanguage = (lang: AvailableLanguages) => {
    i18n
      .changeLanguage(lang)
      .then(() => {
        isVerificationPage && document.location.reload();
      })
      .catch(() => {
        showBanner('error', t('ui.banners.error.change-language'));
      });
  };

  const languagesForContext = availableLanguages.map(({ code }) => {
    return (
      <S.LanguageMenuItem key={code}>
        <S.LanguageWrapper onClick={() => changeLanguage(code)}>
          {/*TODO: remove or rework flags in language switcher*/}

          <S.LanguageImage>
            <span className={`fi fi-${getFlagFromCode(code)} fis`} />
          </S.LanguageImage>
          <S.LanguageCode>{code}</S.LanguageCode>
        </S.LanguageWrapper>
      </S.LanguageMenuItem>
    );
  });

  const languagesForButtons = [...availableLanguages].map(({ code }) => {
    const isCurrentLanguage = currentLanguage === code;
    return (
      <S.LanguageListButton
        key={code}
        color="secondary"
        variant={isCurrentLanguage ? 'outlined' : 'contained'}
        startIcon={
          <S.LanguageListImage>
            <span className={`fi fi-${getFlagFromCode(code)} fis`} />
          </S.LanguageListImage>
        }
        body={code}
        onClick={() => changeLanguage(code)}
      />
    );
  });

  const typeMap: Record<LanguageMenuType, ReturnType<FC>> = {
    menu: (
      <>
        <S.LanguageMenuButton
          variant="text"
          color="secondary"
          aria-controls={isLangMenuOpen ? 'lang-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={isLangMenuOpen ? 'true' : undefined}
          body={currentLanguage ?? ''}
          endIcon={isLangMenuOpen ? <IconUp /> : <IconDrop />}
          onClick={(e) => openLangMenu(e)}
        />

        <S.LanguageMenu
          id="lang-menu"
          open={isLangMenuOpen}
          anchorEl={langMenuAnchor}
          onClose={() => closeLangMenu()}
          onClick={() => closeLangMenu()}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          {languagesForContext}
        </S.LanguageMenu>
      </>
    ),
    list: <S.LanguageList>{languagesForButtons}</S.LanguageList>,
  };

  return typeMap[type];
};
