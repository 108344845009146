import { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styled';
import { Loader } from '../../../Loader';

type TableEmptyRowProps = {
  /**
   * Count of columns, for span all cells in one cell
   */
  colsCount: number;
  isLoading?: boolean;
  customEmptyElement?: string | ReactElement;
};

export const TableEmptyRow: FC<TableEmptyRowProps> = ({
  colsCount,
  isLoading,
  customEmptyElement,
}) => {
  const { t } = useTranslation();

  const emptyDataElement = customEmptyElement ?? t('ui.table.no-data');

  return (
    <S.TableRow>
      <S.TableCell colSpan={colsCount}>
        {isLoading ? <Loader variant="centerOfContainer" /> : emptyDataElement}
      </S.TableCell>
    </S.TableRow>
  );
};
