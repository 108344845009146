import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import * as S from './styled';
import { Error } from '../../../components';

import { RouterPaths } from '../../paths';
import { redirectToOryLogin } from '../../../services';
import { useAppRouteError } from '../../utils';

export const ErrorBoundary = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const error = useAppRouteError();
  let errorElement = (
    <Error
      message={t('ui.errors.default-message')}
      actionButtonText={t('ui.errors.default-action-text')}
      actionButtonCallback={() => navigate(RouterPaths.Root)}
    />
  );

  if ('message' in error) {
    return (
      <S.ErrorBoundaryContainer>
        <Error
          message={error.message}
          actionButtonText={t('ui.errors.default-action-text')}
          actionButtonCallback={() => navigate(RouterPaths.Root)}
        />
      </S.ErrorBoundaryContainer>
    );
  }

  if (error.status === 401) {
    errorElement = (
      <Error
        message={t(`ui.errors.error-boundary.${error.status}.message`)}
        actionButtonText={t(`ui.errors.error-boundary.${error.status}.action-text`)}
        actionButtonCallback={() => redirectToOryLogin()}
      />
    );
  }

  if (error.status === 403) {
    errorElement = (
      <Error
        message={t(`ui.errors.error-boundary.${error.status}.message`)}
        actionButtonText={t(`ui.errors.error-boundary.${error.status}.action-text.root`)}
        actionButtonCallback={() => redirectToOryLogin()}
      />
    );
  }

  if (error.status === 404 && error.internal) {
    errorElement = (
      <Error
        message={t(`ui.errors.error-boundary.${error.status}.message`)}
        actionButtonText={t(`ui.errors.error-boundary.${error.status}.action-text.root`)}
        actionButtonCallback={() => navigate(RouterPaths.Root)}
      />
    );
  }

  if (error.status === 503) {
    errorElement = (
      <Error
        message={t(`ui.errors.error-boundary.${error.status}.message`)}
        actionButtonText={t(`ui.errors.error-boundary.${error.status}.action-text`)}
        actionButtonCallback={() => window.location.reload()}
      />
    );
  }

  return <S.ErrorBoundaryContainer>{errorElement}</S.ErrorBoundaryContainer>;
};
