import styled, { css } from 'styled-components';

export const RecipientTypeRadioGroupWrapper = styled.div<{ $hasError: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;

  padding: 20px 0;
  border-bottom: 2px solid ${({ theme }) => theme.colors.borders.white10};

  font-size: 1.6rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.gray80};

  ${({ $hasError }) =>
    $hasError &&
    css`
      align-items: self-start;
    `};
`;

export const RecipientTypeRadioTitle = styled.div`
  flex-grow: 1;
`;
