import { Dispatch, FC, MouseEvent, SetStateAction, useEffect, useState } from 'react';
import defaultCoinLogo from 'cryptocurrency-icons/svg/icon/generic.svg';

import * as S from './styled';
import { IconDrop, IconUp } from '../../../../../../assets';

import { getCoinCodeForFlags, getWalletNameByCoin } from '../../../../../../utils';
import type { Wallet } from '../../../../../../types';

type WalletCardProps = {
  wallet: Wallet;
  openedWalletUid: string | undefined;
  onClick: (e: MouseEvent<HTMLDivElement>) => void;
};

/**
 * Function to dynamic load coin currency-logo from different fiat/crypto libraries
 */
export const loadCoinLogo = async (coinCode: string, setLogo: Dispatch<SetStateAction<string>>) => {
  let isFiatLogoImportFailed = false;
  let isCryptoLogoImportFailed = false;

  // at first try to fetch fiat logo
  try {
    const fiatLogo = await import(`currency-flags/dist/square-flags/${coinCode}.svg`);
    setLogo(fiatLogo.default);
  } catch {
    isFiatLogoImportFailed = true;
  }

  // then try to fetch crypto logo
  if (isFiatLogoImportFailed) {
    try {
      const cryptoLogo = await import(`cryptocurrency-icons/svg/icon/${coinCode}.svg`);
      setLogo(cryptoLogo.default);
    } catch {
      isCryptoLogoImportFailed = true;
    }
  }

  // setting default value after failed tryings to fetch logos
  if (isFiatLogoImportFailed && isCryptoLogoImportFailed) {
    setLogo(defaultCoinLogo);
  }
};

export const WalletCard: FC<WalletCardProps> = ({ wallet, openedWalletUid, onClick }) => {
  const { balance, coinResponseDto } = wallet;
  const [walletLogo, setWalletLogo] = useState<string>(defaultCoinLogo);

  const coinCode = getCoinCodeForFlags(coinResponseDto);
  // TODO: add ","-char for divide thousandths digits in balance amount
  const mappedBalance = balance;

  useEffect(() => {
    void loadCoinLogo(coinCode, setWalletLogo);
  }, []);

  const menuIconDrop = openedWalletUid === wallet.uid ? <IconUp /> : <IconDrop />;

  return (
    <S.WalletCardContainer onClick={(e) => onClick(e)}>
      <S.WalletLogoContainer>
        <S.WalletLogo src={walletLogo} />
      </S.WalletLogoContainer>

      <S.WalletCardContent>
        <S.WalletName>
          {getWalletNameByCoin(coinResponseDto)}
          {menuIconDrop}
        </S.WalletName>
        <S.WalletAmount>{mappedBalance}</S.WalletAmount>
      </S.WalletCardContent>
    </S.WalletCardContainer>
  );
};
