import styled, { css } from 'styled-components';

import { Button } from '../../../../components';
import { getMediaFor } from '../../../../layouts';

export const MemberInfoContainer = styled.div``;

export const MemberInfoForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      gap: 16px;
    `)};
`;

export const MemberInfoFormFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const MemberInfoFormActions = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const UpdateMemberRoleButton = styled(Button)`
  flex-grow: 1;
`;
