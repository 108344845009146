import styled from 'styled-components';
import { Link as RouterLink } from 'react-router-dom';

export const Link = styled(RouterLink)`
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 100%;

  color: ${({ theme }) => theme.colors.typography.gray88};
`;
