import styled from 'styled-components';

export const DashboardSideBarContainer = styled.div`
  grid-column: 1 / 2;
  grid-row: 1 / 3;
  display: flex;
  flex-direction: column;
  gap: 40px;

  height: 100%;
  width: ${({ theme }) => theme.dimensions.dashboardSidebar};
  overflow: hidden;

  padding: 32px;
`;

export const WalletsWrapper = styled.div`
  flex-grow: 1;
  overflow: auto;
`;
