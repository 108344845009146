import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

export const UTCToLocal = (utc: string, formatOutput = 'DD/MM/YYYY, HH:mm'): string => {
  return dayjs.utc(utc).local().format(formatOutput);
};

export const localToUTC = (local: string | Dayjs): string => {
  return dayjs(local).toISOString();
};
