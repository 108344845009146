import { apiSlice } from '../../api';
import { GetCurrenciesTransferInResult, GetCurrenciesTransferInDTO } from '../coin';
import type {
  GetActiveCountriesResult,
  GetCountriesResult,
  GetCountriesTransferInDTO,
  GetCountriesTransferInResult,
  GetCountriesTransferOutDTO,
  GetCountriesTransferOutResult,
} from './types';

export const countryEndpoints = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCountries: build.query<GetCountriesResult, void>({
      query: () => ({
        url: '/profiles-service/v1/countries',
        method: 'GET',
      }),
    }),
    getActiveCountries: build.query<GetActiveCountriesResult, void>({
      query: () => ({
        url: '/profiles-service/v1/countries/active',
        method: 'GET',
      }),
    }),
    getActiveCountriesTransferIn: build.query<
      GetCountriesTransferInResult,
      GetCountriesTransferInDTO
    >({
      query: (data) => ({
        url: `/processing-service/v1/provider-config/transfer-in/countries${
          data.currency ? `?currency=${data.currency}` : ''
        }`,
        method: 'GET',
      }),
    }),
    getActiveCountriesTransferOut: build.query<
      GetCountriesTransferOutResult,
      GetCountriesTransferOutDTO
    >({
      query: (data) => ({
        url: `/processing-service/v1/provider-config/transfer-out/countries${
          data.currency ? `?country=${data.currency}` : ''
        }`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetCountriesQuery } = countryEndpoints;
export const { useGetActiveCountriesQuery } = countryEndpoints;
export const { useGetActiveCountriesTransferOutQuery } = countryEndpoints;
export const { useGetActiveCountriesTransferInQuery, useLazyGetActiveCountriesTransferInQuery } =
  countryEndpoints;

export type CountryController = typeof countryEndpoints;
