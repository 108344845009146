import { useCallback, useState } from 'react';

export const useFormSteps = (stepsOrder: string[]) => {
  const startStep = stepsOrder[0];
  if (!startStep) {
    throw new Error('Use non-empty array in stepsOrder parameter.');
  }

  const [activeStep, setActiveStep] = useState(startStep);

  const currentStep = stepsOrder.indexOf(activeStep);
  const prevStep = stepsOrder[currentStep - 1];
  const nextStep = stepsOrder[currentStep + 1];

  const isFirstStep = currentStep === 0;
  const isLastStep = currentStep === stepsOrder.length - 1;

  const getNextStep = useCallback(() => {
    if (isLastStep) return;
    setActiveStep(nextStep!);
  }, [isLastStep, nextStep]);

  const getPrevStep = useCallback(() => {
    if (isFirstStep) return;
    setActiveStep(prevStep!);
  }, [isFirstStep, prevStep]);

  return {
    activeStep,
    isFirstStep,
    isLastStep,
    getNextStep,
    getPrevStep,
  };
};
