import { apiSlice } from '../../api';
import type {
  CreateWalletDTO,
  CreateWalletResult,
  GetAvailableCoinsDTO,
  GetAvailableCoinsResult,
  GetWalletDepositMethodsDTO,
  GetWalletDepositMethodsResult,
  GetWalletsDTO,
  GetWalletsResult,
  GetWalletTransferTypesDTO,
  GetWalletTransferTypesResult,
  PostWalletTransfersStatementDTO,
  PostWalletTransfersStatementResult,
} from './types';

export const profileWalletEndpoints = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getWallets: build.query<GetWalletsResult, GetWalletsDTO>({
      query: ({ profileUid }) => ({
        url: `/wallets-service/v1/profiles/${profileUid}/wallets/filter`,
        method: 'POST',
      }),
      providesTags: ['Wallets'],
    }),
    getWalletTransferTypes: build.query<GetWalletTransferTypesResult, GetWalletTransferTypesDTO>({
      query: ({ walletUid, recipientId }) => ({
        url: `/wallets-service/v1/wallets/${walletUid}/transfer-types?recipientId=${recipientId}`,
        method: 'GET',
      }),
    }),
    createWallet: build.mutation<CreateWalletResult, CreateWalletDTO>({
      query: ({ profileUid, body }) => ({
        url: `/wallets-service/v1/profiles/${profileUid}/wallets`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Wallets'],
    }),
    getAvailableCoins: build.query<GetAvailableCoinsResult, GetAvailableCoinsDTO>({
      query: ({ profileUid }) => ({
        url: `/wallets-service/v1/profiles/${profileUid}/wallets/available-coins`,
        method: 'GET',
      }),
    }),
    getWalletDepositMethods: build.query<GetWalletDepositMethodsResult, GetWalletDepositMethodsDTO>(
      {
        query: (data) => ({
          url: `/wallets-service/v1/wallets/${data.walletUid}/deposit-methods?countryCode=${data.countryCode}&currencyCode=${data.currencyCode}`,
          method: 'GET',
        }),
      }
    ),
    postTransferStatement: build.mutation<
      PostWalletTransfersStatementResult,
      PostWalletTransfersStatementDTO
    >({
      query: (data) => ({
        url: `/wallets-service/v1/profiles/${data.profileUid}/transfers/statement`,
        method: 'POST',
        body: data.body,
      }),
    }),
    postTransferStatementCsv: build.mutation<Blob, PostWalletTransfersStatementDTO>({
      query: (data) => ({
        url: `/wallets-service/v1/profiles/${data.profileUid}/transfers/statement/csv`,
        method: 'POST',
        body: data.body,
      }),
    }),
  }),
});

export const { useGetWalletsQuery, useLazyGetWalletsQuery } = profileWalletEndpoints;
export const { useLazyGetWalletTransferTypesQuery } = profileWalletEndpoints;
export const { useCreateWalletMutation } = profileWalletEndpoints;
export const { useGetAvailableCoinsQuery } = profileWalletEndpoints;
export const { useLazyGetWalletDepositMethodsQuery } = profileWalletEndpoints;
export const { usePostTransferStatementMutation } = profileWalletEndpoints;
export const { usePostTransferStatementCsvMutation } = profileWalletEndpoints;

export type ProfileWalletController = typeof profileWalletEndpoints;
