import { json, redirect } from 'react-router-dom';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';

import { RouterPaths } from '../paths';
import { RootState, store } from '../../store';
import { getItemFromStorage } from '../../utils';
import {
  businessFeatureEndpoints,
  GetBusinessFeaturesResult,
  runEndpointInRouter,
} from '../../api';
import { AuthoritiesArray, checkACLRights, i18n, redirectToOryLogin } from '../../services';
import { ProfileInfo, ProfileType } from '../../types';

/**
 * Helper function, which using in loaders/actions,
 * for throwing error into ErrorBoundary component
 *
 * @example 1
 * throw getRouterError({ data: { message: 'auth 401' }, status: 401 });
 * @example 2
 * throw getRouterError(e as FetchBaseQueryError);
 */
export const getRouterError = (error: FetchBaseQueryError) => {
  if (typeof error.status === 'number') {
    return json(error, { status: error.status });
  }

  return new Error(i18n.t('ui.errors.default-message'));
};

/**
 * Route protection function, if user is not authorized
 */
export const authGuard = () => {
  const state = store.getState() as RootState;
  const isAuth = state.auth.authStatus;

  if (!isAuth) {
    redirectToOryLogin();
    throw getRouterError({
      data: { message: i18n.t('ui.errors.error-boundary.401.message') },
      status: 401,
    });
  }
};

/**
 * ACL protection function, if user have not right permissions
 * @example - await aclGuard(['VIEW_CURRENCY_PROVIDERS'], RouterPaths.Profiles);
 * @param rights - authorities array of right, which need to check
 * @param redirectTo - optional - where to redirect
 */
export const aclGuard = async (
  rights: AuthoritiesArray,
  redirectTo?: RouterPaths
): Promise<Response | boolean> => {
  const state = store.getState() as RootState;
  const authoritiesList = state.auth.businessFeatures;

  let list = authoritiesList?.map((feature) => feature.name) ?? null;

  if (list === null) {
    try {
      const result = await runEndpointInRouter(
        businessFeatureEndpoints,
        'getBusinessFeatures',
        undefined,
        true
      );

      const data = result.data as GetBusinessFeaturesResult;
      list = data.map((feature) => feature.name);
    } catch (e) {
      throw getRouterError(e as FetchBaseQueryError);
    }
  }

  const hasAccess = checkACLRights(list as AuthoritiesArray, rights);

  if (redirectTo) {
    if (!hasAccess) throw redirect(redirectTo);
  }

  return hasAccess;
};

// TODO: improve portal guard and add description
export const portalGuard = (
  role: ProfileType,
  pathToRedirect: RouterPaths | undefined = RouterPaths.Root
) => {
  const activeProfile = getItemFromStorage('activeProfile') as ProfileInfo;

  if (role !== activeProfile.profileType) {
    throw redirect(pathToRedirect);
  }
};
