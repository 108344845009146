/**
 * hardBreakpoints - hard breakpoints from design,
 * that don't take into account mutable app content (translations, big words and numbers)
 */
export const hardBreakpoints = {
  desktop: 1440,
  laptop: 1280,
  tablet: 768,
  mobile: 375,
} as const;

/**
 * softBreakpoints - custom breakpoints,
 * which are obtained independently of the content of the application itself
 */
export const softBreakpoints = {} as const;
