import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
  ValidateSliceCaseReducers,
} from '@reduxjs/toolkit';

export type WithPaginationState<T> = T & {
  pagination: {
    pageNumber: number;
    pageSize: number;
  };
};

export const withPaginationSlice = <T, Reducers extends SliceCaseReducers<WithPaginationState<T>>>({
  name = '',
  initialState,
  reducers,
}: {
  name: string;
  initialState: WithPaginationState<T>;
  reducers: ValidateSliceCaseReducers<WithPaginationState<T>, Reducers>;
}) => {
  return createSlice({
    name,
    initialState,
    reducers: {
      ...reducers,
      setPageNumber: (state, action: PayloadAction<number>) => {
        state.pagination.pageNumber = action.payload;
      },
      setPageSize: (state, action: PayloadAction<number>) => {
        state.pagination.pageSize = action.payload;
      },
      resetPagination: (state) => {
        state.pagination = initialState.pagination;
      },
    },
  });
};
