import styled, { css } from 'styled-components';

import { Button } from '../Button';
import { IconButton } from '../IconButton';
import { getMediaFor } from '../../layouts';

export const PaginationContainer = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
`;

export const PagesWrapper = styled.div`
  display: flex;
  gap: 8px;
`;
export const ArrowsWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const PaginationItem = styled.div`
  display: flex;
  align-items: center;
`;

export const PaginationButton = styled(Button)<{ $selected: boolean }>`
  height: 32px;
  min-width: 40px;

  padding: 8px;
  border-radius: 8px;
  font-weight: 700;

  ${({ $selected }) =>
    $selected &&
    css`
      border: 2px solid ${({ theme }) => theme.colors.borders.white};
    `};

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      height: 30px;
      min-width: 30px;
      font-size: 1.4rem;
    `)};
`;

export const PaginationArrows = styled(IconButton)`
  height: 32px;
  width: 40px;

  padding: 8px;
  border-radius: 8px;

  .MuiTouchRipple-child {
    border-radius: 8px;
  }

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      width: 32px;
    `)};
`;

export const PaginationEllipsis = styled.div`
  display: flex;
  justify-content: center;
  width: 30px;

  font-weight: 700;
  font-size: 1.6rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.gray64};

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      font-size: 1.4rem;
    `)};
`;
