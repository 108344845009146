import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import * as S from './styled';
import {
  DatePicker,
  Input,
  removeValueInMultipleSelect,
  Select,
  SelectOption,
  SelectValue,
} from '../../../../components';

import {
  activeProfileUidSelector,
  activeTransfersFiltersCountSelector,
  selectorTransfersState,
  useAppSelector,
} from '../../../../store';
import { AsyncErrorMessage, getAsyncErrorMessage, Shape, yup } from '../../../../forms';
import {
  Recipient,
  TransferDirection,
  TransferFilter,
  TransferState,
  TransferType,
  Wallet,
} from '../../../../types';

type HistoryCsvFilterFormProps = {
  onCancel: () => void;
  onDownload: (data: TransfersFilterFormFields) => void;
  loading: boolean;
  wallets?: Wallet[];
  recipients?: Recipient[];
  errorDownload: boolean;
};
type TransfersFilterFormFields = TransferFilter;

const HistoryCsvFilterSchema = yup.object().shape<Shape<TransfersFilterFormFields>>({
  transferTypes: yup.array().of(yup.string()),
  states: yup.array().of(yup.string()),
  transferUid: yup.string(),
  createdAtTo: yup.date(),
  createdAtFrom: yup.date(),
  walletUid: yup.string(),
  direction: yup.string(),
  recipientIds: yup.array().of(yup.number()),
});

export const HistoryCsvFilterForm: FC<HistoryCsvFilterFormProps> = (props) => {
  const { onDownload, loading, wallets, recipients, errorDownload } = props;
  const { t } = useTranslation();
  const [statementFilterState] = useState<TransferFilter>({
    createdAtFrom: new Date(new Date().setMonth(new Date().getMonth() - 1)).toString(),
    createdAtTo: new Date().toString(),
  });
  const profileUid = useAppSelector(activeProfileUidSelector);
  const transfersState = useAppSelector(selectorTransfersState);
  const activeFiltersCount = useAppSelector(activeTransfersFiltersCountSelector);

  const isFilterActive = Boolean(activeFiltersCount);

  const {
    control,
    register,
    reset,
    setError,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<TransfersFilterFormFields>({
    resolver: yupResolver(HistoryCsvFilterSchema),
    defaultValues: { ...statementFilterState },
  });

  const clearFilter = () => {
    if (profileUid && isFilterActive) {
      try {
        reset({});
      } catch (e) {
        getAsyncErrorMessage(e, setError);
      }
    }
  };

  const removeChipInFilter = (value: SelectValue, fieldName: keyof TransfersFilterFormFields) => {
    removeValueInMultipleSelect(value, fieldName, getValues, setValue);
  };

  const applyFilter: SubmitHandler<TransfersFilterFormFields> = async (values) => {
    if (!profileUid) {
      return;
    }

    try {
      onDownload(values);
    } catch (e) {
      getAsyncErrorMessage(e, setError);
    }
  };

  const transferTypesOptions = Object.values(TransferType).map((type) => {
    return (
      <SelectOption key={type} value={type} name={t(`common.transfer-type.${type}`)}>
        {t(`common.transfer-type.${type}`)}
      </SelectOption>
    );
  });

  const walletsOptions = wallets?.map((wallet) => {
    return (
      <SelectOption key={wallet.uid} name={wallet.name} value={wallet.uid}>
        {wallet.name}
      </SelectOption>
    );
  });

  const recipientsOptions = recipients?.map((recipient) => {
    return (
      <SelectOption key={recipient.id} name={recipient.name} value={recipient.id}>
        {recipient.name}
      </SelectOption>
    );
  });

  const transferStatesOptions = Object.values(TransferState).map((state) => {
    return (
      <SelectOption key={state} value={state} name={t(`common.transfer-state.${state}`)}>
        {t(`common.transfer-state.${state}`)}
      </SelectOption>
    );
  });

  const directionOptions = Object.values(TransferDirection).map((direction) => {
    return (
      <SelectOption
        key={direction}
        value={direction}
        name={t(`common.transfer-direction.${direction}`)}
      >
        {t(`common.transfer-direction.${direction}`)}
      </SelectOption>
    );
  });

  return (
    <S.TransfersFilterForm onSubmit={handleSubmit(applyFilter)}>
      <S.TransfersFilterFormFields>
        {walletsOptions?.length ? (
          <Select
            control={{ control, name: 'walletUid' }}
            errors={errors}
            options={walletsOptions}
            label={t('transfers.actions.history-filter.form.fields.wallet')}
          />
        ) : null}
        <Select
          control={{ control, name: 'transferTypes' }}
          errors={errors}
          options={transferTypesOptions}
          label={t('transfers.actions.history-filter.form.fields.types')}
          multiple
          onMultipleValueDelete={(e, v) => removeChipInFilter(v, 'transferTypes')}
        />
        <Select
          control={{ control, name: 'states' }}
          errors={errors}
          options={transferStatesOptions}
          label={t('transfers.actions.history-filter.form.fields.states')}
          multiple
          onMultipleValueDelete={(e, v) => removeChipInFilter(v, 'states')}
        />
        <S.FullWrapper>
          <Input
            {...register('transferUid')}
            errors={errors}
            type={'string'}
            label={t('transfers.actions.history-filter.form.fields.transferUid')}
          />
          <Select
            control={{ control, name: 'direction' }}
            errors={errors}
            options={directionOptions}
            label={t('transfers.actions.history-filter.form.fields.direction')}
          />
        </S.FullWrapper>
        <S.FullWrapper>
          <DatePicker
            control={{ control, name: 'createdAtFrom' }}
            label={t('transfers.actions.history-filter.form.fields.createdAtFrom')}
            errors={errors}
            disableFuture
          />
          <DatePicker
            control={{ control, name: 'createdAtTo' }}
            label={t('transfers.actions.history-filter.form.fields.createdAtTo')}
            errors={errors}
            disableFuture
          />
        </S.FullWrapper>

        {recipientsOptions && recipientsOptions.length !== 0 && (
          <Select
            control={{ control, name: 'recipientIds' }}
            errors={errors}
            options={recipientsOptions}
            label={t('transfers.actions.history-filter.form.fields.recipients')}
            multiple
            onMultipleValueDelete={(e, v) => removeChipInFilter(v, 'recipientIds')}
          />
        )}

        <AsyncErrorMessage errors={errors} />
        {errorDownload ? (
          <S.ErrorDownloadCsv>
            {t('transfers.actions.history-filter.form.error-empy-csv')}
          </S.ErrorDownloadCsv>
        ) : (
          <></>
        )}
      </S.TransfersFilterFormFields>

      <S.TransfersFilterFormActions>
        <S.ApplyTransfersFilterButton
          body={t('transfers.actions.history-filter.form.accept-filter-btn')}
          type="submit"
          disabled={loading}
        />
      </S.TransfersFilterFormActions>
    </S.TransfersFilterForm>
  );
};
