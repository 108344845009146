import styled, { css } from 'styled-components';

import { getMediaFor } from '../../../theming';

export const ProfileLayoutHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;

  padding: 32px 40px;

  ${({ theme }) =>
    getMediaFor(
      'tablet-max-width',
      theme
    )(css`
      padding: 24px 40px;
    `)};

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      flex-wrap: wrap;
      gap: 16px;
      padding: 16px;
    `)};
`;

export const LeftPart = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const RightPart = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      margin-left: auto;
    `)};
`;
