import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import * as S from './styled';
import {
  Input,
  Select,
  useSelectOptions,
  useSelectOptionsByStringArray,
} from '../../../../components';

import {
  CreateOwnBankRecipientDTO,
  useCreateOwnBankRecipientMutation,
  useGetCurrenciesQuery,
  useGetCurrenciesTransferOutQuery,
} from '../../../../api';
import {
  AsyncErrorMessage,
  bankAccountRegExp,
  getAsyncErrorMessage,
  Shape,
  yup,
} from '../../../../forms';
import { activeProfileUidSelector, useAppSelector } from '../../../../store';

type AddBankAccountFormProps = {
  onCancel: () => void;
};
type AddBankAccountFormFields = CreateOwnBankRecipientDTO['body'];

const addBankAccountSchema = yup.object().shape<Shape<AddBankAccountFormFields>>({
  currencyCode: yup.string().required(),
  bankName: yup.string().required(),
  bankCode: yup.string().matches(bankAccountRegExp).required(),
  account: yup.string().matches(bankAccountRegExp).required(),
});

export const AddBankAccountForm: FC<AddBankAccountFormProps> = ({ onCancel }) => {
  const { t } = useTranslation();

  const profileUid = useAppSelector(activeProfileUidSelector);

  const { data: coins } = useGetCurrenciesTransferOutQuery({ country: undefined });

  const [createOwnBankRecipient, { isLoading: isCreateOwnBankRecipientLoading }] =
    useCreateOwnBankRecipientMutation();

  const {
    control,
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<AddBankAccountFormFields>({
    resolver: yupResolver(addBankAccountSchema),
  });

  const closeForm = () => {
    onCancel();
  };

  const addBankAccount: SubmitHandler<AddBankAccountFormFields> = async (values) => {
    try {
      await createOwnBankRecipient({ profileUid: profileUid, body: values }).unwrap();
      closeForm();
    } catch (e) {
      getAsyncErrorMessage(e, setError);
    }
  };

  const coinsOptions = useSelectOptionsByStringArray(coins);

  return (
    <S.AddBankAccountForm onSubmit={handleSubmit(addBankAccount)}>
      <S.AddBankAccountFormFields>
        <Select
          control={{ control, name: 'currencyCode' }}
          errors={errors}
          options={coinsOptions}
          label={t('recipients.header.actions.add-account.form.bank.fields.currency.label')}
        />
        <Input
          {...register('bankName')}
          errors={errors}
          label={t('recipients.header.actions.add-account.form.bank.fields.bank-name.label')}
        />
        <Input
          {...register('bankCode')}
          errors={errors}
          label={t('recipients.header.actions.add-account.form.bank.fields.bank-code.label')}
        />
        <Input
          {...register('account')}
          errors={errors}
          label={t('recipients.header.actions.add-account.form.bank.fields.account-number.label')}
        />

        <AsyncErrorMessage errors={errors} />
      </S.AddBankAccountFormFields>

      <S.AddBankAccountFormActions>
        <S.CreateBankAccountButton
          body={t('recipients.header.actions.add-account.form.bank.create-account-btn')}
          type="submit"
          loading={isCreateOwnBankRecipientLoading}
        />
      </S.AddBankAccountFormActions>
    </S.AddBankAccountForm>
  );
};
