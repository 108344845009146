import styled, { css } from 'styled-components';

import { getMediaFor } from '../../layouts';
import { IconButton } from '../../components';

export const SendContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  min-height: 100%;
  padding: 0 40px 40px;

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      padding: 0 16px 40px;
    `)};
`;

export const WalletCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  &.limitedHeight {
    max-height: 230px;
    overflow: auto;
  }
`;

export const SectionTitle = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 100%;
  width: 100%;
  gap: 4px;
  color: ${({ theme }) => theme.colors.typography.gray80};
  padding-bottom: 4px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.typography.gray32};
`;

export const AmountContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  select {
    width: 60px;
  }
`;

export const WalletContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 480px;
  gap: 16px;
`;

export const SubTitle = styled.div`
  font-weight: 400;
  font-size: 2.5rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.white};

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      font-size: 1.8rem;
      line-height: 100%;
    `)}
`;

export const Avatar = styled.div`
  border-radius: 15px;
  width: 40px;
  height: 40px;
  text-align: center;
  padding-top: 13px;

  background: ${({ theme }) => theme.colors.typography.gray56};
  color: #fff;
`;

export const ErrorMessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border: 2px solid ${({ theme }) => theme.colors.error};
  border-radius: 15px;

  font-weight: 400;
  font-size: 1.6rem;
  line-height: 100%;
  color: #7a7a7a;
`;

export const ErrorMessageClose = styled(IconButton)`
  padding: 0;
  margin-left: 10px;
  background: transparent;
  color: ${({ theme }) => theme.colors.typography.gray64};

  &:hover {
    background: transparent;
  }
`;

export const ErrorMessageTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 10px 20px 20px;
`;

export const ErrorMessageCloseContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 10px 0 0;
`;

export const RecipientsContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex-grow: 1;

  padding-right: 16px;
  overflow: auto;

  .avatar-cell {
    width: 56px;
  }

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      padding-right: 0;
    `)};
`;

export const MobileCellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  .cell-header {
    font-weight: 700;
    font-size: 1.7rem;
  }
`;

export const EllipsisEmail = styled.div`
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const InfoWrapper = styled.div<{ $isFeeCalcFetching: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${({ $isFeeCalcFetching }) =>
    $isFeeCalcFetching &&
    css`
      & > div:not(.info-loader) {
        opacity: 0.4;
      }
    `};

  .info-loader {
    position: absolute;
    opacity: 1;
  }
`;
