import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styled';
import { IconPlus } from '../../../../../../assets';
import { Modal } from '../../../../../../components';
import { AddWalletForm } from '../../components';

import { useMedia } from '../../../../../theming';
import { useACL } from '../../../../../../services';
import { useGetAvailableCoinsQuery } from '../../../../../../api';
import { activeProfileUidSelector, useAppSelector } from '../../../../../../store';

type AddWalletButtonProps = { withoutText?: boolean };

export const AddWalletButton: FC<AddWalletButtonProps> = ({ withoutText }) => {
  const { t } = useTranslation();
  const { isTabletMaxWidth } = useMedia();
  const hasPermission_WALLET_VIEW = useACL(['WALLET_VIEW']);

  const profileUid = useAppSelector(activeProfileUidSelector);

  const { data: coins } = useGetAvailableCoinsQuery({ profileUid: profileUid });

  // add wallet modal state
  const [isAddWalletModalShown, setAddWalletModalShown] = useState(false);
  const showAddWalletForm = () => setAddWalletModalShown(true);
  const closeAddWalletForm = () => setAddWalletModalShown(false);

  return (
    <>
      {withoutText ? (
        <S.AddWalletIconButton icon={<IconPlus />} onClick={() => showAddWalletForm()} />
      ) : (
        <S.AddWalletButton
          color={isTabletMaxWidth ? 'secondary' : 'primary'}
          startIcon={<IconPlus />}
          body={t('dashboard-layout.wallet-bar.add-wallet-btn')}
          onClick={() => showAddWalletForm()}
        />
      )}

      <Modal
        modalTitle={t('dashboard-layout.wallet-bar.add-wallet-form.title')}
        body={
          <AddWalletForm
            coins={hasPermission_WALLET_VIEW ? coins : []}
            onCancel={() => closeAddWalletForm()}
          />
        }
        open={isAddWalletModalShown}
        onClose={() => closeAddWalletForm()}
      />
    </>
  );
};
