import styled, { css, keyframes } from 'styled-components';

import { IconButton } from '..';

// import { Input } from '../Input';

// export const InputFileUpload = styled(Input);

export const Input = styled.input<{ $dragAndDrop: boolean }>`
  display: none;
`;

export const DnDContainer = styled.div<{ $disabled: boolean }>`
  width: 100%;
  min-height: 150px;
  padding: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  background: #514f4f;
  outline: 2px dashed #92b0b3;
  outline-offset: -10px;
  flex-direction: column;

  transition: outline-offset 0.15s ease-in-out, background-color 0.15s linear;

  &.drag-over {
    outline-offset: -20px;
    outline-color: #c8dadf;
    background-color: #fff;
  }

  &.upload-success {
    background: rgb(208 226 174);
  }

  &.upload-error {
    background: rgb(223 200 200);
  }

  &:hover {
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}
`;

const elipsisAnimation = keyframes`
  to {
    width: 1.25em;
  }
`;

export const Label = styled.div`
  max-width: 80%;
  text-overflow: ellipsis;
  cursor: pointer;
  display: inline-block;
  overflow: hidden;

  .upload-link {
    font-weight: 500;
  }

  &:hover {
    .upload-link {
      color: rgb(139, 211, 0);
    }
  }
`;

export const UploadProgress = styled.div`
  &:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    content: '…';
    width: 0px;
    -webkit-animation: ${elipsisAnimation} steps(4, end) 1200ms infinite;
    animation: ${elipsisAnimation} steps(4, end) 1200ms infinite;
  }
`;

export const UploadFileButton = styled(IconButton)`
  padding: 10px;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  border-radius: 8px;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.typography.gray88};

  .MuiTouchRipple-child {
    border-radius: 8px;
  }
`;
