import styled from 'styled-components';

import { Button } from '../../../../../../components';

export const WalletIBANForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const WalletIBANFormBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  && .fee-input {
    color: ${({ theme }) => theme.colors.typography.white};
  }
`;

export const WalletIBANCard = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.bg.white05};
`;

export const WalletLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  width: 56px;
  height: 56px;

  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.bg.white05};
`;

export const WalletLogo = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;

export const WalletIBANCardInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px 0;

  .wallet-card-name {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.typography.gray80};
  }

  .wallet-card-balance {
    font-weight: 400;
    font-size: 2rem;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.typography.white};
  }
`;

export const WalletIBANFormActions = styled.div`
  display: flex;
  gap: 16px;
`;

export const CancelWalletIBANButton = styled(Button)`
  min-width: 106px;
`;

export const ActionWalletIBANButton = styled(Button)`
  flex-grow: 1;
`;
