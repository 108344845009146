import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Configuration, FrontendApi, Session } from '@ory/client';
import { ConfigurationParameters } from '@ory/client/configuration';
import { AxiosError } from 'axios';
import dayjs from 'dayjs';

import { authActions, useAppActions } from '../../store';
import { getItemFromStorage, removeItemFromStorage, setItemToStorage } from '../../utils';
import type { CustomOryError } from './types';

export const oryLoginUrl = `${process.env.REACT_APP_UI_ORY_SDK_URL}/auth/login?return_to=${window.location.href}`;
export const redirectToOryLogin = () => {
  setItemToStorage('twoFANotificationShownDate', dayjs().format('L'));
  setItemToStorage('twoFANotificationShown', false);
  window.location.replace(oryLoginUrl);
};
/**
 * Create ory instance
 */
export const createOry = (config: ConfigurationParameters) => {
  return new FrontendApi(new Configuration(config));
};

export const useOry = () => {
  const { t } = useTranslation();
  const { setAuthStatus, setTraits, setAuthMethods, setLogoutUrl } = useAppActions(authActions);

  const [isLoading, setLoadingStatus] = useState(false);
  const [session, setSession] = useState<Session | null>(null);
  const [hasSession, setSessionStatus] = useState(false);
  const [error, setError] = useState<CustomOryError | null>(null);
  const [hasError, setErrorStatus] = useState(false);

  /**
   * Initialize ory
   */
  const initOry = async (ory: FrontendApi) => {
    setLoadingStatus(true);

    try {
      // get ory session
      const { data: oryData } = await ory.toSession();
      const userUid = oryData.identity.id;
      setSession(oryData);
      setSessionStatus(true);
      setError(null);
      setErrorStatus(false);
      setAuthStatus(true);
      setTraits({ ...oryData.identity.traits, userUid });
      setAuthMethods(oryData?.authentication_methods ?? []);

      //check userUid
      const savedUserUid = getItemFromStorage('userUid');
      if (!savedUserUid) {
        setItemToStorage('userUid', userUid);
      } else if (savedUserUid !== userUid) {
        setItemToStorage('userUid', userUid);
        removeItemFromStorage('activeProfile');
      }

      // get logout url
      const { data: logoutData } = await ory.createBrowserLogoutFlow();
      setLogoutUrl(logoutData.logout_url);
    } catch (e) {
      const error = e as Error | AxiosError;

      if ('response' in error) {
        if (error?.response && (error.response.status === 401 || error.response.status === 403)) {
          redirectToOryLogin();
        }
      } else {
        setError(t('ui.errors.default-message'));
        setErrorStatus(true);
      }
    } finally {
      setLoadingStatus(false);
    }
  };

  return { initOry, session, hasSession, error, hasError, isLoading };
};
