import styled from 'styled-components';

import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';

export const QontoConnector = styled(StepConnector)(() => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#8BD300',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#8BD300',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: '#rgba(255, 255, 255, 0.1)',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

export const StepLabelContainer = styled.div`
  &.active {
    border: 2px solid #8bd300;
    padding: 8px 16px;
    border-radius: 8px;
    color: #8bd300;
  }

  &.inactive {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    padding: 9px 17px;
    color: #fff;
  }

  &.clickable:hover {
    background: #8bd300;
    cursor: pointer;
    border: 2px solid #8bd300;
    padding: 8px 16px;
    border-radius: 8px;
    color: #fff;
  }

  font-weight: 400;
  font-size: 1.6rem;
  line-height: 100%;
`;

export const QontoStepIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
      color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
      color: '#784af4',
      zIndex: 1,
      fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
      width: 8,
      height: 8,
      borderRadius: '50%',
      backgroundColor: 'currentColor',
    },
  })
);
