import { FC, ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { SnackbarProps as MuiSnackbarProps } from '@mui/material';
import dayjs from 'dayjs';

import * as S from './styled';
import { IconClose24 } from '../../assets';

import { RouterPaths } from '../../router';
import { setItemToStorage } from '../../utils';
import { commonActions, snackbarSelector, useAppActions, useAppSelector } from '../../store';
import type { Snackbar as SnackbarType, SnackbarVariant } from '../../types';

type SnackbarProps = MuiSnackbarProps;

export const Snackbar: FC<SnackbarProps> = () => {
  const { t } = useTranslation();

  const snackbar = useAppSelector(snackbarSelector);
  const { removeSnackbar } = useAppActions(commonActions);

  if (!snackbar) return null;

  const { variant, body, showCloseButton, noAutoHide } = snackbar;

  const closeSnackbar: MuiSnackbarProps['onClose'] = (_, reason) => {
    if (reason === 'clickaway') return;

    if (variant === '2fa') {
      setItemToStorage('twoFANotificationShownDate', dayjs().format('L'));
      setItemToStorage('twoFANotificationShown', true);
    }

    removeSnackbar();
  };

  const closeSnackbarByIconButton = () => {
    if (variant === '2fa') {
      setItemToStorage('twoFANotificationShownDate', dayjs().format('L'));
      setItemToStorage('twoFANotificationShown', true);
    }

    removeSnackbar();
  };

  const variantMap: Record<SnackbarVariant, ReactElement> = {
    default: (
      <S.SnackbarDefaultContainer>
        {body}
        {showCloseButton && (
          <S.SnackbarCloseButton icon={<IconClose24 />} onClick={closeSnackbarByIconButton} />
        )}
      </S.SnackbarDefaultContainer>
    ),
    '2fa': (
      <S.Snackbar2faContainer>
        <S.Snackbar2faBody>
          <S.Snackbar2faTitle>{t('dashboard-layout.2fa-snackbar.title')}</S.Snackbar2faTitle>
          <S.Snackbar2faText>{t('dashboard-layout.2fa-snackbar.text')}</S.Snackbar2faText>
          <S.Snackbar2faLink
            href={process.env.REACT_APP_UI_ORY_SDK_URL + RouterPaths.AccountSettingsTOTP}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('dashboard-layout.2fa-snackbar.enable-2fa-link')}
          </S.Snackbar2faLink>
        </S.Snackbar2faBody>
        {showCloseButton && (
          <S.SnackbarCloseButton icon={<IconClose24 />} onClick={closeSnackbarByIconButton} />
        )}
      </S.Snackbar2faContainer>
    ),
  };

  return (
    <S.Snackbar
      open={Boolean(snackbar)}
      onClose={(event, reason) => closeSnackbar(event, reason)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={noAutoHide ? null : 6000}
    >
      {variantMap[variant]}
    </S.Snackbar>
  );
};

/**
 * Custom hook for easily display snackbar in React Components
 */
export const useSnackbar = () => {
  const { setSnackbar } = useAppActions(commonActions);

  const showSnackbar = useCallback(
    (snackbar: SnackbarType) => {
      setSnackbar(snackbar);
    },
    [setSnackbar]
  );

  return {
    showSnackbar,
  };
};
