import styled, { css } from 'styled-components';

import { Button } from '../../../../components';
import { getMediaFor } from '../../../theming';

export const DashboardHeaderContainer = styled.header`
  position: sticky;
  z-index: 999;
  top: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;

  padding: 32px 40px;
  background-color: ${({ theme }) => theme.colors.bg.dashboardContent};

  ${({ theme }) =>
    getMediaFor(
      'tablet-max-width',
      theme
    )(css`
      padding: 24px 40px;
      border-bottom: 2px solid ${({ theme }) => theme.colors.borders.white10};
      background-color: ${({ theme }) => theme.colors.bg.dashboardLayout};
    `)};

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      flex-wrap: wrap;
      gap: 16px;
      padding: 16px;
    `)};
`;

export const LeftPart = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      gap: 12px;
    `)};
`;

export const RightPart = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;

  @media screen and (max-width: 1308px) {
    gap: 6px;
  }

  ${({ theme }) =>
    getMediaFor(
      'tablet-max-width',
      theme
    )(css`
      gap: 16px;
    `)};

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      gap: 12px;
      margin-left: auto;
    `)};
`;

export const HintModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const HintModalText = styled.div`
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.typography.gray80};
`;

export const GotItButton = styled(Button)`
  flex-grow: 1;
`;
