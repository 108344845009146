import { FC, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { LoaderFunction, useNavigate, useParams } from 'react-router-dom';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query/react';
import { isMobile } from 'react-device-detect';

import * as S from './styled';
import { IconCopy24, IconTrash24 } from '../../assets';
import { IconButton, Modal, ModalConfirmation } from '../../components';
import { Bank, BankOwn, Crypto, Local } from './components';

import {
  GetRecipientByIdDTO,
  recipientEndpoints,
  runEndpointInRouter,
  useDeleteRecipientByIdMutation,
  useGetActiveCountriesQuery,
  useGetLegalCompanyTypesQuery,
  useGetRecipientByIdQuery,
} from '../../api';
import { getItemFromStorage } from '../../utils';
import { useACL } from '../../services';
import { activeProfileUidSelector, useAppSelector } from '../../store';
import { aclGuard, authGuard, getRouterError, RouterPaths } from '../../router';
import { ProfileInfo, RecipientAccountType, RecipientType } from '../../types';
import { AddBankRecipientForm } from '../Recipients/components';

export const recipientLoader: LoaderFunction = async ({ params }) => {
  authGuard();
  await aclGuard(['RECIPIENT_VIEW'], RouterPaths.DashboardLayout);

  const activeProfile = getItemFromStorage('activeProfile') as ProfileInfo;
  const recipientId = params.recipientId;

  try {
    await runEndpointInRouter(recipientEndpoints, 'getRecipientById', {
      profileUid: activeProfile.profileUid,
      recipientId,
    } as GetRecipientByIdDTO);
  } catch (e) {
    throw getRouterError(e as FetchBaseQueryError);
  }

  return null;
};

export const Recipient: FC = () => {
  const { t } = useTranslation();
  const { recipientId } = useParams<'recipientId'>();
  const navigate = useNavigate();
  const hasPermission_RECIPIENT_CREATION = useACL(['RECIPIENT_CREATION']);

  const profileUid = useAppSelector(activeProfileUidSelector);

  const { data: activeCountries } = useGetActiveCountriesQuery();
  const { data: legalCompanyTypes } = useGetLegalCompanyTypesQuery();
  const { data: recipient } = useGetRecipientByIdQuery(
    recipientId ? { profileUid, recipientId } : skipToken
  );
  const [deleteRecipientRequest, { isLoading: isDeleteRecipientLoading }] =
    useDeleteRecipientByIdMutation();

  // recipient-page modal state
  const [isModalPageShown, setModalPageShown] = useState(true);
  const closeModalPage = () => {
    setModalPageShown(false);
    navigate(RouterPaths.Recipients);
  };

  // recipient delete confirmation modal state
  const [isRecipientDeleteModalShown, setRecipientDeleteModalShown] = useState(false);
  const showRecipientDeleteModal = () => setRecipientDeleteModalShown(true);
  const closeRecipientDeleteModal = () => setRecipientDeleteModalShown(false);

  const [isAccountModalShown, setAccountModalShown] = useState(false);
  const showAddAccountForm = () => setAccountModalShown(true);
  const closeAddAccountForm = () => setAccountModalShown(false);

  const deleteRecipient = async () => {
    if (!recipient) return;

    try {
      await deleteRecipientRequest({ profileUid, recipientId: String(recipient.id) }).unwrap();
      closeRecipientDeleteModal();
      closeModalPage();
    } catch (e) {
      // TODO: check this error handling after backend fixing in Recipients - if it ok, then add handling to Ticket.tsx
      throw getRouterError(e as FetchBaseQueryError);
    }
  };

  const sendMoney = () => {
    if (!recipient) return;
    navigate(`${RouterPaths.Send}?recipientId=${recipient.id}`);
  };

  const variantsMap = {
    local: <Local recipient={recipient} />,
    crypto: <Crypto recipient={recipient} />,
    bankLegal: <Bank type="legal" recipient={recipient} />,
    bankNatural: <Bank type="natural" recipient={recipient} />,
    bankOwn: <BankOwn recipient={recipient} />,
    default: null,
  };

  const getVariant = (): keyof typeof variantsMap => {
    if (!recipient) return 'default';

    const type = recipient.recipientType;
    const accountType = recipient.recipientAccountType;

    if (accountType === RecipientAccountType.LOCAL) return 'local';
    if (accountType === RecipientAccountType.CRYPTO) return 'crypto';
    if (accountType === RecipientAccountType.BANK && type === RecipientType.LEGAL)
      return 'bankLegal';
    if (accountType === RecipientAccountType.BANK && type === RecipientType.NATURAL)
      return 'bankNatural';
    if (accountType === RecipientAccountType.BANK && type === RecipientType.OWN) return 'bankOwn';

    return 'default';
  };

  const recipientElement = (
    <S.RecipientContainer>
      <S.RecipientBody>{variantsMap[getVariant()]}</S.RecipientBody>

      <S.RecipientActions>
        <S.DeleteRecipientButton
          icon={<IconTrash24 />}
          onClick={() => showRecipientDeleteModal()}
          disabled={!hasPermission_RECIPIENT_CREATION}
        />
        {isMobile &&
          recipient?.recipientAccountType === RecipientAccountType.BANK &&
          recipient?.recipientType !== RecipientType.OWN && (
            <S.CopyRecipientButton icon={<IconCopy24 />} onClick={showAddAccountForm} />
          )}
        <S.SendMoneyButton
          // startIcon={<IconTrash24 />}
          body={t('recipient.send-money-btn')}
          onClick={() => sendMoney()}
          disabled={!hasPermission_RECIPIENT_CREATION}
        />
      </S.RecipientActions>
    </S.RecipientContainer>
  );

  return (
    <>
      <Helmet title={t('pages.recipient', { value: recipient?.name })} />

      <Modal
        modalTitle={recipient?.name || ''}
        body={recipientElement}
        open={isModalPageShown}
        onClose={() => closeModalPage()}
        disableEscapeKeyDown
        disableBackDropClick
      />

      <Modal
        modalTitle={t('recipients.header.actions.add-recipient.form.title.COPY_CRYPTO')}
        body={
          <AddBankRecipientForm
            activeCountries={activeCountries}
            legalCompanyTypes={legalCompanyTypes}
            onCancel={() => closeAddAccountForm()}
            copiedAccount={recipient}
            isCopyBankRecipient
          />
        }
        open={isAccountModalShown}
        onClose={() => closeAddAccountForm()}
      />

      <ModalConfirmation
        question={t('recipient.delete.confirmation-question')}
        confirmButtonProps={{
          color: 'error',
          body: t('recipient.delete.confirm-btn'),
          loading: isDeleteRecipientLoading,
        }}
        open={isRecipientDeleteModalShown}
        onCancel={() => closeRecipientDeleteModal()}
        onConfirm={() => deleteRecipient()}
      />
    </>
  );
};
