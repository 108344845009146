import { apiSlice } from '../../api';
import type {
  UploadProfileDocumentDTO,
  UploadProfileDocumentResult,
  GetDocumentsByUidResult,
  GetDocumentsByUidDTO,
} from './types';

export const documentProfileEndpoints = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    uploadProfileDocument: build.mutation<UploadProfileDocumentResult, UploadProfileDocumentDTO>({
      query: ({ profileUid, body }) => ({
        url: `/profiles-service/v1/profiles/${profileUid}/documents`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['ProfileDocuments'],
    }),
    getDocumentsByUid: build.query<GetDocumentsByUidResult, GetDocumentsByUidDTO>({
      query: ({ profileUid, body }) => ({
        url: `/profiles-service/v1/profiles/${profileUid}/documents/filter`,
        method: 'POST',
        body,
      }),
      providesTags: ['ProfileDocuments'],
    }),
  }),
});

export const { useUploadProfileDocumentMutation } = documentProfileEndpoints;
export const { useGetDocumentsByUidQuery } = documentProfileEndpoints;

export type DocumentProfileController = typeof documentProfileEndpoints;
