import styled, { css } from 'styled-components';
import { getMediaFor } from '../../layouts';

export const ComingSoonContainer = styled.div`
  position: relative;
  min-height: 100%;
  overflow: hidden;
`;

export const ComingSoonText = styled.div`
  position: absolute;
  top: 40%;
  left: 0;
  transform: translateY(-50%);
  z-index: 99;

  max-width: 420px;
  margin: 0 80px;

  font-weight: 400;
  font-size: 6.4rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.white};
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      top: 24%;
    `)};

  @media screen and (max-width: 539px) {
    top: 24%;
    max-width: 374px;
    margin: 0 40px;
    font-size: 4.6rem;
  }
`;

export const ComingSoonImage = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 9;

  max-height: 100%;
  max-width: 100%;
`;
