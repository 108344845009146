import { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { LoaderFunction } from 'react-router-dom';

import SumsubWebSdk from '@sumsub/websdk-react';
import type {
  MessageHandler,
  PromisedTokenExpirationHandler,
  SnsWebSdkBaseConfig,
  SnsWebSdkOptions,
} from '@sumsub/websdk';

import * as S from './styled';
import { InfoCard, Loader, Title } from '../../components';

import {
  addressVerifyStatusSelector,
  naturalProfileUidSelector,
  profileVerifyStatusSelector,
  useAppSelector,
} from '../../store';
import { useACL } from '../../services';
import { useLazyGetSumsubTokenQuery } from '../../api';
import { aclGuard, authGuard, portalGuard, RouterPaths } from '../../router';
import { ProfileType, SumsubLevelName } from '../../types';

export const verificationLoader: LoaderFunction = async () => {
  authGuard();
  portalGuard(ProfileType.NATURAL, RouterPaths.DashboardLayout);
  await aclGuard(['SELF_KYC'], RouterPaths.DashboardLayout);

  return null;
};

export const Verification: FC = () => {
  const { t, i18n } = useTranslation();
  const hasPermission_SELF_KYC = useACL(['SELF_KYC']);

  const naturalProfileUid = useAppSelector(naturalProfileUidSelector);
  const isVerified = useAppSelector(profileVerifyStatusSelector);
  const isAddressVerified = useAppSelector(addressVerifyStatusSelector);

  const [sumsubVisibility, setSumsubVisibility] = useState(true);
  const [isPendingStatus, setPendingStatus] = useState(false);
  const [isRejectedStatus, setRejectedStatus] = useState(false);
  const [isCompletedStatus, setCompletedStatus] = useState(false);

  const [
    getSumsubToken,
    {
      data: sumsubTokenData,
      isFetching: isSumsubTokenLoading,
      isSuccess: isSumsubTokenQuerySuccess,
    },
  ] = useLazyGetSumsubTokenQuery();

  const hasAllVerifications = isVerified && isAddressVerified;
  const hasDeclinedVerification = isVerified === false || isAddressVerified === false;
  const hasNoVerificationRequests =
    sumsubTokenData &&
    !sumsubTokenData.token &&
    (isVerified === null || isAddressVerified === null);

  useEffect(() => {
    if ((!isVerified || !isAddressVerified) && hasPermission_SELF_KYC) {
      getSumsubToken({
        profileUid: naturalProfileUid!,
        levelName: SumsubLevelName.BASIC_KYC_LEVEL,
      });
    }
  }, []);

  // useEffect(() => {
  //   if (!isVerified) return;
  //
  //   if (!isAddressVerified && hasPermission_SELF_KYC) {
  //     getSumsubToken({
  //       profileUid: naturalProfileUid!,
  //       levelName: SumsubLevelName.ADDRESS,
  //     });
  //   }
  // }, [isVerified]);

  useEffect(() => {
    if (!hasDeclinedVerification && !hasNoVerificationRequests) setSumsubVisibility(true);
    if (hasDeclinedVerification || hasNoVerificationRequests) setSumsubVisibility(false);
  }, [hasDeclinedVerification, hasNoVerificationRequests]);

  const config: SnsWebSdkBaseConfig = {
    lang: i18n.resolvedLanguage,
  };

  const options: SnsWebSdkOptions = {
    debug: process.env.NODE_ENV !== 'production',
    adaptIframeHeight: true,
    addViewportTag: true,
  };

  const expirationHandler: PromisedTokenExpirationHandler = () => {
    hasPermission_SELF_KYC &&
      getSumsubToken({
        profileUid: naturalProfileUid!,
        levelName: SumsubLevelName.BASIC_KYC_LEVEL,
        //levelName: !isVerified ? SumsubLevelName.BASIC_KYC_LEVEL : SumsubLevelName.ADDRESS,
      });
    if (isSumsubTokenQuerySuccess) {
      return Promise.resolve(sumsubTokenData?.token || '');
    }
    return Promise.reject('');
  };

  const messageHandler: MessageHandler = (type, payload) => {
    console.group('%cSamSub Event:', 'font-weight: bold');
    console.log('Type:', type);
    console.log('Payload:', payload);
    console.groupEnd();

    if (
      type === 'idCheck.onApplicantStatusChanged' &&
      // @ts-ignore
      (payload?.reviewStatus === 'pending' || payload?.reviewStatus === 'onHold')
    ) {
      setSumsubVisibility(false);
      setPendingStatus(true);
      setRejectedStatus(false);
      setCompletedStatus(false);
    }

    // @ts-ignore
    if (type === 'idCheck.onApplicantStatusChanged' && payload?.reviewStatus === 'completed') {
      setSumsubVisibility(false);
      setPendingStatus(false);
      setRejectedStatus(false);
      setCompletedStatus(true);
    }

    if (
      type === 'idCheck.onApplicantStatusChanged' &&
      // @ts-ignore
      payload?.reviewStatus === 'completed' &&
      // @ts-ignore
      payload?.reviewResult?.reviewAnswer === 'RED' &&
      // @ts-ignore
      payload?.reviewResult?.reviewRejectType === 'RETRY'
    ) {
      setSumsubVisibility(true);
      setPendingStatus(false);
      setRejectedStatus(true);
      setCompletedStatus(false);
    }

    // type === 'idCheck.onApplicantStatusChanged' &&
    //   // @ts-ignore
    //   payload?.reviewStatus === 'pending' &&
    //   setPendingStatus(true);

    // type === 'idCheck.onApplicantStatusChanged' &&
    //   // @ts-ignore
    //   payload?.reviewStatus === 'completed' &&
    //   // @ts-ignore
    //   payload?.reviewResult?.reviewAnswer === 'RED' &&
    //   // @ts-ignore
    //   payload?.reviewResult?.reviewRejectType === 'RETRY' &&
    //   setRejectedStatus(true);

    // type === 'idCheck.onApplicantStatusChanged' &&
    //   // @ts-ignore
    //   payload?.reviewStatus === 'completed' &&
    //   setCompletedStatus(true);
  };

  const sumsub = () => {
    const sumsubElements = (
      <S.SumsubIframeContainer>
        {isSumsubTokenLoading ? <Loader variant="centerOfContainer" /> : null}

        {isSumsubTokenQuerySuccess ? (
          <div style={sumsubVisibility ? undefined : { display: 'none' }}>
            <SumsubWebSdk
              accessToken={sumsubTokenData?.token || ''}
              expirationHandler={expirationHandler}
              config={config}
              options={options}
              onMessage={messageHandler}
            />
          </div>
        ) : null}

        {!sumsubVisibility &&
          (isPendingStatus || isCompletedStatus || hasNoVerificationRequests) && (
            <S.VerificationSuccessBanner>
              <InfoCard variant="success" text={t('verification.pending-verification-text')} />
            </S.VerificationSuccessBanner>
          )}
      </S.SumsubIframeContainer>
    );

    if (hasDeclinedVerification) {
      return (
        <S.VerificationSuccessBanner>
          <InfoCard variant="error" text={t('verification.error-verification-text')} />
        </S.VerificationSuccessBanner>
      );
    }

    // if (isRejectedStatus) return sumsubElements;
    // if (isPendingStatus || isCompletedStatus) {
    //   return (
    //     <S.VerificationSuccessBanner>
    //       <InfoCard variant="success" text={t('verification.pending-verification-text')} />
    //     </S.VerificationSuccessBanner>
    //   );
    // }

    return sumsubElements;
  };

  return (
    <S.VerificationContainer>
      <Helmet title={t('pages.verification')} />
      <Title text={t('verification.title')} />

      {!hasAllVerifications ? (
        sumsub()
      ) : (
        <S.VerificationSuccessBanner>
          <InfoCard variant="success" text={t('verification.success-verification-text')} />
        </S.VerificationSuccessBanner>
      )}
    </S.VerificationContainer>
  );
};
