import styled, { css } from 'styled-components';
import { Avatar as MuiAvatar } from '@mui/material';

import type { AvatarSize } from './types';

export const Avatar = styled(MuiAvatar)<{ $size: AvatarSize }>`
  ${({ $size }) => {
    switch ($size) {
      case 'small':
        return css`
          height: 32px;
          width: 32px;
        `;
      case 'medium':
        return css`
          height: 40px;
          width: 40px;
        `;
      case 'large':
        return css`
          height: 48px;
          width: 48px;
        `;
    }
  }};

  border-radius: 16px;
  background: transparent;
`;

const commonWrapperStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export const InitialsWrapper = styled.div`
  ${commonWrapperStyles};

  background: ${({ theme }) => theme.colors.bg.avatar};
  font-weight: 500;
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.typography.gray64};
  text-transform: uppercase;
`;

export const ABFWrapper = styled.div`
  ${commonWrapperStyles};
`;

export const DefaultWrapper = styled.div`
  ${commonWrapperStyles};

  background: ${({ theme }) => theme.colors.bg.avatar};
  color: ${({ theme }) => theme.colors.typography.gray64};
`;
