import styled, { css } from 'styled-components';
import { getMediaFor } from '../../../../layouts';

export const TransferDocumentsContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const DocumentsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  width: 100%;
  max-width: 320px;
`;

export const AttachButtonContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 450px;

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      button {
        width: 100%;
      }
    `)}
`;
