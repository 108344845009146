import { FC, SVGProps } from 'react';

export const ImageOperationSuccess: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg viewBox="0 0 340 220" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g clipPath="url(#clip0_2102_46567)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M331.744 39L334 0L298 15.7428L307.223 22.0999L208.019 163.82L132.927 121.298L126.356 117.577L120.979 122.879L57.0498 185.908L48.0708 176.929L40.9998 169.858L33.9287 176.929L4.92871 205.929L19.0708 220.071L40.9998 198.142L49.9287 207.071L56.9498 214.092L64.0205 207.121L129.643 142.423L206.072 185.702L213.98 190.18L219.192 182.735L323.691 33.4501L331.744 39Z"
          fill="#F5B70A"
        />
        <path
          d="M98.9062 104C98.9062 104 93.789 115.09 89 127.57L97.5352 130L117 105.391L98.9062 104Z"
          fill="white"
          stroke="#141414"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M60 138L50 150C50 150 44.1728 143.189 36 151L65 175L82 155L60 138Z" fill="white" />
        <path
          d="M50 150L60 138L82 155L65 175L36 151C44.1728 143.189 50 150 50 150ZM50 150L53 153"
          stroke="#141414"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M218.447 92.8925C215.354 92.8925 212.784 93.6957 210.716 95.0211C193.647 74.8 171.98 57.5508 143.948 59.6995C117.301 61.7477 103.686 83.776 95.333 106.367C104.972 109.017 114.59 111.708 124.189 114.519C128.526 102.371 134.731 89.3784 149.028 89.258C166.458 89.1174 186.739 111.708 196.8 124.037C203.045 131.688 210.274 128.435 214.19 121.447C215.475 121.808 216.9 122.029 218.447 122.029C237.202 122.029 237.242 92.8925 218.447 92.8925Z"
          fill="#141414"
        />
        <path
          d="M98 98L121 105"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M237 90.9998C225.395 91.0662 206.576 104.208 201.318 113.575C180.9 106.121 160.061 99.1117 138 100C106.132 101.285 76.9841 116.225 56 140C64.0121 146.607 72.1887 153.173 80 160C96.2652 140.964 122.12 130.021 146.578 131.266C162.131 132.056 176.499 139.821 191 145C207 150.714 219.739 144 230 134C237.8 126.393 251 116.587 251 104C251 96 244.678 90.9558 237 90.9998Z"
          fill="#141414"
        />
        <path
          d="M63 131L86 155"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M138 100C124.731 100.535 111.933 103.438 100 108.359"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M236 28C227 28 224 31 224 34C224 37.2235 222 38.9995 222 38.9995L225 39.9995C225 39.9995 226 46.9995 232 45.9995L238 59L239 65L248 55L236 28Z"
          fill="white"
          stroke="#141414"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M239.973 24.9997C236 21.9998 231.784 25.1985 230 26C228.213 24.8956 226 24 224 24.9997C217.55 28.2238 225.023 34.9075 226.458 30.6025C229.109 32.6307 233.708 33.7752 235.736 33.7351C237.884 33.6949 238.005 36.5664 236.499 37.47C235.153 38.2933 239.049 40.6026 239.973 40.703C240.374 40.7432 240.776 40.7231 241.177 40.6829C243.068 37.5703 243.945 27.9995 239.973 24.9997Z"
          fill="#141414"
        />
        <path
          d="M198 80.8434L214.909 90L220 65C209.937 70.0252 198 80.8434 198 80.8434Z"
          fill="white"
          stroke="#141414"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M246.268 59.1529C232.152 58.3978 224.719 61.1218 220 65.0002C214.383 69.6165 212.608 76.1328 207.981 84.0397C203.319 91.9941 200 97.0344 200 97.0344L241.329 122C241.329 122 255.731 104.142 262.132 87.3672C267.205 74.0291 267.418 66.8435 262 63C258.788 60.7217 253.598 59.5486 246.268 59.1529Z"
          fill="white"
          stroke="#141414"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M236 56L234 58L235 70L239 65L236 56Z"
          fill="white"
          stroke="#141414"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M248 55L239 65L244 71L252 59L248 55Z"
          fill="white"
          stroke="#141414"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M196 78L168 108V71L196 41V78Z"
          fill="#141414"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M192 77L173 97V73L192 52V77Z" fill="white" />
        <path
          d="M196 78L168 108L201 110L229 79L196 78Z"
          fill="#141414"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M229 97.006C217.635 87.8483 214.508 84.2488 212.023 85.1286C209.217 86.1283 214.168 89.4275 214.128 90.7072C214.067 92.6467 205.068 86.9681 201.941 89.8874C199.395 92.2468 207.513 93.4265 209.237 94.0863C209.237 94.0863 199.977 91.627 198.714 94.8461C197.451 98.0653 204.366 96.2858 206.752 98.9451C206.752 98.9451 198.594 96.0458 198.033 99.125C197.471 102.204 204.326 101.924 206.992 104.984C209.658 108.063 208.544 111.362 216 119L229 97.006Z"
          fill="white"
          stroke="#141414"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M251.147 86.3306C254.66 90.2223 257.972 94.3921 259.999 99.1773C261.966 103.824 263.532 112.084 258.253 115.4C253.696 118.259 247.534 113.275 244.182 110.396C239.103 106.028 234.339 100.971 229 97C224.985 104.367 220.597 111.971 216 119C224.109 125.155 230.553 133.23 240.147 137.539C252.853 143.237 267.406 142.264 277.402 131.88C286.535 122.409 288.803 108.907 285.672 96.4769C282.38 83.4316 272.378 71.2799 262 63L251.147 86.3306Z"
          fill="white"
        />
        <path
          d="M251.147 86.3306C254.66 90.2223 257.972 94.3921 259.999 99.1773C261.966 103.824 263.532 112.084 258.253 115.4C253.696 118.259 247.534 113.275 244.182 110.396C239.103 106.028 234.339 100.971 229 97C224.985 104.367 220.597 111.971 216 119C224.109 125.155 230.553 133.23 240.147 137.539C252.853 143.237 267.406 142.264 277.402 131.88C286.535 122.409 288.803 108.907 285.672 96.4769C282.38 83.4316 272.378 71.2799 262 63"
          stroke="#141414"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2102_46567">
          <rect width="340" height="220" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
