import { useMediaQuery } from 'react-responsive';
import { hardBreakpoints } from '../media';

export const useMedia = () => ({
  isDesktop: useDesktop(),
  isLaptop: useLaptop(),
  isTablet: useTablet(),
  isMobile: useMobile(),

  isLaptopMaxWidth: useLaptopMaxWidth(),
  isTabletMaxWidth: useTabletMaxWidth(),

  isLandscape: useLandscape(),
  isLandscapeMobile: useLandscapeMobile(),
});

/**
 * useDesktop - from 1440px and more
 */
const useDesktop = () => useMediaQuery({ minWidth: hardBreakpoints.desktop });

/**
 * useLaptop - from 1280px to 1439px
 */
const useLaptop = () =>
  useMediaQuery({ minWidth: hardBreakpoints.laptop, maxWidth: hardBreakpoints.desktop - 1 });

/**
 * useTablet - from 768 to 1279px
 */
const useTablet = () =>
  useMediaQuery({ minWidth: hardBreakpoints.tablet, maxWidth: hardBreakpoints.laptop - 1 });

/**
 * useMobile - up to 767px
 */
const useMobile = () => useMediaQuery({ maxWidth: hardBreakpoints.tablet - 1 });

/**
 * useLaptopMaxWidth - up to 1439px
 */
const useLaptopMaxWidth = () => useMediaQuery({ maxWidth: hardBreakpoints.desktop - 1 });

/**
 * useTabletMaxWidth - up to 1279px
 */
const useTabletMaxWidth = () => useMediaQuery({ maxWidth: hardBreakpoints.laptop - 1 });

/**
 * useLandscape - for landscape orientation
 */
const useLandscape = () => useMediaQuery({ orientation: 'landscape' });

/**
 * useLandscapeMobile - up to 767px and landscape orientation
 */
const useLandscapeMobile = () =>
  useMediaQuery({ orientation: 'landscape', maxWidth: hardBreakpoints.tablet - 1 });
