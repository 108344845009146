import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import * as S from './styled';
import { AddressStep, DetailsStep, InfoStep } from './steps';

import {
  GetActiveCountriesResult,
  GetLegalCompanyTypesResult,
  useCreateBankRecipientMutation,
  useGetActiveCountriesTransferOutQuery,
} from '../../../../api';
import {
  AsyncErrorMessage,
  bankAccountRegExp,
  getAsyncErrorMessage,
  nameAndSurnameRegExp,
  Shape,
  useFormSteps,
  yup,
} from '../../../../forms';
import { activeProfileUidSelector, countrySelector, useAppSelector } from '../../../../store';
import {
  BankRecipientLegalInfoDto,
  BankRecipientNaturalInfoDto,
  Country,
  ExtendedRecipient,
  RecipientType,
} from '../../../../types';
import type { AddBankAccountFormFields } from '../../Recipients';

type AddBankRecipientFormProps = {
  activeCountries: GetActiveCountriesResult | undefined;
  legalCompanyTypes: GetLegalCompanyTypesResult | undefined;
  isCopyBankRecipient?: boolean;
  copiedAccount?: ExtendedRecipient;
  onCancel: () => void;
};
type AddBankRecipientFormSteps = 'details' | 'info' | 'address';

const addBankRecipientSchema: Record<AddBankRecipientFormSteps, any> = {
  details: yup.object().shape<Shape<AddBankAccountFormFields>>({
    currencyCode: yup.string().required(),
    bankName: yup.string().required(),
    bankCode: yup.string().matches(bankAccountRegExp).required(),
    account: yup.string().matches(bankAccountRegExp).required(),
    countryCode: yup.string().required(),
  }),
  info: yup.object().shape<Shape<AddBankAccountFormFields>>({
    email: yup.string().email().required(),
    recipientType: yup.string().required(),
    infoDto: yup
      .object()
      .required()
      .when('recipientType', {
        is: (value: AddBankAccountFormFields['recipientType']) => value === RecipientType.LEGAL,
        then: (schema) =>
          schema.shape<Shape<BankRecipientLegalInfoDto>>({
            companyTypeId: yup.number().required(),
            name: yup
              .string()
              .required()
              .max(
                35,
                'recipients.header.actions.add-recipient.form.bank.error.max-length-company-name'
              ),
            incorporationCountryCode: yup.string().required(),
            // incorporationDate: yup.date().min('1900-01-01 00:00:00.000').max(new Date()).required(),
            // registrationNumber: yup.string().required(),
          }),
      })
      .when('recipientType', {
        is: (value: AddBankAccountFormFields['recipientType']) => value === RecipientType.NATURAL,
        then: (schema) =>
          schema.shape<Shape<BankRecipientNaturalInfoDto>>({
            firstName: yup
              .string()
              .matches(nameAndSurnameRegExp)
              .max(
                17,
                'recipients.header.actions.add-recipient.form.bank.error.max-length-first-name'
              )
              .required(),
            lastName: yup
              .string()
              .matches(nameAndSurnameRegExp)
              .max(
                17,
                'recipients.header.actions.add-recipient.form.bank.error.max-length-last-name'
              )
              .required(),
            middleName: yup.string(),
          }),
      }),
  }),
  address: yup.object().shape<Shape<AddBankAccountFormFields>>({
    recipientAddress: yup.object({
      country: yup.string().required(),
      state: yup.string(),
      city: yup.string().required(),
      street: yup.string().required(),
      buildingNumber: yup.string().required(),
      flatNumber: yup.string(),
      zipCode: yup.string().required(),
    }),
  }),
};

export const AddBankRecipientForm: FC<AddBankRecipientFormProps> = ({
  legalCompanyTypes,
  onCancel,
  copiedAccount,
  isCopyBankRecipient,
}) => {
  const { t } = useTranslation();
  const allCountries = useAppSelector(countrySelector);
  const { data: countries } = useGetActiveCountriesTransferOutQuery({ currency: undefined });
  const profileUid = useAppSelector(activeProfileUidSelector);
  const [activeCountries, setActiveCountries] = useState<Country[]>([]);

  const [createBankRecipient, { isLoading: isCreateBankRecipientLoading }] =
    useCreateBankRecipientMutation();

  // form steps data
  const stepsOrder: AddBankRecipientFormSteps[] = ['details', 'info', 'address'];
  const {
    activeStep: _activeStep,
    isFirstStep,
    isLastStep,
    getNextStep,
    getPrevStep,
  } = useFormSteps(stepsOrder);
  const activeStep = _activeStep as AddBankRecipientFormSteps;

  useEffect(() => {
    if (allCountries && countries) {
      setActiveCountries(allCountries.filter((it) => countries?.some((c) => c === it.alpha2Code)));
    }
  }, [countries]);

  // form data
  const formMethods = useForm<AddBankAccountFormFields>({
    defaultValues:
      !isCopyBankRecipient && !copiedAccount
        ? undefined
        : {
            email: copiedAccount?.email,
            recipientAddress: copiedAccount?.addressDto,
            infoDto: { ...copiedAccount?.additionalInfo?.infoDto },
          },
    resolver: yupResolver(addBankRecipientSchema[activeStep]),
    shouldUnregister: false,
    mode: 'onChange',
  });
  const {
    setError,
    handleSubmit,
    trigger,
    formState: { errors },
  } = formMethods;

  const closeForm = () => {
    onCancel();
  };

  const showNextStep = async () => {
    const isStepValid = await trigger();
    if (isStepValid) getNextStep();
  };

  const showPrevStep = () => {
    getPrevStep();
  };

  const addBankRecipient: SubmitHandler<AddBankAccountFormFields> = async (values) => {
    let mappedValues = values;

    if (values.infoDto) {
      mappedValues = {
        ...values,
        infoDto: {
          ...values.infoDto,
        },
      };
    }

    try {
      await createBankRecipient({ profileUid: profileUid, body: mappedValues }).unwrap();
      closeForm();
    } catch (e) {
      getAsyncErrorMessage(e, setError);
    }
  };

  const stepsMap: Record<AddBankRecipientFormSteps, ReturnType<FC>> = {
    details: <DetailsStep activeCountries={activeCountries} />,
    info: (
      <InfoStep
        activeCountries={activeCountries}
        legalCompanyTypes={legalCompanyTypes}
        copiedAccount={copiedAccount}
        isCopyBankRecipient={isCopyBankRecipient}
      />
    ),
    address: <AddressStep activeCountries={activeCountries} />,
  };

  return (
    <FormProvider {...formMethods}>
      <S.AddBankRecipientForm onSubmit={handleSubmit(addBankRecipient)}>
        <S.AddBankRecipientFormFields>
          {stepsMap[activeStep]}

          <AsyncErrorMessage errors={errors} />
        </S.AddBankRecipientFormFields>

        <S.AddBankRecipientFormActions>
          <S.AddBankRecipientPrevStepButton
            variant="contained"
            body={t('ui.forms.buttons.prev-step')}
            disabled={isFirstStep}
            onClick={() => showPrevStep()}
          />

          {!isLastStep && (
            <S.AddBankRecipientNextStepButton
              body={t('ui.forms.buttons.next-step')}
              onClick={() => showNextStep()}
            />
          )}

          {isLastStep && (
            <S.CreateBankRecipientButton
              body={t('recipients.header.actions.add-recipient.form.bank.create-recipient-btn')}
              type="submit"
              loading={isCreateBankRecipientLoading}
            />
          )}
        </S.AddBankRecipientFormActions>
      </S.AddBankRecipientForm>
    </FormProvider>
  );
};
