import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { Divider as MuiDivider, Menu as MuiMenu, MenuItem as MuiMenuItem } from '@mui/material';

export const WalletMenu = styled(MuiMenu)`
  .MuiMenu-paper {
    margin-top: 8px;
    border-radius: 16px;
    background-color: ${({ theme }) => theme.colors.bg.contextMenu};
    box-shadow: ${({ theme }) => theme.shadowsCustom.contextMenu};
  }

  .MuiMenu-list {
    width: 248px;
    padding: 0;
  }
`;

export const WalletAvailableBalanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px;
`;
export const WalletIdWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 0 16px 4px 16px;
`;
export const WalletLinksWrapper = styled.div`
  padding: 8px 0;
`;
export const WalletSettingsWrapper = styled.div`
  padding: 8px 0;
`;

export const WalletMenuDivider = styled(MuiDivider)`
  border: 1px solid ${({ theme }) => theme.colors.borders.white10};
`;

export const WalletMenuItem = styled(MuiMenuItem)`
  &.MuiMenuItem-root {
    padding: 0;
  }
`;

const commonLinkStyles = css`
  display: flex;
  align-items: center;
  gap: 8px;

  width: 100%;
  padding: 8px 16px;

  font-weight: 400;
  font-size: 1.6rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.gray80};

  svg {
    color: ${({ theme }) => theme.colors.typography.gray88};
  }
`;
export const WalletLink = styled(Link)`
  ${commonLinkStyles};
`;
export const WalletContextBtn = styled.div`
  ${commonLinkStyles};
`;
export const WalletLinkIcon = styled.div`
  display: flex;
  align-items: center;
`;
export const WalletIdIconIcon = styled.div`
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
`;
export const OtherLink = styled.button`
  ${commonLinkStyles};
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:disabled {
    opacity: 0.4;
  }

  .end-icon {
    margin-left: auto;
  }
`;

export const WalletAvailableBalance = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  font-weight: 400;
  font-size: 1.6rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.gray88};

  &.wallet-available-balance-amount {
    min-height: 16px;
    margin-left: 32px;

    color: ${({ theme }) => theme.colors.typography.gray80};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.wallet-id-amount {
    min-height: 16px;
    padding: 4px;
    padding-left: 32px;
    padding-bottom: 12px;

    color: ${({ theme }) => theme.colors.typography.gray80};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    cursor: pointer;

    &:hover {
      text-decoration: none;
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
`;
