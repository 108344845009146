/* eslint-disable react-hooks/exhaustive-deps*/
import { FC, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { LoaderFunction, redirect, useNavigate } from 'react-router-dom';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';

import * as S from './styled';
import { ProfileCard } from './components';
import { Loader } from '../../components';
import { ProfileLayoutTitle } from '../../layouts';

import { changeProfile, removeItemFromStorage } from '../../utils';
import { authGuard, getRouterError, RouterPaths, useAppLoading } from '../../router';
import { GetProfilesResult, profileEndpoints, runEndpointInRouter } from '../../api';
import type { ProfileInfo } from '../../types';
import { profileSelector, useAppSelector } from '../../store';

// appear here after redirect (cuz length of profile list > 1)
// 1) show list of profiles
// 2) choose one and set it into LS
// 3) redirect to Root

export const profilesLoader: LoaderFunction = async () => {
  authGuard();

  try {
    const { data: profileData } = await runEndpointInRouter(
      profileEndpoints,
      'getProfiles',
      undefined,
      true
    );

    const profiles = profileData as GetProfilesResult;
    if (profiles?.length === 0) {
      removeItemFromStorage('activeProfile');
      return redirect(RouterPaths.ProfileCreate);
    }
  } catch (e) {
    throw getRouterError(e as FetchBaseQueryError);
  }

  return null;
};

export const Profiles: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isAppLoading = useAppLoading();

  const profiles = useAppSelector(profileSelector);

  const chooseProfileHandler = (profile: ProfileInfo) => {
    changeProfile(profile, navigate);
  };

  const profilesList = useMemo(() => {
    return profiles?.map((profile) => {
      return (
        <ProfileCard
          key={profile.profileUid}
          profile={profile}
          onClick={() => chooseProfileHandler(profile)}
        />
      );
    });
  }, [profiles]);

  return (
    <S.ProfilesContainer>
      <Helmet title={t('pages.profiles')} />
      <ProfileLayoutTitle text={t('profiles.title')} />

      <S.ProfilesList>{profilesList}</S.ProfilesList>

      {isAppLoading && <Loader variant="fullScreen" />}
    </S.ProfilesContainer>
  );
};
