import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { Divider as MuiDivider, Menu as MuiMenu, MenuItem as MuiMenuItem } from '@mui/material';

import { Button, IconButton } from '../../../../components';

export const UserMenuButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export const VerifyAlertButton = styled(IconButton)`
  padding: 0;
  &:hover {
    background-color: transparent;
  }
`;

export const UserMenuButton = styled(Button)`
  padding: 8px 10px;
  color: ${({ theme }) => theme.colors.typography.white50};
`;

export const UserMenu = styled(MuiMenu)`
  .MuiMenu-paper {
    margin-top: 8px;
    border-radius: 16px;
    background-color: ${({ theme }) => theme.colors.bg.contextMenu};
    box-shadow: ${({ theme }) => theme.shadowsCustom.contextMenu};
  }

  .MuiMenu-list {
    padding: 0;
  }
`;

export const UserNameWrapper = styled.div`
  padding: 8px 0;
`;
export const UserLinksWrapper = styled.div`
  padding: 8px 0;
`;
export const UserProfilesWrapper = styled.div`
  padding: 8px 0;
`;
export const OtherLinksWrapper = styled.div`
  padding: 8px 0;
`;
export const UserMenuDivider = styled(MuiDivider)`
  border: 1px solid ${({ theme }) => theme.colors.borders.white10};
`;

export const UserMenuItem = styled(MuiMenuItem)`
  &.MuiMenuItem-root {
    width: 280px;
    padding: 0;
  }
`;

export const UserName = styled.div`
  width: 280px;
  padding: 8px 16px;

  font-size: 1.6rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.gray80};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const commonLinkStyles = css`
  display: flex;
  align-items: center;
  gap: 12px;

  width: 100%;
  padding: 8px 20px;

  font-weight: 400;
  font-size: 1.6rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.gray80};

  svg {
    color: ${({ theme }) => theme.colors.typography.gray88};
  }
`;
export const UserLink = styled(Link)`
  ${commonLinkStyles};
`;
export const OtherLink = styled.button`
  ${commonLinkStyles};
  border: none;
  background-color: transparent;
  cursor: pointer;

  &:disabled {
    opacity: 0.4;
  }
`;
export const ExternalLink = styled.a`
  ${commonLinkStyles};
`;

export const ProfileCardContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 8px 16px;
`;
export const ProfileCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 2px;
`;
export const ProfileName = styled.div`
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.gray88};
  text-transform: capitalize;
  white-space: pre-wrap;
`;
export const ProfileType = styled.div`
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.gray48};
  text-transform: capitalize;
`;
