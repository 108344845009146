export enum ProfileType {
  LEGAL = 'LEGAL',
  NATURAL = 'NATURAL',
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  UNSPECIFIED = 'UNSPECIFIED',
}

export enum AddressType {
  LEGAL = 'LEGAL',
  CORRESPONDENCE = 'CORRESPONDENCE',
}

export enum SumsubLevelName {
  BASIC_KYC_LEVEL = 'BASIC_KYC_LEVEL',
  ADDRESS = 'ADDRESS',
}

// Natural
export enum NaturalIdDocumentType {
  PASSPORT = 'PASSPORT',
  NATIONAL_ID_CARD = 'NATIONAL_ID_CARD',
}

// Legal
export enum RelationType {
  UBO = 'UBO',
  DIRECTOR = 'DIRECTOR',
  REPRESENTATIVE = 'REPRESENTATIVE',
}
