import { FC, MouseEvent, useEffect, useState } from 'react';
import defaultCoinLogo from 'cryptocurrency-icons/svg/icon/generic.svg';

import * as S from './styled';
import { loadCoinLogo } from '../../../layouts';
import { Recipient, Wallet } from '../../../types';
import { IconRightArrow } from '../../../assets/svg/IconRightArrow/IconRightArrow';
import { IconButton, LineInfo } from '../../../components';
import { getCoinCodeForFlags } from '../../../utils';
import { useTranslation } from 'react-i18next';

type RecipientInfoProps = {
  recipient: Recipient;
};

export const RecipientInfo: FC<RecipientInfoProps> = ({ recipient }) => {
  const { t } = useTranslation();
  const { name, recipientAccountType, currencyCode, recipientType, comment } = recipient;
  const [walletLogo, setWalletLogo] = useState<string>(defaultCoinLogo);

  return (
    <S.Col>
      <LineInfo label={t('send.beneficiaryName')} value={name} />
      <LineInfo
        label={t('send.accountNumber')}
        value={recipientAccountType === 'LOCAL' ? t('send.abfAccount') : comment}
      />
      {currencyCode && <LineInfo label={t('send.currencyCode')} value={currencyCode} />}
    </S.Col>
  );
};
