import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { businessFeatureEndpoints } from '../../api';
import type { Traits, AuthMethod } from '../../services';
import type { BusinessFeature } from '../../types';

type AuthState = {
  authStatus: boolean;
  traits: Traits | null;
  authMethods: AuthMethod[];
  logoutUrl: string;
  businessFeatures: BusinessFeature[] | null;
};

const initialState: AuthState = {
  authStatus: false,
  traits: null,
  authMethods: [],
  logoutUrl: '',
  businessFeatures: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthStatus: (state, action: PayloadAction<boolean>) => {
      state.authStatus = action.payload;
    },
    setTraits: (state, action: PayloadAction<Traits | null>) => {
      state.traits = action.payload;
    },
    setAuthMethods: (state, action: PayloadAction<AuthMethod[]>) => {
      state.authMethods = action.payload;
    },
    setLogoutUrl: (state, action: PayloadAction<string>) => {
      state.logoutUrl = action.payload;
    },
    logout: (state) => {
      return state;
    },
  },
  extraReducers: (build) => {
    build.addMatcher(
      businessFeatureEndpoints.endpoints.getBusinessFeatures.matchFulfilled,
      (state, action) => {
        state.businessFeatures = action.payload;
      }
    );
  },
});

export const authActions = authSlice.actions;
export const authReducer = authSlice.reducer;
