import { apiSlice } from '../../api';
import {
  DeleteNotificationDTO,
  GetNotificationCheckDTO,
  GetNotificationCheckResult,
  GetNotificationDTO,
  GetNotificationResult,
  PutNotificationDTO,
  PutNotificationReadResult,
} from './types';

export const notificationEndpoints = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getNotification: build.query<GetNotificationResult, GetNotificationDTO>({
      query: (data) => ({
        url: `/notifications-service/v1/notifications?limit=${data.limit}&offset=${data.offset}`,
        method: 'GET',
        providesTags: ['Notification'],
      }),
    }),
    putAllNotificationsRead: build.mutation<unknown, void>({
      query: (queryArg) => ({
        url: '/notifications-service/v1/notifications/read',
        method: 'PUT',
      }),
    }),
    getNotificationCheck: build.query<GetNotificationCheckResult, GetNotificationCheckDTO>({
      query: () => ({
        url: '/notifications-service/v1/notifications/check',
        method: 'GET',
        providesTags: ['Notification'],
      }),
    }),
    putNotificationRead: build.mutation<PutNotificationReadResult, PutNotificationDTO>({
      query: (notificationUid) => ({
        url: `/notifications-service/v1/notifications/${notificationUid}/read`,
        method: 'PUT',
      }),
    }),
    deleteNotification: build.mutation<DeleteNotificationDTO, DeleteNotificationDTO>({
      query: (notificationUid) => ({
        url: `/notifications-service/v1/notifications/${notificationUid}`,
        method: 'DELETE',
      }),
    }),
    deleteAllNotification: build.mutation<unknown, void>({
      query: (notificationUid) => ({
        url: '/notifications-service/v1/notifications',
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useLazyGetNotificationQuery,
  useLazyGetNotificationCheckQuery,
  useGetNotificationQuery,
  useGetNotificationCheckQuery,
} = notificationEndpoints;
export const { usePutNotificationReadMutation, usePutAllNotificationsReadMutation } =
  notificationEndpoints;
export const { useDeleteNotificationMutation, useDeleteAllNotificationMutation } =
  notificationEndpoints;

export type NaturalController = typeof notificationEndpoints;
