import { useTranslation } from 'react-i18next';

import type { AvailableLanguages } from '../i18n';

/**
 * Helper for right typification, if using key value as template string
 */
export const useTranslationByKeyVariable = (keyVariable: string) => {
  const { t } = useTranslation();

  return t(`${keyVariable}` as unknown as TemplateStringsArray);
};

export const getFlagFromCode = (code: AvailableLanguages) => {
  if (code === 'en') return 'gb';
  return code;
};
