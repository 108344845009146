import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import * as S from './styled';
import { IconVerificationWarning } from '../../../../assets';

import {
  activeProfileTypeSelector,
  addressVerifyStatusSelector,
  profileVerifyStatusSelector,
  useAppSelector,
} from '../../../../store';
import { RouterPaths } from '../../../../router';
import { ProfileType } from '../../../../types';

type VerificationBarType = 'sidebar' | 'mobile';
type VerificationBarProps = {
  type: VerificationBarType;
};

export const VerificationBar: FC<VerificationBarProps> = ({ type }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const isVerified = useAppSelector(profileVerifyStatusSelector);
  const isAddressVerified = useAppSelector(addressVerifyStatusSelector);
  const profileType = useAppSelector(activeProfileTypeSelector);

  const isLegal = profileType === ProfileType.LEGAL;
  const hasAllVerifications = isVerified && isAddressVerified;

  const verifyHandler = () => {
    if (hasAllVerifications) return;
    if (isLegal) return;

    navigate(RouterPaths.Verification);
  };

  const Wrapper = type === 'mobile' ? S.VerificationBarMobileWrapper : S.VerificationBarWrapper;

  const barElement = (
    <Wrapper>
      <S.VerificationBarInfo>
        <S.VerificationBarIconWrapper>
          <IconVerificationWarning />
        </S.VerificationBarIconWrapper>

        {t('dashboard-layout.verification-bar.message')}
      </S.VerificationBarInfo>

      <S.VerificationBarActions>
        <S.VerifyButton
          variant="outlined"
          body={t('dashboard-layout.verification-bar.action-btn')}
          onClick={() => verifyHandler()}
        />
      </S.VerificationBarActions>
    </Wrapper>
  );

  const typeMap: Record<VerificationBarType, ReturnType<FC>> = {
    sidebar: barElement,
    mobile: barElement,
  };

  return typeMap[type];
};
