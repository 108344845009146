import styled, { css } from 'styled-components';

import { getMediaFor } from '../../layouts';

export const InfoCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  min-width: 420px;
  max-width: 100%;
  padding: 40px;

  border-radius: 40px;
  background-color: ${({ theme }) => theme.colors.bg.dialog};
  box-shadow: ${({ theme }) => theme.shadowsCustom.dialog};

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      gap: 16px;
      padding: 16px;
      border-radius: 16px;
    `)};
`;

export const InfoCardImageWrapper = styled.div`
  svg {
    width: 100%;
  }
`;

export const InfoCardText = styled.div`
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 130%;
  color: ${({ theme }) => theme.colors.typography.white};
  text-align: left;

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      font-size: 2.4rem;
      line-height: 133%;
    `)};
`;
