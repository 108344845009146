import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { Comment } from '../../types';

type MessageState = {
  message: Comment[];
  newMessage: Comment | undefined;
};

const initialState: MessageState = {
  message: [],
  newMessage: undefined,
};

export const message = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setChatMessage: (state, action: PayloadAction<Comment[]>) => {
      state.message = action.payload;
    },
    addNewMessages: (state, action: PayloadAction<Comment>) => {
      state.message = [action.payload, ...state.message];
    },
    updateMessageList: (state, action: PayloadAction<Comment[]>) => {
      state.message = [...state.message, ...action.payload];
    },
    setNewMessage: (state, action: PayloadAction<any>) => {
      state.newMessage = action.payload;
    },
  },
});

export const messageActions = message.actions;
export const messageReducer = message.reducer;
