import styled, { css } from 'styled-components';

export const Input = styled.input<{
  $startAdornmentIndent: number | false;
  $endAdornmentIndent: number | false;
}>`
  height: 48px;
  width: 100%;
  padding: 16px;

  border: 2px solid ${({ theme }) => theme.colors.borders.white05};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.bg.white05};

  font-weight: 400;
  font-size: 1.6rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.white};

  &::placeholder {
    color: ${({ theme }) => theme.colors.typography.gray64};
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.borders.gray80};
    outline: none;
  }

  &:active,
  &:focus,
  &:focus-visible {
    border-color: ${({ theme }) => theme.colors.borders.white};
    outline: none;
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:active,
  &:disabled:focus,
  &:disabled:focus-visible {
    border: none;
    color: ${({ theme }) => theme.colors.typography.gray32};
    cursor: not-allowed;
  }

  // custom classes
  &.error {
    border-color: ${({ theme }) => theme.colors.error};
  }

  &.start-adornment {
    ${({ $startAdornmentIndent }) =>
      $startAdornmentIndent && `padding-left: ${$startAdornmentIndent}px`};
  }

  &.end-adornment {
    ${({ $endAdornmentIndent }) =>
      $endAdornmentIndent && `padding-right: ${$endAdornmentIndent}px`};
  }

  &.non-editable {
    border: none;
    color: ${({ theme }) => theme.colors.typography.gray48};
  }

  &.disable-number-arrows {
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    [type='number'] {
      -moz-appearance: textfield;
    }
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const LabelWrapper = styled.label`
  display: block;
  position: relative;
  width: 100%;
`;

export const Label = styled.span`
  display: inline-block;
  margin-bottom: 4px;

  font-weight: 400;
  font-size: 1.6rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.gray48};
`;

export const InputAdornment = styled.span<{
  $type: 'start' | 'end';
  $disabled?: boolean;
  $nonEditable?: boolean;
}>`
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 48px;
  margin: 0 16px;

  font-weight: 400;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.typography.white};

  ${({ $type }) => {
    switch ($type) {
      case 'start':
        return css`
          left: 0;
        `;
      case 'end':
        return css`
          right: 0;
        `;
    }
  }};

  ${({ $disabled }) =>
    $disabled &&
    css`
      color: ${({ theme }) => theme.colors.typography.gray32};
    `};
  ${({ $nonEditable }) =>
    $nonEditable &&
    css`
      color: ${({ theme }) => theme.colors.typography.gray48};
    `};
`;
