import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import * as S from './styled';
import { Loader, Select, SelectOption } from '../../../../components';

import {
  DeleteRoleToRelatedPersonDTO,
  UpdateRoleToRelatedPersonDTO,
  useDeleteRoleFromRelatedPersonMutation,
  useGetLegalRolesQuery,
  useUpdateRoleToRelatedPersonMutation,
} from '../../../../api';
import { useACL } from '../../../../services';
import { activeProfileUidSelector, useAppSelector } from '../../../../store';
import { AsyncErrorMessage, getAsyncErrorMessage, Shape, yup } from '../../../../forms';
import { RelatedPerson } from '../../../../types';

type MemberDetailedInfoProps = {
  member: RelatedPerson | null;
  onCancel: () => void;
};
type MemberInfoFormFields = { roleId: string };

const memberInfoSchema = yup.object().shape<Shape<MemberInfoFormFields>>({
  roleId: yup.string().required(),
});

export const MemberDetailedInfo: FC<MemberDetailedInfoProps> = ({ member, onCancel }) => {
  const { t, i18n } = useTranslation();
  const hasPermission_RELATED_PERSON_ROLE_MANAGEMENT = useACL(['RELATED_PERSON_ROLE_MANAGEMENT']);

  const profileUid = useAppSelector(activeProfileUidSelector);

  const {
    data: rolesList,
    isLoading: isRolesLoading,
    isSuccess: isRolesSuccess,
  } = useGetLegalRolesQuery();
  const [updateRole, { isLoading: isUpdateRoleLoading }] = useUpdateRoleToRelatedPersonMutation();
  const [deleteRole, { isLoading: isDeleteRoleLoading }] = useDeleteRoleFromRelatedPersonMutation();

  const {
    control,
    setError,
    handleSubmit,
    reset,
    formState: { errors, isDirty },
  } = useForm<MemberInfoFormFields>({
    resolver: yupResolver(memberInfoSchema),
  });

  useEffect(() => {
    if (isRolesSuccess && member) {
      const roleId = member.userRoleId ?? 'no-access';
      reset({ roleId: String(roleId) });
    }
  }, [isRolesSuccess]);

  const updateMemberInfo: SubmitHandler<MemberInfoFormFields> = async ({ roleId }) => {
    if (!isDirty || !member) {
      return;
    }

    const isDeleteRole = roleId === 'no-access';

    const updateBody: UpdateRoleToRelatedPersonDTO = {
      profileUid,
      relatedPersonId: member.id,
      body: { roleId: Number(roleId) },
    };
    const deleteBody: DeleteRoleToRelatedPersonDTO = {
      profileUid,
      relatedPersonId: member.id,
    };

    try {
      !isDeleteRole && (await updateRole(updateBody).unwrap());
      isDeleteRole && (await deleteRole(deleteBody).unwrap());
      onCancel();
    } catch (e) {
      getAsyncErrorMessage(e, setError);
    }
  };

  const rolesOptions = useMemo(() => {
    if (!rolesList || !hasPermission_RELATED_PERSON_ROLE_MANAGEMENT) return [];

    const roles = rolesList.map((role) => {
      const { id, name } = role;

      return (
        <SelectOption
          key={id}
          value={String(id)}
          name={t(`common.role-name.${name}` as unknown as TemplateStringsArray)}
        >
          {t(`common.role-name.${name}` as unknown as TemplateStringsArray)}
        </SelectOption>
      );
    });

    const noAccessOption = (
      <SelectOption key="no-access" value="no-access" name={t('team.content.common.no-access')}>
        {t('team.content.common.no-access')}
      </SelectOption>
    );

    return [...roles, noAccessOption];
  }, [rolesList, i18n.resolvedLanguage]);

  return (
    <S.MemberInfoContainer>
      <S.MemberInfoForm onSubmit={handleSubmit(updateMemberInfo)}>
        {isRolesLoading ? (
          <Loader variant="centerOfContainer" />
        ) : (
          <S.MemberInfoFormFields>
            <Select
              control={{ control, name: 'roleId' }}
              errors={errors}
              options={rolesOptions}
              label={t('team.content.detailed-info.form.fields.role.label')}
              nonEditable={isRolesLoading}
            />

            <AsyncErrorMessage errors={errors} />
          </S.MemberInfoFormFields>
        )}

        <S.MemberInfoFormActions>
          <S.UpdateMemberRoleButton
            body={t('team.content.detailed-info.form.update-btn')}
            type="submit"
            loading={isUpdateRoleLoading || isDeleteRoleLoading}
            disabled={!hasPermission_RELATED_PERSON_ROLE_MANAGEMENT}
          />
        </S.MemberInfoFormActions>
      </S.MemberInfoForm>
    </S.MemberInfoContainer>
  );
};
