import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styled';
import { Button } from '../Button';
import { ImageOperationError, ImageOperationSuccess } from '../../assets';

import { bannerSelector, commonActions, useAppActions, useAppSelector } from '../../store';
import type { BannerVariant } from '../../types';

export const Banner: FC = () => {
  const { t } = useTranslation();

  const banner = useAppSelector(bannerSelector);
  const { removeBanner } = useAppActions(commonActions);

  if (!banner) return null;

  const closeBanner = () => {
    banner.onClose && banner.onClose();
    removeBanner();
  };

  const bannerImage =
    banner.variant === 'error' ? <ImageOperationError /> : <ImageOperationSuccess />;
  const bannerTitle =
    banner.title ??
    (banner.variant === 'error' ? (
      <>
        {t('ui.banners.component.error-title')}
        <span className="accent-text"> :(</span>
      </>
    ) : (
      t('ui.banners.component.success-title')
    ));

  return (
    <S.Banner open={Boolean(banner)} maxWidth={false}>
      <S.BannerContainer>
        <S.BannerImageWrapper>{bannerImage}</S.BannerImageWrapper>

        <S.BannerBody>
          <S.BannerTitle>{bannerTitle}</S.BannerTitle>
          <S.BannerMessage>{banner.message}</S.BannerMessage>
        </S.BannerBody>

        <Button body={t('ui.banners.component.action-btn')} onClick={() => closeBanner()} />
      </S.BannerContainer>
    </S.Banner>
  );
};

/**
 * Custom hook for easily display banner in React Components
 */
export const useBanner = () => {
  const { setBanner } = useAppActions(commonActions);

  const showBanner = useCallback(
    (variant: BannerVariant, message: string, title?: string, onClose?: () => void) => {
      setBanner({ variant, message, title, onClose });
    },
    [setBanner]
  );

  return {
    showBanner,
  };
};
