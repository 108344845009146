import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';
import defaultCoinLogo from 'cryptocurrency-icons/svg/icon/generic.svg';

import * as S from './styled';
import { InfoCard, Input, Loader } from '../../../../../../components';
import { loadCoinLogo } from '../WalletCard';

import {
  useGetWalletIBANRequestsQuery,
  useLazyGetIBANFeeQuery,
  useRequestIBANMutation,
} from '../../../../../../api';
import { RouterPaths } from '../../../../../../router';
import { getCoinCodeForFlags } from '../../../../../../utils';
import { AsyncErrorMessage, getAsyncErrorMessage } from '../../../../../../forms';
import type { Wallet } from '../../../../../../types';

type WalletIBANFormProps = {
  wallet: Wallet | undefined;
  onCancel: () => void;
};

export const WalletIBANForm: FC<WalletIBANFormProps> = ({ wallet, onCancel }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    setError,
    formState: { errors },
  } = useForm();

  const [walletLogo, setWalletLogo] = useState<string>(defaultCoinLogo);
  const coinCode = wallet?.coinResponseDto ? getCoinCodeForFlags(wallet.coinResponseDto) : '';

  useEffect(() => {
    void loadCoinLogo(coinCode, setWalletLogo);
  }, []);

  const {
    data: ibanRequests,
    isLoading: isIBANRequestsLoading,
    isSuccess: isIBANRequestsSuccess,
  } = useGetWalletIBANRequestsQuery(wallet?.uid ? { walletUid: wallet.uid } : skipToken);
  const [getIBANFee, { data: feeData, isLoading: isFeeQueryLoading }] = useLazyGetIBANFeeQuery();
  const [sendIBANRequest, { isLoading: isIBANRequestLoading }] = useRequestIBANMutation();

  const getRequestForIBANAvailableStatus = () => {
    if (feeData?.fee === undefined || wallet?.availableAmount === undefined) return false;
    if (feeData.fee === 0) return true;
    if (feeData.fee <= wallet.availableAmount) return true;
    if (feeData.fee > wallet.availableAmount) return false;

    return false;
  };

  const hasIBANRequests = ibanRequests && ibanRequests.length > 0;

  useEffect(() => {
    if (!isIBANRequestsSuccess) return;
    if (!wallet?.uid) return;
    if (hasIBANRequests) return;

    getIBANFee({ walletUid: wallet.uid });
  }, [isIBANRequestsSuccess]);

  const cancel = () => {
    onCancel();
  };

  const handleAction = async () => {
    if (!wallet) return;

    if (!getRequestForIBANAvailableStatus()) {
      navigate(RouterPaths.Receive, { state: { walletUid: wallet.uid } });
      onCancel();
      return;
    }

    try {
      await sendIBANRequest({ walletUid: wallet.uid }).unwrap();
      onCancel();
    } catch (e) {
      getAsyncErrorMessage(
        {
          status: 400,
          data: { message: t('dashboard-layout.wallet-bar.wallet-iban-form.iban-error-message') },
        },
        setError
      );
    }
  };

  return (
    <S.WalletIBANForm>
      <S.WalletIBANFormBody>
        <S.WalletIBANCard>
          <S.WalletLogoContainer>
            <S.WalletLogo src={walletLogo} />
          </S.WalletLogoContainer>

          <S.WalletIBANCardInfo>
            <span className="wallet-card-name">{wallet?.name}</span>
            <span className="wallet-card-balance">{wallet?.availableAmount}</span>
          </S.WalletIBANCardInfo>
        </S.WalletIBANCard>

        {isIBANRequestsLoading || isFeeQueryLoading ? (
          <Loader variant="centerOfContainer" />
        ) : hasIBANRequests ? (
          <InfoCard
            variant="success"
            text={t('dashboard-layout.wallet-bar.wallet-iban-form.iban-requested-message')}
          />
        ) : (
          <Input
            value={feeData?.fee && wallet?.name && `${feeData.fee} ${wallet.name}`}
            className="fee-input"
            name="fee"
            label={t('dashboard-layout.wallet-bar.wallet-iban-form.fields.fee.label')}
            nonEditable
          />
        )}
      </S.WalletIBANFormBody>

      <AsyncErrorMessage errors={errors} />

      {!isIBANRequestsSuccess ||
        (!hasIBANRequests && (
          <S.WalletIBANFormActions>
            <S.CancelWalletIBANButton
              variant="contained"
              body={t('dashboard-layout.wallet-bar.wallet-iban-form.cancel-btn')}
              onClick={() => cancel()}
            />

            <S.ActionWalletIBANButton
              body={
                getRequestForIBANAvailableStatus()
                  ? t('dashboard-layout.wallet-bar.wallet-iban-form.request-btn')
                  : t('dashboard-layout.wallet-bar.wallet-iban-form.top-up-btn')
              }
              loading={isFeeQueryLoading || isIBANRequestLoading || isIBANRequestsLoading}
              onClick={() => handleAction()}
            />
          </S.WalletIBANFormActions>
        ))}
    </S.WalletIBANForm>
  );
};
