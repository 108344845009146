import { FC } from 'react';

import { ProfileContent } from '../components';
import { AddressInfo, PersonalInfo } from './components';

export const NaturalProfile: FC = () => {
  return (
    <ProfileContent>
      <PersonalInfo />
      <AddressInfo />
    </ProfileContent>
  );
};
