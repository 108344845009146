import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import * as S from './styled';
import { Error } from '../../../../components';

import { redirectToOryLogin } from '../../../../services';
import { RouterPaths, useAppRouteError } from '../../../../router';

export const ProfileErrorBoundary = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const error = useAppRouteError();
  let errorElement = (
    <Error
      message={t('ui.errors.default-message')}
      actionButtonText={t('ui.errors.default-action-text')}
      actionButtonCallback={() => navigate(RouterPaths.Profiles)}
    />
  );

  if ('message' in error) {
    return (
      <S.ProfileErrorBoundaryContainer>
        <Error
          message={error.message}
          actionButtonText={t('ui.errors.default-action-text')}
          actionButtonCallback={() => navigate(RouterPaths.Profiles)}
        />
      </S.ProfileErrorBoundaryContainer>
    );
  }

  if (error.status === 503) {
    return (
      <S.ProfileErrorBoundaryBackdrop>
        <Error
          message={t(`ui.errors.error-boundary.${error.status}.message`)}
          actionButtonText={t(`ui.errors.error-boundary.${error.status}.action-text`)}
          actionButtonCallback={() => window.location.reload()}
        />
      </S.ProfileErrorBoundaryBackdrop>
    );
  }

  if (error.status === 401) {
    errorElement = (
      <Error
        message={t(`ui.errors.error-boundary.${error.status}.message`)}
        actionButtonText={t(`ui.errors.error-boundary.${error.status}.action-text`)}
        actionButtonCallback={() => redirectToOryLogin()}
      />
    );
  }

  if (error.status === 403) {
    errorElement = (
      <Error
        message={t(`ui.errors.error-boundary.${error.status}.message`)}
        actionButtonText={t(`ui.errors.error-boundary.${error.status}.action-text.profiles`)}
        actionButtonCallback={() => navigate(RouterPaths.Profiles)}
      />
    );
  }

  return <S.ProfileErrorBoundaryContainer>{errorElement}</S.ProfileErrorBoundaryContainer>;
};
