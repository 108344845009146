import { FC, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { LoaderFunction, useLocation, useNavigate } from 'react-router-dom';

import * as S from './styled';
import { IconExchange } from '../../assets';
import { Input, LineInfo, Loader, Select, SelectOption, TextArea, Title } from '../../components';

import {
  countryEndpoints,
  CreateExchangeTransferDTO,
  GetTransferFeeDTO,
  GetWalletsDTO,
  profileWalletEndpoints,
  runEndpointInRouter,
  useCreateExchangeTransferMutation,
  useGetWalletsQuery,
  useLazyGetTransferFeeQuery,
} from '../../api';
import { useACL } from '../../services';
import { getItemFromStorage, useDebounce } from '../../utils';
import { activeProfileUidSelector, useAppSelector } from '../../store';
import { aclGuard, authGuard, getRouterError, RouterPaths } from '../../router';
import { AsyncErrorMessage, getAsyncErrorMessage, Shape, yup } from '../../forms';
import { ProfileInfo, TransferType, Wallet } from '../../types';
import { Checkbox } from '../../components/Checkbox';

type ExchangeFormFields = CreateExchangeTransferDTO['body'] & {
  senderWalletUid: string;
};

const exchangeFormSchema = yup.object().shape<Shape<ExchangeFormFields>>({
  recipientWalletUid: yup.string().required(),
  senderWalletUid: yup.string().required(),
  amount: yup.number().positive().min(0).required(),
  currencyCode: yup.string().required(),
  reference: yup.string(),
  floatingRateConsent: yup.boolean().required(),
});

export const exchangeLoader: LoaderFunction = async () => {
  authGuard();
  const hasPermission_WALLET_VIEW = await aclGuard(['WALLET_VIEW']);

  try {
    const activeProfile = getItemFromStorage('activeProfile') as ProfileInfo;

    hasPermission_WALLET_VIEW &&
      (await runEndpointInRouter(profileWalletEndpoints, 'getWallets', {
        profileUid: activeProfile.profileUid,
      } as GetWalletsDTO));
    await runEndpointInRouter(countryEndpoints, 'getActiveCountries');
  } catch (e) {
    throw getRouterError(e as FetchBaseQueryError);
  }

  return null;
};

export const Exchange: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const hasPermission_WALLET_VIEW = useACL(['WALLET_VIEW']);

  const profileUid = useAppSelector(activeProfileUidSelector);

  const { data: wallets } = useGetWalletsQuery({ profileUid: profileUid });
  const [getFee, { data: fee, isError: isFeeCalcError, isFetching: isFeeCalcFetching }] =
    useLazyGetTransferFeeQuery();
  const [createExchangeTransfer] = useCreateExchangeTransferMutation();

  // if user click on Exchange page from wallet bar
  const walletUidFromSideBar = location.state?.walletUid;
  const walletFromSideBar = hasPermission_WALLET_VIEW
    ? wallets?.find((w) => w.uid === walletUidFromSideBar)?.uid
    : undefined;

  const {
    control,
    register,
    setError,
    watch,
    trigger,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm<ExchangeFormFields>({
    resolver: yupResolver(exchangeFormSchema),
    defaultValues: { senderWalletUid: walletFromSideBar },
  });

  const recipientWalletUid = watch('recipientWalletUid');
  const senderWalletUid = watch('senderWalletUid');
  const amount = watch('amount');
  const currencyCode = watch('currencyCode');

  const [senderWallet, setSenderWallet] = useState<Wallet | null>(null);
  const [recipientWallet, setRecipientWallet] = useState<Wallet | null>(null);

  const senderCurrencyCode = senderWallet?.coinResponseDto.currencyCode;
  const recipientCurrencyCode = recipientWallet?.coinResponseDto.currencyCode;

  const debouncedFeeCalc = useDebouncedCallback(async (feeArgs: GetTransferFeeDTO) => {
    await getFee(feeArgs).unwrap();
  }, 600);
  const debouncedCurrencyCode = useDebounce<string>(currencyCode, 600);
  const debouncedSenderCurrencyCode = useDebounce<string | undefined>(senderCurrencyCode, 600);

  // get sender and recipient wallets by wallet uids
  useEffect(() => {
    const currentCurrencyCode = getValues().currencyCode;
    if (senderWalletUid && wallets) {
      const senderWallet = wallets.find((w) => w.uid === senderWalletUid);
      if (senderWallet && (!currentCurrencyCode || senderCurrencyCode == currentCurrencyCode))
        setValue('currencyCode', senderWallet.coinResponseDto.currencyCode);
      setSenderWallet(senderWallet!);
    }
    if (recipientWalletUid && wallets) {
      const recipientWallet = wallets.find((w) => w.uid === recipientWalletUid);
      if (recipientWallet && recipientCurrencyCode == currentCurrencyCode)
        setValue('currencyCode', recipientWallet.coinResponseDto.currencyCode);
      setRecipientWallet(recipientWallet!);
    }
  }, [senderWalletUid, recipientWalletUid]);

  // get fee on `amount` change
  useEffect(() => {
    if (!recipientWallet || !senderWallet || !currencyCode || !amount || amount === 0) return;

    const feeArgs = {
      walletUid: senderWalletUid,
      body: {
        transferType: TransferType.EXCHANGE,
        countryCode: null,
        amount,
        toCurrencyCode: currencyCode,
        exchangeCurrencyCode: recipientWallet.coinResponseDto.currencyCode,
      },
    };

    (async () => {
      try {
        const successValidation = await trigger('amount');
        if (!successValidation) return;

        await debouncedFeeCalc(feeArgs);
      } catch (e) {
        // if (e?.status === 400) {
        getAsyncErrorMessage(
          { status: 400, data: { message: t('exchange.form.fee-error') } },
          setError
        );
        // }
        // getAsyncErrorMessage(e, setError);
      }
    })();

    // check maximum available amount on `recipient wallet`, `sender wallet`, `amount` or `currency code` change
  }, [amount, recipientWalletUid, senderWalletUid, currencyCode]);

  // check maximum available amount on `recipient wallet`, `sender wallet`, `amount` or `currency code` change
  // useEffect(() => {
  //   if (!recipientWallet || !senderWallet || !currencyCode || !amount || amount === 0) return;
  //   const availableAmount;
  //
  //   return;
  // }, [recipientWalletUid, senderWalletUid, amount, currencyCode]);

  const changeWallets = () => {
    const currentValues = getValues();
    setValue('recipientWalletUid', currentValues.senderWalletUid);
    setValue('senderWalletUid', currentValues.recipientWalletUid);
    setRecipientWallet(senderWallet);
    setSenderWallet(recipientWallet);
  };

  const exchange: SubmitHandler<ExchangeFormFields> = async (values) => {
    if (!amount || amount === 0) return;

    const mappedValues: CreateExchangeTransferDTO = {
      walletUid: values.senderWalletUid,
      body: {
        recipientWalletUid: values.recipientWalletUid,
        amount: values.amount,
        reference: values.reference ? values.reference : undefined,
        currencyCode: values.currencyCode,
        floatingRateConsent: values.floatingRateConsent,
      },
    };

    try {
      const transfer = await createExchangeTransfer(mappedValues).unwrap();
      navigate(`${RouterPaths.Exchange}/${transfer.uid}`);
    } catch (e) {
      getAsyncErrorMessage(e, setError);
    }
  };

  const senderWalletOptions = useMemo(() => {
    let walletsList = wallets;
    if (!walletsList) return [];

    if (recipientWalletUid) {
      walletsList = walletsList.filter((wallet) => wallet.uid !== recipientWalletUid);
    }

    return walletsList.map((wallet) => {
      const { uid, availableAmount, name } = wallet;
      return (
        <SelectOption key={uid} value={uid} name={`${availableAmount} ${name}`}>
          {availableAmount} {name}
        </SelectOption>
      );
    });
  }, [wallets, recipientWalletUid]);

  const recipientWalletOptions = useMemo(() => {
    let walletsList = wallets;
    if (!walletsList) return [];

    if (senderWalletUid) {
      walletsList = walletsList.filter((wallet) => wallet.uid !== senderWalletUid);
    }

    return walletsList
      .filter((wallet) => wallet.uid !== senderWalletUid)
      .map((wallet) => {
        const { uid, availableAmount, name } = wallet;
        return (
          <SelectOption key={uid} value={uid} name={`${availableAmount} ${name}`}>
            {availableAmount} {name}
          </SelectOption>
        );
      });
  }, [wallets, senderWalletUid]);

  const currencyOptions = useMemo(() => {
    if (!recipientWallet || !senderWallet) return [];

    const currencyList = [senderCurrencyCode] as const;

    return currencyList.map((currencyCode) => {
      return (
        <SelectOption key={currencyCode} value={currencyCode} name={currencyCode!}>
          {currencyCode}
        </SelectOption>
      );
    });
  }, [recipientWallet, senderWallet]);

  return (
    <S.ExchangeContainer>
      <Helmet title={t('pages.exchange')} />

      <Title text={t('exchange.title')} />

      <S.ExchangeForm onSubmit={handleSubmit(exchange)}>
        <S.FieldsWrapper>
          <Select
            control={{ control, name: 'senderWalletUid' }}
            errors={errors}
            options={senderWalletOptions}
            label={t('exchange.form.from.label')}
            placeholder={t('exchange.form.from.placeholder')}
          />

          <S.ChangeWalletsButton
            icon={<IconExchange />}
            onClick={() => changeWallets()}
            disabled={!senderWallet || !recipientWallet}
          />

          <Select
            control={{ control, name: 'recipientWalletUid' }}
            errors={errors}
            options={recipientWalletOptions}
            label={t('exchange.form.to.label')}
            placeholder={t('exchange.form.to.placeholder')}
          />

          <S.FieldWrapper>
            <Input
              {...register('amount', { valueAsNumber: true })}
              type="number"
              step={0.01}
              errors={errors}
              label={t('exchange.form.amount.label')}
              disabled={!recipientWallet || !senderWallet || !currencyCode}
              disableNumberArrows
            />
            <Select
              control={{ control, name: 'currencyCode' }}
              errors={errors}
              options={currencyOptions}
              label={t('exchange.form.currency-code.label')}
              disabled={!recipientWallet || !senderWallet}
            />
          </S.FieldWrapper>

          <AsyncErrorMessage errors={errors} />
        </S.FieldsWrapper>

        {!isFeeCalcError && fee && (
          <>
            <S.InfoWrapper $isFeeCalcFetching={isFeeCalcFetching}>
              <LineInfo
                label={t('exchange.info.transaction-amount')}
                value={`${fee.transactionAmount} ${fee.transactionCurrencyCode}`}
              />
              <LineInfo
                label={t('exchange.info.service-fee')}
                value={`${fee.transactionFee} ${fee.transactionCurrencyCode}`}
              />
              <LineInfo
                label={t('exchange.info.exchange-rate')}
                value={`1 ${fee.transactionCurrencyCode} = ${fee.exchangeRate} ${fee.exchangeResultCurrencyCode}`}
              />
              <LineInfo
                label={t('exchange.info.total')}
                value={`${fee.exchangeResultAmount} ${fee.exchangeResultCurrencyCode}`}
              />
              {isFeeCalcFetching && (
                <Loader variant="centerOfContainer" containerClassName="info-loader" />
              )}
            </S.InfoWrapper>
            <Checkbox
              {...register('floatingRateConsent')}
              type="checkbox"
              errors={errors}
              label={t('exchange.form.floatingRateConsent')}
            />
          </>
        )}
        <S.NextButton
          body={t('exchange.form.next-btn')}
          type="submit"
          loading={isFeeCalcFetching}
          disabled={!fee || isFeeCalcFetching || isFeeCalcError}
        />
      </S.ExchangeForm>
    </S.ExchangeContainer>
  );
};
