import { apiSlice } from '../../api';
import type { GetSumsubTokenDTO, GetSumsubTokenResult } from './types';

export const sumsubEndpoints = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getSumsubToken: build.query<GetSumsubTokenResult, GetSumsubTokenDTO>({
      query: ({ profileUid, levelName }) => ({
        url: `/aml-service/v1/profiles/${profileUid}/sumsub/self-kyc/token`,
        method: 'GET',
        params: { levelName },
      }),
    }),
  }),
});

export const { useLazyGetSumsubTokenQuery } = sumsubEndpoints;

export type SumsubController = typeof sumsubEndpoints;
