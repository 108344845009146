import { FC, MouseEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from '@mui/material';

import * as S from './styled';
import {
  IconDrop,
  IconLogout,
  IconPlusSquare,
  IconUp,
  IconVerificationWarning,
} from '../../../../assets';
import { AddBusinessAccountForm } from '../../components';
import { Avatar, Hint, Modal } from '../../../../components';

import {
  activeProfileTypeSelector,
  addressVerifyStatusSelector,
  companyNameSelector,
  naturalProfileFullNameSelector,
  naturalProfileNameSelector,
  profileSelector,
  profileVerifyStatusSelector,
  useAppSelector,
} from '../../../../store';
import { useACL } from '../../../../services';
import { changeProfile, useLogoutHandler } from '../../../../utils';
import { RouterPaths, useAppLinks, useAppLoading } from '../../../../router';
import { ProfileType } from '../../../../types';

type UserContextMenuProps = {
  showHint: () => void;
};

export const UserContextMenu: FC<UserContextMenuProps> = ({ showHint }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { profileLinks } = useAppLinks();
  const { logout } = useLogoutHandler();
  const isAppLoading = useAppLoading();

  const hasPermission_LEGAL_REGISTRATION = useACL(['LEGAL_REGISTRATION']);

  const isVerified = useAppSelector(profileVerifyStatusSelector);
  const isAddressVerified = useAppSelector(addressVerifyStatusSelector);
  const profileType = useAppSelector(activeProfileTypeSelector);
  const naturalName = useAppSelector(naturalProfileNameSelector);
  const naturalFullName = useAppSelector(naturalProfileFullNameSelector);
  const companyName = useAppSelector(companyNameSelector);
  const profilesData = useAppSelector(profileSelector);

  const isNatural = profileType === ProfileType.NATURAL;
  const isLegal = profileType === ProfileType.LEGAL;
  const userShortName = isNatural ? naturalName : t('menus.user.name.default-legal');
  const userFullName = isNatural ? naturalFullName : companyName;
  const hasAllVerifications = isVerified && isAddressVerified;

  // state and callbacks for user context menu
  const [userMenuAnchor, setUserMenuAnchor] = useState<HTMLButtonElement | null>(null);
  const isUserMenuOpen = Boolean(userMenuAnchor);
  const openUserMenu = (e: MouseEvent<HTMLButtonElement>) => setUserMenuAnchor(e.currentTarget);
  const closeUserMenu = () => setUserMenuAnchor(null);

  // add business account modal state
  const [isAddBusinessAccountModalShown, setAddBusinessAccountShown] = useState(false);
  const showAddBusinessAccountForm = () => setAddBusinessAccountShown(true);
  const closeAddBusinessAccountForm = () => setAddBusinessAccountShown(false);

  const verifyHandler = () => {
    if (hasAllVerifications) return;
    if (isLegal) return;

    navigate(RouterPaths.Verification);
  };

  const logoutHandler = () => {
    logout();
  };

  const addBusinessAccount = () => {
    showAddBusinessAccountForm();
  };

  const getVerifyButton = () => {
    // don't show verify button, if it's legal profile
    if (isLegal) return;

    // show verify button, if this haven't all verifications
    if (!isAppLoading && !hasAllVerifications)
      return (
        <S.VerifyAlertButton
          disableRipple
          icon={<IconVerificationWarning />}
          onClick={() => verifyHandler()}
        />
      );

    return;
  };

  const links = useMemo(() => {
    return profileLinks.map((link) => {
      const { name, icon: Icon, path, isExternal } = link;

      if (isExternal) {
        return (
          <S.UserMenuItem key={path}>
            <S.ExternalLink href={path} target="_blank" rel="noopener noreferrer">
              <Icon />
              {t(`menus.user.links.${name}` as unknown as TemplateStringsArray)}
            </S.ExternalLink>
          </S.UserMenuItem>
        );
      }

      return (
        <S.UserMenuItem key={path}>
          <S.UserLink to={path}>
            <Icon />
            {t(`menus.user.links.${name}` as unknown as TemplateStringsArray)}
          </S.UserLink>
        </S.UserMenuItem>
      );
    });
  }, [profileLinks, i18n.resolvedLanguage]);

  const profiles = useMemo(() => {
    return profilesData?.map((profile) => {
      const { name, profileUid, profileType } = profile;

      // TODO: clarify avatar src, after backend implementing
      return (
        <S.UserMenuItem key={profileUid}>
          <S.ProfileCardContainer onClick={() => changeProfile(profile, navigate)}>
            <Avatar size="small" />

            <S.ProfileCardContent>
              <S.ProfileName>{name}</S.ProfileName>
              <S.ProfileType>{t(`menus.user.profiles.profile-type.${profileType}`)}</S.ProfileType>
            </S.ProfileCardContent>
          </S.ProfileCardContainer>
        </S.UserMenuItem>
      );
    });
  }, [profilesData, i18n.resolvedLanguage]);

  return (
    <>
      <S.UserMenuButtonContainer>
        {getVerifyButton()}
        <S.UserMenuButton
          variant="text"
          color="secondary"
          aria-controls={isUserMenuOpen ? 'user-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={isUserMenuOpen ? 'true' : undefined}
          body={userShortName || ''}
          endIcon={isUserMenuOpen ? <IconUp /> : <IconDrop />}
          onClick={(e) => openUserMenu(e)}
        />
      </S.UserMenuButtonContainer>

      <S.UserMenu
        id="user-menu"
        open={isUserMenuOpen}
        anchorEl={userMenuAnchor}
        onClose={closeUserMenu}
        onClick={closeUserMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {/*Name*/}
        <S.UserNameWrapper onClick={(e) => e.stopPropagation()}>
          <S.UserName>{userFullName}</S.UserName>
        </S.UserNameWrapper>
        <S.UserMenuDivider />

        {/*Links*/}
        <S.UserLinksWrapper>
          {links}
          <S.UserMenuItem>
            <S.OtherLink onClick={() => logoutHandler()}>
              <IconLogout />
              {t('menus.user.links.logout')}
            </S.OtherLink>
          </S.UserMenuItem>
        </S.UserLinksWrapper>
        <S.UserMenuDivider />

        {/*Profiles*/}
        <S.UserProfilesWrapper>{profiles}</S.UserProfilesWrapper>
        <S.UserMenuDivider />

        {/*Other*/}
        <S.OtherLinksWrapper>
          {hasAllVerifications ? (
            <S.UserMenuItem>
              <S.OtherLink
                onClick={() => addBusinessAccount()}
                disabled={!hasPermission_LEGAL_REGISTRATION || !hasAllVerifications}
              >
                <IconPlusSquare />
                {t('menus.user.other.open-business-account.title')}
              </S.OtherLink>
            </S.UserMenuItem>
          ) : (
            <Tooltip
              title={
                <Hint
                  message={t('menus.user.other.open-business-account.hint')}
                  variant="small"
                  icon="warning"
                  customContainerStyles={{ fontSize: '1.4rem', padding: 8, width: 280 }}
                />
              }
              componentsProps={{
                tooltip: { sx: { p: 0, bgcolor: 'black', borderRadius: '16px' } },
              }}
            >
              <S.UserMenuItem>
                <S.OtherLink
                  onClick={() => addBusinessAccount()}
                  disabled={!hasPermission_LEGAL_REGISTRATION || !hasAllVerifications}
                >
                  <IconPlusSquare />
                  {t('menus.user.other.open-business-account.title')}
                </S.OtherLink>
              </S.UserMenuItem>
            </Tooltip>
          )}
        </S.OtherLinksWrapper>
      </S.UserMenu>

      <Modal
        modalTitle={t('menus.user.other.open-business-account.title')}
        body={
          <AddBusinessAccountForm
            onCancel={() => closeAddBusinessAccountForm()}
            showHint={() => showHint()}
          />
        }
        open={isAddBusinessAccountModalShown}
        onClose={() => closeAddBusinessAccountForm()}
      />
    </>
  );
};
