import { store } from '../../store';
import type { AppControllers } from '../types';

/**
 *  Using of the same controller and endpoint automatically provides by generic parameter - ControllersType.
 *
 *  Be attentive with `body` and returning result of request - they are not typed.
 *  You can manually use type assertion for right typification for `body` and result of request.
 *
 *  @param controller - pass the controller from which to take the endpoint
 *  @param endpoint - endpoint name
 *  @param body - endpoint's body
 *  @param returnResult - is need to return result of request?
 */
export const runEndpointInRouter = async <ControllersType extends AppControllers>(
  controller: ControllersType,
  endpoint: keyof ControllersType['endpoints'],
  body: unknown = undefined,
  returnResult = false
): Promise<any | void> => {
  const promise = store.dispatch(
    // @ts-ignore
    controller.endpoints[endpoint as keyof typeof controller.endpoints].initiate(body)
  );
  await promise.unwrap();
  promise.unsubscribe();
  if (returnResult) return promise;
  return;
};
