import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query/react';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { i18n } from '../services';
import { getItemFromStorage } from '../utils';
import { BackendLanguage, ProfileInfo } from '../types';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL,
  credentials: 'include',
  prepareHeaders: (headers) => {
    headers.set('Lang', i18n.resolvedLanguage?.toUpperCase() ?? BackendLanguage.EN);
    headers.set('Resource-Type', process.env.REACT_APP_RESOURCE);

    // If we have active profile in LS => set profile's uid value in header (ProfileUid)
    const activeProfile = getItemFromStorage('activeProfile') as ProfileInfo;
    activeProfile?.profileUid && headers.set('ProfileUid', activeProfile.profileUid);

    return headers;
  },
});

const baseDocgenQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_DOCGEN_BASE_URL,
  credentials: 'include',
  prepareHeaders: (headers) => {
    headers.set('Lang', i18n.resolvedLanguage?.toUpperCase() ?? BackendLanguage.EN);
    headers.set('Resource-Type', process.env.REACT_APP_RESOURCE);

    // If we have active profile in LS => set profile's uid value in header (ProfileUid)
    const activeProfile = getItemFromStorage('activeProfile') as ProfileInfo;
    activeProfile?.profileUid && headers.set('ProfileUid', activeProfile.profileUid);

    return headers;
  },
});

const enhancedBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
  args,
  api,
  extraOptions
) => {
  const result = await baseQuery(args, api, extraOptions);

  // here place to write some interceptors logic
  if (result.error && result.error.status === 401) {
    // code of interceptor is here...
  }

  return result;
};

const enhancedBaseDocgenQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  const result = await baseDocgenQuery(args, api, extraOptions);

  // here place to write some interceptors logic
  if (result.error && result.error.status === 401) {
    // code of interceptor is here...
  }

  return result;
};
export const cacheTags = [
  'NaturalProfile',
  'LegalProfile',
  'ProfileAddress',

  'Notification',

  'Recipients',
  'Wallets',
  'Transfer',

  'TicketComments',
  'Tickets',
  'CurrentTicket',
  'CurrentTicketAttachments',

  'RelatedPersonsLocal',
  'RelatedPersonsExternal',

  'ProfileDocuments',
] as const;

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: enhancedBaseQuery,
  endpoints: () => ({}),
  tagTypes: cacheTags,
});

export const apiDocgenSlice = createApi({
  reducerPath: 'api',
  baseQuery: enhancedBaseDocgenQuery,
  endpoints: () => ({}),
});
