import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { Banner, Snackbar } from '../../types';

type CommonState = {
  banner: Banner | null;
  snackbar: Snackbar | null;
};

const initialState: CommonState = {
  banner: null,
  snackbar: null,
};

export const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setBanner: (state, action: PayloadAction<Banner>) => {
      state.banner = action.payload;
    },
    removeBanner: (state) => {
      state.banner = null;
    },

    setSnackbar: (state, action: PayloadAction<Snackbar>) => {
      state.snackbar = action.payload;
    },
    removeSnackbar: (state) => {
      state.snackbar = null;
    },
  },
});

export const commonActions = commonSlice.actions;
export const commonReducer = commonSlice.reducer;
