import { useCallback, useState } from 'react';

interface Pagination {
  pageNumber: number;
  pageSize: number;
  totalPages?: number;
}

export const usePagination = (initialValue: Pagination) => {
  const [pagination, setPagination] = useState(initialValue);

  const onPageChange = useCallback((page: number) => {
    setPagination((prevState: Pagination) => {
      return {
        ...prevState,
        pageNumber: page,
      };
    });
  }, []);

  const resetPagination = useCallback(() => {
    setPagination((prevState: Pagination) => {
      return {
        ...prevState,
        pageNumber: 0,
      };
    });
  }, []);

  const setPaginationRequest = useCallback((totalPages: number) => {
    setPagination((prevState: Pagination) => {
      return {
        ...prevState,
        totalPages: totalPages,
      };
    });
  }, []);

  return { pagination, onPageChange, resetPagination, setPaginationRequest };
};
