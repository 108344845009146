import { FC, SVGProps } from 'react';

export const IconHomeSmile: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.96645 13.7432C8.82461 13.2094 8.27692 12.8917 7.74316 13.0335C7.2094 13.1754 6.8917 13.7231 7.03355 14.2568C7.61063 16.4283 9.63059 18 12 18C14.3694 18 16.3894 16.4283 16.9665 14.2568C17.1083 13.7231 16.7906 13.1754 16.2568 13.0335C15.7231 12.8917 15.1754 13.2094 15.0335 13.7432C14.6937 15.0221 13.4795 16 12 16C10.5205 16 9.30635 15.0221 8.96645 13.7432Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7256 0.530357C12.2492 0.411593 11.7509 0.411593 11.2744 0.530357C10.7297 0.666131 10.2515 1.00195 9.70683 1.38439L2.70563 6.2853C2.29098 6.57486 1.9258 6.82986 1.65239 7.17351C1.41256 7.47496 1.23303 7.81977 1.12361 8.18912C0.99887 8.61017 0.999378 9.05557 0.999955 9.56133L1.00003 18.8385C1.00002 19.3657 1 19.8204 1.0306 20.195C1.06291 20.5903 1.13422 20.9836 1.32702 21.3619C1.61464 21.9264 2.07358 22.3854 2.63806 22.673C3.01644 22.8658 3.40966 22.9371 3.80501 22.9694C4.17957 23 4.63433 23 5.16147 23H18.8386C19.3657 23 19.8205 23 20.1951 22.9694C20.5904 22.9371 20.9836 22.8658 21.362 22.673C21.9265 22.3854 22.3854 21.9264 22.6731 21.3619C22.8658 20.9836 22.9372 20.5903 22.9695 20.195C23.0001 19.8204 23.0001 19.3657 23 18.8385L23.0001 9.56132C23.0007 9.05558 23.0012 8.61017 22.8765 8.18912C22.767 7.81977 22.5875 7.47496 22.3477 7.17351C22.0743 6.82986 21.7091 6.57485 21.2944 6.2853L14.2932 1.38439C13.7486 1.00195 13.2703 0.666131 12.7256 0.530357ZM11.7582 2.47097C11.917 2.43139 12.0831 2.43139 12.2419 2.47097C12.3783 2.50497 12.5327 2.5935 13.2617 3.10376L20.0616 7.86376C20.6108 8.24819 20.7136 8.33195 20.7826 8.41869C20.8625 8.51918 20.9224 8.63411 20.9588 8.75723C20.9903 8.86351 21 8.99571 21 9.66607V18.8C21 19.3765 20.9993 19.7488 20.9761 20.0321C20.9539 20.3038 20.9162 20.4045 20.891 20.454C20.7952 20.6421 20.6422 20.7951 20.454 20.891C20.4046 20.9162 20.3039 20.9538 20.0322 20.976C19.7489 20.9992 19.3766 21 18.8 21H5.20004C4.62348 21 4.25121 20.9992 3.96787 20.976C3.69621 20.9538 3.59549 20.9162 3.54604 20.891C3.35788 20.7951 3.2049 20.6421 3.10903 20.454C3.08384 20.4045 3.04616 20.3038 3.02396 20.0321C3.00081 19.7488 3.00003 19.3765 3.00003 18.8V9.66607C3.00003 8.99571 3.00974 8.86351 3.04123 8.75723C3.0777 8.63411 3.13754 8.51918 3.21749 8.41869C3.2865 8.33195 3.38924 8.24819 3.93842 7.86376L10.7384 3.10376C11.4674 2.5935 11.6218 2.50497 11.7582 2.47097Z"
        fill="currentColor"
      />
    </svg>
  );
};
