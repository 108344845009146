import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { TODO_ANY as Notification } from '../../types';

type CommonState = {
  data: Notification;
};

const initialState: CommonState = {
  data: undefined,
};

export const notification = createSlice({
  name: 'rightPanel',
  initialState,
  reducers: {
    setNotification: (state, action: PayloadAction<Notification>) => {
      state.data = action.payload;
    },
    removeNotification: (state) => {
      state.data = [];
    },
  },
});

export const notificationActions = notification.actions;
export const notificationReducer = notification.reducer;
