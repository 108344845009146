import { FC, MouseEvent, useState } from 'react';
import { TableRowProps as MuiTableRowProps } from '@mui/material';

import * as S from './styled';
import { IconArrowNext16 } from '../../../../assets';
import { useMedia } from '../../../../layouts';

type TableRowProps = MuiTableRowProps & {
  showIconOnHover?: boolean;
  /**
   *  If you use Table component without header - set this prop to `true` for right styles in TableRow
   */
  tableWithoutHeader?: boolean;
};

export const TableRow: FC<TableRowProps> = (props) => {
  const {
    showIconOnHover = true,
    tableWithoutHeader = false,
    onClick,
    children,
    hover = true,
    ...rest
  } = props;

  const [isHover, setHoverStatus] = useState(false);
  const { isMobile } = useMedia();

  const enhancedOnClick = (e: MouseEvent<HTMLTableRowElement>) => {
    // logic to prevent firing onClick callback when user select text
    const selection = window.getSelection()?.toString();
    if (selection && selection.length > 0) return;

    // onClick logic
    onClick && onClick(e);
  };

  return (
    <S.TableRow
      hover={hover}
      onClick={(e) => enhancedOnClick(e)}
      onMouseEnter={() => setHoverStatus(true)}
      onMouseLeave={() => setHoverStatus(false)}
      {...rest}
      $tableWithoutHeader={tableWithoutHeader}
    >
      {children}
      {hover && showIconOnHover && isHover && !isMobile && (
        <S.TableRowChooseIcon>
          <IconArrowNext16 />
        </S.TableRowChooseIcon>
      )}
    </S.TableRow>
  );
};
