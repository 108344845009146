import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';

import * as S from './styled';
import {
  ProfileInfoContainer,
  ProfileInfoHeader,
  UpdateDocumentForm,
  UpdateDocumentFormFields,
} from '../../../components';
import { IconEdit } from '../../../../../assets';
import { Input, LineInfo, Modal, Select, useSelectOptions } from '../../../../../components';

import {
  useGetActiveCountriesQuery,
  useGetProfileAddressQuery,
  useGetVerificationStatusQuery,
  useUpdateProfileAddressMutation,
  useUploadProfileDocumentMutation,
} from '../../../../../api';
import {
  activeProfileUidSelector,
  addressVerifyStatusSelector,
  profileVerifyStatusSelector,
  useAppSelector,
} from '../../../../../store';
import { useACL } from '../../../../../services';
import { AsyncErrorMessage, Shape, getAsyncErrorMessage, yup } from '../../../../../forms';
import { yupResolver } from '@hookform/resolvers/yup';
import { sortArrayByPropAsc } from '../../../../../utils';
import { ProfileFormFooter } from '../ProfileFormFooter';

type AddressFormFields = {
  country: string;
  zipCode: string;
  city: string;
  street: string;
  buildingNumber: string;
  flatNumber: string;
  state: string;
};

const AddressSchema = yup.object().shape<Shape<AddressFormFields>>({
  country: yup.string().required(),
  zipCode: yup.string().required(),
  city: yup.string().required(),
  street: yup.string().required(),
  buildingNumber: yup.string().required(),
  flatNumber: yup.string().required(),
  state: yup.string().required(),
});

export const AddressInfo: FC = () => {
  const { t } = useTranslation();
  const hasPermission_PROFILE_ADDRESSES_VIEW = useACL(['PROFILE_ADDRESSES_VIEW']);

  const isVerified = useAppSelector(profileVerifyStatusSelector);
  const isAddressVerified = useAppSelector(addressVerifyStatusSelector);
  const profileUid = useAppSelector(activeProfileUidSelector);
  const [isEditMode, setEditMode] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const { data: addresses } = useGetProfileAddressQuery(
    hasPermission_PROFILE_ADDRESSES_VIEW ? { profileUid: profileUid } : skipToken
  );
  const { data: activeCountries } = useGetActiveCountriesQuery();
  const [updateDocument, { isLoading: isUploadDocument }] = useUploadProfileDocumentMutation();
  const { data: isVerificationInitiated } = useGetVerificationStatusQuery({
    profileUid: profileUid,
  });
  const [updateProfileAddress, { isLoading: isUpdateAddressLoading }] =
    useUpdateProfileAddressMutation();
  const address = addresses && addresses[0];
  const country = activeCountries?.find((c) => c.alpha3Code === address?.country)?.name;

  const closeModal = () => setEditMode(false);

  const {
    control,
    register,
    reset,
    setError,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<AddressFormFields>({
    resolver: yupResolver(AddressSchema),
    defaultValues: { ...address },
  });

  const enableEditMode = () => {
    setEditMode(true);
  };
  const disableEditMode = () => {
    reset({ ...address });
    setEditMode(false);
  };

  const countryOptions = useSelectOptions(
    sortArrayByPropAsc(activeCountries, 'name'),
    'alpha3Code',
    'name'
  );

  const updateAddress: SubmitHandler<AddressFormFields> = async (values) => {
    if (!isEditMode || !isDirty) {
      return;
    }

    try {
      await updateProfileAddress({
        profileUid: profileUid,
        body: values,
        addressType: 'CORRESPONDENCE',
      }).unwrap();
      setEditMode(false);
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 6000);
    } catch (e) {
      getAsyncErrorMessage(e, setError);
      setEditMode(false);
    }
  };

  const verefyAddress: SubmitHandler<UpdateDocumentFormFields> = async (values) => {
    if (!isEditMode) {
      return;
    }

    const mappedValues: UpdateDocumentFormFields = {
      ...values,
      expiredAt: null,
    };

    try {
      await updateDocument({ profileUid: profileUid, body: mappedValues }).unwrap();
      setEditMode(false);
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 6000);
    } catch (e) {
      getAsyncErrorMessage(e, setError);
      setEditMode(false);
    }
  };

  return (
    <ProfileInfoContainer>
      <ProfileInfoHeader
        title={t('profile.address-form.header.title')}
        actionButtonProps={{
          startIcon: <IconEdit />,
          body: t('profile.address-form.header.action-btn'),
          onClick: () => enableEditMode(),
        }}
        isActionButtonVisible={!isEditMode}
      />

      <S.AddressForm onSubmit={handleSubmit(updateAddress)}>
        {(isVerificationInitiated?.verificationInitiated || !isEditMode) && (
          <>
            {country && (
              <LineInfo label={t('profile.address-form.fields.country.label')} value={country} />
            )}

            {address?.zipCode && (
              <LineInfo
                label={t('profile.address-form.fields.zip-code.label')}
                value={address.zipCode}
              />
            )}

            {address?.state && (
              <LineInfo
                label={t('profile.address-form.fields.state.label')}
                value={address.state}
              />
            )}

            {address?.city && (
              <LineInfo label={t('profile.address-form.fields.city.label')} value={address.city} />
            )}

            {address?.street && (
              <LineInfo
                label={t('profile.address-form.fields.street.label')}
                value={address.street}
              />
            )}

            {address?.buildingNumber && (
              <LineInfo
                label={t('profile.address-form.fields.building-number.label')}
                value={address.buildingNumber}
              />
            )}

            {address?.flatNumber && (
              <LineInfo
                label={t('profile.address-form.fields.flat-number.label')}
                value={address.flatNumber}
              />
            )}
          </>
        )}

        {!isVerificationInitiated?.verificationInitiated && isEditMode && (
          <>
            <Select
              control={{ control, name: 'country' }}
              errors={errors}
              options={countryOptions}
              label={t('profile.address-form.fields.country.label')}
              nonEditable={!isEditMode}
            />
            <Input
              {...register('zipCode')}
              errors={errors}
              label={t('profile.address-form.fields.zip-code.label')}
              nonEditable={!isEditMode}
            />
            <Input
              {...register('city')}
              errors={errors}
              label={t('profile.address-form.fields.city.label')}
              nonEditable={!isEditMode}
            />
            <Input
              {...register('street')}
              errors={errors}
              label={t('profile.address-form.fields.street.label')}
              nonEditable={!isEditMode}
            />
            <Input
              {...register('buildingNumber')}
              errors={errors}
              label={t('profile.address-form.fields.building-number.label')}
              nonEditable={!isEditMode}
            />
            <Input
              {...register('flatNumber')}
              errors={errors}
              label={t('profile.address-form.fields.flat-number.label')}
              nonEditable={!isEditMode}
            />

            {isEditMode && (
              <ProfileFormFooter
                cancelButtonProps={{
                  body: t('profile.address-form.footer.cancel-btn'),
                  onClick: () => disableEditMode(),
                }}
                sendRequestButtonProps={{
                  body: t('profile.address-form.footer.send-btn'),
                  type: 'submit',
                  loading: isUpdateAddressLoading,
                }}
              />
            )}
          </>
        )}

        {showSuccess && !isVerificationInitiated?.verificationInitiated && (
          <S.SuccessContainer>
            {t('profile.address-form.success-update-no-verification')}
          </S.SuccessContainer>
        )}

        {showSuccess && isVerificationInitiated?.verificationInitiated && (
          <S.SuccessContainer>{t('profile.address-form.success-update')}</S.SuccessContainer>
        )}
        <AsyncErrorMessage errors={errors} />

        <Modal
          modalTitle={t('profile.address-form.form.title')}
          body={
            <UpdateDocumentForm
              onCancel={closeModal}
              onUpdate={verefyAddress}
              typeForm="address-form"
              isLoading={isUploadDocument}
            />
          }
          open={isEditMode && !!isVerificationInitiated?.verificationInitiated}
          onClose={() => disableEditMode()}
        />
      </S.AddressForm>
    </ProfileInfoContainer>
  );
};
