import { FieldErrors } from 'react-hook-form';
import { get } from 'lodash';

/**
 * Function, which returns own error from react-hook-form control by field name
 *
 * @param errors - `FieldErrors` - errors object from react-hook-form control
 * @param name - `string` - can be simple `string` or `object path` (e.g.: 'address.city')
 */
export const getErrorByFieldName = (
  errors: FieldErrors | undefined,
  name: string
): FieldErrors[keyof FieldErrors] | undefined => {
  if (!errors) return undefined;

  const nameSplit = name.split('.');
  const isSimpleName = nameSplit.length <= 1;

  // if name is simple string
  if (isSimpleName) return errors[name];

  // if name is object path
  return get(errors, nameSplit);
};
