import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styled';
import { LanguageMenu, Logo, Modal } from '../../../../components';
import { AppMenu, Notifications, UserContextMenu } from '../../components';
import { useMedia } from '../../../theming';

export const DashboardHeader: FC = () => {
  const { t } = useTranslation();
  const { isMobile, isTabletMaxWidth } = useMedia();

  // shown new legal profile modal hint state
  const [isHintModalShown, setHintModalShown] = useState(false);
  const showHint = () => setHintModalShown(true);
  const closeHint = () => setHintModalShown(false);

  const hintModal = (
    <S.HintModalContainer>
      <S.HintModalText>
        {t('dashboard-layout.create-legal-form.creation-hint-text')}
      </S.HintModalText>
      <S.GotItButton
        body={t('dashboard-layout.create-legal-form.creation-hint-btn')}
        onClick={() => closeHint()}
      />
    </S.HintModalContainer>
  );

  return (
    <S.DashboardHeaderContainer>
      <S.LeftPart>
        <AppMenu />
        {isTabletMaxWidth && <Logo type="white" size="small" />}
      </S.LeftPart>

      <S.RightPart>
        <UserContextMenu showHint={showHint} />
        <Notifications />
        {!isMobile && <LanguageMenu type="menu" />}
      </S.RightPart>

      <Modal
        modalTitle={t('dashboard-layout.create-legal-form.creation-hint-title')}
        body={hintModal}
        open={isHintModalShown}
        onClose={() => closeHint()}
      />
    </S.DashboardHeaderContainer>
  );
};
