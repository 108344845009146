import styled, { css } from 'styled-components';

import { getMediaFor } from '../../../../layouts';

export const TransferInfoContainer = styled.div`
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  gap: 8px;

  max-width: 432px;
  min-width: 432px;

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      min-width: auto;
      max-width: 100%;
    `)};
`;

export const WalletNameValue = styled.div`
  position: relative;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: -8px;
  transform: translate(-100%, -45%);
`;
