import { FC, SVGProps } from 'react';

export const IconCsv: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      fill="currentColor"
      height="24px"
      width="24px"
      version="1.1"
      viewBox="0 0 48 48"
      {...props}
    >
      <g>
        <g>
          <path
            d="M47.987,21.938c-0.006-0.091-0.023-0.178-0.053-0.264c-0.011-0.032-0.019-0.063-0.033-0.094
			c-0.048-0.104-0.109-0.202-0.193-0.285c-0.001-0.001-0.001-0.001-0.001-0.001L42,15.586V10c0-0.022-0.011-0.041-0.013-0.063
			c-0.006-0.088-0.023-0.173-0.051-0.257c-0.011-0.032-0.019-0.063-0.034-0.094c-0.049-0.106-0.11-0.207-0.196-0.293l-9-9
			c-0.086-0.086-0.187-0.148-0.294-0.196c-0.03-0.014-0.06-0.022-0.091-0.032c-0.085-0.03-0.172-0.047-0.263-0.052
			C32.039,0.01,32.021,0,32,0H7C6.448,0,6,0.448,6,1v14.586l-5.707,5.707c0,0-0.001,0.001-0.002,0.002
			c-0.084,0.084-0.144,0.182-0.192,0.285c-0.014,0.031-0.022,0.062-0.033,0.094c-0.03,0.086-0.048,0.173-0.053,0.264
			C0.011,21.96,0,21.978,0,22v19c0,0.552,0.448,1,1,1h5v5c0,0.552,0.448,1,1,1h34c0.552,0,1-0.448,1-1v-5h5c0.552,0,1-0.448,1-1V22
			C48,21.978,47.989,21.96,47.987,21.938z M44.586,21H42v-2.586L44.586,21z M38.586,9H33V3.414L38.586,9z M8,2h23v8
			c0,0.552,0.448,1,1,1h8v5v5H8v-5V2z M6,18.414V21H3.414L6,18.414z M40,46H8v-4h32V46z M46,40H2V23h5h34h5V40z"
          />
          <path
            d="M23.422,27.885c0.147-0.136,0.312-0.235,0.493-0.298c0.181-0.062,0.368-0.093,0.561-0.093
			c0.669,0,1.224,0.266,1.666,0.799l1.122-1.462c-0.329-0.385-0.734-0.677-1.215-0.876c-0.482-0.198-1.028-0.297-1.64-0.297
			c-0.419,0-0.833,0.071-1.241,0.212c-0.408,0.142-0.774,0.36-1.097,0.655c-0.323,0.295-0.584,0.666-0.782,1.113
			c-0.198,0.448-0.298,0.984-0.298,1.607c0,0.499,0.065,0.926,0.195,1.283c0.13,0.358,0.306,0.669,0.527,0.935
			c0.221,0.267,0.476,0.496,0.765,0.689c0.289,0.193,0.598,0.368,0.927,0.527c0.521,0.261,0.952,0.544,1.292,0.85
			c0.34,0.306,0.51,0.72,0.51,1.241c0,0.533-0.142,0.946-0.425,1.241s-0.64,0.442-1.071,0.442c-0.385,0-0.762-0.091-1.131-0.272
			s-0.683-0.431-0.944-0.748l-1.105,1.496c0.34,0.397,0.793,0.725,1.36,0.986c0.567,0.261,1.184,0.391,1.853,0.391
			c0.465,0,0.907-0.079,1.326-0.238c0.419-0.159,0.785-0.394,1.097-0.706c0.312-0.311,0.561-0.694,0.748-1.147
			c0.187-0.453,0.281-0.975,0.281-1.564c0-0.51-0.079-0.952-0.238-1.326c-0.159-0.374-0.363-0.697-0.612-0.969
			c-0.249-0.272-0.527-0.504-0.833-0.697c-0.306-0.193-0.606-0.363-0.901-0.51c-0.499-0.249-0.901-0.513-1.207-0.791
			c-0.306-0.277-0.459-0.671-0.459-1.181c0-0.295,0.042-0.55,0.128-0.765C23.159,28.197,23.275,28.021,23.422,27.885z"
          />
          <path
            d="M15.177,28.854c0.204-0.397,0.459-0.711,0.765-0.944c0.306-0.232,0.663-0.348,1.071-0.348
			c0.737,0,1.337,0.334,1.802,1.003l1.173-1.428c-0.329-0.476-0.754-0.841-1.275-1.097c-0.521-0.255-1.128-0.382-1.819-0.382
			c-0.669,0-1.278,0.156-1.828,0.467c-0.55,0.312-1.017,0.748-1.403,1.309c-0.385,0.562-0.683,1.23-0.892,2.006
			c-0.21,0.777-0.314,1.624-0.314,2.542c0,0.918,0.105,1.762,0.314,2.533s0.504,1.436,0.884,1.997
			c0.38,0.562,0.844,1.001,1.394,1.318s1.165,0.476,1.845,0.476c0.748,0,1.368-0.147,1.861-0.442s0.898-0.68,1.215-1.156
			l-1.173-1.377c-0.193,0.295-0.434,0.544-0.722,0.748s-0.632,0.306-1.028,0.306c-0.419,0-0.785-0.116-1.097-0.349
			c-0.312-0.232-0.569-0.546-0.774-0.943s-0.357-0.864-0.459-1.403c-0.102-0.538-0.153-1.107-0.153-1.708
			c0-0.612,0.051-1.187,0.153-1.726C14.82,29.718,14.973,29.251,15.177,28.854z"
          />
          <polygon points="32.007,35.62 31.956,35.62 29.95,25.964 27.672,25.964 30.613,38 33.197,38 36.189,25.964 34.013,25.964 		" />
        </g>
      </g>
    </svg>
  );
};
