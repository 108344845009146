import { FC, SVGProps } from 'react';

export const IconBellWithDot: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 1C12.9762 1 13.9314 1.20391 14.8086 1.58815C14.3011 2.2587 14 3.09419 14 4C14 6.20914 15.7909 8 18 8C18.3449 8 18.6796 7.95635 18.9989 7.87426C18.9997 7.91613 19 7.95805 19 8C19 10.9127 19.732 12.8439 20.4994 14.0771L20.5112 14.096C20.8685 14.6703 21.151 15.1243 21.3415 15.4547C21.4369 15.6202 21.5238 15.7797 21.5882 15.9215C21.6203 15.9922 21.6562 16.079 21.6843 16.1733C21.7076 16.2515 21.752 16.4187 21.7354 16.6223C21.7242 16.7591 21.6963 16.9928 21.5622 17.2343C21.428 17.4758 21.2443 17.623 21.134 17.7047C20.8838 17.8904 20.5963 17.9329 20.5004 17.947L20.496 17.9477C20.3485 17.9695 20.1813 17.9804 20.0125 17.9869C19.6773 18 19.2133 18 18.6363 18H18.6362H18.6362H18.6359H18.6112H5.3889H5.36419H5.36389H5.36384H5.36379C4.78675 18 4.32275 18 3.98757 17.9869C3.81882 17.9804 3.65154 17.9695 3.50413 17.9477L3.49969 17.947C3.40376 17.9329 3.11634 17.8904 2.86605 17.7047C2.75581 17.623 2.57208 17.4758 2.43793 17.2343C2.30378 16.9928 2.27588 16.7591 2.2647 16.6223C2.24805 16.4187 2.29245 16.2515 2.31576 16.1733C2.34385 16.079 2.37979 15.9922 2.41192 15.9215C2.47628 15.7797 2.56321 15.6202 2.65863 15.4547C2.84906 15.1243 3.13152 14.6703 3.48882 14.0961L3.50066 14.0771C4.26804 12.8439 5.00004 10.9127 5.00004 8C5.00004 6.14348 5.73754 4.36301 7.0503 3.05025C8.36305 1.7375 10.1435 1 12 1ZM10 20C9.44776 20 9.00004 20.4477 9.00004 21C9.00004 21.5523 9.44776 22 10 22H14C14.5523 22 15 21.5523 15 21C15 20.4477 14.5523 20 14 20H10ZM18 6C19.1046 6 20 5.10457 20 4C20 2.89543 19.1046 2 18 2C16.8954 2 16 2.89543 16 4C16 5.10457 16.8954 6 18 6Z"
        fill="currentColor"
      />
    </svg>
  );
};
