import { apiSlice } from '../../api';
import type { GetLegalRolesResult } from './types';

export const roleEndpoints = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getLegalRoles: build.query<GetLegalRolesResult, void>({
      query: () => ({
        url: '/auth-service/v1/legal-roles',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetLegalRolesQuery } = roleEndpoints;

export type RoleController = typeof roleEndpoints;
