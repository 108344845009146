import styled, { css } from 'styled-components';

import { getMediaFor } from '../theming';

export const DashboardLayoutContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: ${({ theme }) => theme.dimensions.dashboardHeader} 1fr;

  height: 100vh;
  width: 100%;

  background-color: ${({ theme }) => theme.colors.bg.dashboardLayout};

  ${({ theme }) =>
    getMediaFor(
      'tablet-max-width',
      theme
    )(css`
      display: flex;
      flex-direction: column;
    `)};
`;

export const DashboardLayoutContent = styled.main`
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  overflow-y: auto;

  padding-top: 8px;
  background-color: ${({ theme }) => theme.colors.bg.dashboardContent};

  ${({ theme }) =>
    getMediaFor(
      'tablet-max-width',
      theme
    )(css`
      flex-grow: 1;
      padding-top: 40px;
    `)};

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      padding-top: 24px;
    `)};
`;
