import styled from 'styled-components';

import { Button } from '../../../../components';

export const ConfirmPasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const ConfirmPasswordSubTitle = styled.div`
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.typography.gray80};
`;

export const ConfirmPasswordForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const ConfirmPasswordFormFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const ConfirmPasswordFormActions = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 8px;
`;

export const Manage2FALink = styled.a`
  align-self: end;
  margin-top: -10px;

  font-weight: 400;
  font-size: 1.6rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.gray64};

  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.typography.gray80};
  }
`;

export const ConfirmPasswordButton = styled(Button)`
  flex-grow: 1;
`;
