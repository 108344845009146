import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { LineInfo } from '../../../../../components';
import { ProfileInfoBody, ProfileInfoContainer, ProfileInfoHeader } from '../../../components';

import { useGetActiveCountriesQuery } from '../../../../../api';
import { Address } from '../../../../../types';

type AddressInfoProps = {
  address: Address;
};

export const AddressInfo: FC<AddressInfoProps> = ({ address }) => {
  const { t } = useTranslation();

  const { data: activeCountries } = useGetActiveCountriesQuery();

  const country = activeCountries?.find((c) => c.alpha3Code === address?.country)?.name;

  return (
    <ProfileInfoContainer>
      <ProfileInfoHeader
        title={t('profile.legal.address.header.title')}
        isActionButtonVisible={false}
      />

      <ProfileInfoBody>
        {address.addressType && (
          <LineInfo
            label={t('profile.legal.address.body.type')}
            value={t(`common.address-type.${address.addressType}`)}
          />
        )}

        {country && <LineInfo label={t('profile.legal.address.body.country')} value={country} />}

        {address.state && (
          <LineInfo label={t('profile.legal.address.body.state')} value={address.state} />
        )}

        {address.city && (
          <LineInfo label={t('profile.legal.address.body.city')} value={address.city} />
        )}

        {address.street && (
          <LineInfo label={t('profile.legal.address.body.street')} value={address.street} />
        )}

        {address.buildingNumber && (
          <LineInfo
            label={t('profile.legal.address.body.building-number')}
            value={address.buildingNumber}
          />
        )}

        {address.flatNumber && (
          <LineInfo
            label={t('profile.legal.address.body.flat-number')}
            value={address.flatNumber}
          />
        )}

        {address.zipCode && (
          <LineInfo label={t('profile.legal.address.body.zip-code')} value={address.zipCode} />
        )}
      </ProfileInfoBody>
    </ProfileInfoContainer>
  );
};
