import { FC, ReactNode, SyntheticEvent, useMemo } from 'react';
import { TabsProps as MuiTabsProps } from '@mui/material';

import * as S from './styled';
import { Tab, TabPanel, TabProps } from './components';

export type TabsProps = Omit<MuiTabsProps, 'value' | 'onChange'> & {
  /**
   * Active tab
   */
  value: number;
  /**
   * Callback, which switches tabs
   *
   * @param e - event
   * @param newValue - new active tab
   */
  onChange: (e: SyntheticEvent, newValue: number) => void;
  /**
   * Array of tabs.
   * `label` - displays in tabs header.
   * `content` - node with content for tab.
   */
  tabs: { label: string; content: ReactNode }[];
  /**
   * Aria name for aria props
   */
  ariaName?: string;
  /**
   * Mui tab props
   */
  tabProps?: TabProps;
};

const a11yProps = (ariaName: TabsProps['ariaName'], index: number) => {
  if (!ariaName) return {};

  return {
    id: `${ariaName}-tab-${index}`,
    'aria-controls': `${ariaName}-tabpanel-${index}`,
  };
};

export const Tabs: FC<TabsProps> = (props) => {
  const { value, onChange, tabs, ariaName, tabProps, ...rest } = props;

  const tabElements = useMemo(() => {
    return tabs.map((tab, index) => {
      return <Tab key={index} label={tab.label} {...a11yProps(ariaName, index)} {...tabProps} />;
    });
  }, [tabs, ariaName, tabProps]);

  const tabPanelElements = useMemo(() => {
    return tabs.map((tab, index) => {
      return (
        <TabPanel key={index} index={index} activeTab={value} ariaName={ariaName}>
          {tab.content}
        </TabPanel>
      );
    });
  }, [tabs, value, ariaName]);

  return (
    <S.TabsContainer>
      <S.TabsHeader>
        <S.Tabs
          value={value}
          onChange={onChange}
          aria-label={ariaName && `${ariaName} tabs`}
          variant="scrollable"
          scrollButtons="auto"
          indicatorColor="secondary"
          {...rest}
        >
          {tabElements}
        </S.Tabs>
      </S.TabsHeader>

      <S.TabsBody>{tabPanelElements}</S.TabsBody>
    </S.TabsContainer>
  );
};
