import { FC, SVGProps } from 'react';

export const IconUp: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.17801 3.18645C5.57569 2.61244 6.42431 2.61244 6.82199 3.18645L10.1088 7.93051C10.5683 8.59371 10.0936 9.5 9.28678 9.5H2.71322C1.9064 9.5 1.43175 8.59371 1.89123 7.93051L5.17801 3.18645Z"
        fill="currentColor"
      />
    </svg>
  );
};
