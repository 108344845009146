import styled, { css } from 'styled-components';

import { getMediaFor } from '../../layouts';

export const TeamContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  height: 100%;
  min-height: 100%;
  padding: 0 40px 40px;

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      padding: 0 16px 40px;
    `)};
`;

export const TeamHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TeamSearchInputWrapper = styled.div`
  max-width: 320px;
`;

export const TeamContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  flex-grow: 1;

  padding-right: 16px;
  overflow: auto;

  .avatar-cell {
    width: 56px;
  }

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      padding-right: 4px;
    `)};
`;

export const MobileCellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (max-width: 409px) {
    max-width: 290px;
  }
`;

export const EllipsisEmail = styled.div`
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
