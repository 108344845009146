import { apiSlice } from '../../api';
import type { GetIBANFeeDTO, GetIBANFeeResult, RequestIBANDTO, RequestIBANResult } from './types';

export const walletEndpoints = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getIBANFee: build.query<GetIBANFeeResult, GetIBANFeeDTO>({
      query: ({ walletUid }) => ({
        url: `/wallets-service/v1/wallets/${walletUid}/viban/fee-calc`,
        method: 'POST',
      }),
    }),
    requestIBAN: build.mutation<RequestIBANResult, RequestIBANDTO>({
      query: ({ walletUid }) => ({
        url: `/wallets-service/v1/wallets/${walletUid}/viban`,
        method: 'POST',
      }),
      invalidatesTags: ['Wallets'],
    }),
    depositMethod: build.query<RequestIBANResult, RequestIBANDTO>({
      query: ({ walletUid }) => ({
        url: `/wallets-service/v1/wallets/${walletUid}/deposit-method`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useLazyGetIBANFeeQuery } = walletEndpoints;
export const { useRequestIBANMutation } = walletEndpoints;
export const { useDepositMethodQuery, useLazyDepositMethodQuery } = walletEndpoints;

export type WalletController = typeof walletEndpoints;
