import { useTranslation } from 'react-i18next';
import { useNavigation, useRouteError } from 'react-router-dom';

type ErrorResponse = {
  status: number;
  statusText: string;
  data: any;
  error?: Error;
  internal: boolean;
};
type ErrorType = Error | ErrorResponse;
type HandledError = { message: string } | ErrorResponse;

/**
 * Enhanced version of standard useRouteError hook with typification and custom data mapping
 */
export const useAppRouteError = (): HandledError => {
  const { t } = useTranslation();
  const error = useRouteError() as ErrorType;

  // common mistake
  if (error instanceof Error) {
    return { message: error.message };
  }

  // router internal mistake
  if (error.internal) {
    return error;
  }

  // rtk-query request mistake and custom mistakes
  if (!error.internal) {
    return { ...error, data: error?.data?.data };
  }

  return { message: t('ui.errors.default-message') };
};

/**
 * Hook, which returns current app loading state
 */
export const useAppLoading = () => {
  const navigation = useNavigation();
  return navigation.state !== 'idle';
};
