import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styled';
import { LineInfo } from '../../../../components';

import { UTCToLocal } from '../../../../utils';
import type { Ticket } from '../../../../types';

type TicketInfoProps = { ticket: Ticket | undefined };

export const TicketInfo: FC<TicketInfoProps> = ({ ticket }) => {
  const { t } = useTranslation();

  return (
    <S.TicketInfoContainer>
      <LineInfo
        label={t('ticket.info.created-at')}
        value={ticket?.createdAt ? UTCToLocal(ticket?.createdAt) : '—'}
      />
      <LineInfo
        label={t('ticket.info.closed-at')}
        value={ticket?.closedAt ? UTCToLocal(ticket?.closedAt) : '—'}
      />
      <LineInfo
        label={t('ticket.info.ticket-type')}
        value={
          t(`common.ticket-type.${ticket?.ticketType}` as unknown as TemplateStringsArray) || '—'
        }
      />
      <LineInfo label={t('ticket.info.message')} value={ticket?.message || '—'} />
      <LineInfo
        label={t('ticket.info.ticket-state')}
        value={
          t(`common.ticket-state.${ticket?.ticketState}` as unknown as TemplateStringsArray) || '—'
        }
      />
    </S.TicketInfoContainer>
  );
};
