import styled from 'styled-components';

import { Button } from '../../../../components';

export const AddCryptoAccountForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const AddCryptoAccountFormFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AddCryptoAccountFormActions = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 8px;
`;

export const AddCryptoAccountNextStepButton = styled(Button)`
  flex-grow: 1;
`;

export const CreateCryptoAccountButton = styled(AddCryptoAccountNextStepButton)``;
