import styled from 'styled-components';

import { TextArea } from '../../../../components';

export const AddTicketForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const AddTicketFormFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AddTicketFormTextArea = styled(TextArea)`
  height: 180px;
`;

export const Description = styled.div`
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 100%;
  width: 100%;
  color: ${({ theme }) => theme.colors.typography.gray80};
  padding-bottom: 4px;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;

  button {
    width: 100%;
  }
`;
