import styled from 'styled-components';
import { Button } from '../../../../components';

export const DownloadButton = styled(Button)`
  height: 32px;
  min-width: 102px;
  border: 0;
  padding: 0;

  &:hover {
    background-color: transparent;
    color: ${({ theme }) => theme.colors.typography.white};
    border: 0;
  }
`;
