import { FC, SVGProps } from 'react';

export const IconABF: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M14.8636 0L5.31818 23.9999H1.5L11.0455 0H14.8636Z" fill="#F5B70A" />
      <path
        d="M18.6818 0H22.5L16.6296 14.76H22.5L21.0682 18.45H15.1619L12.9545 24H9.13636L18.6818 0Z"
        fill="#F5B70A"
      />
    </svg>
  );
};
