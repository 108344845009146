import { CSSProperties, FC } from 'react';
import { TableCellProps as MuiTableCellProps } from '@mui/material';

import * as S from './styled';

export type CellSize = 'medium' | 'large';
export type TableCellOwnProps = {
  align?: 'left' | 'center' | 'right';
  size?: CellSize;
  customCss?: CSSProperties;
};
type TableCellProps = Omit<MuiTableCellProps, 'size'> & TableCellOwnProps;

export const TableCell: FC<TableCellProps> = (props) => {
  const { align = 'left', size = 'medium', children, customCss, ...rest } = props;

  return (
    <S.TableCell {...rest} align={align} size={size} style={customCss}>
      {children}
    </S.TableCell>
  );
};
