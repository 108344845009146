import styled from 'styled-components';
import { Button } from '../../../../components';

export const FullWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
`;

export const TransfersFilterForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const TransfersFilterFormFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TransfersFilterFormActions = styled.div`
  display: flex;
  gap: 16px;
`;

export const ClearTransfersFilterButton = styled(Button)`
  min-width: 106px;
`;

export const ApplyTransfersFilterButton = styled(Button)`
  flex-grow: 1;
`;

export const ErrorDownloadCsv = styled.div`
  font-weight: 400;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.error};
`;
