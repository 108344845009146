import { apiDocgenSlice, apiSlice } from '../../api';
import type {
  DownloadWalletStatementDto,
  DownloadWalletStatementResult,
  DownloadTransferStatementDto,
  DownloadTransferStatementResult,
} from './types';

export const doxgenEndpoints = apiDocgenSlice.injectEndpoints({
  endpoints: (build) => ({
    downloadWalletStatementPdf: build.mutation<
      DownloadWalletStatementResult,
      DownloadWalletStatementDto
    >({
      query: (body) => ({
        url: '/v1/wallet-statement/pdf',
        method: 'POST',
        body,
        responseHandler: async (response) => {
          if (!response.ok) {
            return;
          }
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'wallet-statement.pdf');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
      }),
    }),
    downloadTransactionStatementPdf: build.mutation<
      DownloadTransferStatementResult,
      DownloadTransferStatementDto
    >({
      query: (body) => ({
        url: '/v1/transaction-statement/pdf',
        method: 'POST',
        body,
        responseHandler: async (response) => {
          if (!response.ok) {
            return;
          }
          const blob = await response.blob();
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'transaction-statement.pdf');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
      }),
    }),
  }),
});

export const { useDownloadWalletStatementPdfMutation } = doxgenEndpoints;
export const { useDownloadTransactionStatementPdfMutation } = doxgenEndpoints;

export type DocumentProfileController = typeof doxgenEndpoints;
