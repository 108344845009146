import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import dayjs from 'dayjs';

import * as S from './styled';
import {
  ProfileInfoContainer,
  ProfileInfoHeader,
  UpdateDocumentForm,
  UpdateDocumentFormFields,
} from '../../../components';
import { IconEdit } from '../../../../../assets';
import { LineInfo, Modal } from '../../../../../components';

import {
  naturalProfileSelector,
  profileVerifyStatusSelector,
  useAppSelector,
} from '../../../../../store';
import { useGetActiveCountriesQuery, useUploadProfileDocumentMutation } from '../../../../../api';
import { AsyncErrorMessage, getAsyncErrorMessage } from '../../../../../forms';
import { NaturalIdDocumentType } from '../../../../../types';
import { UTCToLocal } from '../../../../../utils';

type PersonalInfoFormFields = {
  firstNameEn: string;
  lastNameEn: string;
  dateOfBirth: string;
  idDocumentType: `${NaturalIdDocumentType}`;
  validTo: string;
};

export const PersonalInfo: FC = () => {
  const { t } = useTranslation();

  const isVerified = useAppSelector(profileVerifyStatusSelector);
  const naturalProfile = useAppSelector(naturalProfileSelector);
  const [isEditMode, setEditMode] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const { data: activeCountries } = useGetActiveCountriesQuery();
  const [updateDocument, { isLoading: isUploadDocument }] = useUploadProfileDocumentMutation();

  const citizenship = activeCountries?.find(
    (c) => c.alpha3Code === naturalProfile?.citizenship
  )?.name;

  const closeModal = () => setEditMode(false);

  const {
    reset,
    setError,
    formState: { errors },
  } = useForm<PersonalInfoFormFields>({
    defaultValues: { ...naturalProfile },
  });

  const enableEditMode = () => {
    setEditMode(true);
  };
  const disableEditMode = () => {
    reset({ ...naturalProfile });
    setEditMode(false);
  };

  const updateProfileInfo: SubmitHandler<UpdateDocumentFormFields> = async (values) => {
    if (!isEditMode) {
      return;
    }

    const mappedValues: UpdateDocumentFormFields = {
      ...values,
      expiredAt: dayjs(values.expiredAt).toISOString(),
    };

    try {
      await updateDocument({ profileUid: naturalProfile!.profileUid, body: mappedValues }).unwrap();
      setEditMode(false);
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 6000);
    } catch (e) {
      getAsyncErrorMessage(e, setError);
      setEditMode(false);
    }
  };

  return (
    <ProfileInfoContainer>
      <ProfileInfoHeader
        title={t('profile.personal-info-form.header.title')}
        actionButtonProps={{
          startIcon: <IconEdit />,
          disabled: !isVerified,
          body: t('profile.personal-info-form.header.action-btn'),
          onClick: () => enableEditMode(),
        }}
        isActionButtonVisible={!isEditMode}
      />

      <S.PersonalInfoForm>
        {naturalProfile?.firstNameEn && (
          <LineInfo
            label={t('profile.personal-info-form.fields.first-name.label')}
            value={naturalProfile.firstNameEn}
          />
        )}

        {naturalProfile?.lastNameEn && (
          <LineInfo
            label={t('profile.personal-info-form.fields.last-name.label')}
            value={naturalProfile.lastNameEn}
          />
        )}

        {naturalProfile?.dateOfBirth && (
          <LineInfo
            label={t('profile.personal-info-form.fields.date-of-birth.label')}
            value={UTCToLocal(naturalProfile.dateOfBirth, 'DD/MM/YYYY')}
          />
        )}

        {citizenship && (
          <LineInfo
            label={t('profile.personal-info-form.fields.citizenship.label')}
            value={citizenship}
          />
        )}

        {naturalProfile?.idDocumentType && (
          <LineInfo
            label={t('profile.personal-info-form.fields.id-document-type.label')}
            value={naturalProfile.idDocumentType}
          />
        )}

        {naturalProfile?.documentNumber && (
          <LineInfo
            label={t('profile.personal-info-form.fields.document-number.label')}
            value={naturalProfile.documentNumber}
          />
        )}

        {naturalProfile?.validTo && (
          <LineInfo
            label={t('profile.personal-info-form.fields.valid-to.label')}
            value={UTCToLocal(naturalProfile.validTo, 'DD/MM/YYYY')}
          />
        )}

        {showSuccess && (
          <S.SuccessContainer>{t('profile.personal-info-form.success-update')}</S.SuccessContainer>
        )}
        <AsyncErrorMessage errors={errors} />
        <Modal
          modalTitle={t('profile.personal-info-form.form.title')}
          body={
            <UpdateDocumentForm
              onCancel={closeModal}
              onUpdate={updateProfileInfo}
              typeForm="personal-info-form"
              isLoading={isUploadDocument}
            />
          }
          open={isEditMode}
          onClose={() => disableEditMode()}
        />
      </S.PersonalInfoForm>
    </ProfileInfoContainer>
  );
};
