import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import * as S from './styled';
import {
  removeValueInMultipleSelect,
  Select,
  SelectOption,
  SelectValue,
} from '../../../../components';

import {
  activeProfileUidSelector,
  selectorTicketsState,
  ticketsActions,
  useAppActions,
  useAppSelector,
} from '../../../../store';
import { AsyncErrorMessage, getAsyncErrorMessage, Shape, yup } from '../../../../forms';
import { GetTicketsByFilterDTO, UseLazyQueryTrigger } from '../../../../api';
import { TicketFilter, TicketState, TicketType } from '../../../../types';

type TicketsFilterFormProps = {
  onCancel: () => void;
  getTickets: UseLazyQueryTrigger<GetTicketsByFilterDTO>;
  ticketsLoading: boolean;
};
type TicketsFilterFormFields = TicketFilter;

const ticketsFilterSchema = yup.object().shape<Shape<TicketsFilterFormFields>>({
  ticketTypes: yup.array().of(yup.string()),
  ticketStates: yup.array().of(yup.string()),
});

export const TicketsFilterForm: FC<TicketsFilterFormProps> = (props) => {
  const { getTickets, ticketsLoading, onCancel } = props;
  const { t } = useTranslation();

  const profileUid = useAppSelector(activeProfileUidSelector);
  const ticketsState = useAppSelector(selectorTicketsState);
  const { setTicketsFilter, resetPagination } = useAppActions(ticketsActions);

  const {
    control,
    reset,
    setError,
    setValue,
    getValues,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<TicketsFilterFormFields>({
    resolver: yupResolver(ticketsFilterSchema),
    defaultValues: { ...ticketsState.filter },
  });

  const clearFilter = () => {
    reset({ ticketStates: [], ticketTypes: [] }, { keepDefaultValues: true });
  };

  const closeFilter = () => onCancel();

  const removeChipInFilter = (value: SelectValue, fieldName: keyof TicketsFilterFormFields) => {
    removeValueInMultipleSelect(value, fieldName, getValues, setValue);
  };

  const applyFilter: SubmitHandler<TicketsFilterFormFields> = async (values) => {
    if (!isDirty) {
      return;
    }

    try {
      await getTickets({
        profileUid,
        ...ticketsState.initialRequestArgs,
        ...ticketsState.pagination,
        pageNumber: 0,
        ...values,
      }).unwrap();
      setTicketsFilter(values);
      resetPagination();
      closeFilter();
    } catch (e) {
      getAsyncErrorMessage(e, setError);
    }
  };

  const ticketTypesOptions = Object.values(TicketType).map((type) => {
    return (
      <SelectOption key={type} value={type} name={t(`common.ticket-type.${type}`)}>
        {t(`common.ticket-type.${type}`)}
      </SelectOption>
    );
  });

  const ticketStatesOptions = Object.values(TicketState).map((state) => {
    return (
      <SelectOption key={state} value={state} name={t(`common.ticket-state.${state}`)}>
        {t(`common.ticket-state.${state}`)}
      </SelectOption>
    );
  });

  return (
    <S.TicketsFilterForm onSubmit={handleSubmit(applyFilter)}>
      <S.TicketsFilterFormFields>
        <Select
          control={{ control, name: 'ticketTypes' }}
          errors={errors}
          options={ticketTypesOptions}
          label={t('tickets.actions.filter.form.fields.ticket-types')}
          multiple
          onMultipleValueDelete={(e, v) => removeChipInFilter(v, 'ticketTypes')}
        />
        <Select
          control={{ control, name: 'ticketStates' }}
          errors={errors}
          options={ticketStatesOptions}
          label={t('tickets.actions.filter.form.fields.ticket-states')}
          multiple
          onMultipleValueDelete={(e, v) => removeChipInFilter(v, 'ticketStates')}
        />

        <AsyncErrorMessage errors={errors} />
      </S.TicketsFilterFormFields>

      <S.TicketsFilterFormActions>
        <S.ClearTicketsFilterButton
          variant="contained"
          body={t('tickets.actions.filter.form.clear-filter-btn')}
          onClick={() => clearFilter()}
        />
        <S.ApplyTicketsFilterButton
          body={t('tickets.actions.filter.form.accept-filter-btn')}
          type="submit"
          disabled={ticketsLoading}
        />
      </S.TicketsFilterFormActions>
    </S.TicketsFilterForm>
  );
};
