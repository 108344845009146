import { FC } from 'react';

import * as S from './styled';
import { ButtonProps } from '../../../../../components';

type ProfileFormFooterProps = {
  cancelButtonProps: ButtonProps;
  sendRequestButtonProps: ButtonProps;
};

export const ProfileFormFooter: FC<ProfileFormFooterProps> = (props) => {
  const { cancelButtonProps, sendRequestButtonProps } = props;

  return (
    <S.ProfileFormFooter>
      <S.FormCancelButton {...cancelButtonProps} variant="contained" />
      <S.FormSendRequestButton {...sendRequestButtonProps} />
    </S.ProfileFormFooter>
  );
};
