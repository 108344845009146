import { FC } from 'react';
import { MenuItemProps } from '@mui/material';

import * as S from './styled';
import type { SelectName } from '../../types';

type SelectOptionProps = MenuItemProps & { name: SelectName };

export const SelectOption: FC<SelectOptionProps> = ({ children, ...props }) => {
  return <S.Option {...props}>{children}</S.Option>;
};
