import { setItemToStorage } from '../localStorage';
import { logoutURLSelector, useAppSelector } from '../../store';

export const useLogoutHandler = () => {
  const logoutURL = useAppSelector(logoutURLSelector);

  // TODO: add socket disconnecting, after socket implementation

  const logout = () => {
    // socket.disconnect();
    setItemToStorage('twoFANotificationShown', false);
    window.location.replace(`${logoutURL}&return_to=${window.location.href}`);
  };

  return { logout };
};
