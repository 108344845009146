import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styled';
import { Button, LanguageMenu, Logo } from '../../../../components';
import { useLogoutHandler } from '../../../../utils';

export const ProfileLayoutHeader: FC = () => {
  const { t } = useTranslation();
  const { logout } = useLogoutHandler();

  return (
    <S.ProfileLayoutHeader>
      <S.LeftPart>
        <Logo type="white" />
      </S.LeftPart>

      <S.RightPart>
        <LanguageMenu type="menu" />
        <Button
          variant="contained"
          color="secondary"
          body={t('profile-layout.header.logout-btn')}
          onClick={() => logout()}
        />
      </S.RightPart>
    </S.ProfileLayoutHeader>
  );
};
