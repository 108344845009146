export type LocalStorageKeys = keyof typeof LocalStorage;

export type LocalStorageValue =
  | string
  | number
  | unknown[]
  | Record<string, unknown>
  | null
  | boolean;

export enum LocalStorage {
  activeProfile = 'activeProfile',
  userUid = 'userUid',
  i18nextLng = 'i18nextLng',
  twoFANotificationShown = 'twoFANotificationShown',
  twoFANotificationShownDate = 'twoFANotificationShownDate',
}
