import styled, { css } from 'styled-components';

export const LabelContainer = styled.div<{ $disabled: boolean }>`
  background-color: ${({ theme }) => theme.colors.bg.white10};
  width: auto;
  height: auto;
  padding: 12px 16px;
  display: flex;
  border-radius: 8px;
  gap: 12px;
  font-size: 16px;
  justify-content: flex-start;
  color: ${({ theme }) => theme.colors.typography.white};
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}
`;
