import styled, { css } from 'styled-components';

import { HintTextAlign } from './types';

export const DefaultHintContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;

  min-height: 50px;
  width: 100%;

  padding: 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.bg.white10};

  font-weight: 400;
  font-size: 1.4rem;
  line-height: 130%;
  color: ${({ theme }) => theme.colors.typography.gray80};
`;

export const SmallHintContainer = styled.div<{ $hasIcon: boolean }>`
  display: flex;
  align-items: center;
  gap: 8px;

  min-height: 28px;
  width: 100%;

  padding: 6px 16px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.bg.white20};

  font-weight: 400;
  font-size: 1.2rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.white};

  ${({ $hasIcon }) =>
    $hasIcon &&
    css`
      padding: 6px 8px;
    `};
`;

export const HintIcon = styled.div`
  display: flex;
  align-items: center;
`;
export const HintMessage = styled.div<{ $textAlign: HintTextAlign }>`
  width: 100%;

  ${({ $textAlign }) => {
    switch ($textAlign) {
      case 'left':
        return css`
          text-align: left;
        `;
      case 'center':
        return css`
          text-align: center;
        `;
    }
  }};
`;
