import { createTheme } from '@mui/material';
import { enUS, ruRU } from '@mui/material/locale';
import { enUS as enUS_DatePickers, ruRU as ruRU_DatePickers } from '@mui/x-date-pickers';
// do not remove - types for pickers
import type {} from '@mui/x-date-pickers/themeAugmentation';

import { hardBreakpoints } from '../media';
import { i18n, i18nConfig } from '../../../services';
import type { ButtonProps } from '../../../components';
import type { Theme } from './types';

const currentLang = i18n.resolvedLanguage ?? (i18nConfig.fallbackLng as string);
const muiLocaleCore = { en: enUS, ru: ruRU }[currentLang] ?? enUS;
const muiLocaleDatePickers = { en: enUS_DatePickers, ru: ruRU_DatePickers }[currentLang] ?? enUS;

const customThemeProperties: Theme = {
  colors: {
    primary: '#8bd300',
    secondary: '#f5b70a',
    error: '#f55d58',

    bg: {
      dashboardLayout: '#141414',
      dashboardContent: 'rgba(255, 255, 255, 0.05)',
      profileLayout: '#141414',
      profileLayoutContent: '#333333',

      avatar: '#292929',
      contextMenu: '#333333',
      dataPicker: '#525252',
      dialog: '#333333',
      dropList: '#333333',
      notifications: '#333333',
      overlay: '#141414',
      overlayWithAlpha: 'rgba(20, 20, 20, 0.8)',
      walletSelect: '#333333',

      white05: 'rgba(255, 255, 255, 0.05)',
      white10: 'rgba(255, 255, 255, 0.1)',
      white15: 'rgba(255, 255, 255, 0.15)',
      white20: 'rgba(255, 255, 255, 0.2)',
      white: '#ffffff',

      gray32: '#525252',
    },

    typography: {
      gray32: '#525252',
      gray48: '#7a7a7a',
      gray56: '#8f8f8f',
      gray64: '#a3a3a3',
      gray80: '#cccccc',
      gray88: '#e0e0e0',
      gray96: '#f5f5f5',

      white50: 'rgba(255, 255, 255, 0.5)',
      white: '#ffffff',

      black08: '#141414',
      black: '#000000',
    },

    borders: {
      gray64: '#a3a3a3',
      gray80: '#cccccc',
      gray96: '#f5f5f5',

      white05: 'rgba(255, 255, 255, 0.05)',
      white10: 'rgba(255, 255, 255, 0.1)',
      white20: 'rgba(255, 255, 255, 0.2)',
      white30: 'rgba(255, 255, 255, 0.3)',
      white50: 'rgba(255, 255, 255, 0.5)',
      white: '#ffffff',
    },
  },

  shadowsCustom: {
    profileLayoutContent: '0px 10px 40px rgba(27, 32, 50, 0.08)',
    contextMenu: '0px 10px 40px rgba(27, 32, 50, 0.08)',
    dataPicker: '0px 10px 40px rgba(27, 32, 50, 0.08)',
    dialog: '0px 10px 40px rgba(27, 32, 50, 0.08)',
    notifications: ' 0px 24px 128px rgba(27, 32, 50, 0.16)',

    notificationBell: '0px 0px 8px rgba(245, 183, 10, 0.35), 0px 0px 24px rgba(245, 183, 10, 0.35)',
    mobileMenuButton: '0 0 24px 0 rgba(245, 183, 10, 0.35), 0 0 8px 0 rgba(245, 183, 10, 0.35)',
    walletSelect: '0px 10px 40px rgba(27, 32, 50, 0.08)',
  },

  // z-index
  order: {},

  dimensions: {
    dashboardHeader: '104px',
    dashboardSidebar: '312px',
  },
};

// initial mui-theme definition
let theme = createTheme({
  palette: {
    primary: { main: '#8bd300' },
    secondary: { main: '#f5b70a' },
    error: { main: '#f55d58' },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
  components: {},

  ...customThemeProperties,
});

// overriding mui-components styles with custom theme properties
theme = createTheme(theme, {
  components: {
    // button styles
    MuiButton: {
      styleOverrides: {
        contained: ({ ownerState }: { ownerState: ButtonProps }) => ({
          backgroundColor: theme.colors.bg.white10,
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
          '&:hover': {
            backgroundColor: theme.colors.bg.white20,
            boxShadow: 'none',
            color: theme.colors.typography.white,
          },
          '&.Mui-disabled': {
            backgroundColor: theme.colors.bg.white10,
            color: theme.colors.typography.gray80,
          },
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'primary' && {
              padding: '12px 24px',
              borderRadius: '40px',
              fontSize: '1.8rem',
              color: theme.colors.typography.white,
            }),
          ...(ownerState.variant === 'contained' &&
            ownerState.color === 'secondary' && {
              padding: '8px 16px',
              borderRadius: '16px',
              fontSize: '1.6rem',
              color: theme.colors.typography.gray80,
            }),
        }),
        outlined: ({ ownerState }: { ownerState: ButtonProps }) => ({
          color: theme.colors.typography.white,
          borderWidth: '2px',
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'primary' && {
              padding: '10px 24px',
              borderColor: theme.palette.primary.main,
              borderRadius: '40px',
              fontSize: '1.8rem',
              '&:hover': {
                border: `2px solid ${theme.palette.primary.main}`,
                backgroundColor: theme.palette.primary.main,
                color: theme.colors.typography.black08,
              },
              '&.Mui-disabled': {
                color: theme.colors.typography.white,
                border: `2px solid ${theme.palette.primary.main}`,
              },
            }),
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'secondary' && {
              padding: '6px 16px',
              borderColor: theme.colors.borders.white,
              borderRadius: '16px',
              fontSize: '1.6rem',
              '&:hover': {
                border: `2px solid ${theme.colors.borders.white}`,
                backgroundColor: theme.colors.bg.white15,
              },
              '&.Mui-disabled': {
                color: theme.colors.typography.white,
                border: `2px solid ${theme.colors.borders.white}`,
              },
            }),
          ...(ownerState.variant === 'outlined' &&
            ownerState.color === 'error' && {
              padding: '10px 24px',
              borderColor: theme.palette.error.main,
              borderRadius: '40px',
              fontSize: '1.8rem',
              '&:hover': {
                border: `2px solid ${theme.palette.error.main}`,
                backgroundColor: theme.palette.error.main,
                color: theme.colors.typography.black08,
              },
              '&.Mui-disabled': {
                color: theme.colors.typography.white,
                border: `2px solid ${theme.palette.error.main}`,
              },
            }),
        }),
        text: ({ ownerState }: { ownerState: ButtonProps }) => ({
          fontSize: '1.6rem',
          '&.Mui-disabled': {
            color: theme.colors.typography.gray88,
          },
          ...(ownerState.variant === 'text' &&
            ownerState.color === 'primary' && {
              padding: '12px 24px',
              borderRadius: '40px',
              color: theme.colors.typography.white,
            }),
          ...(ownerState.variant === 'text' &&
            ownerState.color === 'secondary' && {
              padding: '8px 16px',
              borderRadius: '16px',
              color: theme.colors.typography.gray88,
            }),
        }),
      },
    },
    // icon button styles
    MuiIconButton: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: theme.colors.bg.white10,
          '&:hover': {
            backgroundColor: theme.colors.bg.white20,
            color: theme.colors.typography.white,
          },
          '&.Mui-disabled': {
            backgroundColor: theme.colors.bg.white10,
          },
        },
        colorSecondary: {},
      },
    },
    // datePicker styles
    MuiCalendarOrClockPicker: {
      styleOverrides: {
        root: {
          '& > div': {
            width: '284px',
          },
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          width: '284px',
        },
      },
    },
    MuiPickersCalendarHeader: {
      styleOverrides: {
        root: {
          position: 'relative',
          justifyContent: 'center',
          alignItems: 'stretch',
          minHeight: '32px',
          maxHeight: '32px',
          marginBottom: 0,
          padding: 0,
          boxShadow: '0px 10px 10px rgba(27, 32, 50, 0.08)',
        },
        labelContainer: {
          minHeight: '32px',
          maxHeight: '32px',
          margin: 0,
          fontWeight: 400,
          fontSize: '1.6rem',
          lineHeight: '100%',
          color: theme.colors.typography.gray96,
        },
        label: {
          margin: 0,
        },
        switchViewButton: {
          display: 'none',
        },
      },
    },
    MuiPickersArrowSwitcher: {
      styleOverrides: {
        spacer: {
          display: 'none',
        },
        button: {
          position: 'absolute',
          color: theme.colors.typography.gray88,
          '&.Mui-disabled': {
            color: theme.colors.typography.gray48,
          },
          '&:first-child': {
            top: '2px',
            left: '16px',
          },
          '&:last-child': {
            top: '2px',
            right: '16px',
          },
        },
      },
    },
    MuiDayPicker: {
      styleOverrides: {
        header: {
          justifyContent: 'space-between',
          margin: '0 16px',
          borderBottom: `1px solid ${theme.colors.borders.white10}`,
        },
        weekDayLabel: {
          height: '36px',
          margin: 0,
          fontSize: '1.6rem',
          lineHeight: '100%',
          color: theme.colors.typography.gray96,
        },
        slideTransition: {
          minHeight: '220px',
        },
        monthContainer: {
          margin: '2px 16px 0',
        },
        weekContainer: {
          justifyContent: 'space-between',
          margin: 0,
        },
      },
    },
    MuiPickersDay: {
      styleOverrides: {
        root: {
          margin: 0,
          borderRadius: '24px',
          backgroundColor: 'transparent',
          fontSize: '1.6rem',
          lineHeight: '100%',
          color: theme.colors.typography.gray88,
          '&:hover': {
            backgroundColor: theme.colors.bg.white10,
          },
          '&.Mui-selected': {
            borderRadius: '16px',
            color: theme.colors.typography.black,
            '&:hover, &:focus': {
              backgroundColor: theme.palette.primary.main,
            },
          },
          '&.Mui-disabled': {
            color: theme.colors.typography.gray48,
          },
        },
        today: {
          '&:not(.Mui-selected)': {
            borderColor: theme.colors.borders.gray96,
          },
        },
      },
    },
    MuiYearPicker: {
      styleOverrides: {
        root: {},
      },
    },
    PrivatePickersYear: {
      styleOverrides: {
        root: {},
        button: {
          width: '64px',
          margin: '6px 0',
          fontWeight: 500,
          fontSize: '1.6rem',
          lineHeight: '100%',
          color: theme.colors.typography.gray88,
          '&:hover': {
            backgroundColor: theme.colors.bg.white10,
          },
          '&.Mui-selected': {
            color: theme.colors.typography.black,
            '&:hover, &:focus': {
              backgroundColor: theme.palette.primary.main,
            },
          },
          '&.Mui-disabled': {
            color: theme.colors.typography.gray48,
          },
        },
      },
    },
    // table styles
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:not(:last-of-type)': {
            borderBottom: `1px solid ${theme.colors.borders.white10}`,
          },
        },
        head: {
          borderBottom: `1px solid ${theme.colors.borders.white30}`,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          border: 'none',
          fontSize: '1.6rem',
          lineHeight: '100%',
          color: theme.colors.typography.gray80,
          '&:last-of-type': {
            paddingRight: '24px',
            [`@media (max-width: ${hardBreakpoints.tablet - 1}px)`]: {
              paddingRight: '16px',
            },
          },
        },
        head: {
          fontWeight: 400,
          color: theme.colors.typography.white,
        },
        alignLeft: {
          paddingLeft: 0,
        },
        alignRight: {
          paddingRight: 0,
        },
        sizeMedium: {
          height: '48px',
          padding: '16px 16px 15px',
        },
        sizeLarge: {
          height: '56px',
          padding: '20px 20px 19px',
        },
      },
    },
    // tooltip styles
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '1.2rem',
        },
      },
    },
  },
});

// add mui-components localization, according i18n
theme = createTheme(theme, muiLocaleCore, muiLocaleDatePickers);

export { theme };
