import { FC } from 'react';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';

import { ProfileContent } from '../components';
import { AddressInfo, CompanyInfo, ContactInfo } from './components';

import {
  useGetLegalCompanyTypesQuery,
  useGetLegalIndustryCodesQuery,
  useGetProfileAddressQuery,
} from '../../../api';
import { useACL } from '../../../services';
import { legalProfileSelector, useAppSelector } from '../../../store';

export const LegalProfile: FC = () => {
  const hasPermission_PROFILE_ADDRESSES_VIEW = useACL(['PROFILE_ADDRESSES_VIEW']);
  const hasPermission_INDUSTRY_CODES_VIEW = useACL(['INDUSTRY_CODES_VIEW']);

  const legalProfile = useAppSelector(legalProfileSelector);

  const { data: legalCompanyTypes } = useGetLegalCompanyTypesQuery();
  const { data: legalIndustryCodes } = useGetLegalIndustryCodesQuery(
    hasPermission_INDUSTRY_CODES_VIEW ? undefined : skipToken
  );
  const { data: addresses } = useGetProfileAddressQuery(
    hasPermission_PROFILE_ADDRESSES_VIEW ? { profileUid: legalProfile!.profileUid } : skipToken
  );

  const companyType = legalCompanyTypes?.find((t) => t.id === legalProfile?.companyTypeId)?.name;
  const industry = legalIndustryCodes?.find((c) => c.id === legalProfile?.industryCodeId)?.name;
  const additionalBusinessNames = legalProfile?.additionalBusinessNames?.join(', ');

  return (
    <ProfileContent>
      {legalProfile && (
        <CompanyInfo
          legalProfile={legalProfile}
          companyType={companyType}
          industry={industry}
          additionalBusinessNames={additionalBusinessNames}
        />
      )}

      {legalProfile && <ContactInfo legalProfile={legalProfile} />}

      {addresses && addresses.map((address) => <AddressInfo key={address.id} address={address} />)}
    </ProfileContent>
  );
};
