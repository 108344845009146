import { apiSlice } from '../../api';
import type {
  CreateTicketDTO,
  CreateTicketResult,
  GetTicketAttachmentsDTO,
  GetTicketAttachmentsResult,
  GetTicketByIdDTO,
  GetTicketByIdResult,
  GetTicketsByFilterDTO,
  GetTicketsByFilterResult,
  PostTicketAttachmentsDTO,
  PostTicketAttachmentsResult,
  UpdateTicketDTO,
  UpdateTicketResult,
} from './types';

export const ticketsEndpoints = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getTicketsByFilter: build.query<GetTicketsByFilterResult, GetTicketsByFilterDTO>({
      query: (body) => ({
        url: '/ticket-service/v1/tickets/filter',
        method: 'POST',
        body,
      }),
      providesTags: ['Tickets'],
    }),
    createTicket: build.mutation<CreateTicketResult, CreateTicketDTO>({
      query: (body) => ({
        url: '/ticket-service/v1/tickets',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Tickets'],
    }),
    getTicketById: build.query<GetTicketByIdResult, GetTicketByIdDTO>({
      query: ({ ticketId }) => ({
        url: `/ticket-service/v1/tickets/${ticketId}`,
        method: 'GET',
      }),
      providesTags: ['CurrentTicket'],
    }),
    // TODO: move `getTicketAttachmentsById` and `postTicketAttachmentsById` to separate controller according swagger
    getTicketAttachmentsById: build.query<GetTicketAttachmentsResult, GetTicketAttachmentsDTO>({
      query: ({ ticketId }) => ({
        url: `/ticket-service/v1/tickets/${ticketId}/attachments`,
        method: 'GET',
      }),
      providesTags: ['CurrentTicketAttachments'],
    }),
    postTicketAttachmentsById: build.mutation<
      PostTicketAttachmentsResult,
      PostTicketAttachmentsDTO
    >({
      query: ({ ticketId, body }) => ({
        url: `/ticket-service/v1/tickets/${ticketId}/attachments`,
        method: 'Post',
        body,
      }),
      invalidatesTags: ['CurrentTicketAttachments'],
    }),
    //
    updateTicket: build.mutation<UpdateTicketResult, UpdateTicketDTO>({
      query: ({ ticketId, body }) => ({
        url: `/ticket-service/v1/tickets/${ticketId}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Tickets', 'CurrentTicket'],
    }),
  }),
});

export const { useLazyGetTicketsByFilterQuery } = ticketsEndpoints;
export const { useCreateTicketMutation } = ticketsEndpoints;
export const { useGetTicketByIdQuery } = ticketsEndpoints;
export const { useUpdateTicketMutation } = ticketsEndpoints;

export const { useGetTicketAttachmentsByIdQuery } = ticketsEndpoints;
export const { usePostTicketAttachmentsByIdMutation } = ticketsEndpoints;

export type TicketsController = typeof ticketsEndpoints;
