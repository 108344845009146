import { FC, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query/react';
import { useNavigate } from 'react-router-dom';

import * as S from './styled';
import { IconFall, IconRise, IconWarningHexagon16 } from '../../assets';
import {
  Button,
  Loader,
  Column,
  Modal,
  Pagination,
  Table,
  TableCell,
  TableRow,
  Title,
} from '../../components';
import { DownloadReportButton, LegalDocumentVerification, TransfersFilterForm } from './components';

import {
  useDownloadTransfersCSVMutation,
  useGetWalletsQuery,
  useLazyGetRecipientsByFilterQuery,
  useLazyGetTransfersByProfileUidQuery,
  useLazyGetVerificationStatusQuery,
} from '../../api';
import {
  activeProfileTypeSelector,
  activeProfileUidSelector,
  activeTransfersFiltersCountSelector,
  activeTransfersFiltersSelector,
  addressVerifyStatusSelector,
  profileVerifyStatusSelector,
  selectorTransfersState,
  useAppSelector,
} from '../../store';
import { useACL } from '../../services';
import { AddWalletButton, useMedia } from '../../layouts';
import { usePagination, UTCToLocal } from '../../utils';
import { getRouterError, RouterPaths, useAppLoading } from '../../router';
import {
  ExtendedBoolean,
  ProfileType,
  TransferDirection,
  TransferPurpose,
  TransferState,
} from '../../types';
import { HistoryCsvFilterForm } from './components/HistoryCsvFilterForm';
import { ReportContextMenu } from './components/ReportContextMenu';

export const History: FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { isMobile } = useMedia();
  const isAppLoading = useAppLoading();
  const { pagination, onPageChange, resetPagination, setPaginationRequest } = usePagination({
    pageNumber: 0,
    pageSize: 20,
  });
  const hasPermission_STORAGE_DOWNLOAD = useACL(['STORAGE_DOWNLOAD']);
  const hasPermission_RECIPIENT_VIEW = useACL(['RECIPIENT_VIEW']);
  const hasPermission_WALLET_VIEW = useACL(['WALLET_VIEW']);

  const transfersState = useAppSelector(selectorTransfersState);
  const activeFiltersCount = useAppSelector(activeTransfersFiltersCountSelector);
  const filter = useAppSelector(activeTransfersFiltersSelector);
  const profileUid = useAppSelector(activeProfileUidSelector);
  const profileType = useAppSelector(activeProfileTypeSelector);
  const isVerified = useAppSelector(profileVerifyStatusSelector);
  const isAddressVerified = useAppSelector(addressVerifyStatusSelector);

  const isLegal = profileType === ProfileType.LEGAL;
  const isNatural = profileType === ProfileType.NATURAL;
  const hasAllVerifications = isVerified && isAddressVerified;
  const isFilterActive = Boolean(activeFiltersCount);

  const [
    getTransfers,
    { data: transfers, isLoading: isTransfersLoading, isUninitialized: isTransfersUninitialized },
  ] = useLazyGetTransfersByProfileUidQuery();
  const [getRecipients, { data: recipientList }] = useLazyGetRecipientsByFilterQuery();
  const { data: wallets } = useGetWalletsQuery(
    profileUid && hasPermission_WALLET_VIEW ? { profileUid } : skipToken
  );
  const [downloadReport] = useDownloadTransfersCSVMutation();
  const [
    getLegalVerificationStatus,
    {
      data: legalVerificationStatus,
      isLoading: isVerificationStatusLoading,
      isUninitialized: isVerificationStatusUninitialized,
    },
  ] = useLazyGetVerificationStatusQuery();

  const isLegalVerified =
    legalVerificationStatus?.isVerified === ExtendedBoolean.YES &&
    legalVerificationStatus?.isAddressVerified === ExtendedBoolean.YES;

  // filter modal state
  const [isFilterModalShown, setFilterModalShown] = useState(false);
  const showFilter = () => setFilterModalShown(true);
  const closeFilter = () => setFilterModalShown(false);

  const [isReportFilterModalShown, setReportFilterModalShown] = useState(false);
  const showReportFilter = () => setReportFilterModalShown(true);
  const closeReportFilter = () => setReportFilterModalShown(false);

  useEffect(() => {
    if (!isLegal || !profileUid) return;
    if (!isVerificationStatusUninitialized) return;

    getLegalVerificationStatus({ profileUid });
  }, [isVerificationStatusUninitialized]);

  useEffect(() => {
    fetchTransfers();
  }, [pagination.pageNumber, wallets]);

  useEffect(() => {
    if (!recipientList?.data?.length && profileUid && hasPermission_RECIPIENT_VIEW) {
      getRecipients({ profileUid, body: { pageNumber: 0, pageSize: 1000 } });
    }
  }, [profileUid]);

  const fetchTransfers = () => {
    if (profileUid) {
      try {
        const body = isFilterActive
          ? {
              profileUid,
              body: { ...transfersState.filter, ...pagination },
            }
          : { profileUid, body: { ...pagination } };
        getTransfers(body).unwrap();
      } catch (e) {
        throw getRouterError(e as FetchBaseQueryError);
      }
    }
  };

  const transferTableColumns: Column[] = [
    { field: '' },
    { field: t('transfers.table.date') },
    { field: t('transfers.table.payerRecipient'), align: 'right' },
    { field: t('transfers.table.amount'), align: 'right' },
    { field: t('transfers.table.state'), align: 'right' },
    { field: t('transfers.table.purpose'), align: 'right' },
  ];

  const transfersTableRows = useMemo(() => {
    if (transfers?.paging) {
      setPaginationRequest(transfers?.paging?.totalPages ?? 0);
    }

    return transfers?.data?.map((transfer) => {
      const {
        transferDirection,
        createdAt,
        amount,
        state,
        currencyCode,
        transferUid,
        transferPurpose,
        payee,
        payer,
        feeTransfers,
      } = transfer;

      const linkedTransferUid = feeTransfers?.length ? feeTransfers[0] : null;
      const transferState = state && t(`common.transfer-state.${state}`);
      const directionArrow =
        transferDirection === TransferDirection.OUT ? <IconFall /> : <IconRise />;

      const mobileCells = (
        <>
          <TableCell align="left">
            <S.MobileCellWrapper>
              <div className="cell-header">{transferState}</div>
              <div>{createdAt && UTCToLocal(createdAt)}</div>
            </S.MobileCellWrapper>
          </TableCell>

          <TableCell align="right">
            <S.MobileCellWrapper>
              <S.MobileCellWrapperRow>
                <div className="cell-header">
                  {amount &&
                    (transferDirection === TransferDirection.OUT
                      ? `-${amount} ${currencyCode}`
                      : `+${amount} ${currencyCode}`)}
                </div>
                <div>{directionArrow}</div>
              </S.MobileCellWrapperRow>

              {transferPurpose && <div>{t(`common.transfer-purpose.${transferPurpose}`)}</div>}
            </S.MobileCellWrapper>
          </TableCell>
        </>
      );

      const cells = (
        <>
          <TableCell className="arrow-cell">{directionArrow}</TableCell>
          <TableCell align="left">{createdAt && UTCToLocal(createdAt)}</TableCell>
          <TableCell align="right">
            {transferDirection === 'OUT' && (payee || '-')}
            {transferDirection === 'IN' && (payer || '-')}
          </TableCell>
          <TableCell align="right">
            {amount &&
              (transferDirection === TransferDirection.OUT
                ? `-${amount} ${currencyCode}`
                : `+${amount} ${currencyCode}`)}
          </TableCell>
          <TableCell align="right">{transferState}</TableCell>
          <TableCell align="right">
            {transferPurpose && t(`common.transfer-purpose.${transferPurpose}`)}
          </TableCell>
        </>
      );

      return (
        <TableRow
          key={transferUid}
          onClick={() =>
            navigate(
              transferPurpose === TransferPurpose.CHARGES
                ? `${RouterPaths.Transfers}/${linkedTransferUid || transferUid}`
                : `${RouterPaths.Transfers}/${transferUid}`
            )
          }
        >
          {isMobile ? mobileCells : cells}
        </TableRow>
      );
    });
  }, [transfers?.data, wallets, i18n.resolvedLanguage, isMobile]);

  const getEmptyDataElement = () => {
    if (wallets && wallets.length === 0) {
      return (
        <S.EmptyTransfersContainer>
          {t('transfers.empty-transfers.no-wallets')}
          <AddWalletButton />
        </S.EmptyTransfersContainer>
      );
    }

    if (wallets && wallets.length !== 0 && wallets.every((w) => Number(w.balance) === 0)) {
      return (
        <S.EmptyTransfersContainer>
          {t('transfers.empty-transfers.wallets-without-fund')}
          <S.EmptyTransfersActionButton
            body={t('transfers.empty-transfers.redirect-btn')}
            variant="outlined"
            onClick={() => navigate(RouterPaths.Receive)}
          />
        </S.EmptyTransfersContainer>
      );
    }

    return t('transfers.empty-transfers.default');
  };

  const getContent = () => {
    if (isNatural && !hasAllVerifications) {
      return (
        <S.EmptyTransfersContainer>
          {t('transfers.empty-transfers.natural-not-verified')}
          <S.EmptyTransfersActionButton
            body={t('transfers.empty-transfers.verify-btn')}
            variant="outlined"
            onClick={() => navigate(RouterPaths.Verification)}
          />
        </S.EmptyTransfersContainer>
      );
    }

    if (isLegal && !isVerificationStatusUninitialized && !isLegalVerified) {
      return <LegalDocumentVerification />;
    }

    return (
      <>
        <S.HistoryHeader>
          <Title text={t('history.title')} />

          <S.ActionButtonsWrapper>
            <DownloadReportButton
              onClick={showReportFilter}
              wallets={wallets}
              recipientList={recipientList}
              disabled={
                !hasPermission_STORAGE_DOWNLOAD ||
                isTransfersLoading ||
                transfers?.data?.length === 0
              }
            />
            <S.TransfersFilterButton
              variant="default"
              size="small"
              body={t('tickets.actions.filter.btn')}
              onClick={() => showFilter()}
              isFilterActive={isFilterActive}
              activeFiltersCount={activeFiltersCount}
            />
          </S.ActionButtonsWrapper>
        </S.HistoryHeader>

        <S.HistoryTableWrapper>
          <Table
            rows={transfersTableRows}
            loading={isTransfersLoading}
            columns={isMobile ? undefined : transferTableColumns}
            customEmptyElement={getEmptyDataElement()}
          />
        </S.HistoryTableWrapper>

        <Pagination
          pageNumber={pagination.pageNumber}
          pageCount={pagination.totalPages}
          onChange={onPageChange}
        />
      </>
    );
  };

  return (
    <>
      <S.HistoryContainer $isLegalVerification={isLegal && !isLegalVerified}>
        <Helmet title={t('pages.history')} />

        {isAppLoading ||
        isTransfersLoading ||
        isTransfersUninitialized ||
        isVerificationStatusLoading ? (
          <Loader variant="centerOfContainer" />
        ) : (
          getContent()
        )}
      </S.HistoryContainer>

      <Modal
        modalTitle={t('transfers.actions.filter.form.title')}
        body={
          <TransfersFilterForm
            onCancel={() => closeFilter()}
            getTransfers={getTransfers}
            resetPagination={resetPagination}
            transfersLoading={isTransfersLoading}
            wallets={wallets}
            recipients={recipientList?.data}
          />
        }
        open={isFilterModalShown}
        onClose={() => closeFilter()}
      />
    </>
  );
};
