import styled, { css } from 'styled-components';

import { getMediaFor } from '../../layouts';
import { Button, FilterButton, IconButton } from '../../components';

export const HistoryContainer = styled.div<{ $isLegalVerification: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 24px;

  height: ${({ $isLegalVerification }) => ($isLegalVerification ? 'auto' : '100%')};
  padding: 0 40px 40px;

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      padding: 0 16px 40px;
    `)};
`;

export const HistoryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
  width: 100%;
`;

export const ActionButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const TransfersFilterButton = styled(FilterButton)`
  min-width: 112px;
`;

export const HistoryTableWrapper = styled.div`
  flex-grow: 1;
  padding-right: 16px;
  overflow: auto;

  .arrow-cell {
    width: 32px;
  }

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      padding-right: 0;
    `)};
`;

const commonMobileCellWrapperStyles = css`
  display: flex;
  flex-direction: column;
  gap: 4px;

  font-size: 1.4rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.gray80};

  .cell-header {
    font-size: 1.6rem;
    line-height: 150%;
    color: ${({ theme }) => theme.colors.typography.gray88};
  }
`;

export const MobileCellWrapper = styled.div`
  ${commonMobileCellWrapperStyles};
`;

export const MobileCellWrapperRow = styled.div`
  ${commonMobileCellWrapperStyles};
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`;

export const WarningStateWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
`;

export const WarningButton = styled(IconButton)`
  padding: 0;
`;

export const TooltipContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

export const TooltipTitle = styled.p`
  font-size: 1.6rem;
  text-align: center;
`;

export const EmptyTransfersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;

  padding: 24px 32px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.bg.white05};

  font-size: 2rem;
  color: ${({ theme }) => theme.colors.typography.white};
`;

export const EmptyTransfersActionButton = styled(Button)``;
