import { apiSlice } from '../../api';
import type { GetBusinessFeaturesResult } from './types';

export const businessFeatureEndpoints = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getBusinessFeatures: build.query<GetBusinessFeaturesResult, void>({
      query: () => ({
        url: '/auth-service/v1/business-features',
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetBusinessFeaturesQuery } = businessFeatureEndpoints;

export type BusinessFeatureController = typeof businessFeatureEndpoints;
