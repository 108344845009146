import { apiSlice } from '../../api';
import type { UploadDocumentToStorageDTO, UploadDocumentToStorageResult } from './types';

export const documentStorageEndpoints = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    uploadDocumentToStorage: build.mutation<
      UploadDocumentToStorageResult,
      UploadDocumentToStorageDTO
    >({
      query: (body) => ({
        url: '/storage-service/v1/documents/upload/temp',
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useUploadDocumentToStorageMutation } = documentStorageEndpoints;

export type DocumentStorageController = typeof documentStorageEndpoints;
