import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import * as S from './styled';
import { DatePicker, Select, SelectOption } from '../../../../components';

import {
  activeProfileUidSelector,
  activeTransfersFiltersCountSelector,
  useAppSelector,
} from '../../../../store';
import { AsyncErrorMessage, getAsyncErrorMessage, Shape, yup } from '../../../../forms';
import { Recipient, Wallet } from '../../../../types';
import dayjs from 'dayjs';

type StatementFilterFormProps = {
  onCancel: () => void;
  onDownload?: (data: StatementFilter) => void;
  loading: boolean;
  wallets?: Wallet[];
  recipients?: Recipient[];
};
export type StatementFilter = {
  createdAtTo: string;
  createdAtFrom: string;
  walletId?: number;
};

const transfersFilterSchema = yup.object().shape<Shape<StatementFilter>>({
  createdAtTo: yup.date().required(),
  createdAtFrom: yup.date().required(),
  walletId: yup.number().required(),
});

export const StatementFilterForm: FC<StatementFilterFormProps> = (props) => {
  const { onDownload, wallets, loading } = props;
  const { t } = useTranslation();

  const profileUid = useAppSelector(activeProfileUidSelector);
  const [statementFilterState] = useState<StatementFilter>({
    createdAtFrom: new Date(new Date().setMonth(new Date().getMonth() - 1)).toString(),
    createdAtTo: new Date().toString(),
  });

  const {
    control,
    setError,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<StatementFilter>({
    resolver: yupResolver(transfersFilterSchema),
    defaultValues: { ...statementFilterState },
  });

  const applyFilter: SubmitHandler<StatementFilter> = async (values) => {
    if (!isDirty || !profileUid) {
      return;
    }

    try {
      onDownload && onDownload(values);
    } catch (e) {
      getAsyncErrorMessage(e, setError);
    }
  };

  const walletsOptions = wallets?.map((wallet) => {
    return (
      <SelectOption key={wallet.uid} name={wallet.name} value={wallet.walletId}>
        {wallet.name}
      </SelectOption>
    );
  });

  return (
    <S.TransfersFilterForm onSubmit={handleSubmit(applyFilter)}>
      <S.TransfersFilterFormFields>
        {walletsOptions?.length ? (
          <Select
            control={{ control, name: 'walletId' }}
            errors={errors}
            required
            options={walletsOptions}
            label={t('transfers.actions.statement-filter.form.fields.wallet')}
          />
        ) : null}
        <S.FullWrapper>
          <DatePicker
            control={{ control, name: 'createdAtFrom' }}
            label={t('transfers.actions.statement-filter.form.fields.createdAtFrom')}
            errors={errors}
            minDate={dayjs().subtract(1, 'year')}
            disableFuture
          />
          <DatePicker
            control={{ control, name: 'createdAtTo' }}
            label={t('transfers.actions.statement-filter.form.fields.createdAtTo')}
            errors={errors}
            disableFuture
            minDate={dayjs().subtract(1, 'year')}
          />
        </S.FullWrapper>

        <AsyncErrorMessage errors={errors} />
      </S.TransfersFilterFormFields>

      <S.TransfersFilterFormActions>
        <S.ApplyTransfersFilterButton
          body={t('transfers.actions.statement-filter.form.accept-filter-btn')}
          type="submit"
          disabled={loading}
          loading={loading}
        />
      </S.TransfersFilterFormActions>
    </S.TransfersFilterForm>
  );
};
