export enum RecipientType {
  OWN = 'OWN',
  NATURAL = 'NATURAL',
  LEGAL = 'LEGAL',
}

export enum RecipientAccountType {
  LOCAL = 'LOCAL',
  BANK = 'BANK',
  CRYPTO = 'CRYPTO',
}
