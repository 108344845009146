import {
  IconBook,
  IconDeposit,
  IconExchange,
  IconGear,
  IconHomeSmile,
  IconSend,
  IconSticker,
  IconUser,
  IconUsers,
} from '../../assets';
import { RouterPaths } from '../paths';
import type { Link } from './types';
import { ProfileType } from '../../types';

export const links: Link[] = [
  // app menu
  {
    name: 'dashboard',
    icon: IconHomeSmile,
    path: RouterPaths.Home,
    portals: [ProfileType.LEGAL, ProfileType.NATURAL],
  },
  {
    name: 'recipients',
    icon: IconBook,
    path: RouterPaths.Recipients,
    portals: [ProfileType.LEGAL, ProfileType.NATURAL],
  },
  // TODO: return after MVP
  // {
  //   name: 'cards',
  //   icon: IconCreditCard,
  //   path: RouterPaths.Cards,
  //   portals: [ProfileType.LEGAL, ProfileType.NATURAL],
  // },
  {
    name: 'send',
    icon: IconSend,
    path: RouterPaths.Send,
    portals: [ProfileType.LEGAL, ProfileType.NATURAL],
  },
  {
    name: 'receive',
    icon: IconDeposit,
    path: RouterPaths.Receive,
    portals: [ProfileType.LEGAL, ProfileType.NATURAL],
  },
  // TODO: return after MVP
  // {
  //   name: 'history',
  //   icon: IconHourglass,
  //   path: RouterPaths.History,
  //   portals: [ProfileType.LEGAL, ProfileType.NATURAL],
  // },
  {
    name: 'exchange',
    icon: IconExchange,
    path: RouterPaths.Exchange,
    portals: [ProfileType.LEGAL, ProfileType.NATURAL],
  },

  // user profile menu
  {
    name: 'profile',
    icon: IconUser,
    path: RouterPaths.Profile,
    portals: [ProfileType.LEGAL, ProfileType.NATURAL],
    isProfile: true,
  },
  {
    name: 'my-tickets',
    icon: IconSticker,
    path: RouterPaths.Tickets,
    portals: [ProfileType.LEGAL, ProfileType.NATURAL],
    isProfile: true,
  },
  {
    name: 'team',
    icon: IconUsers,
    path: RouterPaths.Team,
    portals: [ProfileType.LEGAL],
    isProfile: true,
  },
  {
    name: 'account-settings',
    icon: IconGear,
    path: process.env.REACT_APP_UI_ORY_SDK_URL + RouterPaths.AccountSettings,
    portals: [ProfileType.LEGAL, ProfileType.NATURAL],
    isExternal: true,
    isProfile: true,
  },

  // wallet menu
  {
    name: 'send-money',
    icon: IconSend,
    path: RouterPaths.Send,
    portals: [ProfileType.LEGAL, ProfileType.NATURAL],
    isWallet: true,
  },
  {
    name: 'receive',
    icon: IconDeposit,
    path: RouterPaths.Receive,
    portals: [ProfileType.LEGAL, ProfileType.NATURAL],
    isWallet: true,
  },
  {
    name: 'exchange',
    icon: IconExchange,
    path: RouterPaths.Exchange,
    portals: [ProfileType.LEGAL, ProfileType.NATURAL],
    isWallet: true,
  },
  // TODO: return after MVP
  // {
  //   name: 'withdraw',
  //   icon: IconWithdraw,
  //   path: RouterPaths.Withdraw,
  //   portals: [ProfileType.LEGAL, ProfileType.NATURAL],
  //   isWallet: true,
  // },
];
