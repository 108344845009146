import { FC, MouseEvent } from 'react';
import { ChipProps as MuiChipProps } from '@mui/material';

import * as S from './styled';
import { IconClose16 } from '../../assets';

type ChipProps = Omit<MuiChipProps, 'label' | 'icon' | 'deleteIcon' | 'onDelete'> & {
  body: MuiChipProps['label'];
  startIcon?: MuiChipProps['icon'];
  onDelete?: (e: MouseEvent<HTMLElement>) => void;
};

export const Chip: FC<ChipProps> = (props) => {
  const { body, startIcon, onDelete, ...rest } = props;

  const deleteIcon = (
    <S.ChipDeleteIconContainer>
      <IconClose16 />
    </S.ChipDeleteIconContainer>
  );

  return (
    <S.Chip
      //
      {...rest}
      label={body}
      icon={startIcon}
      deleteIcon={deleteIcon}
      onDelete={onDelete}
    />
  );
};
