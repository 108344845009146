import styled, { css, CSSObject } from 'styled-components';
import { Dialog } from '@mui/material';

import { IconButton } from '../IconButton';
import { getMediaFor } from '../../layouts';

export const Modal = styled(Dialog)<{ $customPaperRootCss?: CSSObject }>`
  .MuiBackdrop-root {
    background-color: ${({ theme }) => theme.colors.bg.overlayWithAlpha};
  }

  .MuiPaper-root {
    width: 420px;
    background-color: ${({ theme }) => theme.colors.bg.dialog};
    border-radius: 40px;
    box-shadow: ${({ theme }) => theme.shadowsCustom.dialog};

    ${({ $customPaperRootCss }) => $customPaperRootCss && css($customPaperRootCss)};

    ${({ theme }) =>
      getMediaFor(
        'mobile',
        theme
      )(css`
        width: 344px;
        max-width: calc(100% - 32px);
        margin: 16px;
        border-radius: 16px;
      `)};
  }
`;

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 40px 24px 40px 40px;
  overflow: auto;

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      gap: 16px;
      padding: 16px 8px 16px 16px;
    `)};
`;

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 10px;
  padding-right: 16px;

  font-weight: 400;
  font-size: 3.2rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.white};

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      padding-right: 8px;
      font-size: 2.4rem;
      line-height: 133%;
    `)};
`;

export const ModalBody = styled.div`
  padding-right: 16px;
  overflow: auto;

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      padding-right: 8px;
    `)};
`;

export const ModalCloseButton = styled(IconButton)`
  padding: 4px;
  background-color: transparent;

  &:hover {
    background: transparent;
  }
`;
