import { FC, ReactElement, useMemo } from 'react';
import { TableProps as MuiTableProps } from '@mui/material';

import * as S from './styled';
import { CellSize, TableCell, TableCellOwnProps, TableEmptyRow, TableRow } from './components';

export type Column = TableCellOwnProps & {
  field: string;
};
type TableProps = MuiTableProps & {
  /**
   * Column items for display header.
   * Don't pass anything to prevent table header displaying.
   */
  columns?: Column[];
  /**
   * Array of row elements or row element, which must defined in parent component.
   */
  rows: ReactElement[] | ReactElement | undefined;
  /**
   * Header size.
   */
  headerSize?: CellSize;
  /**
   * Optional loading state property for display loading, while data is fetching
   */
  loading?: boolean;
  /**
   * Custom text for empty table
   */
  customEmptyElement?: string | ReactElement;
};

export const Table: FC<TableProps> = (props) => {
  const { columns, rows, headerSize = 'medium', loading, customEmptyElement, ...rest } = props;

  const columnsCount = columns ? columns.length : 1;

  const columnsElements = useMemo(() => {
    return columns?.map((column) => {
      const { field, align, size } = column;

      return (
        <TableCell key={field} align={align} size={size || headerSize}>
          {field}
        </TableCell>
      );
    });
  }, [columns, headerSize]);

  const rowsElements = useMemo(() => {
    if (loading) {
      return <TableEmptyRow colsCount={columnsCount} isLoading />;
    }

    if (!rows || (Array.isArray(rows) && rows.length === 0)) {
      return <TableEmptyRow colsCount={columnsCount} customEmptyElement={customEmptyElement} />;
    }

    return rows;
  }, [loading, columnsCount, rows]);

  return (
    <S.Table {...rest} padding="none">
      <S.TableHead>
        <TableRow
          hover={false}
          showIconOnHover={false}
          tableWithoutHeader={!columns || columns.length === 0}
        >
          {columnsElements}
        </TableRow>
      </S.TableHead>

      <S.TableBody>{rowsElements}</S.TableBody>
    </S.Table>
  );
};
