/* eslint-disable import/no-unresolved*/
import { FC, MouseEvent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/free-mode';

import * as S from './styled';
import { AddWalletButton, WalletCard, WalletContextMenu } from './components';

import { useACL } from '../../../../services';
import { useGetWalletsQuery } from '../../../../api';
import { activeProfileUidSelector, useAppSelector } from '../../../../store';
import type { Wallet } from '../../../../types';

type WalletsBarType = 'list' | 'slider';
type WalletsBarProps = { type: WalletsBarType };

export const WalletsBar: FC<WalletsBarProps> = ({ type }) => {
  const { t, i18n } = useTranslation();
  const isMax529 = useMediaQuery({ maxWidth: 529 });

  const hasPermission_WALLET_VIEW = useACL(['WALLET_VIEW']);

  const profileUid = useAppSelector(activeProfileUidSelector);

  const { data: walletsList } = useGetWalletsQuery({ profileUid: profileUid });
  const [currentWallet, setCurrentWallet] = useState<Wallet | undefined>(undefined);
  const [openedWalletUid, setOpenedWalletUid] = useState<string>('');

  // state and callbacks for wallet context menu
  const [walletMenuAnchor, setWalletMenuAnchor] = useState<HTMLDivElement | null>(null);
  const isWalletMenuOpen = Boolean(walletMenuAnchor);
  const openWalletMenu = useCallback((e: MouseEvent<HTMLDivElement>, wallet: Wallet) => {
    setCurrentWallet(wallet);
    setOpenedWalletUid(wallet.uid);
    setWalletMenuAnchor(e.currentTarget);
  }, []);
  const closeWalletMenu = () => {
    setOpenedWalletUid('');
    setWalletMenuAnchor(null);
  };

  const walletsForList = useMemo(() => {
    if (walletsList?.length === 0) {
      return (
        <S.EmptyWalletsList>
          {t('dashboard-layout.wallet-bar.empty-wallet-list')}
        </S.EmptyWalletsList>
      );
    }

    return walletsList?.map((wallet) => {
      return (
        <WalletCard
          openedWalletUid={openedWalletUid}
          aria-controls={isWalletMenuOpen ? 'wallet-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={isWalletMenuOpen ? 'true' : undefined}
          key={wallet.uid}
          wallet={wallet}
          onClick={(e) => openWalletMenu(e, wallet)}
        />
      );
    });
  }, [
    walletsList,
    isWalletMenuOpen,
    openWalletMenu,
    currentWallet,
    openedWalletUid,
    i18n.resolvedLanguage,
  ]);

  const walletsForSlider = useMemo(() => {
    return walletsList?.map((wallet) => {
      return (
        <SwiperSlide key={wallet.uid}>
          <WalletCard
            openedWalletUid={currentWallet?.uid}
            aria-controls={isWalletMenuOpen ? 'wallet-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={isWalletMenuOpen ? 'true' : undefined}
            wallet={wallet}
            onClick={(e) => openWalletMenu(e, wallet)}
          />
        </SwiperSlide>
      );
    });
  }, [walletsList, isWalletMenuOpen, openWalletMenu, currentWallet, i18n.resolvedLanguage]);

  const getSlider = () => {
    if (!walletsList) return null;

    switch (walletsList.length) {
      case 0: {
        return (
          <S.WalletsBarEmptySliderContainer>
            <AddWalletButton />
          </S.WalletsBarEmptySliderContainer>
        );
      }
      case 1: {
        return !isMax529 ? (
          <S.WalletsBarSliderSmallContainer>
            {hasPermission_WALLET_VIEW && walletsForList}
            <AddWalletButton />
          </S.WalletsBarSliderSmallContainer>
        ) : (
          <S.WalletsBarSliderContainer>
            <AddWalletButton withoutText />
            {hasPermission_WALLET_VIEW && (
              <Swiper
                slidesPerView="auto"
                spaceBetween={16}
                centeredSlides={false}
                // freeMode
                modules={[FreeMode]}
              >
                {walletsForSlider}
              </Swiper>
            )}
          </S.WalletsBarSliderContainer>
        );
      }
      default: {
        return (
          <S.WalletsBarSliderContainer>
            <AddWalletButton withoutText />
            {hasPermission_WALLET_VIEW && (
              <Swiper
                slidesPerView="auto"
                spaceBetween={16}
                centeredSlides={false}
                freeMode
                modules={[FreeMode]}
              >
                {walletsForSlider}
              </Swiper>
            )}
          </S.WalletsBarSliderContainer>
        );
      }
    }
  };

  const typeMap: Record<WalletsBarType, ReturnType<FC>> = {
    list: <S.WalletsBarContainer>{walletsForList}</S.WalletsBarContainer>,
    slider: getSlider(),
  };

  return (
    <>
      {typeMap[type]}
      <WalletContextMenu
        open={isWalletMenuOpen}
        anchorEl={walletMenuAnchor}
        closeHandler={closeWalletMenu}
        currentWallet={currentWallet}
      />
    </>
  );
};
