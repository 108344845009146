import { FC, ReactElement, useCallback, useEffect } from 'react';
import { LoaderFunction, Outlet, redirect, useLocation, useNavigation } from 'react-router-dom';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import dayjs from 'dayjs';

import * as S from './styled';
import { useMedia } from '../theming';
import { Loader, useSnackbar } from '../../components';
import { DashboardHeader, DashboardSidebar, VerificationBar, WalletsBar } from './components';

// import { SocketProvider } from '../../services';
import {
  businessFeatureEndpoints,
  GetNaturalProfileDTO,
  GetProfilesResult,
  GetSumsubTokenDTO,
  GetWalletsDTO,
  legalEndpoints,
  naturalEndpoints,
  profileEndpoints,
  profileWalletEndpoints,
  runEndpointInRouter,
  sumsubEndpoints,
} from '../../api';
import {
  activeProfileTypeSelector,
  addressVerifyStatusSelector,
  authMethodsSelector,
  profileVerifyStatusSelector,
  useAppSelector,
} from '../../store';
import { getItemFromStorage, removeItemFromStorage } from '../../utils';
import { aclGuard, authGuard, getRouterError, RouterPaths, useAppLoading } from '../../router';
import { ProfileInfo, ProfileType, SumsubLevelName } from '../../types';

export const dashboardLoader: LoaderFunction = async () => {
  authGuard();

  try {
    // get profiles list
    const { data: profileData } = await runEndpointInRouter(
      profileEndpoints,
      'getProfiles',
      undefined,
      true
    );

    // redirect logic
    const profiles = profileData as GetProfilesResult;
    if (profiles?.length === 0) {
      removeItemFromStorage('activeProfile');
      return redirect(RouterPaths.ProfileCreate);
    }
    const activeProfile = getItemFromStorage('activeProfile') as ProfileInfo;
    if (!activeProfile) return redirect(RouterPaths.Root);

    // get business feature list
    await runEndpointInRouter(businessFeatureEndpoints, 'getBusinessFeatures');

    // get active profile
    const profileType = activeProfile?.profileType;
    const hasPermission_PROFILE_VIEW = await aclGuard(['PROFILE_VIEW']);
    if (hasPermission_PROFILE_VIEW) {
      switch (profileType) {
        case ProfileType.NATURAL: {
          await runEndpointInRouter(naturalEndpoints, 'getNaturalProfile', {
            profileUid: activeProfile.profileUid,
          } as GetNaturalProfileDTO);
          break;
        }

        case ProfileType.LEGAL: {
          await runEndpointInRouter(legalEndpoints, 'getLegalProfile', {
            profileUid: activeProfile.profileUid,
          });
          break;
        }
      }
    }

    // get wallets for sidebar
    const hasPermission_WALLET_VIEW = await aclGuard(['WALLET_VIEW']);
    hasPermission_WALLET_VIEW &&
      (await runEndpointInRouter(profileWalletEndpoints, 'getWallets', {
        profileUid: activeProfile.profileUid,
      } as GetWalletsDTO));

    // get sumsub data for verification status (only for natural)
    const isLegal = profileType === ProfileType.LEGAL;
    const hasPermission_SELF_KYC = await aclGuard(['SELF_KYC']);
    if (!isLegal && hasPermission_SELF_KYC) {
      await runEndpointInRouter(sumsubEndpoints, 'getSumsubToken', {
        profileUid: activeProfile.profileUid,
        levelName: SumsubLevelName.BASIC_KYC_LEVEL,
      } as GetSumsubTokenDTO);
      // await runEndpointInRouter(sumsubEndpoints, 'getSumsubToken', {
      //   profileUid: activeProfile.profileUid,
      //   levelName: SumsubLevelName.ADDRESS,
      // } as GetSumsubTokenDTO);
    }
  } catch (e) {
    throw getRouterError(e as FetchBaseQueryError);
  }

  return null;
};

// TODO: turn on socket, after implementing

export const Dashboard: FC = () => {
  const { isTabletMaxWidth } = useMedia();
  const location = useLocation();
  const navigation = useNavigation();
  const isAppLoading = useAppLoading();
  const { showSnackbar } = useSnackbar();

  const isVerified = useAppSelector(profileVerifyStatusSelector);
  const isAddressVerified = useAppSelector(addressVerifyStatusSelector);
  const profileType = useAppSelector(activeProfileTypeSelector);
  const authMethods = useAppSelector(authMethodsSelector);

  const isLegal = profileType === ProfileType.LEGAL;
  const isNatural = profileType === ProfileType.NATURAL;
  const hasAllVerifications = isVerified && isAddressVerified;

  useEffect(() => {
    const hasTOTP = Boolean(authMethods.find((method) => method.method === 'totp'));
    if (hasTOTP) return;

    const lastLoginDate = getItemFromStorage('twoFANotificationShownDate')
      ? dayjs(getItemFromStorage('twoFANotificationShownDate'))
      : null;
    const isSnackbarShown = getItemFromStorage('twoFANotificationShown');

    const daysDiff = lastLoginDate && Math.abs(lastLoginDate.diff(dayjs(), 'day'));

    let needToShowSnackbar;

    if (daysDiff === 0 && !isSnackbarShown) {
      needToShowSnackbar = true;
    }
    if (daysDiff === 0 && isSnackbarShown) {
      needToShowSnackbar = false;
    }
    // @ts-ignore
    if (daysDiff >= 1) {
      needToShowSnackbar = true;
    }

    needToShowSnackbar &&
      showSnackbar({
        variant: '2fa',
        noAutoHide: true,
        showCloseButton: true,
      });
  }, []);

  /**
   * This function prevent app loader for better UI on Recipient page,
   * when user click to some recipient and goes to detailed Recipient page.
   */
  const preventLoadingOnRecipients = useCallback(() => {
    const isRecipientsPage = location.pathname === RouterPaths.Recipients;
    const isNextRecipientPage =
      navigation.location?.pathname.startsWith(RouterPaths.Recipients) &&
      navigation.location?.pathname.length > RouterPaths.Recipients.length;

    return !(isRecipientsPage && isNextRecipientPage);
  }, [location, navigation]);

  const getBar = (walletBar: ReactElement, verificationBar: ReactElement): ReactElement | null => {
    // wallet bar fo legal profile
    if (!isAppLoading && isLegal) return walletBar;

    // verification bar fo natural profile, when verification status received from server
    if (!isAppLoading && hasAllVerifications === null && isNatural) return verificationBar;

    // nothing, when verification status has initial value from state
    if (hasAllVerifications === null) return null;

    // wallet bar for natural profile
    if (hasAllVerifications) return walletBar;

    // otherwise need to show verification bar
    return verificationBar;
  };

  return (
    // <SocketProvider>
    <S.DashboardLayoutContainer>
      <DashboardHeader />

      {isTabletMaxWidth && getBar(<WalletsBar type="slider" />, <VerificationBar type="mobile" />)}

      {!isTabletMaxWidth && <DashboardSidebar getWalletsBar={getBar} />}

      <S.DashboardLayoutContent>
        {isAppLoading && preventLoadingOnRecipients() ? (
          <Loader variant="centerOfContainer" />
        ) : (
          <Outlet />
        )}
      </S.DashboardLayoutContent>
    </S.DashboardLayoutContainer>
    // </SocketProvider>
  );
};
