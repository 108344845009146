export const cutFloatNumber = (num: number, digitsAfterPoint = 2) => {
  return +parseFloat(num.toFixed(digitsAfterPoint));
};

export const sortArrayByPropAsc = <T>(arr: T[] = [], propertyName: keyof T): T[] => {
  if (!Array.isArray(arr)) return [];

  return [...arr].sort((a, b) => {
    if (a[propertyName] < b[propertyName]) {
      return -1;
    } else if (a[propertyName] > b[propertyName]) {
      return 1;
    } else {
      return 0;
    }
  });
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
