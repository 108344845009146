import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { InputFileUpload } from '../../../../components';
import * as S from './styled';

type AttachDocumentFormProps = {
  onLoad: (path: string) => void;
  disabled: boolean;
  onClose: () => void;
  onSave: () => void;
};

export const AttachDocumentForm: FC<AttachDocumentFormProps> = (props) => {
  const { onLoad, disabled, onSave, onClose } = props;
  const { t } = useTranslation();

  return (
    <S.AttachDocumentFormContainer>
      <InputFileUpload
        inputProps={{ name: 'path' }}
        fileCallback={(files) => onLoad(files[0]!.path)}
        dragAndDrop
        maxSize={10 * 1024 * 1024}
      />
      <S.TransfersFilterFormActions>
        <S.ClearTransfersFilterButton
          variant="contained"
          body={t('transfer.attach-modal.cancel-button')}
          onClick={onClose}
        />
        <S.ApplyTransfersFilterButton
          body={t('transfer.attach-modal.attach-button')}
          disabled={disabled}
          onClick={onSave}
        />
      </S.TransfersFilterFormActions>
    </S.AttachDocumentFormContainer>
  );
};
