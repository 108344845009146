import { FC, MouseEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import * as S from './styled';
import { IconBell, IconBellWithDot, IconTrash16 } from '../../../../assets';

import {
  useDeleteAllNotificationMutation,
  useDeleteNotificationMutation,
  useLazyGetNotificationCheckQuery,
  useLazyGetNotificationQuery,
  usePutAllNotificationsReadMutation,
  usePutNotificationReadMutation,
} from '../../../../api/controllers/notification';
import {
  activeProfileUidSelector,
  profileSelector,
  store,
  useAppSelector,
} from '../../../../store';
import { UTCToLocal, changeProfile } from '../../../../utils';
import { IconDoubleCheck } from '../../../../assets/svg/IconDoubleCheck/IconDoubleCheck';
import { notificationUtils } from './notification.utils';
import { ModalConfirmation, useSnackbar } from '../../../../components';

import { ProfileInfo } from '../../../../types';
import { RouterPaths } from '../../../../router';
import { boolean } from 'yup';

export const Notifications: FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { showSnackbar } = useSnackbar();

  const [notificationsAnchor, setNotificationsAnchor] = useState<HTMLButtonElement | null>(null);
  const [getNotificationCheck, { data: hasUnreadNotifications }] =
    useLazyGetNotificationCheckQuery();
  const [getNotification, { data: notificationsData }] = useLazyGetNotificationQuery();
  const [readNotification] = usePutNotificationReadMutation();
  const [deleteNotification] = useDeleteNotificationMutation();
  const [readAllNotification] = usePutAllNotificationsReadMutation();
  const [deleteAllNotification] = useDeleteAllNotificationMutation();

  const profileUid = useAppSelector(activeProfileUidSelector);
  const profiles = useAppSelector(profileSelector);

  const [newNotification, setNewNotification] = useState();
  const [newNotificationAnimation, setNewNotificationAnimation] = useState(false);
  const [selectedProfile, setSelectedProfile] = useState<ProfileInfo | null>(null);
  const [navigatePath, setNavigatePath] = useState<string | null>(null);
  const [offset, setOffset] = useState(0);
  const [notificationList, setNotifications] = useState<any>([]);
  const [notification, setNotification] = useState<any>();
  const isNotificationsOpen = Boolean(notificationsAnchor);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const onOpenConfirmModal = () => setOpenConfirmModal(true);
  const onCloseConfirmModal = () => setOpenConfirmModal(false);

  store.subscribe(() => {
    if (newNotification != store.getState().notification.data) {
      setNewNotification(store.getState().notification.data);
    }
  });

  const onScrollList = (event: any) => {
    const scrollBottom =
      event.target.scrollTop + event.target.offsetHeight == event.target.scrollHeight;

    if (scrollBottom && notificationList.length >= offset) {
      setOffset(notificationList.length);
    }
  };

  useEffect(() => {
    if (profileUid && notification && notificationList && !openConfirmModal) {
      makeReadNotification(notification.uid, notificationList?.length);
      const { messageType, meta } = notification;
      let profile;
      console.log(meta);
      if (meta?.profileUid) {
        profile = profiles.find((it) => it.profileUid === meta?.profileUid);
        if (!profile) {
          clearState(false);
          return;
        }
      }

      switch (messageType) {
        case 'TICKET_SUPPORT_COMMENT': {
          if (meta?.profileUid && meta?.ticketId) {
            notificationNavigate(
              `${RouterPaths.Tickets}/${meta?.ticketId}`,
              meta?.profileUid,
              profileUid,
              profile
            );
          }
          return;
        }
        default: {
          if (meta?.profileUid) {
            if (meta?.transferUid) {
              notificationNavigate(
                `${RouterPaths.Transfers}/${meta?.transferUid}`,
                meta?.profileUid,
                profileUid,
                profile
              );
              return;
            }
            if (meta?.ticketId) {
              notificationNavigate(
                `${RouterPaths.Tickets}/${meta?.ticketId}`,
                meta?.profileUid,
                profileUid,
                profile
              );
              return;
            }
          }
          clearState(false);
          return;
        }
      }
    }
  }, [notification, notificationList, profileUid]);

  // useEffect(() => {
  //   if (hasUnreadNotifications) {
  //     showSnackbar({
  //       variant: 'newNotification',
  //       noAutoHide: true,
  //       showCloseButton: true,
  //     });
  //   }
  // }, [hasUnreadNotifications]);

  useEffect(() => {
    if (newNotification) {
      getNotification({
        limit: notificationsData?.length ? notificationsData.length : 10,
        offset: 0,
      }).then((data: any) => {
        setNotifications([...data.data]);
        getNotificationCheck();
        setNewNotificationAnimation(true);

        setTimeout(() => {
          setNewNotificationAnimation(false);
        }, 6000);
      });
    }
  }, [newNotification]);

  useEffect(() => {
    getNotification({
      limit: 10,
      offset: offset,
    }).then((data: any) => {
      setNotifications((prevState: any) => {
        if (data) {
          return [...prevState, ...data.data];
        }

        return prevState;
      });
    });
  }, [offset]);

  useEffect(() => {
    getNotification({
      limit: 10,
      offset: 0,
    }).then((data: any) => {
      setNotifications([...data.data]);
      getNotificationCheck();
    });
  }, []);

  const notificationNavigate = (
    path: string,
    profileUid: string,
    currentProfileUid: string,
    profile?: ProfileInfo
  ) => {
    if (profileUid !== currentProfileUid && profile) {
      showConfirmChangeModal(path, profile);
    } else {
      navigate(path);
      clearState();
    }
  };

  const openNotifications = (e: MouseEvent<HTMLButtonElement>) => {
    setNotificationsAnchor(e.currentTarget);
  };
  const closeNotifications = () => {
    setNotificationsAnchor(null);
  };

  const makeReadAllNotification = () => {
    readAllNotification().then(() => {
      getNotification({
        limit: notificationList?.length,
        offset: 0,
      }).then((data: any) => {
        setNotifications([...data.data]);
        getNotificationCheck();
      });
    });
  };

  const makeDeleteAllNotification = () => {
    deleteAllNotification().then(() => {
      getNotification({
        limit: 10,
        offset: 0,
      }).then((data: any) => {
        setNotifications([...data.data]);
        getNotificationCheck();
      });
    });
  };

  const makeReadNotification = (uid: string, length: number) => {
    readNotification(uid).then(() => {
      getNotification({
        limit: length,
        offset: 0,
      }).then((data: any) => {
        setNotifications([...data.data]);
        getNotificationCheck();
      });
    });
  };

  const onDeleteNotification = (uid: string, length: number) => {
    deleteNotification(uid).then(() => {
      getNotification({
        limit: length < 7 ? length + 10 : length,
        offset: 0,
      }).then((data: any) => {
        setNotifications([...data.data]);
        getNotificationCheck();
      });
    });
  };

  const showConfirmChangeModal = (path: string, profile: ProfileInfo) => {
    setNavigatePath(path);
    setSelectedProfile(profile);
    onOpenConfirmModal();
  };

  const handleChangeProfile = () => {
    if (selectedProfile && navigatePath) {
      changeProfile(selectedProfile, navigate, navigatePath);
      clearState();
    }
  };

  const handleReadNotification = (notification: any) => {
    setNotification(notification);
  };

  const clearState = (isCloseNotification = true) => {
    setNavigatePath(null);
    setSelectedProfile(null);
    setNotification(null);
    onCloseConfirmModal();
    isCloseNotification && closeNotifications();
  };

  const notifications = useMemo(() => {
    if (!notificationList || notificationList?.length === 0)
      return (
        <S.NoNotifications>
          {t('dashboard-layout.notifications.no-notifications')}
        </S.NoNotifications>
      );

    return notificationList?.map((notification: any) => {
      const { uid, createdAt, title, message, read } = notification;

      return (
        <S.NotificationCard key={uid} onClick={() => handleReadNotification(notification)}>
          <S.NotificationBody>{message}</S.NotificationBody>

          <S.NotificationFooter>
            <S.NotificationDate>
              {!read && <S.NewNotificationBadge />}
              {createdAt && UTCToLocal(createdAt)}
            </S.NotificationDate>
            <S.NotificationDelete
              disableRipple
              icon={<IconTrash16 />}
              onClick={() => onDeleteNotification(uid, notificationList?.length - 1)}
            />
          </S.NotificationFooter>
        </S.NotificationCard>
      );
    });
  }, [notificationList, i18n.resolvedLanguage]);

  return (
    <>
      <S.NotificationButton
        aria-describedby="notifications-menu"
        aria-controls={isNotificationsOpen ? 'user-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isNotificationsOpen ? 'true' : undefined}
        icon={hasUnreadNotifications ? <IconBellWithDot /> : <IconBell />}
        onClick={(e) => openNotifications(e)}
        $isOpen={isNotificationsOpen}
        $isNewNotification={!!hasUnreadNotifications}
      />

      <S.NotificationsPopover
        id="notifications-menu"
        open={isNotificationsOpen}
        anchorEl={notificationsAnchor}
        onClose={() => closeNotifications()}
        onScroll={(event) => onScrollList(event)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <S.NotificationsContainer onScroll={(event) => onScrollList(event)}>
          <S.NotificationsTitle>
            <div>{t('dashboard-layout.notifications.title')}</div>
            <S.NotificationTitleButtonContainer>
              <S.NotificationDelete
                disableRipple
                icon={<IconDoubleCheck />}
                onClick={makeReadAllNotification}
                tooltipTitle={t('dashboard-layout.notifications.read-all')}
              />
              <S.NotificationDelete
                disableRipple
                icon={<IconTrash16 />}
                onClick={makeDeleteAllNotification}
                tooltipTitle={t('dashboard-layout.notifications.delete-all')}
              />
              {/* <S.NotificationDelete
                disableRipple
                icon={<IconTrash16 color="red" />}
                onClick={() => setShowDeleteAllConfirg(true)}
              /> */}
            </S.NotificationTitleButtonContainer>
          </S.NotificationsTitle>

          {notifications}
        </S.NotificationsContainer>
      </S.NotificationsPopover>

      <ModalConfirmation
        question={t('dashboard-layout.notifications.alert.description', {
          value: selectedProfile?.name,
        })}
        confirmButtonProps={{
          color: 'primary',
          body: t('dashboard-layout.notifications.alert.switch'),
        }}
        open={openConfirmModal}
        onCancel={() => {
          clearState();
          onCloseConfirmModal();
        }}
        onConfirm={() => handleChangeProfile()}
      />
    </>
  );
};
