import styled, { css } from 'styled-components';
import {
  FormControlLabel as MuiControlLabel,
  Radio as MuiRadio,
  RadioGroup as MuiRadioGroup,
} from '@mui/material';

// RadioGroup

export const RadioGroup = styled(MuiRadioGroup)`
  &.MuiFormGroup-root {
    gap: 16px;
  }

  &.MuiFormGroup-row {
    gap: 16px;
  }
`;

// RadioControlLabel
export const RadioControlLabel = styled(MuiControlLabel)`
  &.MuiFormControlLabel-root {
    margin: 0;
    gap: 8px;
  }

  &.MuiFormControlLabel-root.Mui-disabled {
    opacity: 0.3;
  }

  .MuiFormControlLabel-label {
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 100%;
    color: ${({ theme }) => theme.colors.typography.gray80};
  }

  .MuiFormControlLabel-label.Mui-disabled {
    color: ${({ theme }) => theme.colors.typography.gray80};
  }
`;

// Radio
export const Radio = styled(MuiRadio)`
  &.MuiRadio-root {
    padding: 0;
  }
`;

const radioIconCommonStyles = css`
  width: 24px;
  height: 24px;
  border-radius: 50%;
`;

export const RadioIcon = styled.div`
  ${radioIconCommonStyles};
  border: 2px solid ${({ theme }) => theme.colors.borders.white20};
  background-color: ${({ theme }) => theme.colors.bg.white10};
`;

export const RadioIconChecked = styled.div`
  ${radioIconCommonStyles};
  border: 6px solid ${({ theme }) => theme.colors.secondary};
  background-color: ${({ theme }) => theme.colors.bg.white};
`;
