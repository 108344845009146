// names
export const nameAndSurnameRegExp = /^[A-Za-z]*(([,.] |[ '-])[A-Za-z][a-z]*)*(\.?)$/;
export const uboNameAndSurnameRegExp = /^[A-Za-z]*(([,.] |[ ’-])[A-Za-z]+)*(\\.?)*$/;

// bank
export const bankAccountRegExp = /^[A-Za-z0-9]+$/;
export const bankCodeRegExp = /^[A-Za-z0-9]+$/;
export const ibanRegExp =
  /\b[A-Z]{2}[0-9]{2}(?:[ ]?[0-9]{4}){4}(?!(?:[ ]?[0-9]){3})(?:[ ]?[0-9]{1,2})?\b/;

// documents
export const passportRegExp = /^[a-zA-Z0-9]{3,32}$/;
export const registrationNumberRegExp = /^[a-zA-Z0-9+]*$/;

// common
export const noSpacesRegExp = /^\S*$/;
export const phoneRegExp = /^[0-9\\-\\+]{7,15}$/;

//send
export const referenceRegExp = new RegExp('^[a-zA-Z0-9#$%_\\-+=:;,.\\\\/"\'|!?@* ]+$');
