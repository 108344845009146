import styled from 'styled-components';

import { Button } from '../../../../components';

export const AddLocalAccountForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const AddLocalAccountFormFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AddLocalAccountFormActions = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 8px;
`;

export const AddLocalAccountNextStepButton = styled(Button)`
  flex-grow: 1;
`;

export const CreateLocalAccountButton = styled(AddLocalAccountNextStepButton)``;

export const FirstCell = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 280px;
  gap: 8px;
`;

export const FirstRow = styled.div`
  font-size: 2rem;
  color: #fff;
`;

export const SecondRow = styled.div``;
