import styled from 'styled-components';

export const ProfileContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 24px;

  @media screen and (max-width: 631px) {
    flex-direction: column;
    gap: 32px;
  }
`;

export const ProfileInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 280px;

  @media screen and (max-width: 631px) {
    width: 100%;
  }
`;

export const ProfileInfoBody = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;
