import styled from 'styled-components';

export const WalletRowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border-radius: 15px;
  padding-right: 10px;

  &.active {
    background: rgb(255, 255, 255, 0.05);
  }

  &.inactive {
    filter: brightness(40%);
  }

  &.inactive:hover {
    filter: brightness(100%);
  }
`;

export const WalletCardContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const WalletLogoContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 56px;
  height: 56px;

  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.bg.white05};
`;

export const WalletLogo = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
`;

export const WalletCardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
`;

export const WalletName = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 4px;

  font-weight: 400;
  font-size: 1.6rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.gray80};
`;

export const WalletAmount = styled.div`
  font-weight: 400;
  font-size: 2rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.white};
`;
