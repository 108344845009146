import styled, { css } from 'styled-components';
import { TableRow as MuiTableRow } from '@mui/material';

export const TableRow = styled(MuiTableRow)<{ $tableWithoutHeader: boolean }>`
  position: relative;

  &.MuiTableRow-hover:hover {
    border-bottom: 1px solid ${({ theme }) => theme.colors.borders.gray64};
    background-color: transparent;
  }

  ${({ $tableWithoutHeader, theme }) =>
    $tableWithoutHeader &&
    css`
      &.MuiTableRow-root {
        border-bottom: 1px solid ${theme.colors.borders.white10};
      }
      &.MuiTableRow-head {
        border-bottom: none;
      }
    `};
`;

export const TableRowChooseIcon = styled.th`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-45%);

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${({ theme }) => theme.colors.typography.white};
`;
