import { FC, ReactElement } from 'react';
import { FieldErrors, FieldValues, useController, UseControllerProps } from 'react-hook-form';
import {
  FormControlLabelProps as MuiFormControlLabelProps,
  RadioProps as MuiRadioProps,
} from '@mui/material';

import * as S from './styled';
import { ErrorMessage, getErrorByFieldName } from '../../forms';

//region Types
type RadioGroupProps<FormValues extends FieldValues> = {
  radios: Radio[];
  control: UseControllerProps<FormValues>;
  errors?: FieldErrors;
  showErrorMessage?: boolean;
  row?: boolean;
};
type RadioControlLabelProps = Omit<MuiFormControlLabelProps, 'control'> & {
  value: string;
};
export type Radio = RadioControlLabelProps;
type RadioProps = MuiRadioProps;
//endregion

export const RadioGroup = <FormValues extends FieldValues>(
  props: RadioGroupProps<FormValues>
): ReactElement => {
  const { radios, control, errors, showErrorMessage = true, row = false } = props;
  const { field } = useController(control);

  const hasError = errors ? Boolean(getErrorByFieldName(errors, control.name)) : false;

  const radiosElements = radios.map((it) => {
    return <RadioControlLabel key={it.value} {...it} />;
  });

  return (
    <>
      <S.RadioGroup row={row} {...field} value={field.value ?? ''}>
        {radiosElements}
      </S.RadioGroup>

      {showErrorMessage && hasError && <ErrorMessage fieldName={control.name} errors={errors} />}
    </>
  );
};

const RadioControlLabel: FC<RadioControlLabelProps> = (props) => {
  return <S.RadioControlLabel {...props} control={<Radio disableRipple />} labelPlacement="end" />;
};

const Radio: FC<RadioProps> = (props) => {
  return <S.Radio {...props} icon={<S.RadioIcon />} checkedIcon={<S.RadioIconChecked />} />;
};
