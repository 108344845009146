import styled from 'styled-components';

import { Button } from '../Button';

export const ModalConfirmationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const ModalConfirmationBody = styled.div`
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.typography.gray80};
`;

export const ModalConfirmationActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

export const ModalConfirmationCancelButton = styled(Button)`
  min-width: 106px;
`;

export const ModalConfirmationConfirmButton = styled(Button)`
  flex-grow: 1;
`;
