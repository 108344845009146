import type { NavigateFunction } from 'react-router/dist/lib/hooks.d';

import { RouterPaths } from '../../router';
import { apiSlice, cacheTags } from '../../api';
import { store, userActions } from '../../store';
import { getItemFromStorage, setItemToStorage } from '../localStorage';
import type { ProfileInfo } from '../../types';

/**
 * Function, which change active profile in app
 * (set active profile in LocalStorage and redirect to Root page for running root-loader)
 *
 * @param profile - ProfileInfo model
 * @param navigate - navigate function from React-Router-Dom
 */
export const changeProfile = (profile: ProfileInfo, navigate: NavigateFunction, path?: string) => {
  // Check if new profile is the same as new one
  const currentProfile = getItemFromStorage('activeProfile');
  const isProfilesPage = location.pathname === RouterPaths.Profiles;
  if (!isProfilesPage && currentProfile && profile.profileUid === currentProfile.profileUid) return;

  // TODO: Maybe disconnect socket here

  setItemToStorage('activeProfile', profile);
  store.dispatch(userActions.resetVerificationState());
  store.dispatch(apiSlice.util.resetApiState());
  store.dispatch(apiSlice.util.invalidateTags([...cacheTags]));
  navigate(`${path ? path : RouterPaths.Root}?changeProfile=true`);
};
