import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import {
  LoaderFunction,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import * as S from './styled';
import {
  Avatar,
  Button,
  Column,
  IconButton,
  Input,
  LineInfo,
  Loader,
  Modal,
  Pagination,
  Select,
  SelectOption,
  Stepper,
  Table,
  TableCell,
  TableRow,
  TextArea,
  Title,
} from '../../components';
import { useMedia } from '../../layouts';
import { TransferWallet } from './Wallet';
import { AddBankRecipientForm, RecipientsHeader } from '../Recipients/components';
import { IconArrowNext24, IconClose24, IconCopy } from '../../assets';

import {
  CreateTransferDTO,
  useCreateTransferMutation,
  useGetActiveCountriesQuery,
  useGetLegalCompanyTypesQuery,
  useGetWalletsQuery,
  useLazyGetRecipientByIdQuery,
  useLazyGetRecipientsByFilterQuery,
  useLazyGetTransferFeeQuery,
  useLazyGetWalletTransferTypesQuery,
} from '../../api';
import { i18n, useACL } from '../../services';
import { referenceRegExp, Shape, yup } from '../../forms';
import { authGuard, getRouterError, RouterPaths } from '../../router';
import { activeProfileUidSelector, useAppSelector } from '../../store';
import { getItemFromStorage, useDebounce, usePagination } from '../../utils';
import {
  ProfileInfo,
  RecipientAccountType,
  RecipientType,
  TransferType,
  Wallet,
} from '../../types';
import {
  RHFErrorContainer,
  ValidationErrorMessage,
} from '../../forms/components/ErrorMessage/styled';
import { RecipientInfo } from './RecipientInfo';

type TransferInfoFields = {
  reference: string;
  transferType: `${TransferType}`;
  amount: number;
  currencyCode: string;
};

const transferInfoSchema = yup.object().shape<Shape<TransferInfoFields>>({
  reference: yup.string().matches(referenceRegExp).required().max(35),
  transferType: yup.string().required(),
  amount: yup.number().required(),
});

export const sendLoader: LoaderFunction = async () => {
  authGuard();

  const activeProfile = getItemFromStorage('activeProfile') as ProfileInfo;

  try {
  } catch (e) {
    throw getRouterError(e as FetchBaseQueryError);
  }

  return null;
};

export const Send: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useMedia();
  const [searchParams, setSearchParams] = useSearchParams();

  const hasPermission_RECIPIENT_VIEW = useACL(['RECIPIENT_VIEW']);
  const hasPermission_WALLET_VIEW = useACL(['WALLET_VIEW']);

  const profileUid = useAppSelector(activeProfileUidSelector);

  const { data: walletsList } = useGetWalletsQuery({ profileUid: profileUid });
  const [createTransfer, { data, error }] = useCreateTransferMutation();
  const [getRecipient, { data: recipientList, isFetching: isRecipientFetching }] =
    useLazyGetRecipientsByFilterQuery();
  const [getRecipientById, { data: recipient, isFetching: isRecipientByIdFetching }] =
    useLazyGetRecipientByIdQuery();
  const [getTransferTypes, { data: transferTypes, isFetching: isTransferTypesFetching }] =
    useLazyGetWalletTransferTypesQuery();
  const [getFeeCalc, { data: fee, error: feeError, isFetching: isFeeCalcFetching }] =
    useLazyGetTransferFeeQuery();

  // if user click on `Send money` button on Recipient page
  const recipientIdFromRecipientPage = location.state?.recipientId;

  // if user click on Send page from wallet bar
  const walletUidFromSideBar = location.state?.walletUid;
  const walletIdFromSideBar = location.state?.walletId;
  const walletIdFromQuery = searchParams.get('walletId');
  const walletFromSideBar = hasPermission_WALLET_VIEW
    ? walletsList?.find((w) => w.uid === walletUidFromSideBar)
    : undefined;
  const transferFromSideBar = walletFromSideBar?.uid
    ? { walletUid: walletFromSideBar.uid }
    : undefined;

  const { pagination, onPageChange, setPaginationRequest, resetPagination } = usePagination({
    pageNumber: 0,
    pageSize: 20,
  });
  const [activeStep, setActiveStep] = useState(0);
  const [recipientId, setRecipientId] = useState<number | string | null>(
    searchParams.get('recipientId')
  );
  const [errorMessage, setErrorMessage] = useState<string>();
  // @ts-ignore
  const [transfer, setTransfer] = useState<CreateTransferDTO | undefined>(transferFromSideBar);
  const [selectedWallet, setSelectedWallet] = useState<Wallet | undefined>(walletFromSideBar);
  const [searchName, setSearchName] = useState('');

  const { data: activeCountries } = useGetActiveCountriesQuery();
  const { data: legalCompanyTypes } = useGetLegalCompanyTypesQuery();

  const [getBankAccount, { data: copiedAccount, isFetching }] = useLazyGetRecipientByIdQuery();
  const handleFilterChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchName(event.target.value);
    resetPagination();
  };

  const {
    control,
    register,
    getValues,
    formState: { errors, isValid },
    watch,
    reset,
    setValue,
    setError,
    clearErrors,
  } = useForm<TransferInfoFields>({
    resolver: yupResolver(transferInfoSchema),
  });

  const amount = watch('amount');
  const reference = watch('reference');
  const transferType = watch('transferType');
  const currencyCode = watch('currencyCode');
  const debouncedTransferTypeValue = useDebounce<`${TransferType}`>(transferType, 500);
  const debouncedCurrencyTypeValue = useDebounce<string>(currencyCode, 500);
  const debouncedAmountValue = useDebounce<number>(amount, 500);

  //   useEffect(() => {
  //     if (profileUid && selectedWallet && activeStep === 1 && hasPermission_RECIPIENT_VIEW) {
  //       getRecipients({
  //         profileUid: profileUid,
  //         body: {
  //           name: searchName,
  //           currencyCode: currencyCode,
  //           transferType: transferType,
  //           ...pagination,
  //         },
  //       });
  //     }
  //   }, [profileUid, activeStep, selectedWallet, searchName, pagination]);

  useEffect(() => {
    if (error) {
      setErrorMessage((error as any).data.message);
      setTransfer(undefined);
      setSelectedWallet(undefined);
      reset();
      setActiveStep(1);
    }
  }, [error]);

  useEffect(() => {
    if (feeError) {
      setErrorMessage((feeError as any).data.message);
    }
  }, [feeError]);

  useEffect(() => {
    if (data?.uid) {
      navigate(`${RouterPaths.Send}/${data?.uid}`);
    }
  }, [data]);

  useEffect(() => {
    if (selectedWallet?.uid && recipientId) {
      reset();
      getTransferTypes({ walletUid: selectedWallet.uid, recipientId: `${recipientId}` });
    }
  }, [selectedWallet]);

  useEffect(() => {
    if (String(amount)?.length > 0 && amount <= 0) {
      setError('amount', {
        message: t('send.minAmount'),
      });
      return;
    }

    clearErrors('amount');

    if (
      selectedWallet?.uid &&
      debouncedAmountValue &&
      debouncedTransferTypeValue &&
      debouncedCurrencyTypeValue
    ) {
      getFeeCalc({
        walletUid: selectedWallet?.uid,
        body: {
          countryCode: recipient?.additionalInfo.country_code ?? null,
          amount: debouncedAmountValue,
          transferType: debouncedTransferTypeValue,
          toCurrencyCode: debouncedCurrencyTypeValue,
        },
      });
    }
  }, [debouncedAmountValue, debouncedTransferTypeValue, debouncedCurrencyTypeValue]);

  useEffect(() => {
    if (reference?.length > 0 && !referenceRegExp.test(reference)) {
      setError('reference', {
        message: t('send.minAmount'),
      });
    } else {
      clearErrors('reference');
    }
  }, [reference]);

  useEffect(() => {
    if (walletIdFromSideBar) {
      setSearchParams({ walletId: `${walletIdFromSideBar}` });
    }
    if (walletIdFromQuery && walletsList) {
      const wallet = walletsList?.find((it) => it.walletId == Number(walletIdFromQuery));
      setSelectedWallet(wallet);
    }
  }, [walletIdFromSideBar, walletsList, walletIdFromQuery]);

  const handleBack = () => {
    navigate(`${RouterPaths.Send}`);
  };

  const handleCreateTransfer = () => {
    if (selectedWallet && recipientId && amount && currencyCode && reference && transferType) {
      createTransfer({
        walletUid: selectedWallet.uid,
        body: {
          amount: amount,
          currencyCode: currencyCode,
          recipientId: Number(recipientId),
          reference: reference,
          transferType: transferType,
        },
      });
    }
  };

  const selectWallet = (wallet: Wallet) => {
    setSelectedWallet(wallet);
  };

  const [isAccountModalShown, setAccountModalShown] = useState(false);
  const showAddAccountForm = () => setAccountModalShown(true);
  const closeAddAccountForm = () => setAccountModalShown(false);

  const debouncedValue = useDebounce<string>(searchName, 500);

  const recipientsTableColumns: Column[] = [
    { field: '' },
    { field: t('recipients.table.name') },
    { field: t('recipients.table.iban') },
    { field: t('recipients.table.account-type') },
    { field: t('recipients.table.recipient-type') },
    { field: t('recipients.table.currency-code') },
  ];

  useEffect(() => {
    if (profileUid) {
      if (recipientId) {
        getRecipientById({
          profileUid: profileUid,
          recipientId: String(recipientId),
        });
        setActiveStep(1);
      } else {
        getRecipient({
          profileUid: profileUid,
          body: { name: debouncedValue, ...pagination },
        });
      }
    }
  }, [pagination, debouncedValue, recipientId]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchName(event.target.value);
  };

  const handleOpenCopyBank = (recipientId: string) => {
    getBankAccount({ profileUid, recipientId });
  };

  useEffect(() => {
    if (copiedAccount && !isAccountModalShown && !isFetching) {
      showAddAccountForm();
    }
  }, [copiedAccount, isFetching]);

  useEffect(() => {
    resetPagination();
  }, [debouncedValue]);

  const recipientsTableRows = useMemo(() => {
    return recipientList?.data.map((recipient) => {
      const { id, name, recipientAccountType, currencyCode, recipientType, comment } = recipient;

      const avatarName = name.split(' ');
      const avatar =
        recipientAccountType === RecipientAccountType.LOCAL ? (
          <Avatar size="medium" abfContact />
        ) : (
          <Avatar
            size="medium"
            name={`${avatarName[0]?.[0]?.toUpperCase()}${
              avatarName[1] ? avatarName[1]?.[0]?.toUpperCase() : ''
            }`}
          />
        );

      const mobileCells = (
        <>
          <TableCell className="avatar-cell">{avatar}</TableCell>
          <TableCell align="left">
            <S.MobileCellWrapper>
              <div className="cell-header">{name}</div>
              <div />
              {comment && <S.EllipsisEmail>{comment}</S.EllipsisEmail>}
              <div>
                {t('recipients.table.account-type')}:{' '}
                {t(`common.recipient-account-type.${recipientAccountType}`)}
              </div>
              <div>
                {t('recipients.table.recipient-type')}:{' '}
                {t(`common.recipient-type.${recipientType}`)}
              </div>
              {currencyCode && (
                <div>
                  {t('recipients.table.currency-code')}: {currencyCode}
                </div>
              )}
            </S.MobileCellWrapper>
          </TableCell>
        </>
      );

      const cells = (
        <>
          <TableCell className="avatar-cell">{avatar}</TableCell>
          <TableCell width={'20%'}>{name}</TableCell>
          <TableCell>{comment}</TableCell>
          <TableCell>{t(`common.recipient-account-type.${recipientAccountType}`)}</TableCell>
          <TableCell>{t(`common.recipient-type.${recipientType}`)}</TableCell>
          <TableCell>{currencyCode}</TableCell>
        </>
      );

      return (
        <TableRow
          key={id}
          onClick={() => {
            setRecipientId(id);
            const searchParams = {
              recipientId: `${id}`,
            } as any;
            if (selectedWallet || walletIdFromSideBar || walletIdFromQuery) {
              searchParams.walletId =
                selectedWallet?.walletId ?? walletIdFromSideBar ?? walletIdFromQuery;
            }
            setSearchParams(searchParams);
          }}
        >
          {isMobile ? mobileCells : cells}
        </TableRow>
      );
    });
  }, [recipientList, navigate, i18n.resolvedLanguage, isMobile]);

  const wallets = useMemo(() => {
    return walletsList?.map((wallet) => {
      return (
        <TransferWallet
          key={wallet.uid}
          wallet={wallet}
          selectedWallet={selectedWallet}
          onClick={() => selectWallet(wallet)}
        />
      );
    });
  }, [walletsList, isValid, transfer, selectedWallet]);

  const transferTypeOptions = transferTypes?.map((type) => {
    return (
      <SelectOption
        key={type.transferType}
        value={type.transferType}
        name={t(`common.transfer-type.${type.transferType}`)}
      >
        {t(`common.transfer-type.${type.transferType}`)}
      </SelectOption>
    );
  });

  const currencyOptions = useMemo(() => {
    if (transferType && selectedWallet?.coinResponseDto) {
      setValue('currencyCode', selectedWallet?.coinResponseDto.currencyCode);
      return transferTypes
        ?.find((it) => it.transferType === transferType)
        ?.currencies.map((type) => {
          return (
            <SelectOption key={type} value={type} name={type}>
              {type}
            </SelectOption>
          );
        });
    }

    return;
  }, [transferType]);

  const closeErrorMessage = () => {
    setErrorMessage('');
  };

  return (
    <S.SendContainer>
      <Helmet title={t('pages.send')} />
      <Stepper
        stepsLabels={[
          { onClick: handleBack, label: t('send.recipient') },
          { label: t('send.amount') },
          { label: t('send.review') },
        ]}
        activeStep={activeStep}
      />
      {/* <Title text={t('send.title')} /> */}
      {activeStep === 0 ? (
        <>
          <RecipientsHeader
            activeCountries={activeCountries}
            legalCompanyTypes={legalCompanyTypes}
            onChangeSearch={handleChange}
            searchValue={searchName}
            sendPage
          />

          <S.RecipientsContent>
            <Table
              rows={recipientsTableRows}
              loading={isRecipientFetching}
              columns={isMobile ? undefined : recipientsTableColumns}
            />
          </S.RecipientsContent>

          <Pagination
            pageNumber={recipientList?.paging.number ?? 0}
            pageCount={recipientList?.paging.totalPages}
            onChange={onPageChange}
          />
        </>
      ) : (
        <S.WalletContainer>
          {errorMessage && (
            <S.ErrorMessageContainer>
              <S.ErrorMessageTextContainer>{errorMessage}</S.ErrorMessageTextContainer>
              <S.ErrorMessageCloseContainer>
                <S.ErrorMessageClose icon={<IconClose24 />} onClick={closeErrorMessage} />
              </S.ErrorMessageCloseContainer>
            </S.ErrorMessageContainer>
          )}
          {isRecipientByIdFetching || !recipient ? (
            <Loader variant="centerOfContainer" />
          ) : (
            <>
              <RecipientInfo recipient={recipient} />
              <S.SectionTitle>{t('send.abfBalance')}</S.SectionTitle>
              <S.WalletCardContainer
                className={`${selectedWallet && 'limitedHeight'}`}
                style={{ minHeight: Number(wallets?.length) > 3 ? '230px' : 'none' }}
              >
                {hasPermission_WALLET_VIEW && wallets}
              </S.WalletCardContainer>
              {isTransferTypesFetching ? (
                <Loader variant="centerOfContainer" />
              ) : (
                !!selectedWallet?.uid &&
                transferTypeOptions &&
                !isTransferTypesFetching && (
                  <>
                    <Select
                      control={{ control, name: 'transferType' }}
                      errors={errors}
                      options={transferTypeOptions}
                      label={t('send.transferType')}
                    />
                    {transferType && currencyOptions && (
                      <S.AmountContainer>
                        <Input
                          {...register('amount')}
                          type="number"
                          min={0}
                          step={0.01}
                          errors={errors}
                          label={t('send.amount')}
                          disableNumberArrows
                          onKeyDown={(evt) =>
                            ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
                          }
                        />
                        <Select
                          control={{ control, name: 'currencyCode' }}
                          errors={errors}
                          options={currencyOptions}
                          label={t('send.currencyCode')}
                        />
                      </S.AmountContainer>
                    )}

                    {amount && fee && (
                      <S.InfoWrapper $isFeeCalcFetching={isFeeCalcFetching}>
                        {fee?.transactionRate && fee?.transactionRate != 1 && (
                          <LineInfo
                            label={t('send.rate')}
                            value={`1 ${fee.transactionCurrencyCode} = ${fee.transactionRate} ${debouncedCurrencyTypeValue}`}
                          />
                        )}
                        {fee?.transactionRate && fee?.transactionRate != 1 && (
                          <LineInfo
                            label={t('send.transactionAmount')}
                            value={`${fee.transactionAmount} ${fee.transactionCurrencyCode}`}
                          />
                        )}
                        <LineInfo
                          label={t('send.fee')}
                          value={`${fee.transactionFee} ${fee.transactionCurrencyCode}`}
                        />
                        {isFeeCalcFetching && (
                          <Loader variant="centerOfContainer" containerClassName="info-loader" />
                        )}
                      </S.InfoWrapper>
                    )}
                    <div>
                      <TextArea
                        {...register('reference')}
                        errors={errors}
                        label={t('send.reference')}
                      />
                      {!isValid && reference?.length > 35 && (
                        <RHFErrorContainer>
                          <ValidationErrorMessage>
                            {t('send.maxLengthReference')}
                          </ValidationErrorMessage>
                        </RHFErrorContainer>
                      )}
                    </div>
                    <Button
                      body={t('send.nextStep')}
                      type="submit"
                      disabled={
                        !isValid ||
                        Number(amount) > Number(selectedWallet.availableAmount) ||
                        Number(amount) <= 0
                      }
                      onClick={handleCreateTransfer}
                      loading={isRecipientFetching}
                    />
                  </>
                )
              )}
            </>
          )}
        </S.WalletContainer>
      )}
      <Modal
        modalTitle={t('recipients.header.actions.add-recipient.form.title.COPY_CRYPTO')}
        body={
          <AddBankRecipientForm
            activeCountries={activeCountries}
            legalCompanyTypes={legalCompanyTypes}
            onCancel={() => closeAddAccountForm()}
            copiedAccount={copiedAccount}
            isCopyBankRecipient
          />
        }
        open={isAccountModalShown}
        onClose={() => closeAddAccountForm()}
      />
    </S.SendContainer>
  );
};
