import type { SocketOptions } from 'engine.io-client/build/esm/socket';
import type { ManagerOptions } from 'socket.io-client/build/esm/manager';
import { getItemFromStorage } from '../../utils';
import { ProfileInfo } from '../../types';

const activeProfile = getItemFromStorage('activeProfile') as ProfileInfo;

export const socketConfig: Partial<SocketOptions & ManagerOptions> = {
  transports: ['websocket', 'polling'],
  autoConnect: false,
  // query: {
  //   'resource-type': process.env.REACT_APP_RESOURCE, //CLIENT_OFFICE
  //   profileuid: activeProfile.profileUid,
  // },
};
