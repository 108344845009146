import type { InitOptions } from 'i18next';

export const i18nConfig: InitOptions = {
  debug: process.env.NODE_ENV !== 'production',
  defaultNS: 'translation',
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  load: 'languageOnly',
  react: {
    useSuspense: true,
  },
  detection: {
    order: [
      'localStorage',
      'querystring',
      'cookie',
      'sessionStorage',
      'navigator',
      'htmlTag',
      'path',
      'subdomain',
    ],
  },
};
