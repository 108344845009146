import { FC, SVGProps } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import * as S from './styled';
import { LogoWhite, LogoWhiteMobile } from '../../assets';
import { RouterPaths } from '../../router';
import { LogoDark } from '../../assets/svg/LogoDark/LogoDark';

type LogoType = 'white' | 'dark';
type LogoSize = 'small' | 'default';

type LogoProps = {
  type: LogoType;
  size?: LogoSize;
  svgProps?: SVGProps<SVGSVGElement>;
};

export const Logo: FC<LogoProps> = (props) => {
  const { type, size = 'default', svgProps } = props;
  const location = useLocation();
  const navigate = useNavigate();

  // calculate path to redirect by click on Logo
  const isDashboardLayout = location.pathname.includes(RouterPaths.DashboardLayout);
  const pathToRedirect = isDashboardLayout
    ? RouterPaths.DashboardLayout
    : RouterPaths.ProfileLayout;

  const map: Record<LogoType, Record<LogoSize, ReturnType<FC>>> = {
    white: {
      default: <LogoWhite {...svgProps} />,
      small: <LogoWhiteMobile {...svgProps} />,
    },
    dark: {
      default: <LogoDark {...svgProps} />,
      small: <LogoWhiteMobile {...svgProps} />,
    },
  };

  return (
    <S.LogoContainer onClick={() => navigate(pathToRedirect)}>{map[type][size]}</S.LogoContainer>
  );
};
