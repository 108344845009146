import styled, { css } from 'styled-components';
import { Dialog } from '@mui/material';

import { getMediaFor } from '../../layouts';

export const Banner = styled(Dialog)`
  .MuiBackdrop-root {
    background-color: ${({ theme }) => theme.colors.bg.overlayWithAlpha};
  }

  .MuiDialog-paper {
    width: 420px;
    background-color: ${({ theme }) => theme.colors.bg.dialog};
    border-radius: 40px;
    box-shadow: ${({ theme }) => theme.shadowsCustom.dialog};

    ${({ theme }) =>
      getMediaFor(
        'mobile',
        theme
      )(css`
        border-radius: 16px;
      `)};
  }
`;

export const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 40px;

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      gap: 16px;
      padding: 16px;
    `)};
`;

export const BannerBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const BannerImageWrapper = styled.div`
  svg {
    width: 100%;
  }
`;

export const BannerTitle = styled.div`
  font-weight: 400;
  font-size: 3.2rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.white};

  .accent-text {
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.error};
  }
`;

export const BannerMessage = styled.div`
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.colors.typography.gray80};
`;
