export enum TicketType {
  TRANSFER_ISSUES = 'TRANSFER_ISSUES',
  ACCOUNT_ACCESS_AND_SECURITY = 'ACCOUNT_ACCESS_AND_SECURITY',
  BILLING_AND_CHARGES = 'BILLING_AND_CHARGES',
  TECHNICAL_SUPPORT = 'TECHNICAL_SUPPORT',
  ACCOUNT_MANAGEMENT = 'ACCOUNT_MANAGEMENT',
  FEEDBACK_AND_SUGGESTIONS = 'FEEDBACK_AND_SUGGESTIONS',
  LEGAL_AND_COMPLIANCE = 'LEGAL_AND_COMPLIANCE',
}

export enum TicketState {
  OPENED = 'OPENED',
  IN_WORK = 'IN_WORK',
  CLOSED = 'CLOSED',
}
