import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { LoaderFunction, Outlet, useNavigate } from 'react-router-dom';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';

import * as S from './styled';
import { AddBankRecipientForm, RecipientsHeader } from './components';
import {
  Avatar,
  Column,
  IconButton,
  Modal,
  Pagination,
  Table,
  TableCell,
  TableRow,
} from '../../components';

import {
  countryEndpoints,
  CreateBankRecipientDTO,
  CreateCryptoRecipientDTO,
  GetRecipientsByFilterDTO,
  legalEndpoints,
  recipientEndpoints,
  runEndpointInRouter,
  useGetActiveCountriesQuery,
  useGetLegalCompanyTypesQuery,
  useLazyGetRecipientByIdQuery,
  useLazyGetRecipientsByFilterQuery,
} from '../../api';
import { useMedia } from '../../layouts';
import { getItemFromStorage } from '../../utils';
import { useDebounce } from '../../utils/hooks/useDebounce';
import { usePagination } from '../../utils/hooks/usePagination';
import { aclGuard, authGuard, getRouterError, RouterPaths } from '../../router';
import { activeProfileUidSelector, RootState, store, useAppSelector } from '../../store';
import { ProfileInfo, RecipientAccountType, RecipientType } from '../../types';
import { IconCopy } from '../../assets';

export type AddBankAccountFormFields = CreateBankRecipientDTO['body'];
export type AddCryptoAccountFormFields = CreateCryptoRecipientDTO['body'];

// TODO: add slice for pagination, also clarify params for fetching list of recipients by type division
// TODO: add tables of recipients with pagination

export const recipientsLoader: LoaderFunction = async () => {
  authGuard();
  await aclGuard(['COUNTRY_VIEW', 'RECIPIENT_VIEW'], RouterPaths.DashboardLayout);

  const activeProfile = getItemFromStorage('activeProfile') as ProfileInfo;
  const state = store.getState() as RootState;

  const getRecipientsBody = {
    profileUid: activeProfile.profileUid,
    // ...state.tickets.initialRequestArgs,
    // ...state.tickets.pagination,
    body: { pageNumber: 0, pageSize: 20 },
  };

  try {
    await runEndpointInRouter(
      recipientEndpoints,
      'getRecipientsByFilter',
      getRecipientsBody as GetRecipientsByFilterDTO
    );
    await runEndpointInRouter(countryEndpoints, 'getActiveCountries');
    await runEndpointInRouter(legalEndpoints, 'getLegalCompanyTypes');
  } catch (e) {
    throw getRouterError(e as FetchBaseQueryError);
  }

  return null;
};

export const Recipients: FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const { isMobile } = useMedia();

  const profileUid = useAppSelector(activeProfileUidSelector);
  const [searchName, setSearchName] = useState('');

  const { pagination, onPageChange, resetPagination } = usePagination({
    pageNumber: 0,
    pageSize: 20,
  });

  const { data: activeCountries } = useGetActiveCountriesQuery();
  const { data: legalCompanyTypes } = useGetLegalCompanyTypesQuery();
  const [getBankAccount, { data: copiedAccount, isFetching }] = useLazyGetRecipientByIdQuery();
  const [getRecipient, { data: recipientsList, isLoading: isRecipientsLoading }] =
    useLazyGetRecipientsByFilterQuery();

  const [isAccountModalShown, setAccountModalShown] = useState(false);
  const showAddAccountForm = () => setAccountModalShown(true);
  const closeAddAccountForm = () => setAccountModalShown(false);

  const debouncedValue = useDebounce<string>(searchName, 500);

  const recipientsTableColumns: Column[] = [
    { field: '' },
    { field: t('recipients.table.name') },
    { field: t('recipients.table.iban') },
    { field: t('recipients.table.account-type') },
    { field: t('recipients.table.recipient-type') },
    { field: t('recipients.table.currency-code') },
  ];

  useEffect(() => {
    getRecipient({
      profileUid: profileUid,
      body: { name: debouncedValue, ...pagination },
    });
  }, [pagination, debouncedValue]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchName(event.target.value);
  };

  const handleOpenCopyBank = (recipientId: string) => {
    getBankAccount({ profileUid, recipientId });
  };

  useEffect(() => {
    if (copiedAccount && !isAccountModalShown && !isFetching) {
      showAddAccountForm();
    }
  }, [copiedAccount, isFetching]);

  useEffect(() => {
    resetPagination();
  }, [debouncedValue]);

  const recipientsTableRows = useMemo(() => {
    return recipientsList?.data.map((recipient) => {
      const { id, name, recipientAccountType, currencyCode, recipientType, comment } = recipient;

      const avatarName = name.split(' ');
      const avatar =
        recipientAccountType === RecipientAccountType.LOCAL ? (
          <Avatar size="medium" abfContact />
        ) : (
          <Avatar
            size="medium"
            name={`${avatarName[0]?.[0]?.toUpperCase()}${
              avatarName[1] ? avatarName[1]?.[0]?.toUpperCase() : ''
            }`}
          />
        );

      const mobileCells = (
        <>
          <TableCell className="avatar-cell">{avatar}</TableCell>
          <TableCell align="left">
            <S.MobileCellWrapper>
              <div className="cell-header">{name}</div>
              <div />
              {comment && <S.EllipsisEmail>{comment}</S.EllipsisEmail>}
              <div>
                {t('recipients.table.account-type')}:{' '}
                {t(`common.recipient-account-type.${recipientAccountType}`)}
              </div>
              <div>
                {t('recipients.table.recipient-type')}:{' '}
                {t(`common.recipient-type.${recipientType}`)}
              </div>
              {currencyCode && (
                <div>
                  {t('recipients.table.currency-code')}: {currencyCode}
                </div>
              )}
            </S.MobileCellWrapper>
          </TableCell>
        </>
      );

      const cells = (
        <>
          <TableCell className="avatar-cell">{avatar}</TableCell>
          <TableCell width={'20%'}>{name}</TableCell>
          <TableCell>{comment}</TableCell>
          <TableCell>{t(`common.recipient-account-type.${recipientAccountType}`)}</TableCell>
          <TableCell>{t(`common.recipient-type.${recipientType}`)}</TableCell>
          <TableCell>{currencyCode}</TableCell>
          <TableCell>
            {recipientAccountType === RecipientAccountType.BANK &&
              recipientType !== RecipientType.OWN && (
                <IconButton
                  icon={<IconCopy />}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    handleOpenCopyBank(String(id));
                  }}
                />
              )}
          </TableCell>
        </>
      );

      return (
        <TableRow key={id} onClick={() => navigate(`${RouterPaths.Recipients}/${id}`)}>
          {isMobile ? mobileCells : cells}
        </TableRow>
      );
    });
  }, [recipientsList, navigate, i18n.resolvedLanguage, isMobile]);

  return (
    <>
      <S.RecipientsContainer>
        <Helmet title={t('pages.recipients')} />

        <RecipientsHeader
          activeCountries={activeCountries}
          legalCompanyTypes={legalCompanyTypes}
          onChangeSearch={handleChange}
          searchValue={searchName}
        />

        <S.RecipientsContent>
          <Table
            rows={recipientsTableRows}
            loading={isRecipientsLoading}
            columns={isMobile ? undefined : recipientsTableColumns}
          />
        </S.RecipientsContent>

        <Pagination
          pageNumber={recipientsList?.paging.number ?? 0}
          pageCount={recipientsList?.paging.totalPages}
          onChange={onPageChange}
        />
      </S.RecipientsContainer>
      <Modal
        modalTitle={t('recipients.header.actions.add-recipient.form.title.COPY_CRYPTO')}
        body={
          <AddBankRecipientForm
            activeCountries={activeCountries}
            legalCompanyTypes={legalCompanyTypes}
            onCancel={() => closeAddAccountForm()}
            copiedAccount={copiedAccount}
            isCopyBankRecipient
          />
        }
        open={isAccountModalShown}
        onClose={() => closeAddAccountForm()}
      />
      <Outlet />
    </>
  );
};
