import { FC } from 'react';

import * as S from './styled';
import { Button, ButtonProps } from '../../../../components';

type ProfileInfoHeaderProps =
  | {
      title: string;
      isActionButtonVisible: false;
    }
  | {
      title: string;
      isActionButtonVisible: true;
      actionButtonProps: ButtonProps;
    };

export const ProfileInfoHeader: FC<ProfileInfoHeaderProps> = (props) => {
  const { title, isActionButtonVisible } = props;

  return (
    <S.ProfileInfoHeader>
      <S.ProfileInfoTitle>{title}</S.ProfileInfoTitle>
      {isActionButtonVisible && (
        <Button {...props.actionButtonProps} variant="contained" color="secondary" />
      )}
    </S.ProfileInfoHeader>
  );
};
