import styled from 'styled-components';

import { TextArea } from '../../../../components';

export const AddTicketForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const AddTicketFormFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AddTicketFormTextArea = styled(TextArea)`
  height: 180px;

  &.animated {
    transition: all 0.75s;
    height: 0px;
  }
`;

export const Animated = styled.div`
  height: 0px;
  line-height: 0px;
  overflow: hidden;

  transition: all 0.25s;
  transform: translateY(-150%);
  animation: ani 1s forwards;

  &.show {
    height: 100%;
    line-height: 45px;

    @keyframes ani {
      0% {
        transform: translateY(-150%);
      }
      100% {
        transform: translateY(0);
      }
    }

    textarea {
      height: 100px;
    }
  }
`;
