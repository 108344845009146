import { createContext, FC, ReactNode, useContext, useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import { Socket } from 'socket.io-client/build/esm/socket';

import {
  useLazyGetCommentsByTicketIdQuery,
  useLazyGetTransfersByProfileUidQuery,
  useLazyGetWalletsQuery,
} from '../../api';
import { socketConfig as config } from './config';
import {
  activeProfileUidSelector,
  messageActions,
  selectorTransfersState,
  useAppActions,
  useAppSelector,
} from '../../store';
import { useACL } from '../acl';
import { getItemFromStorage } from '../../utils';
import { notificationActions } from '../../store/slices/notification';
import type { ProfileInfo, TODO_ANY as Notification } from '../../types';

const socket = io(process.env.REACT_APP_API_BASE_SOCKET_WS_URL, config);

type WelcomeData = {
  events: string[];
  uid: string;
};

export type SocketContext = {
  isConnected: boolean;
  error: Error | null;
  welcomeData: WelcomeData | null;
  lastNotification: Notification | null;
  socket: Socket;
};

const SocketContext = createContext<SocketContext | null>(null);
SocketContext.displayName = 'SocketContext';

export const SocketProvider: FC<{ children: ReactNode }> = (props) => {
  const hasPermission_WALLET_VIEW = useACL(['WALLET_VIEW']);

  const profileUid = useAppSelector(activeProfileUidSelector);
  const transfersState = useAppSelector(selectorTransfersState);
  const { setNotification } = useAppActions(notificationActions);
  const { setNewMessage } = useAppActions(messageActions);
  // const isUpdateTransactions = updateTransactionsRoutes.includes(location.pathname);

  // api
  const [getWallets] = useLazyGetWalletsQuery();
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const getWalletsWithGuard = hasPermission_WALLET_VIEW ? getWallets : () => {};

  // context values
  const [activeProfile, setActiveProfile] = useState(
    getItemFromStorage('activeProfile') as ProfileInfo
  );
  const [isConnected, setConnectStatus] = useState(socket.connected);
  const [error, setError] = useState<Error | null>(null);
  const [welcomeData, setWelcomeData] = useState<WelcomeData | null>(null);
  const [lastNotification] = useState<Notification | null>(null);

  useEffect(() => {
    if (!isConnected) {
      socket.io.opts.query = {
        token: 'access_token',
        'Resource-Type': process.env.REACT_APP_RESOURCE,
        ProfileUid: activeProfile?.profileUid,
      };

      socket.connect();
    }
  }, [isConnected, activeProfile]);

  useEffect(() => {
    const handleStorageChange = () => {
      const newValue = getItemFromStorage('activeProfile') as ProfileInfo;
      if (newValue !== null) {
        setActiveProfile(newValue);
      }
    };

    window.addEventListener('storageChange', handleStorageChange);

    return () => {
      window.removeEventListener('storageChange', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    socket.on('connect', () => {
      setConnectStatus(true);
    });

    socket.on('disconnect', () => {
      setConnectStatus(false);
    });

    socket.on('error', (data: Error) => {
      setError(data);
    });

    socket.on('welcome', (data: WelcomeData) => {
      setWelcomeData(data);
    });

    socket.on('notification', (data: Notification) => {
      setNotification(data);
    });

    socket.on('wallet_creation_success', () => {
      getWalletsWithGuard({ profileUid: profileUid });
    });

    socket.on('incoming_transfer_reject', () => {
      if (profileUid) {
        getWalletsWithGuard({ profileUid: profileUid });
      }
    });

    socket.on('incoming_transfer_success', () => {
      if (profileUid) {
        getWalletsWithGuard({ profileUid: profileUid });
      }
    });

    socket.on('incoming_transfer_frozen', () => {
      if (profileUid) {
        getWalletsWithGuard({ profileUid: profileUid });
      }
    });

    socket.on('outgoing_transfer_reject', () => {
      if (profileUid) {
        getWalletsWithGuard({ profileUid: profileUid });
      }
    });

    socket.on('outgoing_transfer_success', () => {
      if (profileUid) {
        getWalletsWithGuard({ profileUid: profileUid });
      }
    });

    socket.on('outgoing_transfer_frozen', () => {
      if (profileUid) {
        getWalletsWithGuard({ profileUid: profileUid });
      }
    });

    socket.on('id_verification_success', () => {
      document.location.reload();
    });

    socket.on('id_verification_failed', () => {
      document.location.reload();
    });

    socket.on('address_verification_success', () => {
      document.location.reload();
    });

    socket.on('address_verification_failed', () => {
      document.location.reload();
    });

    socket.on('restriction_initial_add', () => {
      document.location.reload();
    });

    socket.on('restriction_initial_removal', () => {
      document.location.reload();
    });

    socket.on('restriction_transfer_block_add', () => {
      document.location.reload();
    });

    socket.on('restriction_transfer_block_removal', () => {
      document.location.reload();
    });

    socket.on('restriction_profile_block_add', () => {
      document.location.reload();
    });

    socket.on('restriction_profile_block_removal', () => {
      document.location.reload();
    });

    socket.on('legal_verified', () => {
      document.location.reload();
    });

    socket.on('legal_rejected', () => {
      document.location.reload();
    });

    socket.on('ticket_support_comment', (data: any) => {
      setNewMessage(data);
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off('error');
      socket.off('welcome');
      socket.off('notification');
      socket.off('wallet_creation_success');
      socket.off('incoming_transfer_reject');
      socket.off('incoming_transfer_success');
      socket.off('incoming_transfer_frozen');
      socket.off('outgoing_transfer_reject');
      socket.off('outgoing_transfer_success');
      socket.off('outgoing_transfer_frozen');
      socket.off('id_verification_success');
      socket.off('id_verification_failed');
      socket.off('address_verification_success');
      socket.off('restriction_initial_add');
      socket.off('restriction_initial_removal');
      socket.off('restriction_transfer_block_add');
      socket.off('restriction_transfer_block_removal');
      socket.off('restriction_profile_block_add');
      socket.off('restriction_profile_block_removal');
      socket.off('legal_verified');
      socket.off('legal_rejected');
      socket.off('ticket_support_comment');
    };
  }, [profileUid]);

  const contextValues = {
    isConnected,
    error,
    welcomeData,
    lastNotification,
    socket,
  };

  return <SocketContext.Provider value={contextValues} {...props} />;
};

export const useSocket = () => {
  const context = useContext(SocketContext);

  if (!context) {
    throw new Error('useSocket hook must be used inside SocketProvider context');
  }

  const { isConnected, error, welcomeData, lastNotification, socket } = context;

  return {
    isConnected,
    error,
    welcomeData,
    lastNotification,
    socket,
  };
};
