import styled from 'styled-components';

export const ProfileInfoHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

export const ProfileInfoTitle = styled.div`
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 133%;
  color: ${({ theme }) => theme.colors.typography.white};
`;
