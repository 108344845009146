import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { alpha, Menu as MuiMenu, MenuItem as MuiMenuItem } from '@mui/material';

import { IconButton } from '../../../../components';

export const MenuWrapper = styled.div``;

export const Menu = styled.ul`
  display: flex;
  align-items: center;
  gap: 32px;
  list-style: none;
`;

export const MenuItem = styled.li`
  display: block;
`;

export const MenuLink = styled(NavLink)`
  display: flex;
  align-items: center;
  gap: 8px;

  height: 40px;
  padding: 8px 0;

  font-weight: 400;
  font-size: 1.6rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.white50};
  text-decoration: none;

  transition: all 0.3s ease-in;

  &:hover {
    color: ${({ theme }) => theme.colors.typography.white};
  }

  &.active {
    color: ${({ theme }) => theme.colors.typography.white};
    border-bottom: 1px solid ${({ theme }) => theme.colors.typography.white};
  }

  &.pending {
  }
`;

export const BurgerMenuButton = styled(IconButton)`
  height: 40px;
  width: 40px;

  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.secondary};
  box-shadow: ${({ theme }) => theme.shadowsCustom.mobileMenuButton};
  color: ${({ theme }) => theme.colors.typography.gray88};

  transition: all 0.3s ease-in;

  &:hover {
    background-color: ${({ theme }) => alpha(theme.colors.secondary, 0.5)};
    box-shadow: none;
    color: ${({ theme }) => theme.colors.typography.white};
  }

  .MuiTouchRipple-child {
    border-radius: 16px;
  }

  &.opened-burger-menu {
    padding: 6px;
    border: 2px solid ${({ theme }) => theme.colors.typography.white};
    background-color: transparent;
    box-shadow: none;
    color: ${({ theme }) => theme.colors.typography.white};
  }
`;

export const BurgerMenu = styled(MuiMenu)`
  .MuiMenu-paper {
    margin-top: 8px;
    border-radius: 16px;
    background-color: ${({ theme }) => theme.colors.bg.contextMenu};
    box-shadow: ${({ theme }) => theme.shadowsCustom.contextMenu};
  }

  .MuiMenu-list {
    padding: 0;
  }
`;

export const BurgerMenuWrapper = styled.div`
  padding: 8px 0;
`;

export const BurgerMenuItem = styled(MuiMenuItem)`
  &.MuiMenuItem-root {
    width: 344px;
    padding: 0;
  }
`;

export const BurgerLink = styled(NavLink)`
  display: flex;
  align-items: center;
  gap: 8px;

  width: 100%;
  padding: 8px 16px;

  font-weight: 400;
  font-size: 1.6rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.gray88};

  svg {
    color: ${({ theme }) => theme.colors.typography.white};
  }

  &.active {
    color: ${({ theme }) => theme.colors.typography.white};
    background-color: ${({ theme }) =>
      alpha(theme.colors.primary, theme.palette.action.selectedOpacity)};
  }
`;

export const BurgerMenuLanguageWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.borders.white10};
`;
