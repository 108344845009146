export const getCurrentDateLocale = async (currentLang: string | undefined) => {
  let locale;

  try {
    locale = await import(`dayjs/locale/${currentLang}.js`);
  } catch {
    locale = null;
  }

  return locale;
};
