import { apiSlice } from '../../api';
import type { GetTransferFeeDTO, GetTransferFeeResult } from './types';

export const feeEndpoints = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getTransferFee: build.query<GetTransferFeeResult, GetTransferFeeDTO>({
      query: ({ walletUid, body }) => ({
        url: `/wallets-service/v1/wallets/${walletUid}/transfers/fee-calc`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const { useLazyGetTransferFeeQuery } = feeEndpoints;

export type FeeController = typeof feeEndpoints;
