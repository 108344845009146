import styled from 'styled-components';

export const AddressForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 100%;
`;

export const TransferInfoItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 8px;

  padding: 8px 0 16px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.borders.white10};

  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 100%;
`;

export const TransferInfoItemTitle = styled.div`
  color: ${({ theme }) => theme.colors.typography.gray80};
`;

export const TransferInfoItemValue = styled.div`
  color: ${({ theme }) => theme.colors.typography.white};
`;

export const SuccessContainer = styled.div`
  border: 2px solid #8bd300;
  border-radius: 10px;
  padding: 10px;

  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #8bd300;
`;
