import styled from 'styled-components';

export const TextArea = styled.textarea`
  resize: none;
  height: 100px;
  width: 100%;
  padding: 16px;

  border: 2px solid ${({ theme }) => theme.colors.borders.white05};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.bg.white05};

  font-weight: 400;
  font-size: 1.6rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.white};

  &::placeholder {
    color: ${({ theme }) => theme.colors.typography.gray64};
  }

  &:hover {
    border-color: ${({ theme }) => theme.colors.borders.gray80};
    outline: none;
  }

  &:active,
  &:focus,
  &:focus-visible {
    border-color: ${({ theme }) => theme.colors.borders.white};
    outline: none;
  }

  &:disabled,
  &:disabled:hover,
  &:disabled:active,
  &:disabled:focus,
  &:disabled:focus-visible {
    border: none;
    color: ${({ theme }) => theme.colors.typography.gray32};
    cursor: not-allowed;
  }

  // custom classes
  &.error {
    border-color: ${({ theme }) => theme.colors.error};
  }

  &.non-editable {
    border: none;
    color: ${({ theme }) => theme.colors.typography.gray48};
  }
`;

export const TextAreaWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const LabelWrapper = styled.label`
  display: block;
  position: relative;
  width: 100%;
`;

export const Label = styled.span`
  display: inline-block;
  margin-bottom: 4px;

  font-weight: 400;
  font-size: 1.6rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.gray48};
`;
