import styled from 'styled-components';

import { Button } from '../../../../components';

export const AddBankRecipientForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const AddBankRecipientFormFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AddBankRecipientFormActions = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 8px;
`;

export const AddBankRecipientPrevStepButton = styled(Button)`
  min-width: 90px;
`;

export const AddBankRecipientNextStepButton = styled(Button)`
  flex-grow: 1;
`;

export const CreateBankRecipientButton = styled(AddBankRecipientNextStepButton)``;
