import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from '../../styled';
import type { ExtendedRecipient } from '../../../../types';

type BankOwnProps = {
  recipient: ExtendedRecipient | undefined;
};

export const BankOwn: FC<BankOwnProps> = ({ recipient }) => {
  const { t } = useTranslation();

  return (
    <S.RecipientInputContainer>
      <S.RecipientInput
        name="currency"
        defaultValue={recipient?.currencyCode}
        label={t('recipient.fields.currency')}
        nonEditable
      />
      <S.RecipientInput
        name="bankName"
        defaultValue={recipient?.additionalInfo?.bank_name}
        label={t('recipient.fields.bank-name')}
        nonEditable
      />
      <S.RecipientInput
        name="bankCode"
        defaultValue={recipient?.additionalInfo?.bank_code}
        label={t('recipient.fields.bank-code')}
        nonEditable
      />
      <S.RecipientInput
        name="account"
        defaultValue={recipient?.additionalInfo?.account}
        label={t('recipient.fields.account')}
        nonEditable
      />
    </S.RecipientInputContainer>
  );
};
