import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import * as S from './styled';
import { Input } from '../../../../components';

import { RouterPaths } from '../../../../router';
import { useExecuteTransferMutation } from '../../../../api';
import { activeProfileUidSelector, useAppSelector } from '../../../../store';
import { AsyncErrorMessage, getAsyncErrorMessage, Shape, yup } from '../../../../forms';
import { PasswordType } from '../../../../types';

type ConfirmSendFormProps = {
  onCancel: () => void;
  onOpenSuccess: () => void;
  onOpenError: () => void;
  transferUid: string;
};
type ConfirmSendFormFields = { password: string };

const confirmSendSchema = yup.object().shape<Shape<{ password: string }>>({
  password: yup.string().required(),
});

export const ConfirmPasswordBody: FC<ConfirmSendFormProps> = ({
  transferUid,
  onCancel,
  onOpenError,
  onOpenSuccess,
}) => {
  const { t } = useTranslation();

  const profileUid = useAppSelector(activeProfileUidSelector);

  const [executeTransfer, { isLoading: isLoadingExecute }] = useExecuteTransferMutation();

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm<ConfirmSendFormFields>({
    resolver: yupResolver(confirmSendSchema),
  });

  const closeForm = () => {
    onCancel();
  };

  const addLocalAccount: SubmitHandler<ConfirmSendFormFields> = async (values) => {
    try {
      await executeTransfer({
        profileUid: profileUid,
        transferUid: transferUid,
        body: { password: values.password, passwordType: PasswordType.TOTP },
      }).unwrap();
      closeForm();
      onOpenSuccess();
    } catch (e: any) {
      if (e?.status === 401) {
        getAsyncErrorMessage(e, setError);
      } else {
        closeForm();
        onOpenError();
      }
    }
  };

  return (
    <S.ConfirmPasswordContainer>
      <S.ConfirmPasswordSubTitle>{t('send.confirm-pass-subtitle')}</S.ConfirmPasswordSubTitle>

      <S.ConfirmPasswordForm onSubmit={handleSubmit(addLocalAccount)}>
        <S.ConfirmPasswordFormFields>
          <Input {...register('password')} errors={errors} />
          <S.Manage2FALink
            href={process.env.REACT_APP_UI_ORY_SDK_URL + RouterPaths.AccountSettingsTOTP}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('send.manage-2fa')}
          </S.Manage2FALink>

          <AsyncErrorMessage errors={errors} />
        </S.ConfirmPasswordFormFields>

        <S.ConfirmPasswordFormActions>
          <S.ConfirmPasswordButton body={t('send.send')} type="submit" loading={isLoadingExecute} />
        </S.ConfirmPasswordFormActions>
      </S.ConfirmPasswordForm>
    </S.ConfirmPasswordContainer>
  );
};
