import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import * as S from './styled';
import { AddressStep, DetailsStep } from './steps';

import {
  AsyncErrorMessage,
  getAsyncErrorMessage,
  Shape,
  useFormSteps,
  yup,
} from '../../../../forms';
import { activeProfileUidSelector, useAppSelector } from '../../../../store';
import { GetActiveCountriesResult, useCreateCryptoRecipientMutation } from '../../../../api';
import type { AddCryptoAccountFormFields } from '../../Recipients';

type AddCryptoRecipientFormProps = {
  activeCountries: GetActiveCountriesResult | undefined;
  onCancel: () => void;
};
type AddCryptoRecipientFormSteps = 'details' | 'recipientAddress';

const addCryptoRecipientSchema: Record<AddCryptoRecipientFormSteps, any> = {
  details: yup.object().shape<Shape<AddCryptoAccountFormFields>>({
    currencyCode: yup.string().required(),
    name: yup.string().required(),
    email: yup.string().email().required(),
    address: yup.string().required(),
    recipientType: yup.string().required(),
  }),
  recipientAddress: yup.object().shape<Shape<AddCryptoAccountFormFields>>({
    recipientAddress: yup.object({
      country: yup.string().required(),
      state: yup.string(),
      city: yup.string().required(),
      street: yup.string().required(),
      buildingNumber: yup.string().required(),
      flatNumber: yup.string(),
      zipCode: yup.string().required(),
    }),
  }),
};

export const AddCryptoRecipientForm: FC<AddCryptoRecipientFormProps> = ({
  activeCountries,
  onCancel,
}) => {
  const { t } = useTranslation();

  const profileUid = useAppSelector(activeProfileUidSelector);

  const [createCryptoRecipient, { isLoading: isCreateCryptoRecipientLoading }] =
    useCreateCryptoRecipientMutation();

  // form steps data
  const stepsOrder: AddCryptoRecipientFormSteps[] = ['details', 'recipientAddress'];
  const {
    activeStep: _activeStep,
    isFirstStep,
    isLastStep,
    getNextStep,
    getPrevStep,
  } = useFormSteps(stepsOrder);
  const activeStep = _activeStep as AddCryptoRecipientFormSteps;

  // form data
  const formMethods = useForm<AddCryptoAccountFormFields>({
    resolver: yupResolver(addCryptoRecipientSchema[activeStep]),
    shouldUnregister: false,
    mode: 'onChange',
  });
  const {
    setError,
    handleSubmit,
    trigger,
    formState: { errors },
  } = formMethods;

  const closeForm = () => {
    onCancel();
  };

  const showNextStep = async () => {
    const isStepValid = await trigger();
    if (isStepValid) getNextStep();
  };

  const showPrevStep = () => {
    getPrevStep();
  };

  const addCryptoRecipient: SubmitHandler<AddCryptoAccountFormFields> = async (values) => {
    try {
      await createCryptoRecipient({ profileUid: profileUid, body: values }).unwrap();
      closeForm();
    } catch (e) {
      getAsyncErrorMessage(e, setError);
    }
  };

  const stepsMap: Record<AddCryptoRecipientFormSteps, ReturnType<FC>> = {
    details: <DetailsStep />,
    recipientAddress: <AddressStep activeCountries={activeCountries} />,
  };

  return (
    <FormProvider {...formMethods}>
      <S.AddCryptoRecipientForm onSubmit={handleSubmit(addCryptoRecipient)}>
        <S.AddCryptoRecipientFormFields>
          {stepsMap[activeStep]}

          <AsyncErrorMessage errors={errors} />
        </S.AddCryptoRecipientFormFields>

        <S.AddCryptoRecipientFormActions>
          <S.AddCryptoRecipientPrevStepButton
            variant="contained"
            body={t('ui.forms.buttons.prev-step')}
            disabled={isFirstStep}
            onClick={() => showPrevStep()}
          />

          {!isLastStep && (
            <S.AddCryptoRecipientNextStepButton
              body={t('ui.forms.buttons.next-step')}
              onClick={() => showNextStep()}
            />
          )}

          {isLastStep && (
            <S.CreateCryptoRecipientButton
              body={t('recipients.header.actions.add-recipient.form.crypto.create-recipient-btn')}
              type="submit"
              loading={isCreateCryptoRecipientLoading}
            />
          )}
        </S.AddCryptoRecipientFormActions>
      </S.AddCryptoRecipientForm>
    </FormProvider>
  );
};
