import { FC, MouseEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styled';
import { IconClose24, IconMenu } from '../../../../assets';
import { LanguageMenu } from '../../../../components';

import { useMedia } from '../../../theming';
import { useAppLinks } from '../../../../router';

export const AppMenu: FC = () => {
  const { t, i18n } = useTranslation();
  const { appLinks } = useAppLinks();
  const { isMobile, isTabletMaxWidth } = useMedia();

  // state and callbacks for burger context menu
  const [burgerMenuAnchor, setBurgerMenuAnchor] = useState<HTMLButtonElement | null>(null);
  const isBurgerMenuOpen = Boolean(burgerMenuAnchor);
  const openBurgerMenu = (e: MouseEvent<HTMLButtonElement>) => setBurgerMenuAnchor(e.currentTarget);
  const closeBurgerMenu = () => setBurgerMenuAnchor(null);

  const appMenu = useMemo(() => {
    return appLinks.map((link) => {
      const { name, icon: Icon, path } = link;

      return (
        <S.MenuItem key={path}>
          <S.MenuLink
            to={path}
            className={({ isActive, isPending }) =>
              isActive ? 'active' : isPending ? 'pending' : ''
            }
          >
            <Icon />
            {t(`menus.app.${name}` as unknown as TemplateStringsArray)}
          </S.MenuLink>
        </S.MenuItem>
      );
    });
  }, [appLinks, i18n.resolvedLanguage]);

  const burgerAppMenu = useMemo(() => {
    return appLinks.map((link) => {
      const { name, icon: Icon, path } = link;

      return (
        <S.BurgerMenuItem key={path}>
          <S.BurgerLink
            to={path}
            className={({ isActive, isPending }) =>
              isActive ? 'active' : isPending ? 'pending' : ''
            }
          >
            <Icon />
            {t(`menus.app.${name}` as unknown as TemplateStringsArray)}
          </S.BurgerLink>
        </S.BurgerMenuItem>
      );
    });
  }, [appLinks, i18n.resolvedLanguage]);

  return !isTabletMaxWidth ? (
    <S.MenuWrapper>
      <S.Menu>{appMenu}</S.Menu>
    </S.MenuWrapper>
  ) : (
    <>
      <S.BurgerMenuButton
        aria-controls={isBurgerMenuOpen ? 'burger-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isBurgerMenuOpen ? 'true' : undefined}
        disableRipple
        className={isBurgerMenuOpen ? 'opened-burger-menu' : ''}
        icon={isBurgerMenuOpen ? <IconClose24 /> : <IconMenu />}
        onClick={(e) => openBurgerMenu(e)}
      />

      <S.BurgerMenu
        id="burger-menu"
        open={isBurgerMenuOpen}
        anchorEl={burgerMenuAnchor}
        onClose={() => closeBurgerMenu()}
        onClick={() => closeBurgerMenu()}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <S.BurgerMenuWrapper>{burgerAppMenu}</S.BurgerMenuWrapper>
        {isMobile && (
          <S.BurgerMenuLanguageWrapper>
            <LanguageMenu type="list" />
          </S.BurgerMenuLanguageWrapper>
        )}
      </S.BurgerMenu>
    </>
  );
};
