import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import * as S from './styled';
import { Select, SelectOption } from '../../../../../../components';

import {
  GetCoinsByFilterResult,
  useCreateWalletMutation,
  useGetWalletsQuery,
} from '../../../../../../api';
import {
  activeProfileTypeSelector,
  activeProfileUidSelector,
  addressVerifyStatusSelector,
  profileVerifyStatusSelector,
  useAppSelector,
} from '../../../../../../store';
import { useACL } from '../../../../../../services';
import { getWalletNameByCoin } from '../../../../../../utils';
import { AsyncErrorMessage, getAsyncErrorMessage, Shape, yup } from '../../../../../../forms';
import { ProfileType } from '../../../../../../types';

type AddWalletFormProps = {
  coins: GetCoinsByFilterResult | undefined;
  onCancel: () => void;
};
type AddWalletFormFields = {
  coinId: number;
};

const addWalletSchema = yup.object().shape<Shape<AddWalletFormFields>>({
  coinId: yup.number().required(),
});

export const AddWalletForm: FC<AddWalletFormProps> = ({ coins, onCancel }) => {
  const { t } = useTranslation();
  const hasPermission_WALLET_CREATION = useACL(['WALLET_CREATION']);

  const isVerified = useAppSelector(profileVerifyStatusSelector);
  const isAddressVerified = useAppSelector(addressVerifyStatusSelector);
  const profileUid = useAppSelector(activeProfileUidSelector);
  const profileType = useAppSelector(activeProfileTypeSelector);
  const isNatural = profileType === ProfileType.NATURAL;

  const [createWallet, { isLoading: isCreateWalletLoading }] = useCreateWalletMutation();
  const { data: walletsList } = useGetWalletsQuery({ profileUid: profileUid });

  const coinsWithoutExistWallets = coins?.filter((c) => {
    const isWalletExist = walletsList?.some((w) => w.name === c.name);
    return !isWalletExist;
  });

  const {
    control,
    setError,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<AddWalletFormFields>({
    resolver: yupResolver(addWalletSchema),
  });

  const closeForm = () => {
    onCancel();
  };

  const addWallet: SubmitHandler<AddWalletFormFields> = async ({ coinId }) => {
    if (!isDirty) {
      return;
    }

    try {
      await createWallet({
        profileUid: profileUid,
        body: { coinId: coinId, name: coins?.find((c) => c.id === coinId)?.name },
      }).unwrap();
      closeForm();
    } catch (e) {
      // @ts-ignore
      if (e?.status === 403) {
        getAsyncErrorMessage(
          {
            status: 403,
            data: {
              message: t('dashboard-layout.wallet-bar.add-wallet-form.error-with-verification'),
            },
          },
          setError
        );
        return;
      }

      getAsyncErrorMessage(e, setError);
    }
  };

  const getDisablingCreateBtn = () => {
    if (!hasPermission_WALLET_CREATION) return true;
    if (isNatural && !isVerified) return true;
    if (isNatural && !isAddressVerified) return true;

    return false;
  };

  const coinsOptions = useMemo(() => {
    if (!coinsWithoutExistWallets) return [];

    return coinsWithoutExistWallets.map((coin) => {
      const name = getWalletNameByCoin(coin);
      return (
        <SelectOption key={coin.id} value={coin.id} name={name}>
          {name}
        </SelectOption>
      );
    });
  }, [coinsWithoutExistWallets]);

  return (
    <S.AddWalletForm onSubmit={handleSubmit(addWallet)}>
      <S.AddWalletFormFields>
        <Select
          control={{ control, name: 'coinId' }}
          errors={errors}
          options={coinsOptions}
          label={t('dashboard-layout.wallet-bar.add-wallet-form.fields.coinId.label')}
          placeholder={t('dashboard-layout.wallet-bar.add-wallet-form.fields.coinId.placeholder')}
        />

        <AsyncErrorMessage errors={errors} />
      </S.AddWalletFormFields>

      <S.AddWalletFormActions>
        <S.CancelCreateWalletButton
          variant="contained"
          body={t('dashboard-layout.wallet-bar.add-wallet-form.cancel-btn')}
          onClick={() => closeForm()}
        />
        <S.CreateWalletButton
          body={t('dashboard-layout.wallet-bar.add-wallet-form.create-btn')}
          type="submit"
          disabled={getDisablingCreateBtn()}
          loading={isCreateWalletLoading}
        />
      </S.AddWalletFormActions>
    </S.AddWalletForm>
  );
};
