import styled, { css } from 'styled-components';

import { Button, IconButton } from '../../../components';
import { getMediaFor } from '../../../layouts';

export const TransferContainer = styled.div<{ $isSendPage?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  padding: ${({ $isSendPage }) => ($isSendPage ? '0' : '0 40px 40px')};

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      padding: 0 16px 40px;
    `)};
`;
export const TransferInfoContainer = styled.div`
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  gap: 8px;

  max-width: 432px;
  min-width: 432px;

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      min-width: auto;
      max-width: 100%;
    `)};
`;
export const TransferHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;

  @media screen and (max-width: 529px) {
    gap: 16px;
  }
`;
export const ErrorMessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border: 2px solid ${({ theme }) => theme.colors.error};
  border-radius: 15px;

  font-weight: 400;
  font-size: 1.6rem;
  line-height: 100%;
  color: #7a7a7a;
`;

export const ErrorMessageClose = styled(IconButton)`
  padding: 0;
  margin-left: 10px;
  background: transparent;
  color: ${({ theme }) => theme.colors.typography.gray64};

  &:hover {
    background: transparent;
  }
`;

export const ErrorMessageTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 10px 20px 20px;
`;

export const ErrorMessageCloseContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 10px 0 0;
`;
export const TitleWrapper = styled.div`
  display: flex;
  gap: 16px;
`;
export const AmountContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;

  select {
    width: 60px;
  }
`;
export const TransferExecuteButton = styled(Button)`
  height: 34px;
`;

export const TransferBody = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 24px;
  width: 100%;
`;

export const WalletNameValue = styled.div`
  position: relative;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: -8px;
  transform: translate(-100%, -45%);
`;

export const TransferInfoWithActonBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 450px;
  gap: 24px;
`;

export const TransferReviewButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;

  width: 100%;
  max-width: 450px;
`;

export const DownloadButtonContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 450px;

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      button {
        width: 100%;
      }
    `)}
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
