import styled from 'styled-components';

import { Button } from '../../../../components';

export const AddBankAccountForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const AddBankAccountFormFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AddBankAccountFormActions = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 8px;
`;

export const AddBankAccountNextStepButton = styled(Button)`
  flex-grow: 1;
`;

export const CreateBankAccountButton = styled(AddBankAccountNextStepButton)``;
