import { apiSlice } from '../../api';
import type {
  GetWalletIBANRequestsResult,
  GetWalletIBANRequestsDTO,
  GetIBANRequestAvailableDTO,
  GetIBANRequestAvailableResult,
} from './types';

export const walletIBANRequestEndpoints = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getWalletIBANRequests: build.query<GetWalletIBANRequestsResult, GetWalletIBANRequestsDTO>({
      query: ({ walletUid }) => ({
        url: `/wallets-service/v1/wallets/${walletUid}/iban-requests`,
        method: 'GET',
      }),
    }),
    getIbanRequestAvailable: build.query<GetIBANRequestAvailableResult, GetIBANRequestAvailableDTO>(
      {
        query: () => ({
          url: '/guava-gateway-service/v1/iban/request-available',
          method: 'GET',
        }),
      }
    ),
  }),
});

export const { useGetWalletIBANRequestsQuery } = walletIBANRequestEndpoints;
export const { useGetIbanRequestAvailableQuery, useLazyGetIbanRequestAvailableQuery } =
  walletIBANRequestEndpoints;

export type WalletIBANRequestController = typeof walletIBANRequestEndpoints;
