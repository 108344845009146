import { ChangeEvent, FC, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { LoaderFunction } from 'react-router-dom';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { useDebounce } from '../../utils/hooks/useDebounce';

import * as S from './styled';
import {
  Accordion,
  Avatar,
  Input,
  Modal,
  Table,
  TableCell,
  TableRow,
  Title,
} from '../../components';
import { MemberDetailedInfo } from './components';

import {
  GetLocalRelatedPersonsDTO,
  relatedPersonEndpoints,
  runEndpointInRouter,
  useGetExternalRelatedPersonsQuery,
  useGetLocalRelatedPersonsQuery,
} from '../../api';
import { useMedia } from '../../layouts';
import { getItemFromStorage } from '../../utils';
import { activeProfileUidSelector, useAppSelector } from '../../store';
import { aclGuard, authGuard, getRouterError, portalGuard, RouterPaths } from '../../router';
import { ProfileInfo, ProfileType, RelatedPerson } from '../../types';

type RelatedPersonTable = 'local' | 'external';

export const teamLoader: LoaderFunction = async () => {
  authGuard();
  portalGuard(ProfileType.LEGAL, RouterPaths.DashboardLayout);
  await aclGuard(['LEGAL_RELATED_PERSONS_VIEW'], RouterPaths.DashboardLayout);

  const activeProfile = getItemFromStorage('activeProfile') as ProfileInfo;

  try {
    await runEndpointInRouter(relatedPersonEndpoints, 'getLocalRelatedPersons', {
      profileUid: activeProfile.profileUid,
    } as GetLocalRelatedPersonsDTO);
    await runEndpointInRouter(relatedPersonEndpoints, 'getExternalRelatedPersons', {
      profileUid: activeProfile.profileUid,
    } as GetLocalRelatedPersonsDTO);
  } catch (e) {
    throw getRouterError(e as FetchBaseQueryError);
  }

  return null;
};

export const Team: FC = () => {
  const { t, i18n } = useTranslation();
  const { isMobile } = useMedia();

  const profileUid = useAppSelector(activeProfileUidSelector);

  const { data: localPersons, isSuccess: isLocalPersonsFetchSuccess } =
    useGetLocalRelatedPersonsQuery({ profileUid });
  const { data: externalPersons, isSuccess: isExternalPersonsFetchSuccess } =
    useGetExternalRelatedPersonsQuery({ profileUid });
  const [localList, setLocalList] = useState(localPersons);
  const [externalList, setExternalList] = useState(externalPersons);

  useEffect(() => {
    if (isLocalPersonsFetchSuccess) {
      setLocalList(localPersons);
    }

    if (isExternalPersonsFetchSuccess) {
      setExternalList(externalPersons);
    }
  }, [localPersons, externalPersons]);

  // active accordion change state
  const [activeTable, setActiveTable] = useState<RelatedPersonTable | false>('local');
  const accordionClickHandler =
    (table: RelatedPersonTable) => (e: SyntheticEvent, isExpanded: boolean) => {
      setActiveTable(isExpanded ? table : false);
    };

  // detailed info modal state
  const [activeMember, setActiveMember] = useState<RelatedPerson | null>(null);
  const [isDetailedModalShown, setDetailedModalShown] = useState(false);
  const showDetailedInfoModal = (isAllow: boolean, person: RelatedPerson) => {
    if (!isAllow) return;
    setDetailedModalShown(true);
    setActiveMember(person);
  };
  const closeDetailedInfoModal = () => {
    setDetailedModalShown(false);
    setActiveMember(null);
  };

  // search input state
  const [searchValue, setSearchValue] = useState('');
  const searchMember = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };
  const debouncedValue = useDebounce<string>(searchValue, 500);

  useEffect(() => {
    if (activeTable === 'local') {
      setLocalList(
        localPersons?.filter((member) =>
          member.relatedProfileName.toLowerCase().includes(debouncedValue.toLowerCase())
        )
      );
    }

    if (activeTable === 'external') {
      setExternalList(
        externalPersons?.filter((member) =>
          member.relatedProfileName.toLowerCase().includes(debouncedValue)
        )
      );
    }
  }, [debouncedValue]);

  const localTableRows = useMemo(() => {
    return localList?.map((person) => {
      const { id, relatedProfileName, relatedProfileType, email, userRoleName } = person;
      const isLegal = relatedProfileType === ProfileType.LEGAL;

      const mobileCells = (
        <>
          <TableCell className="avatar-cell">
            <Avatar size="medium" />
          </TableCell>

          <TableCell>
            <S.MobileCellWrapper>
              {relatedProfileName}
              <S.EllipsisEmail>{email}</S.EllipsisEmail>
              {userRoleName
                ? t(`common.role-name.${userRoleName}` as unknown as TemplateStringsArray)
                : t('team.content.common.no-access')}
            </S.MobileCellWrapper>
          </TableCell>
        </>
      );

      const cells = (
        <>
          <TableCell className="avatar-cell">
            <Avatar size="medium" />
          </TableCell>
          <TableCell>{relatedProfileName}</TableCell>
          <TableCell>{email}</TableCell>
          <TableCell>
            {userRoleName
              ? t(`common.role-name.${userRoleName}` as unknown as TemplateStringsArray)
              : t('team.content.common.no-access')}
          </TableCell>
        </>
      );

      return (
        <TableRow
          key={id}
          hover={!isLegal}
          onClick={() => showDetailedInfoModal(!isLegal, person)}
          tableWithoutHeader
        >
          {isMobile ? mobileCells : cells}
        </TableRow>
      );
    });
  }, [localPersons, localList, i18n.resolvedLanguage, isMobile]);

  const externalTableRows = useMemo(() => {
    return externalList?.map((person) => {
      const { id, relatedProfileName, relationType } = person;

      const mobileCells = (
        <>
          <TableCell className="avatar-cell">
            <Avatar size="medium" />
          </TableCell>

          <TableCell>
            <S.MobileCellWrapper>
              <div>{relatedProfileName}</div>
              <div>{t(`common.relation-type.${relationType}`)}</div>
              <div>{t('team.content.common.no-access')}</div>
            </S.MobileCellWrapper>
          </TableCell>
        </>
      );

      const cells = (
        <>
          <TableCell className="avatar-cell">
            <Avatar size="medium" />
          </TableCell>
          <TableCell>{relatedProfileName}</TableCell>
          <TableCell>{t(`common.relation-type.${relationType}`)}</TableCell>
          <TableCell>{t('team.content.common.no-access')}</TableCell>
        </>
      );

      return (
        <TableRow key={id} hover={false} tableWithoutHeader>
          {isMobile ? mobileCells : cells}
        </TableRow>
      );
    });
  }, [externalPersons, externalList, i18n.resolvedLanguage, isMobile]);

  const local = <Table rows={localTableRows} />;
  const external = <Table rows={externalTableRows} />;

  return (
    <>
      <S.TeamContainer>
        <Helmet title={t('pages.team')} />

        <S.TeamHeader>
          <Title text={t('team.header.title')} />
          <S.TeamSearchInputWrapper>
            <Input
              name="search"
              placeholder={t('team.header.search-placeholder')}
              onChange={searchMember}
              value={searchValue}
            />
          </S.TeamSearchInputWrapper>
        </S.TeamHeader>

        <S.TeamContent>
          <Accordion
            expanded={activeTable === 'local'}
            onChange={accordionClickHandler('local')}
            defaultExpanded={false}
            header={t('team.content.local.title')}
            body={local}
          />
          <Accordion
            expanded={activeTable === 'external'}
            onChange={accordionClickHandler('external')}
            defaultExpanded={false}
            header={t('team.content.external.title')}
            body={external}
          />
        </S.TeamContent>
      </S.TeamContainer>

      <Modal
        modalTitle={t('team.content.detailed-info.title')}
        body={
          <MemberDetailedInfo member={activeMember} onCancel={() => closeDetailedInfoModal()} />
        }
        open={isDetailedModalShown}
        onClose={() => closeDetailedInfoModal()}
      />
    </>
  );
};
