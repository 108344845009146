import styled from 'styled-components';

import { Button, IconButton, Input } from '../../components';

export const RecipientContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const RecipientBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const RecipientActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

export const DeleteRecipientButton = styled(IconButton)`
  min-width: 72px;
  border-radius: 40px;
`;
export const CopyRecipientButton = styled(IconButton)`
  min-width: 72px;
  border-radius: 40px;
`;

export const SendMoneyButton = styled(Button)`
  flex-grow: 1;
`;

export const RecipientInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const RecipientInput = styled(Input)`
  && {
    color: ${({ theme }) => theme.colors.typography.white};
  }
`;
