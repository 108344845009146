export enum BackendLanguage {
  EN = 'EN',
  RU = 'RU',
}

export enum SortType {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum ResourceType {
  CLIENT_OFFICE = 'CLIENT_OFFICE',
  ADMIN_PANEL = 'ADMIN_PANEL',
  AML_OFFICE = 'AML_OFFICE',
  SUPPORT_OFFICE = 'SUPPORT_OFFICE',
  FINANCE_OFFICE = 'FINANCE_OFFICE',
}

export enum ExtendedBoolean {
  YES = 'YES',
  NO = 'NO',
  UNKNOWN = 'UNKNOWN',
}

export enum PasswordType {
  TOTP = 'TOTP',
  PASSWORD = 'PASSWORD',
}
