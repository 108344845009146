import styled, { css } from 'styled-components';

import { getMediaFor } from '../../layouts';

export const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  min-height: 100%;
  padding: 0 40px 40px;

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      padding: 0 16px 40px;
    `)};

  @media screen and (max-width: 631px) {
    gap: 32px;
    padding: 0 40px 40px;
  }
`;
