import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { LineInfo } from '../../../../../components';
import { ProfileInfoBody, ProfileInfoContainer, ProfileInfoHeader } from '../../../components';
import { LegalEntity } from '../../../../../types';
import { UTCToLocal } from '../../../../../utils';

type CompanyInfoProps = {
  legalProfile: LegalEntity;
  companyType: string | undefined;
  industry: string | undefined;
  additionalBusinessNames: string | undefined;
};

export const CompanyInfo: FC<CompanyInfoProps> = ({
  legalProfile,
  companyType,
  industry,
  additionalBusinessNames,
}) => {
  const { t } = useTranslation();

  return (
    <ProfileInfoContainer>
      <ProfileInfoHeader
        title={t('profile.legal.company-info.header.title')}
        isActionButtonVisible={false}
      />

      <ProfileInfoBody>
        <LineInfo
          label={t('profile.legal.company-info.body.company-name')}
          value={legalProfile.companyName}
        />

        {companyType && (
          <LineInfo label={t('profile.legal.company-info.body.company-type')} value={companyType} />
        )}

        <LineInfo
          label={t('profile.legal.company-info.body.registration-number')}
          value={legalProfile.registrationNumber}
        />

        {legalProfile.registrationDate && (
          <LineInfo
            label={t('profile.legal.company-info.body.registration-date')}
            value={UTCToLocal(legalProfile.registrationDate)}
          />
        )}

        {industry && (
          <LineInfo label={t('profile.legal.company-info.body.industry')} value={industry} />
        )}

        {additionalBusinessNames && (
          <LineInfo
            label={t('profile.legal.company-info.body.additional-business-names')}
            value={additionalBusinessNames}
          />
        )}

        {legalProfile.formationDate && (
          <LineInfo
            label={t('profile.legal.company-info.body.formation-date')}
            value={UTCToLocal(legalProfile.formationDate, 'DD/MM/YYYY')}
          />
        )}

        {legalProfile.licenceValidTo && (
          <LineInfo
            label={t('profile.legal.company-info.body.licence-valid-to')}
            value={UTCToLocal(legalProfile.licenceValidTo, 'DD/MM/YYYY')}
          />
        )}
      </ProfileInfoBody>
    </ProfileInfoContainer>
  );
};
