import { FC, ReactNode } from 'react';
import { DialogProps, ModalProps as MuiModalProps } from '@mui/material';
import { CSSObject } from 'styled-components';

import * as S from './styled';
import { IconClose24 } from '../../assets';

export type ModalProps = Omit<DialogProps, 'onClose'> & {
  modalTitle?: string;
  body: ReactNode;
  disableBackDropClick?: boolean;
  onClose?: () => void;
  hideCloseButton?: boolean;
  customPaperRootCss?: CSSObject;
};

export const Modal: FC<ModalProps> = (props) => {
  const {
    modalTitle,
    body,
    open,
    disableBackDropClick,
    onClose,
    hideCloseButton,
    customPaperRootCss,
    ...rest
  } = props;

  const onCloseCallback: MuiModalProps['onClose'] = (e, reason) => {
    if (disableBackDropClick && reason === 'backdropClick') return;
    onClose && onClose();
  };

  return (
    <S.Modal
      {...rest}
      open={open}
      onClose={(e, reason) => onCloseCallback(e, reason)}
      maxWidth={false}
      $customPaperRootCss={customPaperRootCss}
    >
      <S.ModalContainer>
        {modalTitle && (
          <S.ModalHeader>
            {modalTitle}
            {!hideCloseButton && onClose && (
              <S.ModalCloseButton icon={<IconClose24 />} onClick={onClose} />
            )}
          </S.ModalHeader>
        )}

        <S.ModalBody>{body}</S.ModalBody>
      </S.ModalContainer>
    </S.Modal>
  );
};
