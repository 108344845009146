import styled from 'styled-components';
import { TableCell as MuiTableCell, TableRow as MuiTableRow } from '@mui/material';

export const TableRow = styled(MuiTableRow)``;
export const TableCell = styled(MuiTableCell)`
  text-align: center;

  &:last-of-type {
    padding: 20px;
  }
`;
