import { ChangeEvent, CSSProperties, FC, useState } from 'react';

import * as S from './styled';
import { InputProps } from '../Input';
import { IconDownload24, IconFileAttach } from '../../assets';

import { useACL } from '../../services';
import { UploadDocumentToStorageResult, useUploadDocumentToStorageMutation } from '../../api';

type InputFileUploadProps = {
  inputProps?: InputProps;

  fileCallback: (response: UploadDocumentToStorageResult) => void;
  dragAndDrop?: boolean;
  multiple?: boolean;
  fileTypes?: string;
  maxSize?: number;
  style?: CSSProperties;
  dragAndDropContainerClassName?: string;
};

const defaultFileTypes = '.JPEG, .JPG, .PNG, .PDF, .DOC, .DOCX, .XLS, .XLSX, .RTF, .CSV, .TXT';

export const InputFileUpload: FC<InputFileUploadProps> = (props) => {
  const {
    inputProps,
    fileCallback,
    dragAndDrop,
    multiple,
    fileTypes,
    maxSize,
    style,
    dragAndDropContainerClassName,
  } = props;
  const hasPermission_STORAGE_UPLOAD_TEMP = useACL(['STORAGE_UPLOAD_TEMP']);

  let inputRef: HTMLInputElement | null = null;
  const [uploadFiles, { data: uploadFile, isLoading: isUpdating }] =
    useUploadDocumentToStorageMutation();
  const [dragOver, setDragOver] = useState(false);
  const [uploadError, setUploadError] = useState(false);
  const [uploadSuccsess, setUploadSuccsess] = useState(false);

  function selectFiles(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files) {
      startUploadFiles(event.target.files);
    }
  }

  function startUploadFiles(files: FileList) {
    const fd = new FormData();
    const types = fileTypes ?? defaultFileTypes;
    for (let i = 0; i < files.length; i++) {
      const file = files[i] as Blob;
      if (maxSize && file.size > maxSize) {
        showUploadError();
        return;
      }
      if (
        !types.split(', ').find(
          // @ts-ignore
          (it) => it.toLowerCase() == `.${(file.name as string).split('.').pop()}`.toLowerCase()
        )
      ) {
        showUploadError();
        return;
      }

      fd.append('files', file);
    }
    setUploadSuccsess(false);
    // @ts-ignore
    uploadFiles(fd)
      .then((res: any) => {
        if (res.error) {
          showUploadError();
        } else {
          fileCallback(res.data);
          setUploadSuccsess(true);
        }
      })
      .catch((err: any) => {
        showUploadError();
      });
  }

  function showUploadError() {
    setUploadError(true);
    setTimeout(() => {
      setUploadError(false);
    }, 5000);
  }

  function inputClick() {
    inputRef?.click();
  }

  function dropHandler(ev: any) {
    ev.preventDefault();
    setDragOver(false);
    if (ev.dataTransfer.files && ev.dataTransfer.files.length) {
      startUploadFiles(ev.dataTransfer.files);
    }
  }

  function dragOverHandler(ev: any) {
    ev.preventDefault();
    return false;
  }

  function onDragEnterHandler(ev: any) {
    ev.preventDefault();
    setDragOver(true);
  }

  function dragLeaveHandler(ev: any) {
    ev.preventDefault();
    if (ev.target.id == 'dropzone') {
      setDragOver(false);
    }
  }

  return (
    <>
      <S.Input
        {...inputProps}
        type="file"
        multiple={multiple}
        $dragAndDrop={!!dragAndDrop}
        onChange={($event) => selectFiles($event)}
        ref={(input) => {
          inputRef = input;
        }}
        accept={fileTypes ?? defaultFileTypes}
      />

      {dragAndDrop ? (
        <S.DnDContainer
          style={style}
          id="dropzone"
          onDrop={dropHandler}
          onDragOver={dragOverHandler}
          onDragEnter={onDragEnterHandler}
          onDragLeave={dragLeaveHandler}
          className={`${dragOver && !uploadError && !isUpdating ? 'drag-over' : ''} ${
            uploadSuccsess && !dragOver && !uploadError && !isUpdating ? 'upload-success' : ''
          } ${uploadError && !isUpdating ? 'upload-error' : ''} ${
            dragAndDropContainerClassName ?? ''
          }`}
          $disabled={!hasPermission_STORAGE_UPLOAD_TEMP}
        >
          <IconDownload24 />
          {dragOver && !uploadError && !isUpdating && <p>Drop your files to start upload!</p>}
          {!uploadSuccsess && !dragOver && !uploadError && !isUpdating && (
            <S.Label onClick={() => inputClick()}>
              <strong className="upload-link">
                Choose a{fileTypes && `${fileTypes.toLowerCase().replaceAll(', ', '/')}`} file
              </strong>
              {' or drag it here.'}
            </S.Label>
          )}
          {uploadSuccsess && !dragOver && !uploadError && !isUpdating && (
            <S.Label onClick={() => inputClick()}>
              File &quot;{uploadFile?.[0]?.fileName}&quot; uploaded successfully!
            </S.Label>
          )}
          {uploadError && !isUpdating && <S.Label>Failed to upload files!</S.Label>}
          {isUpdating && <S.UploadProgress>Uploading</S.UploadProgress>}
        </S.DnDContainer>
      ) : (
        <S.UploadFileButton
          icon={<IconFileAttach />}
          onClick={() => inputClick()}
          disabled={!hasPermission_STORAGE_UPLOAD_TEMP}
        />
      )}
    </>
  );

  // return (
  //   <Input {...inputProps} type="file" startAdornment={<IconDownload24 />} multiple={multiple} />
  // );
};
