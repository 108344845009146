import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styled';
import { Avatar } from '../../../../components';
import type { ProfileInfo } from '../../../../types';

type ProfileCardProps = { profile: ProfileInfo; onClick: (profile: ProfileInfo) => void };

export const ProfileCard: FC<ProfileCardProps> = (props) => {
  const { profile, onClick } = props;
  const { t } = useTranslation();

  // TODO: clarify avatar src, after backend implementing
  return (
    <S.ProfileCardContainer onClick={() => onClick(profile)}>
      <Avatar size="large" />

      <S.ProfileCardContent>
        <S.ProfileName>{profile.name}</S.ProfileName>

        <S.ProfileType>
          {t(`profiles.profile-card.profile-type.${profile.profileType}`)}
        </S.ProfileType>
      </S.ProfileCardContent>
    </S.ProfileCardContainer>
  );
};
