import { ElementType, MouseEvent, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FieldErrors, FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { SelectProps as MuiSelectProps } from '@mui/material';
import { useTheme } from 'styled-components';

import * as S from './styled';
import { IconDrop } from '../../assets';
import { Chip } from '../Chip';

import { getNameByValue } from './utils';
import { ErrorMessage, getErrorByFieldName } from '../../forms';
import type { SelectValue } from './types';

type SelectProps<FormValues extends FieldValues> = MuiSelectProps & {
  options: ReactElement[];
  control: UseControllerProps<FormValues>;
  errors?: FieldErrors;
  nonEditable?: boolean;
  showErrorMessage?: boolean;
  onMultipleValueDelete?: (e: MouseEvent<HTMLElement>, value: SelectValue) => void;
};

export const Select = <FormValues extends FieldValues>(
  props: SelectProps<FormValues>
): ReactElement => {
  const {
    options,
    multiple,
    control,
    errors,
    label,
    placeholder,
    disabled,
    nonEditable,
    showErrorMessage = true,
    onMultipleValueDelete,
    ...rest
  } = props;
  const { field } = useController(control);
  const { t } = useTranslation();
  const theme = useTheme();

  const Wrapper = label ? S.LabelWrapper : (S.SelectWrapper as ElementType);
  const hasError = errors ? Boolean(getErrorByFieldName(errors, control.name)) : false;
  const placeholderCalc = placeholder
    ? {
        content: `"${placeholder}"`,
        color: !nonEditable ? theme.colors.typography.gray64 : theme.colors.typography.gray48,
      }
    : {};
  const getRenderValue: MuiSelectProps['renderValue'] = (selected) => {
    const _selected = selected as SelectValue | SelectValue[];

    if (typeof _selected === 'object' && multiple) {
      return (
        <S.MultipleValuesContainer>
          {_selected.map((value) => (
            <Chip
              key={value}
              body={getNameByValue(options, value)}
              onDelete={(e) => onMultipleValueDelete && onMultipleValueDelete(e, value)}
              onMouseDown={(e) => e.stopPropagation()}
            />
          ))}
        </S.MultipleValuesContainer>
      );
    }

    if (typeof _selected !== 'object') {
      return getNameByValue(options, _selected);
    }

    return _selected;
  };

  return (
    <Wrapper>
      {label && <S.Label>{label}</S.Label>}

      <S.Select
        {...rest}
        {...field}
        multiple={multiple}
        renderValue={getRenderValue}
        value={field.value ?? (multiple ? [] : '')}
        error={hasError}
        sx={{ '& .MuiSelect-select .notranslate::after': placeholderCalc }}
        disabled={disabled}
        readOnly={nonEditable}
        $isActive={Boolean(field.value)}
        $isMultiple={multiple}
        $disabled={disabled}
        $nonEditable={nonEditable}
        IconComponent={IconDrop}
        MenuProps={{ PaperProps: { sx: S.getSelectDropperStyles(theme) } }}
        inputProps={{ autoComplete: 'off' }}
      >
        {options.length !== 0 ? (
          options
        ) : (
          <S.NoOptions disabled>{t('ui.select.no-options')}</S.NoOptions>
        )}
      </S.Select>

      {showErrorMessage && hasError && <ErrorMessage fieldName={control.name} errors={errors} />}
    </Wrapper>
  );
};
