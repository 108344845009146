import styled, { css } from 'styled-components';

import { getMediaFor } from '../../../theming';

export const WalletsBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const EmptyWalletsList = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 24px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.bg.white05};

  font-weight: 400;
  font-size: 2rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.white};
`;

const walletsBarSliderContainerCommonStyles = css`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 24px 48px 24px 40px;

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      padding: 16px;
    `)};
`;

export const WalletsBarEmptySliderContainer = styled.div`
  ${walletsBarSliderContainerCommonStyles};
  justify-content: flex-end;
`;

export const WalletsBarSliderSmallContainer = styled.div`
  ${walletsBarSliderContainerCommonStyles};
  justify-content: space-between;
`;

export const WalletsBarSliderContainer = styled.div`
  ${walletsBarSliderContainerCommonStyles};

  .swiper {
    //width: 100%;
    margin: 0;
  }

  .swiper-wrapper {
  }

  .swiper-slide {
    max-width: 248px;
    width: auto;
  }
`;
