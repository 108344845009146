import { apiSlice } from '../../api';
import type {
  GetExternalRelatedPersonsDTO,
  GetExternalRelatedPersonsResult,
  GetLocalRelatedPersonsDTO,
  GetLocalRelatedPersonsResult,
} from './types';

export const relatedPersonEndpoints = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getLocalRelatedPersons: build.query<GetLocalRelatedPersonsResult, GetLocalRelatedPersonsDTO>({
      query: ({ profileUid }) => ({
        url: `/profiles-service/v1/profiles/${profileUid}/related-persons/local`,
        method: 'GET',
      }),
      providesTags: ['RelatedPersonsLocal'],
    }),
    getExternalRelatedPersons: build.query<
      GetExternalRelatedPersonsResult,
      GetExternalRelatedPersonsDTO
    >({
      query: ({ profileUid }) => ({
        url: `/profiles-service/v1/profiles/${profileUid}/related-persons/external`,
        method: 'GET',
      }),
      providesTags: ['RelatedPersonsExternal'],
    }),
  }),
});

export const { useGetLocalRelatedPersonsQuery } = relatedPersonEndpoints;
export const { useGetExternalRelatedPersonsQuery } = relatedPersonEndpoints;

export type RelatedPersonController = typeof relatedPersonEndpoints;
