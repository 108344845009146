import { ReactElement } from 'react';
import { FieldPath, FieldValues, UseFormGetValues, UseFormSetValue } from 'react-hook-form';

import type { SelectName, SelectValue } from '../types';

/**
 * Function for mapping array with some entities for Select options.
 *
 * Need to choose two keys of entity - the first for 'name', and the second for 'value' of Select option.
 *
 * For generic type, need to pass the type of entity.
 * @param dataArray - array of objects, with some two keys, which values need to pass in Select options
 * @param keyToValue - first key, which value will be the value of Select option
 * @param keyToName - second key, which value will be the name of Select option
 */
export const mapDataToSelect = <T>(
  dataArray: T[] = [],
  keyToValue: keyof T,
  keyToName: keyof T
) => {
  return dataArray.map((it) => ({ value: it[keyToValue], name: it[keyToName] }));
};

/**
 * Property `renderValue` in Select component responsible for rendering name by selected item in Select.
 * But callback in this property returns only value.
 *
 * This function helps to find right `name` for selected item in Select.
 *
 * @param options - options, which was passed in Select props.
 * @param value - value, which gets in `renderValue` callback (names `selected` in callback).
 */
export const getNameByValue = (options: ReactElement[], value: SelectValue): SelectName => {
  if (value && options.length === 0) {
    console.warn('Your array of options have 0 length, but you provide a value to select');
    return '';
  }

  const searchElement = options.find((it) => {
    return it.props.value === value;
  });

  return searchElement?.props?.name;
};

/**
 * Function for deleting one known element from array of multiple select value
 *
 * Now implemented by Chip component
 *
 * @param valueToDelete - pass the value from event of handler
 * @param name - name of field
 * @param getValues - getValues method from useForm hook
 * @param setValue - setValue method from useForm hook
 */
export const removeValueInMultipleSelect = <
  TFieldValues extends FieldValues,
  TFieldName extends FieldPath<TFieldValues>
>(
  valueToDelete: SelectValue,
  name: TFieldName,
  getValues: UseFormGetValues<TFieldValues>,
  setValue: UseFormSetValue<TFieldValues>
) => {
  const fieldValue = getValues(name);
  if (!fieldValue) return;

  const newFieldValue = fieldValue.filter((v: SelectValue) => v !== valueToDelete);
  setValue(name, newFieldValue, { shouldDirty: true, shouldTouch: true, shouldValidate: true });
};
