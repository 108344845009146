import { FC, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import * as S from './styled';
import { Stepper, Title } from '../../components';
import { Transfer as TransferInfo } from '../Transfer';

import { useLazyGetTransferByTransferUidQuery } from '../../api';
import { activeProfileUidSelector, useAppSelector } from '../../store';
import { FullTransfer } from '../../types';

type TransferReviewProps = {
  type: 'forSend' | 'forExchange';
};

export const TransferReview: FC<TransferReviewProps> = ({ type }) => {
  const { t } = useTranslation();
  const { transferId } = useParams<{ transferId: string }>();

  const profileUid = useAppSelector(activeProfileUidSelector);

  const [getTransfer, { data: transfer }] = useLazyGetTransferByTransferUidQuery();

  const { reset } = useForm<FullTransfer>({
    defaultValues: { ...transfer },
  });

  useEffect(() => {
    if (transferId && profileUid) {
      getTransfer({
        transferUid: transferId ?? '',
        profileUid: profileUid ?? '',
      });
    }
  }, [transferId, profileUid]);

  useEffect(() => {
    if (transfer) {
      reset({ ...transfer });
    }
  }, [transfer]);

  return (
    <S.ReviewContainer>
      <Helmet title={type === 'forSend' ? t('pages.send') : t('pages.exchange')} />
      {type === 'forSend' && (
        <Stepper
          stepsLabels={[
            { label: t('send.amount') },
            { label: t('send.recipient') },
            { label: t('send.review') },
          ]}
          activeStep={3}
        />
      )}
      <Title text={type === 'forSend' ? t('send.title') : t('exchange.title')} />
      <TransferInfo type={type} />
    </S.ReviewContainer>
  );
};
