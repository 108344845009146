import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { Input, Select, useSelectOptions } from '../../../../components';

import { sortArrayByPropAsc } from '../../../../utils';
import { GetActiveCountriesResult } from '../../../../api';
import type { ProfileCreateFormFields } from '../../ProfileCreate';

type AddressStepProps = {
  activeCountries: GetActiveCountriesResult | undefined;
};

export const AddressStep: FC<AddressStepProps> = ({ activeCountries }) => {
  const { t } = useTranslation();

  const {
    control,
    register,
    formState: { errors },
  } = useFormContext<ProfileCreateFormFields>();

  const activeCountriesOptions = useSelectOptions(
    sortArrayByPropAsc(activeCountries, 'name'),
    'alpha3Code',
    'name'
  );

  return (
    <>
      <Select
        control={{ control, name: 'address.country' }}
        errors={errors}
        options={activeCountriesOptions}
        label={t('profile-create.form.address-step.country.label')}
      />
      <Input
        {...register('address.state')}
        errors={errors}
        label={t('profile-create.form.address-step.state.label')}
      />
      <Input
        {...register('address.city')}
        errors={errors}
        label={t('profile-create.form.address-step.city.label')}
      />
      <Input
        {...register('address.street')}
        errors={errors}
        label={t('profile-create.form.address-step.street.label')}
      />
      <Input
        {...register('address.buildingNumber')}
        errors={errors}
        label={t('profile-create.form.address-step.building-number.label')}
      />
      <Input
        {...register('address.flatNumber')}
        errors={errors}
        label={t('profile-create.form.address-step.flat-number.label')}
      />
      <Input
        {...register('address.zipCode')}
        errors={errors}
        label={t('profile-create.form.address-step.zip-code.label')}
      />
    </>
  );
};
