import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import languageDetector from 'i18next-browser-languagedetector';

import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/ru';

import { i18nConfig as config } from './config';

const availableLanguages = [
  { code: 'en', name: 'English' },
  // { code: 'ru', name: 'Русский' },
] as const;
const availableLanguageCodes = availableLanguages.map((l) => l.code);
type AvailableLanguages = (typeof availableLanguageCodes)[number];

const getTranslates = (language: AvailableLanguages) =>
  require(`./languages/${language}.translation.json`);

const resources = availableLanguageCodes.reduce(
  (acc, lang) => ({
    ...acc,
    [lang]: {
      [config.defaultNS! as string]: {
        ...getTranslates(lang),
      },
    },
  }),
  {}
);

const parseMissingKeyHandler = (key: string) => {
  console.warn(`Missing value for key: ${key}`);
  return '';
};

i18n.on('initialized', () => {
  dayjs.locale(i18n.resolvedLanguage);
});

i18n.on('languageChanged', () => {
  dayjs.locale(i18n.resolvedLanguage);
});

i18n
  .use(languageDetector)
  .use(initReactI18next)
  .init({
    ...config,
    parseMissingKeyHandler,
    resources,
    supportedLngs: availableLanguageCodes,
  });

export { i18n, availableLanguages, availableLanguageCodes };
export type { AvailableLanguages };
