import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PopoverProps } from '@mui/material';

import * as S from './styled';
import { Modal } from '../../../../components';
import { useAppLinks } from '../../../../router';

import { HistoryCsvFilterForm } from '../HistoryCsvFilterForm';
import { Wallet } from '../../../../types';
import { StatementFilter, StatementFilterForm } from '../StatementFilterForm';
import {
  GetRecipientsByFilterResult,
  useDownloadTransfersCSVMutation,
  usePostTransferStatementCsvMutation,
  usePostTransferStatementMutation,
} from '../../../../api';
import { useAppSelector, activeProfileUidSelector } from '../../../../store';
import { download, UTCToLocal } from '../../../../utils';
import { IconPdf } from '../../../../assets/svg/IconPdf/IconPdf';
import { IconCsv } from '../../../../assets/svg/IconCsv/IconCsv';
import {
  DownloadWalletStatementDto,
  useDownloadWalletStatementPdfMutation,
} from '../../../../api/controllers/docgen';

type WalletContextMenuProps = {
  open: boolean;
  wallets?: Wallet[];
  recipientList?: GetRecipientsByFilterResult;
  anchorEl: PopoverProps['anchorEl'];
  closeHandler: () => void;
};

// TODO: commented code maybe used in future after MVP

export const ReportContextMenu: FC<WalletContextMenuProps> = (props) => {
  const { open, closeHandler, anchorEl, wallets, recipientList } = props;
  const { t, i18n } = useTranslation();
  const { walletLinks } = useAppLinks();
  const [getStatement, { data: statement }] = usePostTransferStatementMutation();
  const [downloadReport, { isLoading: isLoadingReport }] = useDownloadTransfersCSVMutation();
  const [downloadStetementCsv] = usePostTransferStatementCsvMutation();
  const [downloadPdf] = useDownloadWalletStatementPdfMutation();
  const profileUid = useAppSelector(activeProfileUidSelector);
  const [statementModalType, setStatementModalType] = useState<'PDF' | 'CSV' | null>(null);
  const [statementLoading, setStatementLoading] = useState(false);
  const [showErrorHistoryCsv, setShowErrorHistoryCsv] = useState(false);

  const [openModalStatement, setOpenModalStatement] = useState(false);
  const onOpenStatementModal = () => setOpenModalStatement(true);
  const onCloseStatementModal = () => {
    setOpenModalStatement(false);
    setStatementModalType(null);
  };

  const [openModalReport, setOpenModalReport] = useState(false);
  const onOpenReportModal = () => setOpenModalReport(true);
  const onCloseReportModal = () => setOpenModalReport(false);

  useEffect(() => {
    if (statement && statement.transfers) {
      const mappedData: DownloadWalletStatementDto = {
        abfpayCompanyInfo: {
          abfCompanyName: statement.abfpayCompanyInfo.companyName,
          abfCountry: statement.abfpayCompanyInfo.country,
          abfZip: statement.abfpayCompanyInfo.zip,
          abfCity: statement.abfpayCompanyInfo.city,
          abfStreet: statement.abfpayCompanyInfo.street,
          abfBuildingNumber: statement.abfpayCompanyInfo.buildingNumber,
          abfOfficeNumber: statement.abfpayCompanyInfo.officeNumber,
        },
        balanceSummary: statement.balanceSummary,
        dateFrom: UTCToLocal(statement.dateFrom, 'DD/MM/YYYY, HH:mm'),
        dateTo: UTCToLocal(statement.dateTo, 'DD/MM/YYYY, HH:mm'),
        walletId: statement.walletId,
        issuerInfo: statement.issuerInfo,
        currentDate: UTCToLocal(new Date().toISOString(), 'DD/MM/YYYY, HH:mm'),
        requesterInfo: statement.requesterInfo,
        transfers: statement.transfers?.map((it) => ({
          ...it,
          finishedAt: UTCToLocal(it.finishedAt, 'DD/MM/YYYY, HH:mm'),
        })),
      };

      downloadPdf(mappedData).then(() => {
        onCloseStatementModal();
        setStatementLoading(false);
      });
    }
  }, [statement]);

  const handleOpenStatementPdf = () => {
    setStatementModalType('PDF');
    onOpenStatementModal();
  };

  const handleOpenStatementCsv = () => {
    setStatementModalType('CSV');
    onOpenStatementModal();
  };

  const onDownloadPdf = (data: StatementFilter) => {
    setStatementLoading(true);
    const dateAtFrom = new Date(data.createdAtFrom);
    dateAtFrom.setUTCHours(0, 0, 0, 0);
    const dateAtTo = new Date(data.createdAtTo);
    dateAtTo.setUTCDate(dateAtTo.getUTCDate() + 1);
    dateAtTo.setUTCHours(0, 0, 0, 0);
    getStatement({
      body: {
        walletId: data.walletId ?? 0,
        createdAtFrom: dateAtFrom.toISOString(),
        createdAtTo: dateAtTo.toISOString(),
      },
      profileUid,
    }).then((data: any) => {
      if (data?.error) {
        setStatementLoading(false);
      }
    });
  };

  const onDownloadCsv = (data: StatementFilter) => {
    setStatementLoading(true);
    const dateAtFrom = new Date(data.createdAtFrom);
    dateAtFrom.setUTCHours(0, 0, 0, 0);
    const dateAtTo = new Date(data.createdAtTo);
    dateAtTo.setUTCDate(dateAtTo.getUTCDate() + 1);
    dateAtTo.setUTCHours(0, 0, 0, 0);
    profileUid &&
      downloadStetementCsv({
        body: {
          walletId: data.walletId ?? 0,
          createdAtFrom: dateAtFrom.toISOString(),
          createdAtTo: dateAtTo.toISOString(),
        },
        profileUid,
      }).then(async (res: any) => {
        if (res) {
          await download(res.error?.data, 'text/csv', 'statement.csv');
          onCloseStatementModal();
          setStatementLoading(false);
        }
      });
  };

  const reportList = [
    {
      name: t('transfers.actions.download-report-context.report-csv'),
      icon: IconCsv,
      onClick: onOpenReportModal,
    },
    {
      name: t('transfers.actions.download-report-context.statement-csv'),
      icon: IconCsv,
      onClick: handleOpenStatementCsv,
    },
    {
      name: t('transfers.actions.download-report-context.statement-pdf'),
      icon: IconPdf,
      onClick: handleOpenStatementPdf,
    },
  ];

  const links = useMemo(() => {
    return reportList.map((link, index) => {
      const { name, icon: Icon, onClick } = link;
      return (
        <S.WalletMenuItem key={index}>
          <S.WalletLink onClick={onClick}>
            <S.WalletLinkIcon>
              <Icon />
            </S.WalletLinkIcon>
            {name}
          </S.WalletLink>
        </S.WalletMenuItem>
      );
    });
  }, [walletLinks, i18n.resolvedLanguage]);

  return (
    <>
      <S.WalletMenu
        id="wallet-menu"
        open={open}
        anchorEl={anchorEl}
        onClose={() => closeHandler()}
        onClick={() => closeHandler()}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {/*Links*/}
        <S.WalletLinksWrapper>{links}</S.WalletLinksWrapper>
      </S.WalletMenu>
      <Modal
        modalTitle={t('transfers.actions.filter.form.title')}
        body={
          <StatementFilterForm
            onCancel={() => onCloseStatementModal()}
            wallets={wallets}
            onDownload={
              statementModalType === 'PDF'
                ? onDownloadPdf
                : statementModalType === 'CSV'
                ? onDownloadCsv
                : undefined
            }
            loading={statementLoading}
          />
        }
        open={openModalStatement}
        onClose={() => onCloseStatementModal()}
      />
      <Modal
        modalTitle={t('transfers.actions.filter.form.title')}
        body={
          <HistoryCsvFilterForm
            onCancel={() => onCloseReportModal()}
            onDownload={(data) => {
              setShowErrorHistoryCsv(false);
              profileUid &&
                downloadReport({
                  profileUid,
                  // TODO: check csv reports downloading - is need pagination in body? Why always same report is downloading?
                  body: { ...data },
                }).then(async (res: any) => {
                  if (res) {
                    if (res.error?.data) {
                      await download(res.error?.data, 'text/csv', 'history.csv');
                      onCloseReportModal();
                    } else {
                      setShowErrorHistoryCsv(true);
                    }
                  }
                });
            }}
            loading={isLoadingReport}
            errorDownload={showErrorHistoryCsv}
            wallets={wallets}
            recipients={recipientList?.data}
          />
        }
        open={openModalReport}
        onClose={() => onCloseReportModal()}
      />
    </>
  );
};
