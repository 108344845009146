export enum DocumentState {
  UPLOADED = 'UPLOADED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  ARCHIVED = 'ARCHIVED',
  EXPIRED = 'EXPIRED',
}

export enum DocumentSource {
  SUMSUB = 'SUMSUB',
  CLIENT = 'CLIENT',
  EMPLOYEE = 'EMPLOYEE',
}

export enum DocumentType {
  PASSPORT = 'PASSPORT',
  UTILITY_BILL = 'UTILITY_BILL',
  NATIONAL_ID_CARD = 'NATIONAL_ID_CARD',
  SELFIE = 'SELFIE',
  REGISTRY_EXTRACT = 'REGISTRY_EXTRACT',
  SHAREHOLDER_EXTRACT = 'SHAREHOLDER_EXTRACT',
  FINANCIAL_STATEMENT = 'FINANCIAL_STATEMENT',
  SOURCE_OF_FUNDS = 'SOURCE_OF_FUNDS',
  OWNERSHIP_STRUCTURE = 'OWNERSHIP_STRUCTURE',
  POWER_OF_ATTORNEY = 'POWER_OF_ATTORNEY',
  LICENCE = 'LICENCE',
  ARTICLES_OF_ASSOCIATION = 'ARTICLES_OF_ASSOCIATION',
  PARTNERSHIP_AGREEMENT = 'PARTNERSHIP_AGREEMENT',
  CHARTER_BY_LAWS = 'CHARTER_BY_LAWS',
  CERTIFICATE_OF_INCORPORATION = 'CERTIFICATE_OF_INCORPORATION',
  MEMORANDUM_OF_ASSOCIATION = 'MEMORANDUM_OF_ASSOCIATION',
  ACT_OF_FORMATION = 'ACT_OF_FORMATION',
  BUSINESS_REGISTRATION_CERTIFICATE = 'BUSINESS_REGISTRATION_CERTIFICATE',
  PROOF_OF_ADDRESS = 'PROOF_OF_ADDRESS',
  PROOF_OF_BANK_ACCOUNT = 'PROOF_OF_BANK_ACCOUNT',
  EXPLANATION_NEED_OF_SILVERTIP_PAY_SERVICES = 'EXPLANATION_NEED_OF_SILVERTIP_PAY_SERVICES',
  INTERNAL_POLICIES_ML_TF = 'INTERNAL_POLICIES_ML_TF',
  INTERNAL_POLICIES_SANCTION_IMPLEMENTATION = 'INTERNAL_POLICIES_SANCTION_IMPLEMENTATION',
  WOLFSBERG_QUESTIONNAIRE = 'WOLFSBERG_QUESTIONNAIRE',
  AML_CTF_KYC_AND_EU_SANCTIONS_IMPLEMENTATION_PROCEDURES = 'AML_CTF_KYC_AND_EU_SANCTIONS_IMPLEMENTATION_PROCEDURES',
  AML_CTF_KYC_EMPLOYEES = 'AML_CTF_KYC_EMPLOYEES',
  AUDITOR_REPORT_WITH_AML_CTF_KYC_COMPLIANT = 'AUDITOR_REPORT_WITH_AML_CTF_KYC_COMPLIANT',
  PROOF_OF_DIRECTOR_APPOINTMENT = 'PROOF_OF_DIRECTOR_APPOINTMENT',
  OTHER = 'OTHER',
}
