import { CSSProperties, FC, ReactNode } from 'react';

import * as S from './styled';
import { IconInfoHexagon, IconWarningHexagon16 } from '../../assets';
import type { HintIcon, HintTextAlign, HintVariant } from './types';

type HintProps = {
  message: string;
  /**
   * Variant of Hint:
   *
   * 'small' - usually for form's errors
   *
   * 'default' - for other hints
   */
  variant: HintVariant;
  icon: HintIcon;
  textAlign?: HintTextAlign;
  customContainerStyles?: CSSProperties;
};

export const Hint: FC<HintProps> = (props) => {
  const { message, variant, icon, textAlign = 'left', customContainerStyles } = props;

  const iconElement: ReactNode = {
    info: <IconInfoHexagon />,
    warning: <IconWarningHexagon16 />,
    none: null,
  }[icon];

  const variantMap: Record<HintVariant, ReturnType<FC>> = {
    small: (
      <S.SmallHintContainer $hasIcon={Boolean(iconElement)} style={customContainerStyles}>
        {iconElement && <S.HintIcon>{iconElement}</S.HintIcon>}
        <S.HintMessage $textAlign={textAlign}>{message}</S.HintMessage>
      </S.SmallHintContainer>
    ),
    default: (
      <S.DefaultHintContainer style={customContainerStyles}>
        {iconElement && <S.HintIcon>{iconElement}</S.HintIcon>}
        <S.HintMessage $textAlign={textAlign}>{message}</S.HintMessage>
      </S.DefaultHintContainer>
    ),
  };

  return variantMap[variant];
};
