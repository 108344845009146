import { FC } from 'react';
import { Step, StepLabel, Stepper as StepperMui, StepIconProps } from '@mui/material';

import * as S from './styled';

type StepperProps = {
  activeStep?: number;
  stepsLabels: {
    onClick?: () => void;
    label: string;
  }[];
};

export const Stepper: FC<StepperProps> = (props) => {
  const { activeStep, stepsLabels } = props;

  function QontoStepIcon(props: StepIconProps, item: any) {
    const { active, completed, className } = props;

    return (
      <S.QontoStepIconRoot ownerState={{ active }} className={className}>
        <S.StepLabelContainer
          className={`${active || completed ? 'active' : 'inactive'} ${
            !!item?.onClick && 'clickable'
          }`}
          onClick={item.onClick}
        >
          {item.label}
        </S.StepLabelContainer>
      </S.QontoStepIconRoot>
    );
  }

  return (
    <StepperMui
      style={{ marginLeft: '-8px' }}
      activeStep={activeStep}
      connector={<S.QontoConnector />}
    >
      {stepsLabels.map((it, index) => {
        const stepProps: { completed?: boolean } = {};
        return (
          <Step key={index} {...stepProps}>
            <StepLabel StepIconComponent={(e) => QontoStepIcon(e, it)} />
          </Step>
        );
      })}
    </StepperMui>
  );
};
