import { FC, SVGProps } from 'react';

export const ImageOperationError: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg viewBox="0 0 340 220" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M255 19.3336L175.903 31.0002L175 24.6669L254.097 13.0002L255 19.3336ZM217.242 70.0002L312 51.2502L310.758 45.0002L216 63.7502L217.242 70.0002ZM61 180.284L177.789 202L179 195.717L62.2108 174L61 180.284ZM142.014 185L221 173.373L219.986 167L141 178.628L142.014 185ZM287 82.0002H191V88.0002H287V82.0002ZM150.451 119L272 111.824L271.661 105L150 112.177L150.451 119Z"
        fill="#F55D58"
      />
      <path
        d="M130.319 9.28669C129.416 9.62515 129 16 129 16L129.078 22L145 23C145 23 145.324 15.4918 145.324 14.138C145.324 12.7841 143.97 5.78926 143.068 6.12772C142.165 6.35336 141.714 8.83541 141.714 8.83541L141.037 12.1072C141.037 12.1072 141.601 6.35335 140.811 5.90207C140.134 5.45079 139.006 5.67643 139.006 5.67643L137.991 9.96361C137.991 9.96361 137.652 4.99951 136.637 4.99951C135.621 4.99951 134.832 6.57899 134.832 6.57899L134.493 11.3175L133.252 13.5739C133.252 13.5739 131.673 12.7841 131.447 11.8816C131.221 10.979 130.319 9.28669 130.319 9.28669Z"
        fill="white"
      />
      <path
        d="M84.4007 57.7992C77.2977 61.3507 69.7952 64.9232 66.6879 72.6915C61.7238 70.4351 57.1103 67.4978 53.5 64.0003C57.7412 55.518 65.0338 49.6596 73.3443 45.3889C74.9238 44.5992 76.5033 43.8094 78.0827 43.1325C81.1037 41.9241 84.3463 42.3599 86.6571 44.8248C90.0417 48.6607 90.2674 55.3171 84.4007 57.7992Z"
        fill="#141414"
        stroke="#141414"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M122.5 49C110.316 53.5128 96.3603 53.0607 84.2884 57.912C77.6689 61.2218 70.6275 52.9959 74.0002 47C79.364 37.8049 101 38.0454 110 36C119.346 33.9386 125.95 30.1499 129 21L144.535 21.8094C144.535 34.6767 133.752 44.837 122.5 49Z"
        fill="#141414"
        stroke="#141414"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M129 22.0002C129.887 15.2349 129 10.8533 130.219 9.50024C131.439 8.14717 131.328 12.8374 133.213 13.5139C135.097 14.1905 133.989 5.73379 136.428 5.05725C138.866 4.26796 137.869 11.9354 137.869 11.9354C137.869 11.9354 138.201 5.05725 140.086 5.28276C141.97 5.50827 140.973 12.3864 140.973 12.3864C140.973 12.3864 142.081 5.62103 143.079 5.9593C144.077 6.29756 145.552 9.22922 144 24.0002"
        stroke="#141414"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M133 13.0002C136.086 15.1624 137 18.0002 137 18.0002Z" fill="#141414" />
      <path
        d="M133 13.0002C136.086 15.1624 137 18.0002 137 18.0002"
        stroke="#141414"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M212 73.0005L226 82.0005L241 54.0005C241 54.0005 233 50.0005 229 57.0005L217 52.0005L212 73.0005Z"
        fill="#141414"
        stroke="#141414"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M129 191L122 204L152 215C152 215 156 207 147 204L150 192L129 191Z"
        fill="#141414"
        stroke="#141414"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M125 191.001C134.025 149.595 110.641 116.878 82.9996 103.001C72.8806 97.9411 79.9489 86.4943 84.9998 80.0004C117.492 37.8055 179.113 29.8723 223 53.0005L211 76.0005C177.492 58.6261 133.415 59.2313 106 88.0006C138.266 107.519 165.538 147.842 152 195.001L125 191.001Z"
        fill="white"
        stroke="#141414"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M126 187L152 189"
        stroke="#141414"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M218 51.0005L206 73.0005"
        stroke="#141414"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M78 94.0005L66 63.0005C80.0871 57.3633 109 46.0005 109 46.0005L120 77.0005C120 77.0005 82.7834 92.2147 78 94.0005Z"
        fill="white"
        stroke="#141414"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M94.0003 66.0005L89 68.0005L90.9997 73.0005L96 71.0005L94.0003 66.0005Z"
        stroke="#141414"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.5 76.0005C55.1564 96.6467 63.6513 87.0672 72 90.0005C80.3487 92.9338 67.5128 116.257 63 136C58.3744 156.647 90.9077 130.057 97 141C103.318 152.283 53.1026 207.662 17 208"
        stroke="#141414"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M56.168 189.516L41.6104 197.921L44.995 203.783L59.5527 195.378L56.168 189.516Z"
        fill="#141414"
      />
      <path
        d="M100 76C91.8769 84.1231 78.3087 93.7893 68.6061 83.9739C64.883 80.2508 65.4471 74.8354 67.7035 70.5482C63.8676 66.8251 60.0317 62.9892 56.4215 59.1533C55.4061 60.5072 54.3907 62.0867 53.6009 63.6661C48.524 73.2559 48.8625 85.779 56.4215 94.0149C63.9804 102.364 75.601 105.071 86.4317 102.364C95.0061 100.22 101.682 95.8667 108 90C105.501 85.0026 103.11 80.7557 100 76Z"
        fill="#141414"
        stroke="#141414"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M100 76C107.884 68.506 112.173 65.1333 108.695 71.1512C106.608 74.8982 109.159 73.4221 113.681 69.9022C118.202 66.3823 114.84 72.8544 112.985 75.2388C112.985 75.2388 117.854 70.3564 119.13 71.6054C120.405 72.9679 115.42 77.9639 115.42 77.9639C115.42 77.9639 120.405 73.195 120.985 75.2388C121.448 76.942 111.014 86.8205 108 89.9998"
        fill="white"
      />
      <path
        d="M100 76C107.884 68.506 112.173 65.1333 108.695 71.1512C106.608 74.8982 109.159 73.4221 113.681 69.9022C118.202 66.3823 114.84 72.8544 112.985 75.2388C112.985 75.2388 117.854 70.3564 119.13 71.6054C120.405 72.9679 115.42 77.9639 115.42 77.9639C115.42 77.9639 120.405 73.195 120.985 75.2388C121.448 76.942 111.014 86.8205 108 89.9998"
        stroke="#141414"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M88.5 26.5003L90 26.0003C90 26.0003 88.3846 23.1438 85 21.0002C81.5026 18.8567 75.1462 21.972 76.5 27.5002C77.0641 29.9823 78.1462 31.5122 79.5 32.4148L77 44.0003L74 46.0003C75.4512 48.1771 77.3729 49.0003 80 49.0003C84.1525 49.0003 88.3106 45.3489 88 41.0003H85L86.5 32.4148C89.8846 30.7225 88.5 26.5003 88.5 26.5003Z"
        fill="white"
        stroke="#141414"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M84.8349 20.4623C86.1057 22.9492 82.3058 25.2407 80.4994 26.1731C79.1746 26.8723 80.0176 28.6205 81.3424 28.6205C83.0287 28.6205 79.703 31.6602 79 32.0003C70.2242 27.4275 80 11.0005 84.8349 20.4623Z"
        fill="#141414"
      />
      <path
        d="M83.5885 24.9464L82 23.3248C81.5764 22.8924 80.7292 22.8924 80.3056 23.3248C79.882 23.7572 79.882 24.514 80.4115 25.0545C80.941 25.5951 81.4705 26.1356 82 26.6762C82.4236 27.1086 83.2708 27.1086 83.6944 26.6762C84.118 26.1356 84.118 25.3789 83.5885 24.9464Z"
        fill="#141414"
      />
    </svg>
  );
};
