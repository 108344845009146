import { PayloadAction } from '@reduxjs/toolkit';

import { withPaginationSlice, WithPaginationState } from '../utils';
import type { TransferFilter } from '../../types';

type TransfersState = {
  filter: TransferFilter;
};

const initialState: WithPaginationState<TransfersState> = {
  filter: {},
  pagination: {
    pageNumber: 0,
    pageSize: 20,
  },
};

export const transfersSlice = withPaginationSlice({
  name: 'transfers',
  initialState,
  reducers: {
    setTransfersFilter: (state, action: PayloadAction<TransferFilter>) => {
      state.filter = action.payload;
    },
    clearTransfersFilter: (state) => {
      state.filter = initialState.filter;
    },
  },
});

export const transfersActions = transfersSlice.actions;
export const transfersReducer = transfersSlice.reducer;
