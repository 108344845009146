import { FC } from 'react';

import * as S from './styled';

type ProfileLayoutTitleProps = {
  className?: string;
  text: string;
};

export const ProfileLayoutTitle: FC<ProfileLayoutTitleProps> = (props) => {
  const { className, text } = props;

  return <S.ProfileLayoutTitle className={className}>{text}</S.ProfileLayoutTitle>;
};
