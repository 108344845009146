import { css, DefaultTheme } from 'styled-components';
import { CssArgs, GetMediaForBreakpoint, hardBreakpoints } from '../media';

export const getMediaFor = (breakpoint: GetMediaForBreakpoint, theme: DefaultTheme) => {
  switch (breakpoint) {
    case 'desktop': {
      return (...args: CssArgs) => css`
        @media screen and (min-width: ${hardBreakpoints.desktop}px) {
          ${
            // @ts-ignore
            css([...args, theme])
          };
        }
      `;
    }
    case 'laptop': {
      return (...args: CssArgs) => css`
        @media (min-width: ${hardBreakpoints.laptop}px) and (max-width: ${hardBreakpoints.desktop -
          1}px) {
          ${
            // @ts-ignore
            css([...args, theme])
          };
        }
      `;
    }
    case 'tablet': {
      return (...args: CssArgs) => css`
        @media (min-width: ${hardBreakpoints.tablet}px) and (max-width: ${hardBreakpoints.laptop -
          1}px) {
          ${
            // @ts-ignore
            css([...args, theme])
          };
        }
      `;
    }
    case 'mobile': {
      return (...args: CssArgs) => css`
        @media (max-width: ${hardBreakpoints.tablet - 1}px) {
          ${
            // @ts-ignore
            css([...args, theme])
          };
        }
      `;
    }

    case 'laptop-max-width': {
      return (...args: CssArgs) => css`
        @media (max-width: ${hardBreakpoints.desktop - 1}px) {
          ${
            // @ts-ignore
            css([...args, theme])
          };
        }
      `;
    }
    case 'tablet-max-width': {
      return (...args: CssArgs) => css`
        @media (max-width: ${hardBreakpoints.laptop - 1}px) {
          ${
            // @ts-ignore
            css([...args, theme])
          };
        }
      `;
    }

    case 'landscape': {
      return (...args: CssArgs) => css`
        @media (orientation: landscape) {
          ${
            // @ts-ignore
            css([...args, theme])
          };
        }
      `;
    }
    case 'landscape-mobile': {
      return (...args: CssArgs) => css`
        @media (orientation: landscape) and (max-width: ${hardBreakpoints.tablet - 1}px) {
          ${
            // @ts-ignore
            css([...args, theme])
          };
        }
      `;
    }
  }
};
