import styled, { css } from 'styled-components';

import { Button } from '../../components';
import { getMediaFor } from '../../layouts';

export const ReceiveContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  padding: 0 40px 40px;

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      padding: 0 16px 40px;
    `)};
`;

export const ReceiveBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 432px;

  @media screen and (max-width: 529px) {
    max-width: 100%;
  }
`;

export const WalletContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AmountContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  select {
    width: 60px;
  }
`;

export const AlertContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px;
  font-size: 12px;
  gap: 8px;
  border-radius: 40px;
  background: #4d4d4d;
  color: #fff;
`;

export const SectionTitle = styled.div`
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 100%;
  width: 100%;
  color: ${({ theme }) => theme.colors.typography.gray80};
  padding-bottom: 4px;

  &.bordered {
    border-bottom: 2px solid ${({ theme }) => theme.colors.typography.gray32};
  }
`;

export const WalletCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  &.limitedHeight {
    max-height: 230px;
    overflow: auto;
    scrollbar-gutter: auto;
  }
`;

export const RequisiteInfo = styled.div`
  padding: 0 0 40px 0;
`;

export const RequisiteTitle = styled.div`
  padding: 0 0 16px 0;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.typography.gray48};
  line-height: 32px;
`;

export const RequisiteInfoItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const CopyDetailsButton = styled(Button)`
  width: 100%;
  height: 40px;
  margin-top: 24px;
`;
