import { FC } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { LoaderFunction } from 'react-router-dom';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';

import * as S from './styled';
import { Title } from '../../components';
import { LegalProfile } from './LegalProfile';
import { NaturalProfile } from './NaturalProfile';
import {
  countryEndpoints,
  GetProfileAddressDTO,
  legalEndpoints,
  profileEndpoints,
  runEndpointInRouter,
} from '../../api';
import { getItemFromStorage } from '../../utils';
import { aclGuard, authGuard, getRouterError, RouterPaths } from '../../router';
import { activeProfileTypeSelector, useAppSelector } from '../../store';
import { ProfileInfo, ProfileType } from '../../types';

export const profileLoader: LoaderFunction = async () => {
  authGuard();
  await aclGuard(['COUNTRY_VIEW'], RouterPaths.DashboardLayout);
  const hasPermission_PROFILE_ADDRESSES_VIEW = await aclGuard(['PROFILE_ADDRESSES_VIEW']);
  const hasPermission_INDUSTRY_CODES_VIEW = await aclGuard(['INDUSTRY_CODES_VIEW']);

  try {
    const activeProfile = getItemFromStorage('activeProfile') as ProfileInfo;
    const profileType = activeProfile.profileType;
    const isLegal = profileType === ProfileType.LEGAL;

    hasPermission_PROFILE_ADDRESSES_VIEW &&
      (await runEndpointInRouter(profileEndpoints, 'getProfileAddress', {
        profileUid: activeProfile.profileUid,
      } as GetProfileAddressDTO));
    await runEndpointInRouter(countryEndpoints, 'getActiveCountries');

    // for legal
    if (isLegal) {
      await runEndpointInRouter(legalEndpoints, 'getLegalCompanyTypes');
      hasPermission_INDUSTRY_CODES_VIEW &&
        (await runEndpointInRouter(legalEndpoints, 'getLegalIndustryCodes'));
    }
  } catch (e) {
    throw getRouterError(e as FetchBaseQueryError);
  }

  return null;
};

export const Profile: FC = () => {
  const { t } = useTranslation();

  const profileType = useAppSelector(activeProfileTypeSelector);

  const profilesMap: Record<ProfileType, ReturnType<FC>> = {
    [ProfileType.LEGAL]: <LegalProfile />,
    [ProfileType.NATURAL]: <NaturalProfile />,
  };

  return (
    <S.ProfileContainer>
      <Helmet title={t('pages.profile')} />
      <Title text={t('profile.title')} />

      {profileType && profilesMap[profileType]}
    </S.ProfileContainer>
  );
};
