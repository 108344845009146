import { FC, SVGProps } from 'react';

export const IconRise: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ rotate: '180deg' }}
      {...props}
    >
      <path
        d="M7.14251 4.42916C7.53091 3.78182 8.46909 3.78182 8.85749 4.42915L13.0913 11.4855C13.4912 12.152 13.0111 13 12.2338 13H3.76619C2.9889 13 2.50878 12.152 2.9087 11.4855L7.14251 4.42916Z"
        fill="#8BD300"
      />
    </svg>
  );
};
