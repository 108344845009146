import { FC } from 'react';

import * as S from './styled';

type TitleProps = {
  text: string;
  className?: string;
};

export const Title: FC<TitleProps> = (props) => {
  const { text, className } = props;

  return <S.Title className={className}>{text}</S.Title>;
};
