import { useMemo } from 'react';

import { links } from './links';
import { activeProfileTypeSelector, useAppSelector } from '../../store';

export const useAppLinks = () => {
  const profileType = useAppSelector(activeProfileTypeSelector);

  const appLinks = useMemo(() => {
    return links
      .filter((link) => link.portals.includes(profileType!))
      .filter((link) => !link.isProfile && !link.isWallet);
  }, [profileType]);

  const profileLinks = useMemo(() => {
    return links
      .filter((link) => link.portals.includes(profileType!))
      .filter((link) => link.isProfile);
  }, [profileType]);

  const walletLinks = useMemo(() => {
    return links
      .filter((link) => link.portals.includes(profileType!))
      .filter((link) => link.isWallet);
  }, [profileType]);

  return {
    appLinks,
    profileLinks,
    walletLinks,
  };
};
