import styled from 'styled-components';

export const Checkbox = styled.input`
  position: relative;
  width: 22px;
  height: 22px;
  min-width: 22px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  border: 2px solid rgba(255, 255, 255, 0.05);
  border-radius: 6px;
  background-color: rgba(255, 255, 255, 0.05);
  transition: background-color 0.2s ease-in-out;

  &:hover {
    border-color: ${({ theme }) => theme.colors.borders.gray80};
    outline: none;
  }

  &::after {
    content: '';
    position: absolute;
    top: 45%;
    left: 50%;
    width: 0;
    height: 0;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: translate(-50%, -50%) rotate(45deg);
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &:checked::after {
    width: 7px;
    height: 12px;
    opacity: 1;
  }
  &:checked {
    background-color: rgb(139, 211, 0);
  }
`;

export const CheckboxWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const LabelWrapper = styled.label`
  display: block;
  position: relative;
  width: 100%;
`;

export const Label = styled.span`
  display: inline-block;
  margin-top: 4px;

  font-weight: 400;
  font-size: 1.6rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.gray48};
`;

export const CheckboxWithLabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
export const CheckboxContainer = styled.div``;
