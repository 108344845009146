export const download = (data: string, type: string, fileName = 'download') => {
  const blob = new Blob([data], { type });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.id = 'download-link';
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
  document.getElementById('download-link')?.remove();
};

export const createDownloadFileLink = (token: string) => {
  return `${process.env.REACT_APP_API_BASE_URL}/storage-service/v1/documents/download/${token}?rt=${process.env.REACT_APP_RESOURCE}`;
};

export const downloadBlob = (blob: Blob, fileName = 'download') => {
  const blobUrl = URL.createObjectURL(blob);

  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = fileName;

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  window.URL.revokeObjectURL(blobUrl);
};
