/* eslint-disable react-hooks/exhaustive-deps*/
import { FC, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { LoaderFunction, useNavigate } from 'react-router-dom';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';

import * as S from './styled';
import { AddTicketForm, TicketsFilterForm } from './components';
import { Column, Modal, Pagination, Table, TableCell, TableRow, Title } from '../../components';

import {
  GetTicketsByFilterDTO,
  runEndpointInRouter,
  ticketsEndpoints,
  useLazyGetTicketsByFilterQuery,
} from '../../api';
import {
  activeProfileUidSelector,
  activeTicketsFiltersCountSelector,
  RootState,
  selectorTicketsState,
  store,
  ticketsActions,
  useAppActions,
  useAppSelector,
} from '../../store';
import { useMedia } from '../../layouts';
import { authGuard, getRouterError, RouterPaths } from '../../router';
import { getItemFromStorage, UTCToLocal } from '../../utils';
import { ProfileInfo } from '../../types';

export const ticketsLoader: LoaderFunction = async () => {
  authGuard();

  const activeProfile = getItemFromStorage('activeProfile') as ProfileInfo;
  const state = store.getState() as RootState;

  const getTicketsByFilterBody = {
    profileUid: activeProfile.profileUid,
    ...state.tickets.initialRequestArgs,
    ...state.tickets.pagination,
  };

  try {
    await runEndpointInRouter(
      ticketsEndpoints,
      'getTicketsByFilter',
      getTicketsByFilterBody as GetTicketsByFilterDTO
    );
  } catch (e) {
    throw getRouterError(e as FetchBaseQueryError);
  }

  return null;
};

export const Tickets: FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { isMobile } = useMedia();

  const profileUid = useAppSelector(activeProfileUidSelector);
  const ticketsState = useAppSelector(selectorTicketsState);
  const activeFiltersCount = useAppSelector(activeTicketsFiltersCountSelector);
  const isFilterActive = Boolean(activeFiltersCount);
  const { setPageNumber } = useAppActions(ticketsActions);

  const [getTickets, { data: ticketsList, isFetching: isTicketsFetching }] =
    useLazyGetTicketsByFilterQuery();

  useEffect(() => {
    try {
      const body = isFilterActive
        ? {
            profileUid,
            ...ticketsState.initialRequestArgs,
            ...ticketsState.filter,
            ...ticketsState.pagination,
          }
        : { profileUid, ...ticketsState.initialRequestArgs, ...ticketsState.pagination };
      getTickets(body, true).unwrap();
    } catch (e) {
      throw getRouterError(e as FetchBaseQueryError);
    }
  }, [ticketsState.pagination]);

  // new ticket modal state
  const [isTicketModalShown, setTicketModalShown] = useState(false);
  const showAddTicketForm = () => setTicketModalShown(true);
  const closeAddTicketForm = () => setTicketModalShown(false);

  // filter modal state
  const [isFilterModalShown, setFilterModalShown] = useState(false);
  const showFilter = () => setFilterModalShown(true);
  const closeFilter = () => setFilterModalShown(false);

  const ticketsTableColumns: Column[] = [
    { field: t('tickets.table.header.id') },
    { field: t('tickets.table.header.ticket-type') },
    { field: t('tickets.table.header.ticket-state') },
    { field: t('tickets.table.header.created-at') },
    { field: t('tickets.table.header.close-at') },
  ];

  const mobileTicketsTableColumns: Column[] = [
    { field: t('tickets.table.header.ticket-type') },
    { field: t('tickets.table.header.ticket-state') },
  ];

  const ticketsTableRows = useMemo(() => {
    return ticketsList?.data.map((ticket) => {
      const { id, ticketType, ticketState, createdAt, closedAt } = ticket;

      const mobileCells = (
        <>
          <TableCell>
            <S.MobileCellWrapper>
              <div className="cell-header">{`TC${id}`}</div>
              <div>{t(`common.ticket-type.${ticketType}`)}</div>
            </S.MobileCellWrapper>
          </TableCell>

          <TableCell>
            <S.MobileCellWrapper>
              <div className="cell-header">{t(`common.ticket-state.${ticketState}`)}</div>
              <div>
                {t('tickets.table.header.created-at')}: {UTCToLocal(createdAt, 'DD/MM/YYYY')}
              </div>
              <div>
                {t('tickets.table.header.close-at')}:{' '}
                {closedAt ? UTCToLocal(closedAt, 'DD/MM/YYYY') : '—'}
              </div>
            </S.MobileCellWrapper>
          </TableCell>
        </>
      );

      const cells = (
        <>
          <TableCell>{`TC${id}`}</TableCell>
          <TableCell>{t(`common.ticket-type.${ticketType}`)}</TableCell>
          <TableCell>{t(`common.ticket-state.${ticketState}`)}</TableCell>
          <TableCell>{UTCToLocal(createdAt, 'DD/MM/YYYY')}</TableCell>
          <TableCell>{closedAt ? UTCToLocal(closedAt, 'DD/MM/YYYY') : '—'}</TableCell>
        </>
      );

      return (
        <TableRow key={id} onClick={() => navigate(`${RouterPaths.Tickets}/${id}`)}>
          {isMobile ? mobileCells : cells}
        </TableRow>
      );
    });
  }, [ticketsList, navigate, i18n.resolvedLanguage, isMobile]);

  return (
    <>
      <S.TicketsContainer>
        <Helmet title={t('pages.tickets')} />

        <S.TicketsHeader>
          <Title text={t('tickets.title')} />

          <S.TicketsActions>
            <S.TicketsActionButtons>
              <S.AddTicketButton
                color="secondary"
                body={t('tickets.actions.add-ticket.btn')}
                onClick={() => showAddTicketForm()}
              />
              <S.TicketFilterButton
                variant="default"
                size="small"
                body={t('tickets.actions.filter.btn')}
                onClick={() => showFilter()}
                isFilterActive={isFilterActive}
                activeFiltersCount={activeFiltersCount}
              />
            </S.TicketsActionButtons>
          </S.TicketsActions>
        </S.TicketsHeader>

        <S.TicketsTableContainer>
          <Table
            columns={isMobile ? mobileTicketsTableColumns : ticketsTableColumns}
            rows={ticketsTableRows}
            loading={isTicketsFetching}
          />
        </S.TicketsTableContainer>

        <Pagination
          pageNumber={ticketsState.pagination.pageNumber}
          pageCount={ticketsList?.paging.totalPages}
          onChange={(p) => setPageNumber(p)}
        />
      </S.TicketsContainer>

      <Modal
        modalTitle={t('tickets.actions.add-ticket.form.title')}
        body={<AddTicketForm onCancel={() => closeAddTicketForm()} />}
        open={isTicketModalShown}
        onClose={() => closeAddTicketForm()}
        customPaperRootCss={{ width: '640px' }}
      />
      <Modal
        modalTitle={t('tickets.actions.filter.form.title')}
        body={
          <TicketsFilterForm
            onCancel={() => closeFilter()}
            getTickets={getTickets}
            ticketsLoading={isTicketsFetching}
          />
        }
        open={isFilterModalShown}
        onClose={() => closeFilter()}
      />
    </>
  );
};
