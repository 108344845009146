import styled, { css } from 'styled-components';

import { getMediaFor } from '../theming';

export const ProfileLayoutContainer = styled.div`
  display: flex;
  flex-direction: column;

  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.bg.profileLayout};
`;

export const ProfileLayoutContent = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px 40px 40px;

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      padding: 16px 16px 40px;
    `)}
`;
