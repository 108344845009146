import { LocalStorage, LocalStorageKeys, LocalStorageValue } from './types';

export const storageEvent = new Event('storageChange');

export const setItemToStorage = (key: LocalStorageKeys, value: LocalStorageValue) => {
  const serializedValue = JSON.stringify(value);
  localStorage.setItem(LocalStorage[key], serializedValue);
  window.dispatchEvent(storageEvent);
};

export const removeItemFromStorage = (key: LocalStorageKeys) => {
  localStorage.removeItem(LocalStorage[key]);
  window.dispatchEvent(storageEvent);
};

export const getItemFromStorage = (key: LocalStorageKeys) => {
  const value = localStorage.getItem(LocalStorage[key]);
  return value ? JSON.parse(value) : null;
};

export const clearStorage = () => {
  localStorage.clear();
};

export const isItemExistInStorage = (key: LocalStorageKeys) => {
  return Boolean(getItemFromStorage(key));
};
