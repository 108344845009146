import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styled';
import { Button } from '../../../../components';
import { DocumentButton } from '../../../../components/DocumentButton';
import { IconFileAttach } from '../../../../assets';

import { useACL } from '../../../../services';
import { TransferDocumentDtoWA } from '../../../../api';
import { createDownloadFileLink } from '../../../../utils';

type TransferDocumentsProps = { documents?: TransferDocumentDtoWA[]; onAttachClick?: () => void };

export const TransferDocuments: FC<TransferDocumentsProps> = ({ documents, onAttachClick }) => {
  const { t } = useTranslation();
  const hasPermission_STORAGE_DOWNLOAD = useACL(['STORAGE_DOWNLOAD']);

  const downloadFile = (token?: string) => {
    if (!token) {
      return;
    }
    location.href = createDownloadFileLink(token);
  };

  return (
    <S.TransferDocumentsContainer>
      {documents?.length !== 0 && (
        <S.DocumentsContainer>
          {documents?.map((document, index) => (
            <DocumentButton
              key={document.id}
              content={`Document ${index + 1}`}
              startIcon={<IconFileAttach />}
              onClick={() => downloadFile(document.token)}
              disabled={!hasPermission_STORAGE_DOWNLOAD}
            />
          ))}
        </S.DocumentsContainer>
      )}

      {onAttachClick ? (
        <S.AttachButtonContainer>
          {documents && (!documents?.length || documents?.length < 3) && (
            <Button
              style={{ width: '100%' }}
              body={t('transfer.attach-modal.title')}
              startIcon={<IconFileAttach />}
              onClick={onAttachClick}
            />
          )}
        </S.AttachButtonContainer>
      ) : null}
    </S.TransferDocumentsContainer>
  );
};
