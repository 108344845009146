import { FC, SVGProps } from 'react';

export const IconInfoHexagon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5547 2.57164C8.2188 2.3477 7.7812 2.3477 7.4453 2.57164L3.4453 5.2383C3.1671 5.42377 3 5.736 3 6.07035V9.92962C3 10.264 3.1671 10.5762 3.4453 10.7617L7.4453 13.4283C7.7812 13.6523 8.2188 13.6523 8.5547 13.4283L12.5547 10.7617C12.8329 10.5762 13 10.264 13 9.92962V6.07035C13 5.736 12.8329 5.42377 12.5547 5.2383L8.5547 2.57164ZM6.3359 0.907536C7.3436 0.235737 8.6564 0.235737 9.6641 0.907536L13.6641 3.5742C14.4987 4.1306 15 5.06729 15 6.07035V9.92962C15 10.9327 14.4987 11.8694 13.6641 12.4258L9.6641 15.0924C8.6564 15.7642 7.3436 15.7642 6.3359 15.0924L2.3359 12.4258C1.5013 11.8694 1 10.9327 1 9.92962V6.07035C1 5.06729 1.5013 4.1306 2.3359 3.5742L6.3359 0.907536ZM9 4.99999C9 5.55227 8.55228 5.99999 8 5.99999C7.44772 5.99999 7 5.55227 7 4.99999C7 4.4477 7.44772 3.99999 8 3.99999C8.55228 3.99999 9 4.4477 9 4.99999ZM9 7.99999C9 7.4477 8.55228 6.99999 8 6.99999C7.44772 6.99999 7 7.4477 7 7.99999V11C7 11.5523 7.44772 12 8 12C8.55228 12 9 11.5523 9 11V7.99999Z"
        fill="currentColor"
      />
    </svg>
  );
};
