import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import * as S from './styled';
import { IconDownload24, IconPlus, IconTrash24 } from '../../../../assets';
import { Input, InputFileUpload, Select, SelectOption } from '../../../../components';

import { activeProfileUidSelector, useAppSelector } from '../../../../store';
import { AsyncErrorMessage, getAsyncErrorMessage, Shape, yup } from '../../../../forms';
import { useGetDocumentsByUidQuery, useUploadProfileDocumentMutation } from '../../../../api';
import { DocumentState, DocumentType } from '../../../../types';

type LegalDocumentVerificationFormFields = {
  documents: {
    documentType: `${DocumentType}` | '';
    tempPath: string;
    comment?: string;
  }[];
};

const legalDocumentVerificationSchema = yup
  .object()
  .shape<Shape<LegalDocumentVerificationFormFields>>({
    documents: yup.array().of(
      yup.object().shape({
        documentType: yup.string().required(),
        tempPath: yup.string().required(),
        comment: yup.string().when('documentType', {
          is: 'OTHER',
          then: yup.string().required(),
          otherwise: yup.string().notRequired(),
        }),
      })
    ),
  });

const maxDocumentsCount = 10;
const allowedVerificationDocuments: Partial<Record<DocumentType, DocumentType>> = {
  [DocumentType.REGISTRY_EXTRACT]: DocumentType.REGISTRY_EXTRACT,
  [DocumentType.SHAREHOLDER_EXTRACT]: DocumentType.SHAREHOLDER_EXTRACT,
  [DocumentType.ARTICLES_OF_ASSOCIATION]: DocumentType.ARTICLES_OF_ASSOCIATION,
  [DocumentType.PARTNERSHIP_AGREEMENT]: DocumentType.PARTNERSHIP_AGREEMENT,
  [DocumentType.CHARTER_BY_LAWS]: DocumentType.CHARTER_BY_LAWS,
  [DocumentType.CERTIFICATE_OF_INCORPORATION]: DocumentType.CERTIFICATE_OF_INCORPORATION,
  [DocumentType.MEMORANDUM_OF_ASSOCIATION]: DocumentType.MEMORANDUM_OF_ASSOCIATION,
  [DocumentType.ACT_OF_FORMATION]: DocumentType.ACT_OF_FORMATION,
  [DocumentType.BUSINESS_REGISTRATION_CERTIFICATE]: DocumentType.BUSINESS_REGISTRATION_CERTIFICATE,
  [DocumentType.OWNERSHIP_STRUCTURE]: DocumentType.OWNERSHIP_STRUCTURE,
  [DocumentType.PROOF_OF_DIRECTOR_APPOINTMENT]: DocumentType.PROOF_OF_DIRECTOR_APPOINTMENT,
  [DocumentType.PROOF_OF_ADDRESS]: DocumentType.PROOF_OF_ADDRESS,
  [DocumentType.OTHER]: DocumentType.OTHER,
} as const;

export const LegalDocumentVerification: FC = () => {
  const { t } = useTranslation();

  const profileUid = useAppSelector(activeProfileUidSelector);

  const { data: uploadedDocuments } = useGetDocumentsByUidQuery({
    profileUid,
    body: { state: DocumentState.UPLOADED, pageNumber: 0, pageSize: 30 },
  });
  const [uploadDocument, { isLoading: isDocumentUploading }] = useUploadProfileDocumentMutation();

  // form state
  const {
    control,
    register,
    setValue,
    setError,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm<LegalDocumentVerificationFormFields>({
    defaultValues: {
      documents: [{ documentType: '', tempPath: '' }],
    },
    resolver: yupResolver(legalDocumentVerificationSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'documents',
  });

  const documents = watch('documents');
  const currentDocumentsArrayLength = documents.length;
  //

  const uploadDocuments: SubmitHandler<LegalDocumentVerificationFormFields> = async (values) => {
    for (const doc of values.documents) {
      try {
        await uploadDocument({
          profileUid,
          body: {
            documentType: doc.documentType as DocumentType,
            tempPath: doc.tempPath,
            comment: doc.comment,
          },
        }).unwrap();
      } catch (e) {
        getAsyncErrorMessage(e, setError);
      } finally {
        reset();
      }
    }
  };

  const verificationDocumentsOptions = Object.values(allowedVerificationDocuments).map((type) => {
    return (
      <SelectOption key={type} value={type} name={t(`common.document-type.${type}`)}>
        {t(`common.document-type.${type}`)}
      </SelectOption>
    );
  });

  return (
    <S.LegalDocumentVerificationContainer>
      <S.NeededDocuments>
        <S.NeededDocumentsTitle>{t('transfers.legal-documents.title')}</S.NeededDocumentsTitle>

        <S.NeededDocumentsList>
          <S.NeededDocumentsListItem>
            {t('transfers.legal-documents.docs.full-extract')}
          </S.NeededDocumentsListItem>
          <S.NeededDocumentsListItem>
            {t('transfers.legal-documents.docs.extract')}
          </S.NeededDocumentsListItem>
          <S.NeededDocumentsListItem>
            {t('transfers.legal-documents.docs.charter')}
          </S.NeededDocumentsListItem>
          <S.NeededDocumentsListItem>
            {t('transfers.legal-documents.docs.certificate-of-incorporation')}
          </S.NeededDocumentsListItem>
          <S.NeededDocumentsListItem>
            {t('transfers.legal-documents.docs.ownership-structure')}
          </S.NeededDocumentsListItem>
          <S.NeededDocumentsListItem>
            {t('transfers.legal-documents.docs.address-proof')}
          </S.NeededDocumentsListItem>
          <S.NeededDocumentsListItem>
            {t('transfers.legal-documents.docs.directors-appointment')}
          </S.NeededDocumentsListItem>
        </S.NeededDocumentsList>
      </S.NeededDocuments>

      {uploadedDocuments?.data.length !== 0 && (
        <S.UploadedDocuments>
          <S.UploadedDocumentsTitle>
            <IconDownload24 />
            {t('transfers.legal-documents.uploaded-docs.title')}
          </S.UploadedDocumentsTitle>

          <S.UploadedDocumentsList>
            {uploadedDocuments?.data.map((d) => {
              return (
                <S.UploadedDocumentsListItem key={d.documentUid}>
                  {t(`common.document-type.${d.documentType}`)}
                </S.UploadedDocumentsListItem>
              );
            })}
          </S.UploadedDocumentsList>
        </S.UploadedDocuments>
      )}

      <S.LegalDocumentsWrapper>
        <S.LegalDocumentsForm onSubmit={handleSubmit(uploadDocuments)}>
          {fields.map((field, index) => {
            return (
              <S.LegalDocumentsFieldWrapper
                key={field.id}
                $hasFieldError={Boolean(errors.documents && errors.documents[index]?.documentType)}
              >
                <Select
                  control={{ control, name: `documents.${index}.documentType` as const }}
                  errors={errors}
                  options={verificationDocumentsOptions}
                  label={t('transfers.legal-documents.form.fields.document-type')}
                />
                <InputFileUpload
                  inputProps={{
                    ...register(`documents.${index}.tempPath` as const),
                    errors,
                    label: t('transfers.legal-documents.form.fields.document-type'),
                  }}
                  dragAndDrop
                  fileCallback={(res) =>
                    setValue(`documents.${index}.tempPath` as const, res[0]!.path)
                  }
                  dragAndDropContainerClassName="custom-file-input-container"
                />
                {documents[index]?.documentType === 'OTHER' && (
                  <Input
                    {...register(`documents.${index}.comment`)}
                    errors={errors}
                    showErrorMessage={false}
                    label={t('transfers.legal-documents.form.fields.description')}
                  />
                )}

                <S.LegalDocumentsFormActions>
                  <S.ActionButton
                    className="add-btn"
                    icon={<IconPlus height={24} width={24} />}
                    onClick={() => append({ documentType: '', tempPath: '' })}
                    disabled={
                      uploadedDocuments?.data &&
                      uploadedDocuments.data.length + currentDocumentsArrayLength >=
                        maxDocumentsCount
                    }
                    tooltipTitle={t('transfers.legal-documents.form.add-field-btn-hint')}
                  />
                  <S.ActionButton
                    className="remove-btn"
                    icon={<IconTrash24 />}
                    onClick={() => remove(index)}
                    disabled={currentDocumentsArrayLength === 1}
                    tooltipTitle={t('transfers.legal-documents.form.remove-field-btn-hint')}
                  />
                </S.LegalDocumentsFormActions>
              </S.LegalDocumentsFieldWrapper>
            );
          })}

          <AsyncErrorMessage errors={errors} />

          <S.UploadDocumentsButton
            type="submit"
            startIcon={<IconDownload24 />}
            body={t('transfers.legal-documents.form.upload-documents-btn')}
            disabled={isDocumentUploading}
          />
        </S.LegalDocumentsForm>
      </S.LegalDocumentsWrapper>
    </S.LegalDocumentVerificationContainer>
  );
};
