import { FC, SVGProps } from 'react';

export const IconTrash16: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2 4H4M14 4H12M4 4V12C4 13.1046 4.89543 14 6 14H10C11.1046 14 12 13.1046 12 12V4M4 4H6M12 4H10M6 4V2C6 1.44772 6.44772 1 7 1H9C9.55228 1 10 1.44772 10 2V4M6 4H10"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
