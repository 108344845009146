import styled from 'styled-components';
import { IconButton as MuiIconButton } from '@mui/material';

export const IconButton = styled(MuiIconButton)`
  color: ${({ theme }) => theme.colors.typography.gray80};

  &.Mui-disabled {
    opacity: 0.4;
    color: ${({ theme }) => theme.colors.typography.gray80};
  }
`;

export const Icon = styled.img`
  max-width: 100%;
  object-fit: contain;
`;
