/* eslint-disable react-hooks/exhaustive-deps*/
import { FC, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';

import * as S from './styled';
import { ProfileCard } from './components';
import { Loader, ModalConfirmation, Error } from '../../components';
import { ProfileLayoutHeader } from '../../layouts';

import { getRouterError, useAppLoading } from '../../router';
import { ProfileLayoutContainer, ProfileLayoutContent } from '../../layouts/Profile/styled';
import {
  useLazyGetKycSyncProfilesQuery,
  usePutKycSyncMutation,
} from '../../api/controllers/kyc-sync';

export const KycSync: FC = () => {
  const { t } = useTranslation();
  const { sharingLogUid } = useParams<'sharingLogUid'>();
  const isAppLoading = useAppLoading();
  const [getKycProfiles, { data: kycProfiles, isLoading: isKycSyncLoading }] =
    useLazyGetKycSyncProfilesQuery();
  const [syncProfile, { isLoading: isSyncLoading }] = usePutKycSyncMutation();

  const [selectedProfile, setSelectedProfile] = useState<string>();

  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isConfirmModalShown, setConfirmModalShown] = useState(false);

  const showConfirmationModal = () => setConfirmModalShown(true);
  const closeConfirmationModal = () => setConfirmModalShown(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!sharingLogUid) {
          return;
        }
        await getKycProfiles({ sharingLogUid: sharingLogUid });
      } catch (e) {
        throw getRouterError(e as FetchBaseQueryError);
      }
    };

    fetchData();
  }, []);

  const chooseProfileHandler = async () => {
    try {
      if (!selectedProfile || !sharingLogUid) {
        return;
      }
      const url = await syncProfile({
        sharingLogUid: sharingLogUid,
        body: {
          profileUid: selectedProfile,
        },
      }).unwrap();
      closeConfirmationModal();
      window.location.href = url.redirectUrl;
    } catch (e) {
      closeConfirmationModal();
      setIsError(true);
      //@ts-ignore
      setErrorMessage(e?.data?.message);
    }
  };

  const handleSelectProfile = (profileUid: string) => {
    setSelectedProfile(profileUid);
    showConfirmationModal();
  };

  const profilesList = useMemo(() => {
    return kycProfiles?.map((profile) => {
      return (
        <ProfileCard key={profile.profileUid} profile={profile} onClick={handleSelectProfile} />
      );
    });
  }, [kycProfiles]);

  return (
    <>
      <ProfileLayoutContainer>
        <ProfileLayoutHeader />

        <ProfileLayoutContent>
          {isError ? (
            <Error
              message={errorMessage}
              actionButtonText={t('ui.errors.default-action-text')}
              actionButtonCallback={() => setIsError(false)}
            />
          ) : (
            <S.ProfilesContainer>
              <Helmet title={t('pages.kyc-sync')} />
              {isKycSyncLoading ? (
                <Loader variant="centerOfContainer" />
              ) : (
                <>
                  <S.KycSyncTitle>{t('kyc-sync.title')}</S.KycSyncTitle>
                  <S.KycSyncSubitle>{t('kyc-sync.subtitle')}</S.KycSyncSubitle>
                  {kycProfiles?.length ? (
                    <S.ProfilesList>{profilesList}</S.ProfilesList>
                  ) : (
                    <S.KycSyncLayoutDescription>
                      {t('kyc-sync.empty-profile')}
                    </S.KycSyncLayoutDescription>
                  )}
                </>
              )}

              {isAppLoading && <Loader variant="fullScreen" />}
            </S.ProfilesContainer>
          )}
        </ProfileLayoutContent>
      </ProfileLayoutContainer>

      <ModalConfirmation
        question={t('kyc-sync.modal.confirmation-title')}
        hintText={t('kyc-sync.modal.confirmation-question')}
        confirmButtonProps={{
          color: 'primary',
          body: t('kyc-sync.modal.confirm-btn'),
          loading: isSyncLoading,
        }}
        open={isConfirmModalShown}
        onCancel={() => closeConfirmationModal()}
        onConfirm={() => chooseProfileHandler()}
      />
    </>
  );
};
