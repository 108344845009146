import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from '../../styled';
import { Tabs, TabsProps } from '../../../../components';

import { useGetActiveCountriesQuery, useGetLegalCompanyTypesQuery } from '../../../../api';
import type { ExtendedRecipient } from '../../../../types';

type BankProps = {
  type: 'legal' | 'natural';
  recipient: ExtendedRecipient | undefined;
};

export const Bank: FC<BankProps> = ({ type, recipient }) => {
  const { t } = useTranslation();

  const { data: legalCompanyTypes } = useGetLegalCompanyTypesQuery();
  const { data: activeCountries } = useGetActiveCountriesQuery();

  const isLegal = type === 'legal';

  const [activeTab, setActiveTab] = useState(0);
  const tabChangeHandler: TabsProps['onChange'] = (e, newTab) => {
    setActiveTab(newTab);
  };

  // natural variables
  const naturalName = recipient?.additionalInfo?.infoDto?.middleName
    ? `${recipient?.additionalInfo?.infoDto?.firstName} ${recipient?.additionalInfo?.infoDto?.middleName} ${recipient?.additionalInfo?.infoDto?.lastName}`
    : recipient?.name;

  // legal variables
  const legalCompanyTypeId = recipient?.additionalInfo?.infoDto?.companyTypeId;
  const legalCompanyType = legalCompanyTypes?.find((t) => t.id === legalCompanyTypeId)?.name;
  const legalIncorporationCountryCode =
    recipient?.additionalInfo?.infoDto?.incorporationCountryCode;
  const legalIncorporationCountry = activeCountries?.find(
    (c) => c.alpha2Code === legalIncorporationCountryCode
  )?.name;

  // common variables
  const addressCountry = activeCountries?.find(
    (c) => c.alpha2Code === recipient?.addressDto?.country
  )?.name;

  const companyTab = (
    <S.RecipientInputContainer>
      <S.RecipientInput
        name="companyName"
        defaultValue={recipient?.additionalInfo?.infoDto?.name}
        label={t('recipient.fields.company-name')}
        nonEditable
      />
      <S.RecipientInput
        name="companyType"
        defaultValue={legalCompanyType}
        label={t('recipient.fields.company-type')}
        nonEditable
      />
      <S.RecipientInput
        name="incorporationCountry"
        defaultValue={legalIncorporationCountry}
        label={t('recipient.fields.incorporation-country')}
        nonEditable
      />
      <S.RecipientInput
        name="email"
        defaultValue={recipient?.email}
        label={t('recipient.fields.email')}
        nonEditable
      />
    </S.RecipientInputContainer>
  );

  const infoTab = (
    <S.RecipientInputContainer>
      <S.RecipientInput
        name="name"
        defaultValue={naturalName}
        label={t('recipient.fields.name')}
        nonEditable
      />
      <S.RecipientInput
        name="email"
        defaultValue={recipient?.email}
        label={t('recipient.fields.email')}
        nonEditable
      />
    </S.RecipientInputContainer>
  );

  const accountTab = (
    <S.RecipientInputContainer>
      <S.RecipientInput
        name="currency"
        defaultValue={recipient?.currencyCode}
        label={t('recipient.fields.currency')}
        nonEditable
      />
      <S.RecipientInput
        name="bankName"
        defaultValue={recipient?.additionalInfo?.bank_name}
        label={t('recipient.fields.bank-name')}
        nonEditable
      />
      <S.RecipientInput
        name="bankCode"
        defaultValue={recipient?.additionalInfo?.bank_code}
        label={t('recipient.fields.bank-code')}
        nonEditable
      />
      <S.RecipientInput
        name="account"
        defaultValue={recipient?.additionalInfo?.account}
        label={t('recipient.fields.account')}
        nonEditable
      />
    </S.RecipientInputContainer>
  );

  const addressTab = (
    <S.RecipientInputContainer>
      <S.RecipientInput
        name="country"
        defaultValue={addressCountry}
        label={t('recipient.fields.country')}
        nonEditable
      />
      {recipient?.addressDto?.state && (
        <S.RecipientInput
          name="state"
          defaultValue={recipient.addressDto.state}
          label={t('recipient.fields.state')}
          nonEditable
        />
      )}
      <S.RecipientInput
        name="zipCode"
        defaultValue={recipient?.addressDto?.zipCode}
        label={t('recipient.fields.zip-code')}
        nonEditable
      />
      <S.RecipientInput
        name="city"
        defaultValue={recipient?.addressDto?.city}
        label={t('recipient.fields.city')}
        nonEditable
      />
      <S.RecipientInput
        name="street"
        defaultValue={recipient?.addressDto?.street}
        label={t('recipient.fields.street')}
        nonEditable
      />
      <S.RecipientInput
        name="buildingNumber"
        defaultValue={recipient?.addressDto?.buildingNumber}
        label={t('recipient.fields.building-number')}
        nonEditable
      />
      {recipient?.addressDto?.flatNumber && (
        <S.RecipientInput
          name="flatNumber"
          defaultValue={recipient.addressDto.flatNumber}
          label={t('recipient.fields.flat-number')}
          nonEditable
        />
      )}
    </S.RecipientInputContainer>
  );

  return (
    <>
      <Tabs
        value={activeTab}
        onChange={tabChangeHandler}
        tabs={[
          {
            label: t(`recipient.tabs.${isLegal ? 'company' : 'info'}`),
            content: isLegal ? companyTab : infoTab,
          },
          { label: t('recipient.tabs.account'), content: accountTab },
          { label: t('recipient.tabs.address'), content: addressTab },
        ]}
      />
    </>
  );
};
