import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { Country } from '../../types';

type CountriesState = {
  countries: Country[];
};

const initialState: CountriesState = {
  countries: [],
};

export const country = createSlice({
  name: 'country',
  initialState,
  reducers: {
    setCountries: (state, action: PayloadAction<Country[]>) => {
      state.countries = action.payload;
    },
  },
});

export const countryActions = country.actions;
export const countryReducer = country.reducer;
