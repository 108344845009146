import { FC, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { skipToken } from '@reduxjs/toolkit/dist/query/react';

import * as S from './styled';
import { IconCopy, IconWarningHexagon16 } from '../../assets';
import { LineInfo, Modal, Select, SelectOption, Title } from '../../components';
import { TransferWallet } from '../Send/Wallet';

import { activeProfileUidSelector, countrySelector, useAppSelector } from '../../store';
import { useACL } from '../../services';
import {
  DepositMethodDTO,
  useGetCurrenciesTransferInQuery,
  useGetWalletsQuery,
  useLazyGetActiveCountriesTransferInQuery,
  useLazyGetCurrenciesTransferInQuery,
  useLazyGetWalletDepositMethodsQuery,
} from '../../api';
import { Wallet } from '../../types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { yup, Shape, referenceRegExp } from '../../forms';
import { WalletIBANForm } from '../../layouts';

type ReceiveFields = {
  countryCode: string;
  currencyCode: string;
  allowedTransferType: string;
};

const transferInfoSchema = yup.object().shape<Shape<ReceiveFields>>({
  countryCode: yup.string().required(),
  currencyCode: yup.string().required(),
  allowedTransferType: yup.string().required(),
});

export const Receive: FC = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const allCountries = useAppSelector(countrySelector);
  const hasPermission_WALLET_VIEW = useACL(['WALLET_VIEW']);
  const hasPermission_WALLET_DEPOSIT_METHODS_VIEW = useACL(['WALLET_DEPOSIT_METHODS_VIEW']);
  const hasPermission_ISSUE_IBAN = useACL(['ISSUE_IBAN']);

  const profileUid = useAppSelector(activeProfileUidSelector);

  const { data: walletsList } = useGetWalletsQuery(
    profileUid && hasPermission_WALLET_VIEW ? { profileUid } : skipToken
  );
  const [getDepositMethods, { data: depositMethods }] = useLazyGetWalletDepositMethodsQuery();
  const [getCountries, { data: countries }] = useLazyGetActiveCountriesTransferInQuery();
  const [getCurrencies, { data: currencies }] = useLazyGetCurrenciesTransferInQuery();

  const walletUidFromSideBar = location.state?.walletUid;
  const walletFromSideBar = walletsList?.find((w) => w.uid === walletUidFromSideBar);

  const [selectedWallet, setSelectedWallet] = useState<Wallet | undefined>(walletFromSideBar);
  const [allowedTransferTypes, setAllowedTransferTypes] = useState<string[]>([]);
  // IBAN modal state
  const [isIBANModalShown, setIBANModalShown] = useState(false);
  const showIBANForm = () => {
    setIBANModalShown(true);
  };
  const closeIBANForm = () => {
    setIBANModalShown(false);
  };
  const {
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm<ReceiveFields>({
    resolver: yupResolver(transferInfoSchema),
  });

  const currencyCode = watch('currencyCode');
  const countryCode = watch('countryCode');
  const allowedTransferType = watch('allowedTransferType');

  useEffect(() => {
    selectedWallet?.uid &&
      hasPermission_WALLET_DEPOSIT_METHODS_VIEW &&
      currencyCode &&
      countryCode &&
      getDepositMethods({
        walletUid: selectedWallet.uid,
        countryCode,
        currencyCode,
      });
    setValue('allowedTransferType', '');
  }, [selectedWallet, countryCode, currencyCode]);

  useEffect(() => {
    getCountries({ currency: undefined });
  }, []);

  useEffect(() => {
    if (countryCode) {
      setValue('currencyCode', '');
      setValue('allowedTransferType', '');
      getCurrencies({ country: countryCode });
    }
  }, [countryCode]);

  useEffect(() => {
    if (depositMethods?.length) {
      const tempAllowedTransferTypes = new Set<string>([]);

      depositMethods.forEach((it) => {
        it.allowedTransferTypes.forEach((type) => tempAllowedTransferTypes.add(type));
      });

      setAllowedTransferTypes(Array.from(tempAllowedTransferTypes));
    }
  }, [depositMethods]);

  const wallets = useMemo(() => {
    return walletsList?.map((wallet) => {
      return (
        <TransferWallet
          key={wallet.uid}
          wallet={wallet}
          selectedWallet={selectedWallet}
          onClick={() => selectWallet(wallet)}
        />
      );
    });
  }, [walletsList, selectedWallet]);

  const selectWallet = (wallet: Wallet) => {
    setSelectedWallet(wallet);
  };

  const handleCopyDetailsClick = (value?: DepositMethodDTO) => {
    if (value) {
      const { beneficiaryName, currencyCode, bankName, swiftCode, bankAddress, referenceCode } =
        value;

      const text = `${t('receive.beneficiaryName')}: ${beneficiaryName}\n${t(
        'receive.currencyCode'
      )}: ${currencyCode}\n${t('receive.bankName')}: ${bankName}\n${t(
        'receive.bankCode'
      )}: ${swiftCode}\n${t('receive.bankAddress')}: ${bankAddress}\n${t(
        'receive.referenceCode'
      )}: ${referenceCode}`;

      navigator.clipboard.writeText(text);
    }
  };

  const countriesOptions = useMemo(() => {
    if (countries) {
      return allCountries
        ?.filter((it) => countries.some((c) => c === it.alpha2Code))
        ?.map((type) => (
          <SelectOption key={type.alpha2Code} value={type.alpha2Code} name={type.name}>
            {type?.name}
          </SelectOption>
        ));
    }

    return [];
  }, [countries]);

  const currenciesOptions = useMemo(() => {
    if (currencies) {
      return currencies?.map((type) => {
        return (
          <SelectOption key={type} value={type} name={type}>
            {type}
          </SelectOption>
        );
      });
    }

    return [];
  }, [currencies]);

  const allowedTransferTypesOptions = useMemo(() => {
    if (allowedTransferTypes) {
      return allowedTransferTypes?.map((type) => {
        return (
          <SelectOption key={type} value={type} name={type}>
            {type}
          </SelectOption>
        );
      });
    }

    return [];
  }, [allowedTransferTypes]);

  return (
    <S.ReceiveContainer>
      <Helmet title={t('pages.receive')} />
      <Title text={t('receive.title')} />

      <S.ReceiveBody>
        <S.WalletContainer>
          {walletsList?.length ? (
            <>
              <S.SectionTitle className="bordered">{t('send.abfBalance')}</S.SectionTitle>
              <S.WalletCardContainer
                className={`${selectedWallet && 'limitedHeight'}`}
                style={{ minHeight: Number(wallets?.length) > 3 ? '230px' : 'none' }}
              >
                {wallets}
              </S.WalletCardContainer>
            </>
          ) : (
            <S.SectionTitle>{t('receive.noWallets')}</S.SectionTitle>
          )}
        </S.WalletContainer>
        <S.AmountContainer>
          {selectedWallet && (
            <Select
              control={{ control, name: 'countryCode' }}
              errors={errors}
              options={countriesOptions}
              label={t('receive.selectCountryCode')}
            />
          )}
          {countryCode && (
            <Select
              control={{ control, name: 'currencyCode' }}
              errors={errors}
              options={currenciesOptions}
              label={t('receive.selectCurrencyCode')}
            />
          )}
          {currencyCode && (
            <Select
              control={{ control, name: 'allowedTransferType' }}
              errors={errors}
              options={allowedTransferTypesOptions}
              label={t('receive.allowedTransferType')}
            />
          )}
        </S.AmountContainer>
        {selectedWallet && depositMethods && currencyCode && allowedTransferType && (
          <S.RequisiteInfo>
            <S.RequisiteTitle>{t('receive.bank-details')}</S.RequisiteTitle>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
              {depositMethods
                .filter((dep) =>
                  dep.allowedTransferTypes.some((type) => type === allowedTransferType)
                )
                .map((it, index) => {
                  return (
                    <div key={index}>
                      <S.RequisiteInfoItems>
                        {it.beneficiaryName && (
                          <LineInfo
                            label={t('receive.beneficiaryName')}
                            value={it.beneficiaryName}
                          />
                        )}

                        {it.currencyCode && (
                          <LineInfo label={t('receive.currencyCode')} value={it.currencyCode} />
                        )}

                        {it.bankName && (
                          <LineInfo label={t('receive.bankName')} value={it.bankName} />
                        )}

                        {it.iban && <LineInfo label={t('receive.iban')} value={it.iban} />}

                        {it.swiftCode && (
                          <LineInfo label={t('receive.swiftCode')} value={it.swiftCode} />
                        )}

                        {it.bankAddress && (
                          <LineInfo label={t('receive.bankAddress')} value={it.bankAddress} />
                        )}

                        {it.referenceCode && (
                          <LineInfo label={t('receive.referenceCode')} value={it.referenceCode} />
                        )}
                        {it.referenceCode && (
                          <S.AlertContainer>
                            <IconWarningHexagon16 width={'24px'} height={'24px'} />
                            {t('receive.request-iban-description')}
                          </S.AlertContainer>
                        )}
                      </S.RequisiteInfoItems>
                      <S.CopyDetailsButton
                        startIcon={<IconCopy />}
                        variant="contained"
                        body={t('receive.copy-details-btn')}
                        onClick={() => handleCopyDetailsClick(it)}
                      />
                    </div>
                  );
                })}
            </div>
            {selectedWallet?.canRequestIban && hasPermission_ISSUE_IBAN && (
              <S.CopyDetailsButton
                startIcon={<IconCopy />}
                variant="outlined"
                body={t('receive.request-iban-btn')}
                onClick={showIBANForm}
              />
            )}
          </S.RequisiteInfo>
        )}
      </S.ReceiveBody>

      <Modal
        modalTitle={t('dashboard-layout.wallet-bar.wallet-iban-form.title')}
        body={<WalletIBANForm wallet={selectedWallet} onCancel={() => closeIBANForm()} />}
        open={isIBANModalShown}
        onClose={() => closeIBANForm()}
        customPaperRootCss={{ width: '640px' }}
      />
    </S.ReceiveContainer>
  );
};
