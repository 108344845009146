import { FC, ReactNode } from 'react';

import * as S from './styled';

type TabPanelProps = {
  children?: ReactNode;
  index: number;
  activeTab: number;
  ariaName?: string;
};

const a11yProps = (ariaName: TabPanelProps['ariaName'], index: number) => {
  if (!ariaName) return {};

  return {
    id: `${ariaName}-tabpanel-${index}`,
    'aria-labelledby': `${ariaName}-tab-${index}`,
  };
};

export const TabPanel: FC<TabPanelProps> = (props) => {
  const { children, index, activeTab, ariaName, ...rest } = props;

  return (
    <S.TabPanel
      hidden={activeTab !== index}
      role="tabpanel"
      {...a11yProps(ariaName, index)}
      {...rest}
    >
      {activeTab === index && children}
    </S.TabPanel>
  );
};
