import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import {
  Input,
  Select,
  useSelectOptions,
  useSelectOptionsByStringArray,
} from '../../../../../../components';

import { sortArrayByPropAsc } from '../../../../../../utils';
import { GetActiveCountriesResult, useGetCurrenciesTransferOutQuery } from '../../../../../../api';
import type { AddBankAccountFormFields } from '../../../../Recipients';

type DetailsStepProps = {
  activeCountries: GetActiveCountriesResult | undefined;
};

export const DetailsStep: FC<DetailsStepProps> = ({ activeCountries }) => {
  const { t } = useTranslation();

  const { data: coins } = useGetCurrenciesTransferOutQuery({ country: undefined });

  const {
    control,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext<AddBankAccountFormFields>();
  const account = watch('account');
  const coinsOptions = useSelectOptionsByStringArray(coins);

  const activeCountriesOptions = useSelectOptions(
    sortArrayByPropAsc(activeCountries, 'name'),
    'alpha2Code',
    'name'
  );

  useEffect(() => {
    if (account?.length > 1) {
      const alpha2Code = account.substring(0, 2);
      const activeCountry = activeCountries?.find(
        (country) => country.alpha2Code.toUpperCase() === alpha2Code.toUpperCase()
      );

      if (activeCountry) {
        setValue('countryCode', activeCountry.alpha2Code);
      }
    }
  }, [account]);

  return (
    <>
      <Select
        control={{ control, name: 'currencyCode' }}
        errors={errors}
        options={coinsOptions}
        label={t('recipients.header.actions.add-recipient.form.bank.fields.currency.label')}
      />
      <Input
        {...register('bankName')}
        errors={errors}
        label={t('recipients.header.actions.add-recipient.form.bank.fields.bank-name.label')}
      />
      <Input
        {...register('bankCode')}
        errors={errors}
        label={t('recipients.header.actions.add-recipient.form.bank.fields.bank-code.label')}
      />
      <Input
        {...register('account')}
        errors={errors}
        label={t('recipients.header.actions.add-recipient.form.bank.fields.account-number.label')}
      />
      <Select
        control={{ control, name: 'countryCode' }}
        errors={errors}
        options={activeCountriesOptions}
        label={t('recipients.header.actions.add-recipient.form.bank.fields.country-code.label')}
      />
    </>
  );
};
