import { useMemo } from 'react';

import { SelectOption } from '../components';
import { mapDataToSelect } from './utils';
import type { SelectName, SelectValue } from '../types';

/**
 * Custom hook, which returns array of Select options, without custom settings.
 *
 * Need to choose two keys of entity - the first for 'name', and the second for 'value' of Select option.
 *
 * For generic type, need to pass the type of entity.
 * @param dataArray - array of objects, with some two keys, which values need to pass in Select options
 * @param keyToValue - first key, which value will be the value of Select option
 * @param keyToName - second key, which value will be the name of Select option
 */
export const useSelectOptions = <T,>(
  dataArray: T[] = [],
  keyToValue: keyof T,
  keyToName: keyof T
) => {
  return useMemo(() => {
    return mapDataToSelect(dataArray, keyToValue, keyToName).map((it) => {
      return (
        <SelectOption
          key={it.value as SelectValue}
          value={it.value as SelectValue}
          name={it.name as SelectName}
        >
          {it.name as SelectName}
        </SelectOption>
      );
    });
  }, [dataArray, keyToName, keyToValue]);
};

export const useSelectOptionsByStringArray = <T,>(dataArray: string[] = []) => {
  return useMemo(() => {
    return dataArray.map((it) => {
      return (
        <SelectOption key={it as SelectValue} value={it as SelectValue} name={it as SelectName}>
          {it as SelectName}
        </SelectOption>
      );
    });
  }, [dataArray]);
};
