import styled, { css } from 'styled-components';
import { LoadingButton } from '@mui/lab';

import type { ButtonIconSize } from './types';

export const Button = styled(LoadingButton)`
  font-weight: 400;
  line-height: 100%;
  text-transform: none;
  transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  // when button disabled
  &.Mui-disabled {
    opacity: 0.4;
  }

  // start icon in button
  .MuiButton-startIcon {
    margin-left: 0;
  }

  // end icon in button
  .MuiButton-endIcon {
    margin-right: 0;
  }

  // end and start icons when loading=true
  .MuiLoadingButton-endIconLoadingEnd,
  .MuiLoadingButton-startIconLoadingStart {
    opacity: 1;
  }

  // end and start icons when loading=true + loadingPosition=center
  .MuiLoadingButton-endIconLoadingCenter,
  .MuiLoadingButton-startIconLoadingCenter {
    img,
    svg {
      opacity: 0;
    }
  }

  // loading indicator at start
  .MuiLoadingButton-loadingIndicatorStart {
    order: -2;
    position: static;
    left: 0;
    margin-right: 8px;
  }

  // loading indicator at end
  .MuiLoadingButton-loadingIndicatorEnd {
    order: 1;
    position: static;
    right: 0;
    margin-left: 8px;
  }
`;

export const Icon = styled.img<{ $iconSize: ButtonIconSize }>`
  ${({ $iconSize }) => {
    switch ($iconSize) {
      case 'small':
        return css`
          height: 16px;
          width: 16px;
        `;
      case 'medium':
        return css`
          height: 24px;
          width: 24px;
        `;
    }
  }};

  max-width: 100%;
  object-fit: contain;
`;
