import styled, { css } from 'styled-components';

import { Button } from '../Button';
import type { FilterButtonSize } from './types';

export const FilterButton = styled(Button)<{ $size: FilterButtonSize }>`
  ${({ $size }) => {
    switch ($size) {
      case 'small':
        return css`
          height: 32px;
        `;
      case 'medium':
        return css`
          height: 40px;
        `;
    }
  }};

  min-width: 102px;
`;
