import { FC } from 'react';
import { TabProps as MuiTabProps } from '@mui/material';

import * as S from './styled';

export type TabProps = MuiTabProps;

export const Tab: FC<TabProps> = (props) => {
  return <S.Tab {...props} />;
};
