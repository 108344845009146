import * as yup from 'yup';
import { string, StringSchema } from 'yup';

yup.setLocale({
  mixed: {
    required: 'ui.forms.error-messages.required-field',
    notType: 'ui.forms.error-messages.type-error',
  },
  boolean: {},
  date: {
    min: ({ min }) => ({ key: 'ui.forms.error-messages.min-date', value: min, isDate: true }),
    max: ({ max }) => ({ key: 'ui.forms.error-messages.max-date', value: max, isDate: true }),
  },
  number: {
    min: ({ min }) => ({ key: 'ui.forms.error-messages.min-number', value: min }),
  },
  string: {
    email: 'ui.forms.error-messages.valid-email',
    matches: 'ui.forms.error-messages.reg-exp-doesnt-match',
    max: ({ max }) => ({ key: 'ui.forms.error-messages.max-string', value: max }),
  },
  object: {},
});

yup.addMethod<StringSchema>(string, 'emailWithoutPlus', function () {
  return this.test(
    'emailWithoutPlus-test',
    'ui.forms.error-messages.valid-email',
    function (value) {
      const { path, createError } = this;
      if (value === undefined) return false;

      const isContainAt = value.includes('@');
      const isContainPlus = value.includes('+');
      const isValid = !isContainPlus && isContainAt;

      return isValid || createError({ path, message: 'ui.forms.error-messages.valid-email' });
    }
  );
});

export { yup };
