import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import * as S from './styled';
import { DetailsStep, GeneralStep } from './steps';

import {
  AsyncErrorMessage,
  getAsyncErrorMessage,
  Shape,
  uboNameAndSurnameRegExp,
  useFormSteps,
  yup,
} from '../../../../forms';
import { CreateLegalProfileDTO, useCreateLegalProfileMutation } from '../../../../api';

type AddBusinessAccountFormProps = {
  onCancel: () => void;
  showHint: () => void;
};
export type AddBusinessAccountFormFields = CreateLegalProfileDTO;
type AddBusinessAccountSteps = 'general' | 'details';

const addBusinessAccountSchema: Record<AddBusinessAccountSteps, any> = {
  general: yup.object().shape<Shape<AddBusinessAccountFormFields>>({
    companyName: yup.string().matches(uboNameAndSurnameRegExp).required(),
    companyTypeId: yup.string().required(),
    country: yup.string().required(),
    registrationNumber: yup.string().required(),
    phone: yup.string().required(),
    email: yup.string().email().required(),
    website: yup.string().required(),
  }),
  details: yup.object().shape<Shape<AddBusinessAccountFormFields>>({
    relationType: yup.string().required(),
    documentType: yup.string().required(),
    documentTempPath: yup.string().required(),
    industryCodeId: yup.number().required(),
    description: yup.string().max(16384).required(),
    purpose: yup.string().max(16384).required(),
  }),
};

export const AddBusinessAccountForm: FC<AddBusinessAccountFormProps> = ({ onCancel, showHint }) => {
  const { t } = useTranslation();

  const [createLegalProfile, { isLoading: isCreateLegalLoading }] = useCreateLegalProfileMutation();

  // form steps data
  const stepsOrder: AddBusinessAccountSteps[] = ['general', 'details'];
  const {
    activeStep: _activeStep,
    isFirstStep,
    isLastStep,
    getNextStep,
    getPrevStep,
  } = useFormSteps(stepsOrder);
  const activeStep = _activeStep as AddBusinessAccountSteps;

  // form data
  const formMethods = useForm<AddBusinessAccountFormFields>({
    resolver: yupResolver(addBusinessAccountSchema[activeStep]),
    shouldUnregister: false,
    mode: 'onChange',
  });
  const {
    setError,
    handleSubmit,
    trigger,
    formState: { errors },
  } = formMethods;

  const closeForm = () => {
    onCancel();
  };

  const showNextStep = async () => {
    const isStepValid = await trigger();
    if (isStepValid) getNextStep();
  };

  const showPrevStep = () => {
    getPrevStep();
  };

  const createBusinessAccount: SubmitHandler<AddBusinessAccountFormFields> = async (values) => {
    try {
      await createLegalProfile(values).unwrap();
      closeForm();
      showHint();
    } catch (e) {
      getAsyncErrorMessage(e, setError);
    }
  };

  const stepsMap: Record<AddBusinessAccountSteps, ReturnType<FC>> = {
    general: <GeneralStep />,
    details: <DetailsStep />,
  };

  return (
    <FormProvider {...formMethods}>
      <S.AddBusinessAccountForm onSubmit={handleSubmit(createBusinessAccount)}>
        <S.AddBusinessAccountFormFields>
          {stepsMap[activeStep]}

          <AsyncErrorMessage errors={errors} />
        </S.AddBusinessAccountFormFields>

        <S.AddBusinessAccountFormActions>
          <S.AddBusinessAccountPrevStepButton
            variant="contained"
            body={t('ui.forms.buttons.prev-step')}
            disabled={isFirstStep}
            onClick={() => showPrevStep()}
          />

          {!isLastStep && (
            <S.AddBusinessAccountNextStepButton
              body={t('ui.forms.buttons.next-step')}
              onClick={() => showNextStep()}
            />
          )}

          {isLastStep && (
            <S.AddBusinessAccount
              body={t('dashboard-layout.create-legal-form.create-legal-btn')}
              type="submit"
              loading={isCreateLegalLoading}
            />
          )}
        </S.AddBusinessAccountFormActions>
      </S.AddBusinessAccountForm>
    </FormProvider>
  );
};
