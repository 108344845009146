import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from '../../styled';
import type { ExtendedRecipient } from '../../../../types';

type CryptoProps = {
  recipient: ExtendedRecipient | undefined;
};

export const Crypto: FC<CryptoProps> = ({ recipient }) => {
  const { t } = useTranslation();

  return (
    <S.RecipientInputContainer>
      {recipient?.email && (
        <S.RecipientInput
          name="email"
          defaultValue={recipient.email}
          label={t('recipient.fields.email')}
          nonEditable
        />
      )}
      <S.RecipientInput
        name="name"
        defaultValue={recipient?.name}
        label={t('recipient.fields.name')}
        nonEditable
      />
      <S.RecipientInput
        name="coinName"
        defaultValue={recipient?.currencyCode}
        label={t('recipient.fields.coin-name')}
        nonEditable
      />
      <S.RecipientInput
        name="address"
        defaultValue={recipient?.additionalInfo.address}
        label={t('recipient.fields.address')}
        nonEditable
      />
    </S.RecipientInputContainer>
  );
};
