import { apiSlice } from '../../api';
import { ProfileType } from '../../../types';
import type {
  GetLegalCompanyTypesResult,
  GetLegalIndustryCodesResult,
  GetLegalProfileDTO,
  GetLegalProfileResult,
  PostLegalSearchDTO,
  PostLegalSearchResult,
} from './types';

export const legalEndpoints = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getLegalProfile: build.query<GetLegalProfileResult, GetLegalProfileDTO>({
      query: ({ profileUid }) => ({
        url: `/profiles-service/v1/legals/${profileUid}`,
        method: 'GET',
      }),
      transformResponse: (response: GetLegalProfileResult) => ({
        ...response,
        profileType: ProfileType.LEGAL,
      }),
      providesTags: ['LegalProfile'],
    }),
    getLegalCompanyTypes: build.query<GetLegalCompanyTypesResult, void>({
      query: () => ({
        url: '/profiles-service/v1/legals/company-types',
        method: 'GET',
      }),
    }),
    getLegalIndustryCodes: build.query<GetLegalIndustryCodesResult, void>({
      query: () => ({
        url: '/profiles-service/v1/legals/industry-codes',
        method: 'GET',
      }),
      transformResponse: (response: GetLegalIndustryCodesResult) => {
        return response.map((code) => {
          // remove codes in name
          return {
            ...code,
            name: code.name.replace(/^[^-\n–]*\s?[–-]?\s?/, ''),
          };
        });
      },
    }),
    searchLegal: build.mutation<PostLegalSearchResult, PostLegalSearchDTO>({
      query: ({ pattern }) => ({
        url: '/profiles-service/v1/legals/search',
        method: 'POST',
        body: { pattern },
      }),
    }),
  }),
});

export const { useGetLegalProfileQuery } = legalEndpoints;
export const { useGetLegalCompanyTypesQuery } = legalEndpoints;
export const { useGetLegalIndustryCodesQuery } = legalEndpoints;
export const { useSearchLegalMutation } = legalEndpoints;

export type LegalController = typeof legalEndpoints;
