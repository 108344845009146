import styled, { css } from 'styled-components';

import { IconButton, TextArea } from '../../../../components';
import { getMediaFor } from '../../../../layouts';

export const TicketChatContainer = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.bg.white05};
  overflow: hidden;
`;

export const TicketChatHeader = styled.div`
  padding: 24px;
  background-color: ${({ theme }) => theme.colors.bg.white05};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.white05};

  font-weight: 400;
  font-size: 2rem;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.typography.white};

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      padding: 16px;
    `)};
`;

export const TicketChatBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  min-height: 352px;
  max-height: 520px;

  padding: 24px;
  overflow: auto;

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      padding: 16px;
    `)};
`;

export const TicketChatMessage = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  max-width: 320px;
  padding: 16px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.bg.white05};

  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 150%;
  color: ${({ theme }) => theme.colors.typography.gray88};
  white-space: pre-wrap;

  &.ticket-chat-message-support {
    align-self: flex-start;
  }
  &.ticket-chat-message-user {
    align-self: flex-end;
  }
  .ticket-chat-message-title {
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 100%;
  }
`;

export const TicketChatFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  padding: 24px;
  background-color: ${({ theme }) => theme.colors.bg.white05};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.white05};

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      padding: 16px;
    `)};
`;

export const TicketChatForm = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
`;

export const TicketChatTextField = styled(TextArea)`
  height: 48px;
  padding: 14px 16px;

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      padding: 6px 12px;
    `)};
`;

export const SendTicketMessageButton = styled(IconButton)`
  padding: 10px;
  border: 2px solid ${({ theme }) => theme.colors.primary};
  border-radius: 8px;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.typography.gray88};

  .MuiTouchRipple-child {
    border-radius: 8px;
  }
`;

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
`;

export const MessageDate = styled.div`
  color: ${({ theme }) => theme.colors.typography.gray88};
  font-size: 1.2rem;
  line-height: 100%;
`;
