import { apiSlice } from '../../api';
import type {
  CancelTransferDTO,
  CancelTransferResult,
  CreateExchangeTransferDTO,
  CreateExchangeTransferResult,
  CreateTransferDTO,
  CreateTransferResult,
  DownloadTransfersCSVDTO,
  DownloadTransfersCSVResult,
  ExecuteTransferDTO,
  ExecuteTransferResult,
  GetTransferByTransferUidDTO,
  GetTransferByTransferUidResult,
  GetTransferDocumentsDTO,
  GetTransferDocumentsResult,
  GetTransferStatementDTO,
  GetTransferStatementResult,
  GetTransfersByProfileUidDTO,
  GetTransfersByProfileUidResult,
  PostTransferUploadDocumentsDTO,
  PostTransferUploadDocumentsResult,
  TransferPrecheckDTO,
  TransferPrecheckResult,
} from './types';

export const transferEndpoints = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    createTransfer: build.mutation<CreateTransferResult, CreateTransferDTO>({
      query: ({ walletUid, body }) => ({
        url: `/wallets-service/v1/wallets/${walletUid}/transfers`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Wallets'],
    }),
    createExchangeTransfer: build.mutation<CreateExchangeTransferResult, CreateExchangeTransferDTO>(
      {
        query: ({ walletUid, body }) => ({
          url: `/wallets-service/v1/wallets/${walletUid}/transfers/exchange`,
          method: 'POST',
          body,
        }),
        invalidatesTags: ['Wallets'],
      }
    ),
    executeTransfer: build.mutation<ExecuteTransferResult, ExecuteTransferDTO>({
      query: ({ profileUid, transferUid, body }) => ({
        url: `/wallets-service/v2/profiles/${profileUid}/transfers/${transferUid}/execute`,
        method: 'POST',
        headers: {
          'MFA-type': body.passwordType,
          'MFA-code': body.password,
        },
      }),
      invalidatesTags: ['Wallets'],
    }),
    cancelTransfer: build.mutation<CancelTransferResult, CancelTransferDTO>({
      query: ({ profileUid, transferUid }) => ({
        url: `/wallets-service/v1/profiles/${profileUid}/transfers/${transferUid}/cancel`,
        method: 'POST',
      }),
      invalidatesTags: ['Wallets'],
    }),
    transferPrecheck: build.mutation<TransferPrecheckResult, TransferPrecheckDTO>({
      query: (body) => ({
        url: '/aml-service/v1/aml/transfer-precheck',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Wallets'],
    }),
    downloadTransfersCSV: build.mutation<DownloadTransfersCSVResult, DownloadTransfersCSVDTO>({
      query: ({ profileUid, body }) => ({
        url: `/wallets-service/v1/profiles/${profileUid}/transfers/csv`,
        method: 'POST',
        body,
      }),
    }),
    getTransfersByProfileUid: build.query<
      GetTransfersByProfileUidResult,
      GetTransfersByProfileUidDTO
    >({
      query: ({ profileUid, body }) => ({
        url: `/wallets-service/v1/profiles/${profileUid}/transfers/filter`,
        method: 'POST',
        body,
      }),
      providesTags: ['Transfer'],
    }),
    getTransferByTransferUid: build.query<
      GetTransferByTransferUidResult,
      GetTransferByTransferUidDTO
    >({
      query: ({ profileUid, transferUid }) => ({
        url: `/wallets-service/v1/profiles/${profileUid}/transfers/${transferUid}`,
        method: 'GET',
      }),
    }),
    getTransferStatement: build.query<GetTransferStatementResult, GetTransferStatementDTO>({
      query: ({ transferUid }) => ({
        url: `/wallets-service/v1/transfers/${transferUid}/statement`,
        method: 'GET',
      }),
    }),

    // TODO: move `postTransferUploadDocuments` and `getTransferDocuments` requests to separate controller
    postTransferUploadDocuments: build.mutation<
      PostTransferUploadDocumentsResult,
      PostTransferUploadDocumentsDTO
    >({
      query: ({ profileUid, transferUid, transferDocumentRequestDtoWA }) => ({
        url: `/wallets-service/v1/profiles/${profileUid}/transfers/${transferUid}/documents`,
        method: 'POST',
        body: transferDocumentRequestDtoWA,
      }),
    }),
    getTransferDocuments: build.query<GetTransferDocumentsResult, GetTransferDocumentsDTO>({
      query: ({ profileUid, transferUid }) => ({
        url: `/wallets-service/v1/profiles/${profileUid}/transfers/${transferUid}/documents`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useCreateTransferMutation } = transferEndpoints;
export const { useCreateExchangeTransferMutation } = transferEndpoints;
export const { useExecuteTransferMutation } = transferEndpoints;
export const { useCancelTransferMutation } = transferEndpoints;
export const { useTransferPrecheckMutation } = transferEndpoints;
export const { useDownloadTransfersCSVMutation } = transferEndpoints;
export const { useLazyGetTransfersByProfileUidQuery } = transferEndpoints;
export const { useLazyGetTransferStatementQuery, useGetTransferStatementQuery } = transferEndpoints;
export const { useGetTransferByTransferUidQuery, useLazyGetTransferByTransferUidQuery } =
  transferEndpoints;

export const { usePostTransferUploadDocumentsMutation, useGetTransferDocumentsQuery } =
  transferEndpoints;

export type TransferController = typeof transferEndpoints;
