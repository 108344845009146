import styled from 'styled-components';

import { Button } from '../../../../../../components';

export const WalletSettingsForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const WalletSettingsFormActions = styled.div`
  display: flex;
  gap: 16px;
`;

export const CancelWalletSettingsButton = styled(Button)`
  min-width: 106px;
`;

export const AcceptWalletSettingsButton = styled(Button)`
  flex-grow: 1;
`;
