import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import * as S from './styled';
import { ProfileLayoutHeader } from './components';

export const Profile: FC = () => {
  return (
    <S.ProfileLayoutContainer>
      <ProfileLayoutHeader />

      <S.ProfileLayoutContent>
        <Outlet />
      </S.ProfileLayoutContent>
    </S.ProfileLayoutContainer>
  );
};
