import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';

import * as S from './styled';
import { Button, DatePicker, InputFileUpload, Select, SelectOption } from '../../../../components';

import { UploadProfileDocumentDTO } from '../../../../api';
import { AsyncErrorMessage, Shape, yup } from '../../../../forms';
import { DocumentType, NaturalIdDocumentType } from '../../../../types';

export type UpdateDocumentFormFields = UploadProfileDocumentDTO['body'];
type UpdateDocumentFormProps = {
  onCancel: () => void;
  onUpdate: (item: UpdateDocumentFormFields) => void;
  typeForm: 'address-form' | 'personal-info-form';
  isLoading: boolean;
};

const allowedNaturalAddressDocumentType: Partial<Record<DocumentType, DocumentType>> = {
  [DocumentType.UTILITY_BILL]: DocumentType.UTILITY_BILL,
  [DocumentType.PROOF_OF_ADDRESS]: DocumentType.PROOF_OF_ADDRESS,
} as const;

export const UpdateDocumentForm: FC<UpdateDocumentFormProps> = ({
  onCancel,
  onUpdate,
  typeForm,
  isLoading,
}) => {
  const { t } = useTranslation();

  const updateDocumentSchema = yup.object().shape<Shape<UpdateDocumentFormFields>>({
    documentType: yup.string().required(),
    tempPath: yup.string(),
    expiredAt: typeForm === 'personal-info-form' ? yup.string().required() : yup.string(),
  });

  const {
    control,
    register,
    setValue,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<UpdateDocumentFormFields>({
    resolver: yupResolver(updateDocumentSchema),
    defaultValues: { documentType: typeForm === 'address-form' ? 'UTILITY_BILL' : 'PASSPORT' },
  });

  const documentAddressTypeOptions = Object.values(allowedNaturalAddressDocumentType).map(
    (type) => {
      return (
        <SelectOption key={type} value={type} name={t(`common.document-type.${type}`)}>
          {t(`common.document-type.${type}`)}
        </SelectOption>
      );
    }
  );

  const documentProfileTypeOptions = Object.values(NaturalIdDocumentType).map((type) => {
    return (
      <SelectOption key={type} value={type} name={t(`common.id-document-type.${type}`)}>
        {t(`common.id-document-type.${type}`)}
      </SelectOption>
    );
  });

  return (
    <S.AddTicketForm onSubmit={handleSubmit(onUpdate)}>
      <S.AddTicketFormFields>
        <S.Description>{t(`profile.${typeForm}.form.description`)}</S.Description>
        <Select
          control={{ control, name: 'documentType' }}
          errors={errors}
          options={
            typeForm == 'address-form' ? documentAddressTypeOptions : documentProfileTypeOptions
          }
          label={t('profile.profile-form-common.document-type')}
        />

        {typeForm == 'personal-info-form' && (
          <DatePicker
            control={{ control, name: 'expiredAt' }}
            label={t('profile.profile-form-common.expired-at')}
            errors={errors}
            disablePast
          />
        )}

        <InputFileUpload
          inputProps={{
            ...register('tempPath'),
            errors,
            // label: t('profile.profile-form-common.label'),
          }}
          dragAndDrop
          fileCallback={(res) => setValue('tempPath', res[0]!.path)}
        />

        <AsyncErrorMessage errors={errors} />
      </S.AddTicketFormFields>
      <S.ButtonContainer>
        <Button
          body={t('profile.profile-form-common.cancel')}
          onClick={onCancel}
          variant="contained"
          loading={isLoading}
        />
        <Button body={t('profile.profile-form-common.update')} type="submit" loading={isLoading} />
      </S.ButtonContainer>
    </S.AddTicketForm>
  );
};
