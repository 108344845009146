import styled from 'styled-components';
import { Button } from '../../../../../components';

export const ProfileFormFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;

  padding-top: 8px;
`;

export const FormCancelButton = styled(Button)`
  width: 106px;
`;
export const FormSendRequestButton = styled(Button)`
  width: 166px;
`;
