import { FC, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { LoaderFunction, redirect, useNavigate, useParams } from 'react-router-dom';
import { FetchBaseQueryError, skipToken } from '@reduxjs/toolkit/dist/query/react';

import * as S from './styled';
import { Button, IconButton, ModalConfirmation, Title } from '../../components';
import { AddAttachmentProps, TicketChat, TicketInfo } from './components';
import { IconArrowPrevious16, IconDownload24 } from '../../assets';

import {
  commentsEndpoints,
  GetCommentsByTicketIdDTO,
  GetTicketAttachmentsDTO,
  GetTicketByIdDTO,
  runEndpointInRouter,
  ticketsEndpoints,
  useGetCommentsByTicketIdQuery,
  useGetTicketAttachmentsByIdQuery,
  useGetTicketByIdQuery,
  useLazyGetCommentsByTicketIdQuery,
  usePostTicketAttachmentsByIdMutation,
  useUpdateTicketMutation,
} from '../../api';
import { useACL } from '../../services';
import { authGuard, getRouterError, RouterPaths } from '../../router';
import { TicketState } from '../../types';
import { messageActions, newMessageSelector, useAppActions, useAppSelector } from '../../store';

// TODO: add infinity scroll to chat comments, remove `pageSize: 1000`

export const ticketLoader: LoaderFunction = async ({ params, request }) => {
  authGuard();

  const ticketId = params.ticketId;

  try {
    await runEndpointInRouter(ticketsEndpoints, 'getTicketById', { ticketId } as GetTicketByIdDTO);
    await runEndpointInRouter(commentsEndpoints, 'getCommentsByTicketId', {
      ticketId,
      params: { pageNumber: 0, pageSize: 1000 },
    } as GetCommentsByTicketIdDTO);

    await runEndpointInRouter(ticketsEndpoints, 'getTicketAttachmentsById', {
      ticketId,
    } as GetTicketAttachmentsDTO);
    const isChangingProfile = new URLSearchParams(new URL(request.url).search).has('changeProfile');

    if (isChangingProfile) {
      return redirect(`${new URL(request.url).origin}${new URL(request.url).pathname}`);
    }
  } catch (e) {
    throw getRouterError(e as FetchBaseQueryError);
  }

  return null;
};

export const createDownloadFileLink = (token: string) => {
  return `${process.env.REACT_APP_API_BASE_URL}/storage-service/v1/documents/download/${token}?rt=${process.env.REACT_APP_RESOURCE}`;
};

export const Ticket: FC = () => {
  const { t } = useTranslation();
  const { ticketId } = useParams<{ ticketId: string }>();
  const navigate = useNavigate();
  const hasPermission_STORAGE_DOWNLOAD = useACL(['STORAGE_DOWNLOAD']);

  const { data: ticket } = useGetTicketByIdQuery(ticketId ? { ticketId } : skipToken);
  const [getComments, { data: comments }] = useLazyGetCommentsByTicketIdQuery();
  const [updateTicket, { isLoading: isUpdateTicketLoading }] = useUpdateTicketMutation();

  const { data: ticketAttachments } = useGetTicketAttachmentsByIdQuery(
    ticketId ? { ticketId } : skipToken
  );
  const [uploadDocument] = usePostTicketAttachmentsByIdMutation();
  const { setChatMessage, updateMessageList } = useAppActions(messageActions);
  const [openedDocument, setOpenedDocument] = useState('');
  const isClosed = Boolean(ticket?.ticketState === TicketState.CLOSED);
  const newMessage = useAppSelector(newMessageSelector);

  // close confirmation modal state
  const [isCloseModalShown, setCloseModalShown] = useState(false);
  const showConfirmationModal = () => setCloseModalShown(true);
  const closeConfirmationModal = () => setCloseModalShown(false);

  const backToTickets = () => {
    navigate(RouterPaths.Tickets);
  };

  const uploadAttachment = (item: AddAttachmentProps) => {
    if (!ticketId) return;

    uploadDocument({
      ticketId,
      body: {
        name: item.fileName,
        tempPath: item.tempPath,
      },
    });
  };
  useEffect(() => {
    if (ticketId) {
      getComments({ ticketId, params: { pageNumber: 0, pageSize: 1000 } });
    }
  }, []);
  useEffect(() => {
    if (comments) {
      setChatMessage(comments.data);
    }
  }, [comments]);

  useEffect(() => {
    if (newMessage && ticketId) {
      getComments({ ticketId, params: { pageNumber: 0, pageSize: 1000 } });
    }
  }, [newMessage]);

  const closeTicket = async () => {
    if (!ticket) return;
    if (isClosed) return;

    const { id, ticketType, language } = ticket;

    try {
      await updateTicket({
        ticketId: String(id),
        body: { ticketState: TicketState.CLOSED, ticketType, language },
      }).unwrap();
      closeConfirmationModal();
    } catch {}
  };

  const downloadFile = (token: string) => {
    if (!token) {
      return;
    }
    location.href = createDownloadFileLink(token);
  };

  const documents = useMemo(() => {
    return ticketAttachments?.map((it, index) => {
      // downloadFile(it.fileReference);
      return (
        <S.AttachmentContainer
          className={`${openedDocument === it.token ? 'show' : ''}`}
          key={index}
          onClick={() => setOpenedDocument(it.token)}
        >
          <S.AttachmentTitleContainer className={`${openedDocument === it.token ? 'show' : ''}`}>
            <S.AttachmentTitle>{`${t('ticket.info.document')} ${index + 1}`}</S.AttachmentTitle>
            {openedDocument === it.token ? (
              <S.DownloadButton
                icon={<IconDownload24 />}
                onClick={() => downloadFile(it.token)}
                disabled={!hasPermission_STORAGE_DOWNLOAD}
              />
            ) : (
              <IconDownload24 />
            )}
          </S.AttachmentTitleContainer>
          <S.AttachmentComment className={`${openedDocument === it.token ? 'show' : ''}`}>
            {it.comment}
          </S.AttachmentComment>
        </S.AttachmentContainer>
      );
    });
  }, [ticketAttachments, openedDocument]);

  return (
    <>
      <S.TicketContainer>
        <Helmet title={t('pages.ticket', { value: ticketId })} />

        <S.TicketHeader>
          <IconButton icon={<IconArrowPrevious16 />} onClick={() => backToTickets()} />
          <Title text={t('ticket.title', { value: ticketId })} />
          {!isClosed && (
            <Button
              variant="contained"
              color="secondary"
              body={t('ticket.info.close.btn')}
              onClick={() => showConfirmationModal()}
            />
          )}
        </S.TicketHeader>

        <S.TicketBody>
          <S.TicketInfoWrapper>
            <TicketInfo ticket={ticket} />
            <S.TicketAttachmentsContainer>{documents}</S.TicketAttachmentsContainer>
          </S.TicketInfoWrapper>

          <S.TicketChatWrapper>
            <TicketChat ticket={ticket} addAttachment={uploadAttachment} />
          </S.TicketChatWrapper>
        </S.TicketBody>
      </S.TicketContainer>

      <ModalConfirmation
        question={t('ticket.info.close.confirmation-question')}
        confirmButtonProps={{
          color: 'error',
          body: t('ticket.info.close.confirm-btn'),
          loading: isUpdateTicketLoading,
        }}
        open={isCloseModalShown}
        onCancel={() => closeConfirmationModal()}
        onConfirm={() => closeTicket()}
      />
    </>
  );
};
