import styled, { css } from 'styled-components';

import { getMediaFor } from '../../layouts';

export const RecipientsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  height: 100%;
  padding: 0 40px 40px;

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      padding: 0 16px 40px;
    `)};
`;

export const RecipientsContent = styled.div`
  flex-grow: 1;
  padding-right: 16px;
  overflow: auto;

  .avatar-cell {
    width: 56px;
  }

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      padding-right: 0;
    `)};
`;

export const MobileCellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;

  @media screen and (max-width: 409px) {
    max-width: 265px;
  }

  .cell-header {
    font-weight: 700;
    font-size: 1.7rem;
  }
`;

export const EllipsisEmail = styled.div`
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
