import { FC, SVGProps } from 'react';

export const IconCalendar: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 1C6 0.447715 5.55228 0 5 0C4.44772 0 4 0.447715 4 1H6ZM10 3C10 3.55228 10.4477 4 11 4C11.5523 4 12 3.55228 12 3H10ZM12 1C12 0.447715 11.5523 0 11 0C10.4477 0 10 0.447715 10 1H12ZM12 14H4V16H12V14ZM3 13V7H1V13H3ZM3 7V5H1V7H3ZM13 5V7H15V5H13ZM13 7V13H15V7H13ZM2 8H14V6H2V8ZM4 4H5V2H4V4ZM5 4H12V2H5V4ZM6 3V1H4V3H6ZM12 3V1H10V3H12ZM15 5C15 3.34315 13.6569 2 12 2V4C12.5523 4 13 4.44772 13 5H15ZM3 5C3 4.44772 3.44772 4 4 4V2C2.34315 2 1 3.34315 1 5H3ZM4 14C3.44772 14 3 13.5523 3 13H1C1 14.6569 2.34315 16 4 16V14ZM12 16C13.6569 16 15 14.6569 15 13H13C13 13.5523 12.5523 14 12 14V16Z"
        fill="currentColor"
      />
    </svg>
  );
};
