import { PayloadAction } from '@reduxjs/toolkit';

import { withPaginationSlice, WithPaginationState } from '../utils';
import { SortType, TicketFilter, TicketState } from '../../types';
import type { GetTicketsByFilterDTO } from '../../api';

type TicketsState = {
  initialRequestArgs: GetTicketsByFilterDTO;
  filter: TicketFilter;
};

const initialState: WithPaginationState<TicketsState> = {
  initialRequestArgs: {
    ticketStates: [TicketState.CLOSED, TicketState.IN_WORK, TicketState.OPENED],
    sort: SortType.DESC,
  },
  filter: {
    ticketStates: [],
    ticketTypes: [],
  },
  pagination: {
    pageNumber: 0,
    pageSize: 10,
  },
};

export const ticketsSlice = withPaginationSlice({
  name: 'tickets',
  initialState,
  reducers: {
    setTicketsFilter: (state, action: PayloadAction<TicketFilter>) => {
      state.filter = action.payload;
    },
    clearTicketsFilter: (state) => {
      state.filter = initialState.filter;
    },
  },
});

export const ticketsActions = ticketsSlice.actions;
export const ticketsReducer = ticketsSlice.reducer;
