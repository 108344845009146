import styled, { css } from 'styled-components';

import { getMediaFor } from '../../layouts';

export const ReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 40px 40px;

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      padding: 0 16px 40px;
    `)};
`;

export const TransferReviewContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`;

export const TransferReviewFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  max-width: 450px;
  width: 100%;
`;

export const TransferReviewFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TransferReviewActions = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  max-width: 450px;
`;

export const TransferInfoDirection = styled.div`
  position: relative;
`;

export const IconWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: -4px;
  transform: translate(-100%, -45%);
`;

export const TransferReviewDocuments = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  width: 310px;

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      width: 450px;
    `)};
`;
