import { FC, SVGProps } from 'react';

export const IconDrop: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.17801 8.81355C5.57569 9.38756 6.42431 9.38756 6.82199 8.81355L10.1088 4.06949C10.5683 3.40629 10.0936 2.5 9.28678 2.5H2.71322C1.9064 2.5 1.43175 3.40629 1.89123 4.06949L5.17801 8.81355Z"
        fill="currentColor"
      />
    </svg>
  );
};
