import { FC, MouseEvent, useEffect, useState } from 'react';
import defaultCoinLogo from 'cryptocurrency-icons/svg/icon/generic.svg';

import * as S from './styled';
import { loadCoinLogo } from '../../../layouts';
import { Wallet } from '../../../types';
import { IconRightArrow } from '../../../assets/svg/IconRightArrow/IconRightArrow';
import { IconButton } from '../../../components';
import { getCoinCodeForFlags } from '../../../utils';

type WalletProps = {
  wallet: Wallet;
  disabled?: boolean;
  onClick: (e: MouseEvent<HTMLButtonElement>) => void;
  selectedWallet: Wallet | undefined;
};

export const TransferWallet: FC<WalletProps> = ({ wallet, onClick, disabled, selectedWallet }) => {
  const { name, availableAmount, coinResponseDto } = wallet;
  const [walletLogo, setWalletLogo] = useState<string>(defaultCoinLogo);

  const coinCode = getCoinCodeForFlags(coinResponseDto);
  const mappedBalance = availableAmount;

  useEffect(() => {
    void loadCoinLogo(coinCode, setWalletLogo);
  }, []);

  return (
    <S.WalletRowContainer
      className={`${
        selectedWallet?.uid === wallet.uid ? 'active' : selectedWallet?.uid ? 'inactive' : ''
      }`}
    >
      <S.WalletCardContainer>
        <S.WalletLogoContainer>
          <S.WalletLogo src={walletLogo} />
        </S.WalletLogoContainer>

        <S.WalletCardContent>
          <S.WalletName>{name}</S.WalletName>
          <S.WalletAmount>{mappedBalance}</S.WalletAmount>
        </S.WalletCardContent>
      </S.WalletCardContainer>
      <IconButton onClick={onClick} disabled={disabled} icon={<IconRightArrow />} />
    </S.WalletRowContainer>
  );
};
