/* eslint-disable @typescript-eslint/no-unused-vars*/
/* eslint-disable react-hooks/exhaustive-deps*/
import { useEffect, useMemo, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Router as RouterType } from '@remix-run/router/dist/router';
import styled from 'styled-components';
import 'flag-icons/css/flag-icons.min.css';

// don't remove i18n import
import { createOry, i18n, oryConfig, redirectToOryLogin, SocketProvider, useOry } from './services'; // don't remove i18n import
import { routes } from './router';
import { GlobalStyles } from './layouts';
import { Banner, Error, Loader, Snackbar } from './components';
import './assets/fonts/fonts.css';

import fontRobotoNormal from './assets/fonts/Roboto/roboto-v30-latin_cyrillic-500.ttf';
import fontRobotoBold from './assets/fonts/Roboto/roboto-v30-latin_cyrillic-700.ttf';
import { useGetCountriesQuery, useGetProfilesQuery } from './api';
import { useAppActions } from './store';
import { countryActions } from './store/slices/country';
import { profilesActions } from './store/slices/profiles';

const ory = createOry(oryConfig);

export const App = () => {
  const { t } = useTranslation();
  const { initOry, hasError, error, isLoading } = useOry();
  const { data: countries } = useGetCountriesQuery();
  const { data: profiles } = useGetProfilesQuery();
  const { setCountries } = useAppActions(countryActions);
  const { setProfiles } = useAppActions(profilesActions);
  const router = useRef<RouterType | null>(null);

  useEffect(() => {
    (async () => {
      await initOry(ory);
      router.current = createBrowserRouter(routes);
    })();
  }, []);

  useEffect(() => {
    if (countries?.length) {
      setCountries(countries);
    }
  }, [countries]);

  useEffect(() => {
    if (profiles?.length) {
      setProfiles(profiles);
    }
  }, [profiles]);

  const routerProvider = useMemo(() => {
    if (isLoading) return <ErrorWrapper />;
    if (!router.current) return <ErrorWrapper />;

    if (router.current && !isLoading) {
      return (
        <RouterProvider
          router={router.current}
          fallbackElement={<Loader variant="fullScreen" opaque />}
        />
      );
    }

    return (
      <ErrorWrapper>
        <GlobalStyles />
        <Error
          message={t('ui.errors.default-message')}
          actionButtonText={t('ui.errors.default-action-text')}
          actionButtonCallback={() => window.location.reload()}
        />
      </ErrorWrapper>
    );
  }, [router.current, isLoading]);

  if (hasError) {
    const message = typeof error === 'string' ? error : t('ui.errors.default-message');
    return (
      <ErrorWrapper>
        <GlobalStyles />
        <Error
          message={message}
          actionButtonText={t('ui.errors.default-action-text')}
          actionButtonCallback={() => redirectToOryLogin()}
        />
      </ErrorWrapper>
    );
  }

  if (isLoading) {
    return <Loader variant="fullScreen" opaque />;
  }

  return (
    <SocketProvider>
      <Helmet defaultTitle="ABF Payments" titleTemplate="%s - ABF Payments" />
      <GlobalStyles />
      <Banner />
      <Snackbar />
      {routerProvider}
    </SocketProvider>
  );
};

const ErrorWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  background-color: ${({ theme }) => theme.colors.bg.overlay};
`;
