import styled, { css } from 'styled-components';

import { Button } from '../../components';
import { getMediaFor } from '../../layouts';

export const TransferContainer = styled.div<{ $isSendPage?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;

  padding: ${({ $isSendPage }) => ($isSendPage ? '0' : '0 40px 40px')};

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      padding: 0 16px 40px;
    `)};
`;

export const TransferHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;

  @media screen and (max-width: 529px) {
    gap: 16px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  gap: 16px;
`;

export const TransferExecuteButton = styled(Button)`
  height: 34px;
`;

export const TransferBody = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 24px;
  width: 100%;
`;

export const TransferInfoWithActonBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 450px;
  gap: 24px;
`;

export const TransferReviewButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;

  width: 100%;
  max-width: 450px;
`;

export const DownloadButtonContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 450px;

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      button {
        width: 100%;
      }
    `)}
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
