import styled, { css, DefaultTheme } from 'styled-components';
import { StandardCSSProperties } from '@mui/system';

import { Button } from '../Button';

export const getOpenDatepickerStyles = (theme: DefaultTheme): StandardCSSProperties => ({
  marginTop: '8px',
  borderRadius: '8px',
  backgroundColor: theme.colors.bg.dataPicker,
  boxShadow: theme.shadowsCustom.dataPicker,
});

export const DateInput = styled.div<{
  $isActive?: boolean;
  $disabled?: boolean;
  $nonEditable?: boolean;
}>`
  position: relative;

  .MuiInputAdornment-root {
    position: absolute;
    top: 0;
    right: 0;

    height: 100%;
    max-height: initial;
    margin: 0;
  }

  .MuiButtonBase-root {
    margin: 0 8px 0 0;
    color: ${({ theme }) => theme.colors.typography.gray64};

    ${({ $isActive }) =>
      $isActive &&
      css`
        color: ${({ theme }) => theme.colors.typography.white};
      `};
    ${({ $disabled }) =>
      $disabled &&
      css`
        color: ${({ theme }) => theme.colors.typography.gray32};
      `};
    ${({ $nonEditable }) =>
      $nonEditable &&
      css`
        color: ${({ theme }) => theme.colors.typography.gray48};
      `};
  }

  &:hover .MuiInputAdornment-root .MuiButtonBase-root {
    color: ${({ theme }) => theme.colors.typography.gray80};

    ${({ $disabled }) =>
      $disabled &&
      css`
        color: ${({ theme }) => theme.colors.typography.gray32};
      `};
    ${({ $nonEditable }) =>
      $nonEditable &&
      css`
        color: ${({ theme }) => theme.colors.typography.gray48};
      `};
  }
`;

export const DateWrapper = styled.div``;

export const LabelWrapper = styled.label`
  display: block;
`;

export const Label = styled.span`
  display: inline-block;
  margin-bottom: 4px;

  font-weight: 400;
  font-size: 1.6rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.typography.gray48};
`;

export const ActionBarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;

  height: 36px;
  background-color: ${({ theme }) => theme.colors.bg.white10};
`;

export const ClearDateButton = styled(Button)`
  padding: 10px 16px;

  font-weight: 500;
  font-size: 1.4rem;
  line-height: 133%;
  color: ${({ theme }) => theme.colors.typography.white};

  &:hover {
    background-color: initial;
  }
`;
