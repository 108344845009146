import { businessFeaturesSelector, useAppSelector } from '../../store';
import type { AuthoritiesArray } from './authorities';

// ACL (Access Control List)

type CheckACLRights = (availableRights: AuthoritiesArray, toCheck: AuthoritiesArray) => boolean;

/**
 * Helper for take available rights for user by ACL (Access Control List) outside React context
 *
 * @param availableRights - rights, which available for this profile
 * @param toCheck - rights, which need to check
 **/
export const checkACLRights: CheckACLRights = (availableRights, toCheck) => {
  if (!availableRights || availableRights.length === 0) return false;
  if (!toCheck || toCheck.length === 0) return false;

  return toCheck.every((v) => availableRights.includes(v));
};

/**
 * Hook for taking available rights for user by ACL (Access Control List)
 *
 * @example
 * const isAvailable = useACL(['VIEW_CURRENCY_PROVIDERS', 'EDIT_FEE_RULES']);
 **/
export const useACL = (rights: AuthoritiesArray): boolean => {
  const authoritiesList = useAppSelector(businessFeaturesSelector);

  if (authoritiesList === null) return false;

  return checkACLRights(authoritiesList.map((feature) => feature.name) as AuthoritiesArray, rights);
};
