import { FC, SVGProps } from 'react';

export const LogoWhite: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="146"
      height="48"
      viewBox="0 0 146 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0_2102_45749)">
        <path
          d="M55.419 22.5251H58.9465L51.9167 5.64746H49.3467L42.1154 22.5251H45.6681L47.1546 18.9826H53.9324L55.419 22.5251ZM48.1877 16.3004L50.5813 10.2274L52.975 16.3004H48.1877Z"
          fill="white"
        />
        <path
          d="M60.9086 5.64746V22.5251H66.5526C68.014 22.5251 69.5509 22.2721 70.8107 21.1587C71.9698 20.1212 72.2469 18.7295 72.2469 17.7427C72.2469 16.857 72.0454 15.5412 71.0375 14.5797C70.6092 14.1748 69.9541 13.6941 68.8454 13.5169C69.299 13.2639 70.7351 12.3783 70.7351 10.2021C70.7351 8.78513 70.1556 7.46934 69.173 6.71022C67.8124 5.64746 65.9983 5.64746 65.066 5.64746H60.9086ZM64.1841 8.32966H65.1416C66.477 8.32966 67.6612 8.91165 67.6612 10.4552C67.6612 12.606 65.6203 12.6566 65.1164 12.6566H64.1841V8.32966ZM64.1841 15.187H65.3935C66.099 15.187 66.9305 15.187 67.636 15.5159C68.6943 15.9967 68.8706 16.9077 68.8706 17.439C68.8706 18.0969 68.6439 18.9067 67.8376 19.3874C67.1069 19.8176 66.1998 19.8429 65.5951 19.8429H64.1841V15.187Z"
          fill="white"
        />
        <path
          d="M84.1978 5.64746H75.2783V22.5251H78.5539V15.0858H83.8451V12.2518H78.5539V8.48149H84.1978V5.64746Z"
          fill="white"
        />
        <path
          d="M43.9799 25.4757V42.3533H47.2554V36.1033H49.3719C51.312 36.1033 52.6978 35.5466 53.6301 34.6104C54.9151 33.3199 55.0159 31.498 55.0159 30.8401C55.0159 29.6255 54.6631 27.9808 53.3025 26.7662C52.0679 25.6781 50.7577 25.4757 48.868 25.4757H43.9799ZM47.2554 28.3097H48.3892C49.1703 28.3097 50.027 28.3603 50.7073 28.8411C51.186 29.1954 51.6396 29.828 51.6396 30.7642C51.6396 31.498 51.3372 32.2571 50.7325 32.7379C50.027 33.2693 49.2207 33.2693 48.5908 33.2693H47.2554V28.3097Z"
          fill="white"
        />
        <path
          d="M65.1048 32.8138C64.0717 31.1944 62.434 31.0425 61.6025 31.0425C58.3774 31.0425 56.1853 33.6235 56.1853 36.8624C56.1853 40.076 58.4026 42.7076 61.7537 42.7076C62.5348 42.7076 63.9961 42.5305 65.1048 40.9363V42.3533H68.1787V31.3968H65.1048V32.8138ZM62.3836 33.7754C64.1473 33.7754 65.4071 35.1165 65.4071 36.8624C65.4071 38.6084 64.1473 39.9748 62.3836 39.9748C60.6198 39.9748 59.36 38.6084 59.36 36.8624C59.36 35.1165 60.6198 33.7754 62.3836 33.7754Z"
          fill="white"
        />
        <path
          d="M70.8826 48.1479H74.3597L83.1531 31.3968H79.6761L76.6525 37.6215L73.2762 31.3968H69.6984L74.8888 40.7086L70.8826 48.1479Z"
          fill="white"
        />
        <path
          d="M84.6671 42.3533H87.741V36.7612C87.741 36.1792 87.7662 35.2936 88.1694 34.6357C88.4969 34.0537 89.026 33.6741 89.8575 33.6741C90.1599 33.6741 90.7142 33.7248 91.1173 34.2308C91.3441 34.5092 91.6212 35.0912 91.6212 36.3817V42.3533H94.6952V36.7612C94.6952 36.1792 94.7204 35.2936 95.1235 34.6357C95.4511 34.0537 95.9802 33.6741 96.8117 33.6741C97.114 33.6741 97.6683 33.7248 98.0715 34.2308C98.2982 34.5092 98.5754 35.0912 98.5754 36.3817V42.3533H101.649V35.4707C101.649 34.2561 101.599 33.3199 100.893 32.3836C100.465 31.827 99.6336 31.0425 97.9455 31.0425C96.0558 31.0425 94.9723 32.1812 94.4432 33.0415C94.1661 32.4849 93.2842 31.0425 90.9913 31.0425C90.3362 31.0425 88.9001 31.1691 87.741 32.6367V31.3968H84.6671V42.3533Z"
          fill="white"
        />
        <path
          d="M112.46 38.8361C111.956 39.7218 111.074 40.2784 109.915 40.2784C108.73 40.2784 108.05 39.6964 107.723 39.241C107.37 38.7602 107.193 38.0517 107.193 37.3938H115.357V37.1408C115.357 35.9515 115.206 34.2308 114.047 32.8391C113.291 31.9282 112.006 31.0425 109.789 31.0425C108.478 31.0425 106.967 31.3209 105.682 32.5861C104.901 33.3705 104.019 34.7116 104.019 36.8877C104.019 38.6084 104.523 40.0254 105.732 41.1641C106.816 42.2015 108.151 42.7076 109.915 42.7076C113.719 42.7076 114.954 40.1013 115.281 39.2916L112.46 38.8361ZM107.294 35.4201C107.546 33.9525 108.73 33.2187 109.864 33.2187C110.998 33.2187 112.208 33.9272 112.46 35.4201H107.294Z"
          fill="white"
        />
        <path
          d="M117.737 42.3533H120.811V36.7612C120.811 36.1286 120.836 35.3442 121.29 34.6357C121.743 33.9525 122.373 33.6741 123.18 33.6741C123.482 33.6741 124.137 33.7248 124.616 34.2308C125.17 34.8128 125.195 35.7744 125.195 36.3817V42.3533H128.269V35.4707C128.269 34.408 128.168 33.3199 127.412 32.3836C126.556 31.3209 125.145 31.0425 124.187 31.0425C122.424 31.0425 121.391 32.0041 120.811 32.8138V31.3968H117.737V42.3533Z"
          fill="white"
        />
        <path
          d="M136.387 34.0284V31.3968H134.27V27.6518H131.196V31.3968H129.987V34.0284H131.196V42.3533H134.27V34.0284H136.387Z"
          fill="white"
        />
        <path
          d="M145.446 32.2065C144.06 31.1438 142.775 31.0425 141.969 31.0425C141.087 31.0425 139.978 31.1438 139.121 31.827C138.492 32.3583 137.988 33.244 137.988 34.3067C137.988 35.1924 138.315 35.8756 138.819 36.407C139.474 37.0902 140.381 37.4444 141.162 37.7228L141.868 37.9758C142.271 38.1276 142.825 38.406 142.825 39.0892C142.825 39.8483 142.17 40.2784 141.314 40.2784C140.406 40.2784 139.499 39.7218 138.895 39.0132L137.282 40.8857C137.962 41.6195 139.247 42.7076 141.515 42.7076C142.85 42.7076 143.909 42.3533 144.791 41.5436C145.672 40.7339 146 39.7471 146 38.7602C146 38.0517 145.773 37.3938 145.446 36.9383C144.816 36.078 143.531 35.6478 142.674 35.3695L141.943 35.1418C141.087 34.8634 140.961 34.4333 140.961 34.1549C140.961 33.6235 141.414 33.2693 142.145 33.2693C142.473 33.2693 143.203 33.3199 143.934 34.0031L145.446 32.2065Z"
          fill="white"
        />
        <path d="M19.6538 5.64746L5.61538 42.3532H0L14.0385 5.64746H19.6538Z" fill="white" />
        <path
          d="M25.2692 5.64746H30.8846L22.251 28.2216H30.8846L28.7788 33.8651H20.0926L16.8462 42.3533H11.2308L25.2692 5.64746Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2102_45749">
          <rect width="146" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
