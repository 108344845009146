import { apiSlice } from '../../api';
import type {
  CreateLegalProfileDTO,
  CreateLegalProfileResult,
  CreateNaturalProfileDTO,
  CreateNaturalProfileResult,
  GetProfileAddressDTO,
  GetProfileAddressResult,
  GetProfilesResult,
  UpdateProfileAddressDTO,
  UpdateProfileAddressResult,
} from './types';

export const profileEndpoints = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getProfiles: build.query<GetProfilesResult, void>({
      query: () => ({
        url: '/profiles-service/v1/profiles',
        method: 'GET',
      }),
      providesTags: ['NaturalProfile', 'LegalProfile'],
    }),
    getProfilesNoCache: build.query<GetProfilesResult, void>({
      query: () => ({
        url: '/profiles-service/v1/profiles',
        method: 'GET',
        keepUnusedDataFor: 0,
      }),
    }),
    createNaturalProfile: build.mutation<CreateNaturalProfileResult, CreateNaturalProfileDTO>({
      query: (body) => ({
        url: '/profiles-service/v1/profiles/natural',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['NaturalProfile'],
    }),
    createLegalProfile: build.mutation<CreateLegalProfileResult, CreateLegalProfileDTO>({
      query: (body) => ({
        url: '/profiles-service/v1/profiles/legal',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['LegalProfile'],
    }),
    getProfileAddress: build.query<GetProfileAddressResult, GetProfileAddressDTO>({
      query: ({ profileUid }) => ({
        url: `/profiles-service/v1/profiles/${profileUid}/addresses`,
        method: 'GET',
      }),
      providesTags: ['ProfileAddress'],
    }),
    updateProfileAddress: build.mutation<UpdateProfileAddressResult, UpdateProfileAddressDTO>({
      query: ({ profileUid, addressType, body }) => ({
        url: `/profiles-service/v1/profiles/addresses/${addressType}`,
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['ProfileAddress'],
    }),
  }),
});

export const { useGetProfilesQuery } = profileEndpoints;
export const { useCreateNaturalProfileMutation } = profileEndpoints;
export const { useCreateLegalProfileMutation } = profileEndpoints;
export const { useGetProfileAddressQuery } = profileEndpoints;
export const { useUpdateProfileAddressMutation } = profileEndpoints;

export type ProfileController = typeof profileEndpoints;
