import styled from 'styled-components';

import { Button } from '../../../../components';

export const AddBusinessAccountForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const AddBusinessAccountFormFields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  .custom-file-input-container {
    min-height: auto;
    padding: 10px 20px;

    outline: none;
    border-radius: 8px;
    border: 2px solid ${({ theme }) => theme.colors.borders.white05};

    background-color: ${({ theme }) => theme.colors.bg.white05};
    color: ${({ theme }) => theme.colors.typography.gray56};

    &.drag-over {
      outline: none;
      background-color: ${({ theme }) => theme.colors.bg.gray32};
    }

    &.upload-success {
      background-color: ${({ theme }) => theme.colors.bg.white05};
      border: 1px solid ${({ theme }) => theme.colors.primary};
    }

    &.upload-error {
      background-color: ${({ theme }) => theme.colors.bg.white05};
      border: 1px solid ${({ theme }) => theme.colors.error};
    }

    div {
      max-width: 100%;
      text-align: center;
    }
  }
`;

export const AddBusinessAccountFormActions = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 8px;
`;

export const AddBusinessAccountPrevStepButton = styled(Button)`
  min-width: 90px;
`;

export const AddBusinessAccountNextStepButton = styled(Button)`
  flex-grow: 1;
`;

export const AddBusinessAccount = styled(AddBusinessAccountNextStepButton)``;
