import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styled';
import ImageManWait from '../../assets/svg/ImageManWait/image-man-wait.svg';

export const ComingSoon: FC = () => {
  const { t } = useTranslation();

  return (
    <S.ComingSoonContainer>
      <S.ComingSoonText>{t('ui.coming-soon.text')}</S.ComingSoonText>
      <S.ComingSoonImage src={ImageManWait} />
    </S.ComingSoonContainer>
  );
};
