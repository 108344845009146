import styled, { css } from 'styled-components';

import { getMediaFor } from '../../layouts';

export const VerificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  min-height: 100%;
  padding: 0 40px 40px;

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      padding: 0 16px 40px;
    `)};
`;

export const SumsubIframeContainer = styled.div`
  flex-grow: 1;
`;

export const VerificationSuccessBanner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
