import { ElementType, FC, ForwardedRef, forwardRef, InputHTMLAttributes } from 'react';
import { FieldErrors } from 'react-hook-form';

import * as S from './styled';
import { ErrorMessage, getErrorByFieldName } from '../../forms';

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  name: string;
  ref?: ForwardedRef<HTMLInputElement>;
  errors?: FieldErrors;
  label?: string;
  nonEditable?: boolean;
  showErrorMessage?: boolean;
};

export const Checkbox: FC<InputProps> = forwardRef((props, ref) => {
  const {
    name,
    errors,
    label,
    nonEditable,
    showErrorMessage = true,
    className = '',
    disabled,
    ...rest
  } = props;

  const hasError = errors ? Boolean(getErrorByFieldName(errors, name)) : false;
  const Wrapper = label ? S.LabelWrapper : (S.CheckboxWrapper as ElementType);

  const getInputClassName = () => {
    let inputClassName = className;
    if (nonEditable) {
      inputClassName += ' non-editable';
    }
    if (hasError) {
      inputClassName += ' error';
    }
    return inputClassName;
  };

  return (
    <Wrapper>
      <S.CheckboxWithLabelContainer>
        <S.CheckboxContainer>
          <S.Checkbox
            {...rest}
            ref={ref}
            type="checkbox"
            name={name}
            className={getInputClassName()}
            readOnly={nonEditable}
            disabled={disabled}
          />
        </S.CheckboxContainer>
        {label && <S.Label>{label}</S.Label>}
      </S.CheckboxWithLabelContainer>
      {showErrorMessage && hasError && <ErrorMessage fieldName={name} errors={errors} />}
    </Wrapper>
  );
});
