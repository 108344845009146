import styled from 'styled-components';

export const RHFErrorContainer = styled.div`
  margin-top: 6px;
`;

export const ValidationErrorMessage = styled.div`
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.error};
`;
