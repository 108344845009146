import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import * as S from './styled';
import { Error } from '../../../../components';
import { RouterPaths } from '../../../../router';

export const DashboardPage404: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <S.DashboardPage404Container>
      <Error
        message={t('ui.errors.error-boundary.404.message')}
        actionButtonText={t('ui.errors.error-boundary.404.action-text.dashboard')}
        actionButtonCallback={() => navigate(RouterPaths.Home)}
      />
    </S.DashboardPage404Container>
  );
};
