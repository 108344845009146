import styled from 'styled-components';

import { Button } from '../../../../components';

export const AttachDocumentFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const TransfersFilterFormActions = styled.div`
  display: flex;
  gap: 16px;
`;

export const ClearTransfersFilterButton = styled(Button)`
  flex-grow: 1;
`;

export const ApplyTransfersFilterButton = styled(Button)`
  flex-grow: 1;
`;
