import styled, { css } from 'styled-components';

import { getMediaFor } from '../../layouts';

export const ProfilesContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 420px;
  margin-bottom: 40px;
  padding: 40px;

  border-radius: 40px;
  background-color: ${({ theme }) => theme.colors.bg.profileLayoutContent};
  box-shadow: ${({ theme }) => theme.shadowsCustom.profileLayoutContent};

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      padding: 16px;
      border-radius: 16px;
    `)};
`;

export const ProfilesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  margin-top: 24px;
`;
