import { ElementType, FC, ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { isMobile } from 'react-device-detect';
import { FieldErrors, FieldValues, useController, UseControllerProps } from 'react-hook-form';
import { useTheme } from 'styled-components';
import { Dayjs } from 'dayjs';

import { TextFieldProps } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import {
  DesktopDatePicker as MuiDesktopDatePicker,
  DesktopDatePickerProps as MuiDesktopDatePickerProps,
  LocalizationProvider,
  MobileDatePicker as MuiMobileDatePicker,
  MobileDatePickerProps as MuiMobileDatePickerProps,
} from '@mui/x-date-pickers';
import { PickersActionBarProps } from '@mui/x-date-pickers/PickersActionBar';
import { useLocaleText } from '@mui/x-date-pickers/internals';

import * as S from './styled';
import { Input } from '../Input';
import { ErrorMessage, getErrorByFieldName } from '../../forms';
import { IconArrowNext16, IconArrowPrevious16, IconCalendar } from '../../assets';

type TInputDate = Dayjs | Date | null;
type TDate = Dayjs;
type MuiPickerProps =
  | Omit<MuiMobileDatePickerProps<TInputDate, TDate>, 'value' | 'onChange' | 'renderInput'>
  | Omit<MuiDesktopDatePickerProps<TInputDate, TDate>, 'value' | 'onChange' | 'renderInput'>;
type DatePickerProps<FormValues extends FieldValues> = MuiPickerProps & {
  control: UseControllerProps<FormValues>;
  errors?: FieldErrors;
  label?: string;
  placeholder?: string;
  nonEditable?: boolean;
  showErrorMessage?: boolean;
};

const DatePickerActionBar: FC<PickersActionBarProps> = ({ onClear }) => {
  const localeText = useLocaleText();

  return (
    <S.ActionBarWrapper>
      <S.ClearDateButton
        variant="text"
        color="secondary"
        disableRipple
        body={localeText.clearButtonLabel}
        onClick={() => onClear()}
      />
    </S.ActionBarWrapper>
  );
};

export const DatePicker = <FormValues extends FieldValues>(
  props: DatePickerProps<FormValues>
): ReactElement => {
  const {
    control,
    errors,
    label,
    placeholder,
    disabled,
    nonEditable,
    showErrorMessage = true,
    ...rest
  } = props;
  const { t, i18n } = useTranslation();
  const { field } = useController(control);
  const theme = useTheme();

  const hasError = errors ? Boolean(getErrorByFieldName(errors, control.name)) : false;

  const renderInput = useCallback(
    (params: TextFieldProps) => {
      const { inputRef, inputProps, InputProps } = params;

      return (
        <S.DateInput
          $isActive={Boolean(inputProps?.value)}
          $disabled={disabled}
          $nonEditable={nonEditable}
        >
          <Input
            ref={inputRef}
            {...inputProps}
            name={field.name}
            onBlur={field.onBlur}
            placeholder={placeholder}
            nonEditable={nonEditable}
            className={`${inputProps?.className} ${hasError ? 'error' : ''}`}
          />
          {InputProps?.endAdornment}
        </S.DateInput>
      );
    },
    [disabled, field.name, field.onBlur, hasError, nonEditable, placeholder]
  );

  const DatePickerWrapper = label ? S.LabelWrapper : (S.DateWrapper as ElementType);
  const DatePickerSource = isMobile ? MuiMobileDatePicker : MuiDesktopDatePicker;

  return (
    <LocalizationProvider
      dateAdapter={AdapterDayjs}
      adapterLocale={i18n.resolvedLanguage}
      dateFormats={{
        dayOfMonth: 'DD',
        fullDate: 'DD/MM/YYYY',
        normalDate: 'DD/MM/YYYY',
        year: 'YYYY',
        month: 'MM/YYYY',
      }}
      localeText={{
        clearButtonLabel: t('ui.date-picker.clear-btn'),
        previousMonth: t('ui.date-picker.prev-month'),
        nextMonth: t('ui.date-picker.next-month'),
      }}
    >
      <DatePickerWrapper>
        {label && <S.Label>{label}</S.Label>}

        <DatePickerSource
          {...rest}
          inputRef={field.ref}
          value={field.value || null}
          onChange={field.onChange}
          inputFormat={'DD/MM/YYYY'}
          disabled={disabled}
          readOnly={nonEditable}
          showToolbar={false}
          renderInput={renderInput}
          components={{
            ...rest.components,
            ActionBar: DatePickerActionBar,
            OpenPickerIcon: IconCalendar,
            LeftArrowIcon: IconArrowPrevious16,
            RightArrowIcon: IconArrowNext16,
          }}
          componentsProps={{
            actionBar: { actions: ['clear'] },
          }}
          OpenPickerButtonProps={{ disableRipple: true }}
          DialogProps={{ PaperProps: { sx: S.getOpenDatepickerStyles(theme) } }}
          PaperProps={{ sx: S.getOpenDatepickerStyles(theme) }}
        />

        {showErrorMessage && hasError && <ErrorMessage fieldName={control.name} errors={errors} />}
      </DatePickerWrapper>
    </LocalizationProvider>
  );
};
