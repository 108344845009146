import styled from 'styled-components';

export const FullScreen = styled.div<{ $opaque: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.colors.bg.overlay};
  ${({ $opaque }) => ($opaque ? 'opacity: 1' : 'opacity: 0.8')};
  z-index: ${({ theme }) => theme.zIndex.drawer + 1};
`;

export const CenterOfContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: -webkit-fill-available;
  height: 100%;
  width: 100%;

  //border: 2px solid ${({ theme }) => theme.colors.borders.white05};
  border-radius: 16px;
`;
