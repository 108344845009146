import { apiSlice } from '../../api';
import type {
  CreateCommentInTicketDTO,
  CreateCommentInTicketResult,
  GetCommentsByTicketIdDTO,
  GetCommentsByTicketIdResult,
} from './types';

export const commentsEndpoints = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getCommentsByTicketId: build.query<GetCommentsByTicketIdResult, GetCommentsByTicketIdDTO>({
      query: ({ ticketId, params }) => ({
        url: `/ticket-service/v1/tickets/${ticketId}/comments`,
        method: 'GET',
        params,
      }),
      providesTags: ['TicketComments'],
    }),
    createCommentInTicket: build.mutation<CreateCommentInTicketResult, CreateCommentInTicketDTO>({
      query: ({ ticketId, body }) => ({
        url: `/ticket-service/v1/tickets/${ticketId}/comments`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['TicketComments'],
    }),
  }),
});

export const { useGetCommentsByTicketIdQuery, useLazyGetCommentsByTicketIdQuery } =
  commentsEndpoints;
export const { useCreateCommentInTicketMutation } = commentsEndpoints;

export type CommentsController = typeof commentsEndpoints;
