import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PopoverProps, Tooltip } from '@mui/material';

import * as S from './styled';
import { Modal } from '../../../../../../components';
import { WalletIBANForm } from '../../components';
import { IconBanknote, IconCopy, IconDeposit, IconWallet } from '../../../../../../assets';

import { useACL } from '../../../../../../services';
import { useAppLinks } from '../../../../../../router';
import type { Wallet } from '../../../../../../types';
import {
  useGetIbanRequestAvailableQuery,
  useLazyGetIbanRequestAvailableQuery,
} from '../../../../../../api';
import { IconDoubleCheck } from '../../../../../../assets/svg/IconDoubleCheck/IconDoubleCheck';

type WalletContextMenuProps = {
  open: boolean;
  anchorEl: PopoverProps['anchorEl'];
  closeHandler: () => void;
  currentWallet: Wallet | undefined;
};

// TODO: commented code maybe used in future after MVP

export const WalletContextMenu: FC<WalletContextMenuProps> = (props) => {
  const { open, anchorEl, closeHandler, currentWallet } = props;
  const { t, i18n } = useTranslation();
  const { walletLinks } = useAppLinks();
  const hasPermission_ISSUE_IBAN = useACL(['ISSUE_IBAN']);
  const [walletForModals, setWalletForModals] = useState<Wallet | undefined>(undefined);
  const [copiedWalledId, setCopiedWalletId] = useState(false);

  // IBAN modal state
  const [isIBANModalShown, setIBANModalShown] = useState(false);
  const showIBANForm = () => {
    setWalletForModals(currentWallet);
    setIBANModalShown(true);
  };
  const closeIBANForm = () => {
    setIBANModalShown(false);
  };

  useEffect(() => {
    setCopiedWalletId(false);
  }, [open]);

  // settings modal state
  // const [isSettingsModalShown, setSettingsModalShown] = useState(false);
  // const showSettingsForm = () => {
  //   setWalletForModals(currentWallet);
  //   setSettingsModalShown(true);
  // };
  // const closeSettingsForm = () => {
  //   setSettingsModalShown(false);
  // };

  const copyWalletId = () => {
    void navigator.clipboard.writeText(`${currentWallet?.walletId}`).then(() => {
      setCopiedWalletId(true);
    });
  };

  const links = useMemo(() => {
    return walletLinks.map((link) => {
      const { name, icon: Icon, path } = link;

      return (
        <S.WalletMenuItem key={path}>
          <S.WalletLink
            to={path}
            state={{ walletUid: currentWallet?.uid, walletId: currentWallet?.walletId }}
          >
            <S.WalletLinkIcon>
              <Icon />
            </S.WalletLinkIcon>
            {t(`menus.wallet.links.${name}` as unknown as TemplateStringsArray)}
          </S.WalletLink>
        </S.WalletMenuItem>
      );
    });
  }, [walletLinks, currentWallet, i18n.resolvedLanguage]);

  return (
    <>
      <S.WalletMenu
        id="wallet-menu"
        open={open}
        anchorEl={anchorEl}
        onClose={() => closeHandler()}
        onClick={() => closeHandler()}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        {/*Available balance*/}
        <S.WalletAvailableBalanceWrapper onClick={(e) => e.stopPropagation()}>
          <S.WalletAvailableBalance>
            <IconBanknote />
            {t('menus.wallet.available-balance.title')}
          </S.WalletAvailableBalance>
          <S.WalletAvailableBalance className="wallet-available-balance-amount">
            {currentWallet?.availableAmount}
          </S.WalletAvailableBalance>
        </S.WalletAvailableBalanceWrapper>
        <S.WalletIdWrapper onClick={(e) => e.stopPropagation()}>
          <S.WalletAvailableBalance>
            <IconWallet />
            {t('menus.wallet.wallet-id.title')}
          </S.WalletAvailableBalance>
          <Tooltip title={t('menus.wallet.wallet-id.copy-wallet-id')}>
            <S.WalletAvailableBalance className="wallet-id-amount" onClick={copyWalletId}>
              {currentWallet?.walletId}
              <S.WalletIdIconIcon>
                {copiedWalledId ? <IconDoubleCheck /> : <IconCopy />}
              </S.WalletIdIconIcon>
            </S.WalletAvailableBalance>
          </Tooltip>
        </S.WalletIdWrapper>

        {/*Links*/}
        <S.WalletMenuDivider />
        <S.WalletLinksWrapper>{links}</S.WalletLinksWrapper>

        {/*Other actions (IBAN, settings, copy)*/}
        {currentWallet?.canRequestIban && hasPermission_ISSUE_IBAN && (
          <>
            <S.WalletMenuDivider />
            <S.WalletSettingsWrapper>
              <S.WalletMenuItem>
                <S.OtherLink onClick={() => showIBANForm()}>
                  <S.WalletLinkIcon>
                    <IconDeposit />
                  </S.WalletLinkIcon>
                  {t('menus.wallet.settings.request-iban')}
                </S.OtherLink>
              </S.WalletMenuItem>

              {/*<S.WalletMenuItem>*/}
              {/*  <S.OtherLink onClick={() => showSettingsForm()}>*/}
              {/*    <S.WalletLinkIcon>*/}
              {/*      <IconSettings />*/}
              {/*    </S.WalletLinkIcon>*/}
              {/*    {t('menus.wallet.settings.settings')}*/}
              {/*  </S.OtherLink>*/}
              {/*</S.WalletMenuItem>*/}

              {/*{!currentWallet?.coinResponseDto.currencyCode && (*/}
              {/*  <S.WalletMenuItem>*/}
              {/*    <S.OtherLink onClick={() => copyWalletAddress()}>*/}
              {/*      <S.WalletLinkIcon>*/}
              {/*        <IconWallet />*/}
              {/*      </S.WalletLinkIcon>*/}
              {/*      {t('menus.wallet.settings.copy-address')}*/}
              {/*      <S.WalletLinkIcon className="end-icon">*/}
              {/*        <IconCopy />*/}
              {/*      </S.WalletLinkIcon>*/}
              {/*    </S.OtherLink>*/}
              {/*  </S.WalletMenuItem>*/}
              {/*)}*/}
            </S.WalletSettingsWrapper>
          </>
        )}
      </S.WalletMenu>

      <Modal
        modalTitle={t('dashboard-layout.wallet-bar.wallet-iban-form.title')}
        body={<WalletIBANForm wallet={walletForModals} onCancel={() => closeIBANForm()} />}
        open={isIBANModalShown}
        onClose={() => closeIBANForm()}
        customPaperRootCss={{ width: '640px' }}
      />

      {/*<Modal*/}
      {/*  modalTitle={t('dashboard-layout.wallet-bar.wallet-settings-form.title', {*/}
      {/*    value: walletForModal?.name || '',*/}
      {/*  })}*/}
      {/*  body={*/}
      {/*    <WalletSettingsForm wallet={walletForModal} onCancel={() => closeSettingsForm()} />*/}
      {/*  }*/}
      {/*  open={isSettingsModalShown}*/}
      {/*  onClose={() => closeSettingsForm()}*/}
      {/*/>*/}
    </>
  );
};
