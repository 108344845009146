import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;

    font-size: 62.5%;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  body {
    font-family: Roboto, sans-serif;
    margin: 0;
    background-color: ${({ theme }) => theme.colors.bg.overlay};
  }

  p {
    margin: 0;
  }

  ul, ol, li {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
  }

  input, textarea {
    font-family: Roboto, sans-serif;
    margin: 0;
    padding: 0;
  }

  /* Works on Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #525252 #474747;
  }

  /* Works on Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  *::-webkit-scrollbar-track {
    background-color: #474747;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 1px solid #686868;
    background-color: #525252;
  }

  ::-webkit-scrollbar-thumb:hover {
  }
`;
