import styled, { css } from 'styled-components';

import { getMediaFor } from '../../layouts';
import { Button, IconButton } from '../../components';

export const ExchangeContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  min-height: 100%;
  padding: 0 40px 40px;

  ${({ theme }) =>
    getMediaFor(
      'mobile',
      theme
    )(css`
      padding: 0 16px 40px;
    `)};
`;

export const ExchangeForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 450px;
`;

export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const InfoWrapper = styled.div<{ $isFeeCalcFetching: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;

  ${({ $isFeeCalcFetching }) =>
    $isFeeCalcFetching &&
    css`
      & > div:not(.info-loader) {
        opacity: 0.4;
      }
    `};

  .info-loader {
    position: absolute;
    opacity: 1;
  }
`;

export const FieldWrapper = styled.div`
  display: flex;
  gap: 16px;

  & > label {
    flex: 1;
  }
`;

export const ChangeWalletsButton = styled(IconButton)`
  border-radius: 16px;
`;

export const NextButton = styled(Button)``;
