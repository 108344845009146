import { FC, forwardRef, ReactElement, ReactNode } from 'react';
import { IconButtonProps as MuiIconButtonProps, Tooltip, TooltipProps } from '@mui/material';

import * as S from './styled';

type IconButtonProps = MuiIconButtonProps & {
  tooltipTitle?: NonNullable<ReactNode>;
  tooltipProps?: Omit<TooltipProps, 'title' | 'children'>;
  icon: string | ReactElement;
};

export const IconButton: FC<IconButtonProps> = forwardRef((props, ref) => {
  const { tooltipTitle, tooltipProps, icon, color = 'primary', ...rest } = props;

  const iconElement = typeof icon === 'string' ? <S.Icon src={icon} /> : icon;

  if (tooltipTitle) {
    return (
      <Tooltip title={tooltipTitle} {...tooltipProps}>
        <S.IconButton {...rest} color={color}>
          {iconElement}
        </S.IconButton>
      </Tooltip>
    );
  }

  return (
    <S.IconButton {...rest} ref={ref} color={color}>
      {iconElement}
    </S.IconButton>
  );
});
