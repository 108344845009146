import { FC, MouseEvent, ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as S from './styled';
import { IconDrop, IconUp } from '../../../../assets';
import { Input, Modal, Title } from '../../../../components';
import {
  AddBankRecipientForm,
  // AddCryptoAccountForm,
  // AddCryptoRecipientForm,
  AddLocalAccountForm,
} from '../../components';

import { useACL } from '../../../../services';
import { GetActiveCountriesResult, GetLegalCompanyTypesResult } from '../../../../api';
import { RecipientAccountType } from '../../../../types';
import { AddBankAccountForm } from '../AddBankAccountForm';

type RecipientsHeaderProps = {
  activeCountries: GetActiveCountriesResult | undefined;
  legalCompanyTypes: GetLegalCompanyTypesResult | undefined;
  onChangeSearch: (e: any) => void;
  searchValue: string;
  sendPage?: boolean;
};
type NewAccountRecipientAccountType = Exclude<RecipientAccountType, RecipientAccountType.LOCAL>;

export const RecipientsHeader: FC<RecipientsHeaderProps> = ({
  activeCountries,
  legalCompanyTypes,
  onChangeSearch,
  searchValue,
  sendPage,
}) => {
  const { t } = useTranslation();
  const hasPermission_RECIPIENT_CREATION = useACL(['RECIPIENT_CREATION']);

  // TODO: add search input logic (also add common component for it)

  // ACCOUNT BUTTON LOGIC
  // new account modal state
  const [isAccountModalShown, setAccountModalShown] = useState(false);
  const showAddAccountForm = () => setAccountModalShown(true);
  const closeAddAccountForm = () => setAccountModalShown(false);

  // new account type logic
  const [activeAccountForm, setActiveAccountForm] = useState<NewAccountRecipientAccountType>(
    RecipientAccountType.BANK
  );
  const chooseNewAccountRecipientType = (type: NewAccountRecipientAccountType) => {
    setActiveAccountForm(type);
    showAddAccountForm();
  };

  // new account context menu
  const [newAccountAnchor, setNewAccountAnchor] = useState<HTMLButtonElement | null>(null);
  const isNewAccountMenuOpen = Boolean(newAccountAnchor);
  const openNewAccountMenu = (e: MouseEvent<HTMLButtonElement>) =>
    setNewAccountAnchor(e.currentTarget);
  const closeNewAccountMenu = () => setNewAccountAnchor(null);

  // map for accounts forms by RecipientAccountType
  const newAccountTypeMap: Partial<Record<NewAccountRecipientAccountType, ReactElement>> = {
    [RecipientAccountType.BANK]: <AddBankAccountForm onCancel={() => closeAddAccountForm()} />,
    // [RecipientAccountType.CRYPTO]: <AddCryptoAccountForm onCancel={() => closeAddAccountForm()} />,
  };

  const newAccountMenuItems = Object.keys(newAccountTypeMap).map((it) => {
    const type = it as NewAccountRecipientAccountType;

    return (
      <S.AddAccountMenuItem key={type} disabled={!hasPermission_RECIPIENT_CREATION}>
        <S.RecipientTypeButton onClick={() => chooseNewAccountRecipientType(type)}>
          {t(`common.recipient-account-type.${type}`)}
        </S.RecipientTypeButton>
      </S.AddAccountMenuItem>
    );
  });

  //  RECIPIENT BUTTON LOGIC
  // new recipient modal state
  const [isRecipientModalShown, setRecipientModalShown] = useState(false);
  const showAddRecipientForm = () => setRecipientModalShown(true);
  const closeAddRecipientForm = () => setRecipientModalShown(false);

  // new recipient account type logic
  const [activeRecipientForm, setActiveRecipientForm] = useState<RecipientAccountType>(
    RecipientAccountType.BANK
  );
  const chooseRecipientAccountType = (type: RecipientAccountType) => {
    setActiveRecipientForm(type);
    showAddRecipientForm();
  };

  // new recipient context menu
  const [newRecipientAnchor, setNewRecipientAnchor] = useState<HTMLButtonElement | null>(null);
  const isNewRecipientMenuOpen = Boolean(newRecipientAnchor);
  const openNewRecipientMenu = (e: MouseEvent<HTMLButtonElement>) =>
    setNewRecipientAnchor(e.currentTarget);
  const closeNewRecipientMenu = () => setNewRecipientAnchor(null);

  // map for recipient forms by RecipientAccountType
  const recipientAccountTypeMap: Partial<Record<RecipientAccountType, ReactElement>> = {
    [RecipientAccountType.LOCAL]: <AddLocalAccountForm onCancel={() => closeAddRecipientForm()} />,
    [RecipientAccountType.BANK]: (
      <AddBankRecipientForm
        activeCountries={activeCountries}
        legalCompanyTypes={legalCompanyTypes}
        onCancel={() => closeAddRecipientForm()}
      />
    ),
    // [RecipientAccountType.CRYPTO]: (
    //   <AddCryptoRecipientForm
    //     activeCountries={activeCountries}
    //     onCancel={() => closeAddRecipientForm()}
    //   />
    // ),
  };

  const newRecipientMenuItems = Object.keys(recipientAccountTypeMap).map((it) => {
    const type = it as RecipientAccountType;

    return (
      <S.AddAccountMenuItem key={type} disabled={!hasPermission_RECIPIENT_CREATION}>
        <S.RecipientTypeButton onClick={() => chooseRecipientAccountType(type)}>
          {t(`common.recipient-account-type.${type}`)}
        </S.RecipientTypeButton>
      </S.AddAccountMenuItem>
    );
  });

  return (
    <>
      <S.RecipientsHeader>
        {!sendPage && <Title text={t('recipients.header.title')} />}

        <S.RecipientsActionsBar>
          <S.RecipientsSearchInputWrapper>
            <Input
              name="search"
              placeholder={t('recipients.header.search-field.placeholder')}
              onChange={onChangeSearch}
              value={searchValue}
            />
          </S.RecipientsSearchInputWrapper>

          <S.RecipientsButtonsContainer>
            <S.AddAccountButton
              color="secondary"
              aria-controls={isNewAccountMenuOpen ? 'new-account-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={isNewAccountMenuOpen ? 'true' : undefined}
              endIcon={isNewAccountMenuOpen ? <IconUp /> : <IconDrop />}
              body={t('recipients.header.actions.add-account.btn')}
              onClick={(e) => openNewAccountMenu(e)}
            />
            <S.AddRecipientButton
              color="secondary"
              aria-controls={isNewRecipientMenuOpen ? 'new-recipient-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={isNewRecipientMenuOpen ? 'true' : undefined}
              endIcon={isNewRecipientMenuOpen ? <IconUp /> : <IconDrop />}
              body={t('recipients.header.actions.add-recipient.btn')}
              onClick={(e) => openNewRecipientMenu(e)}
            />
          </S.RecipientsButtonsContainer>
        </S.RecipientsActionsBar>
      </S.RecipientsHeader>

      <Modal
        modalTitle={t(`recipients.header.actions.add-account.form.title.${activeAccountForm}`)}
        body={newAccountTypeMap[activeAccountForm]}
        open={isAccountModalShown}
        onClose={() => closeAddAccountForm()}
      />
      <Modal
        modalTitle={t(`recipients.header.actions.add-recipient.form.title.${activeRecipientForm}`)}
        body={recipientAccountTypeMap[activeRecipientForm]}
        open={isRecipientModalShown}
        onClose={() => closeAddRecipientForm()}
      />

      <S.AddAccountMenu
        id="new-account-menu"
        open={isNewAccountMenuOpen}
        anchorEl={newAccountAnchor}
        onClose={() => closeNewAccountMenu()}
        onClick={() => closeNewAccountMenu()}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {newAccountMenuItems}
      </S.AddAccountMenu>
      <S.AddAccountMenu
        id="new-recipient-menu"
        open={isNewRecipientMenuOpen}
        anchorEl={newRecipientAnchor}
        onClose={() => closeNewRecipientMenu()}
        onClick={() => closeNewRecipientMenu()}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {newRecipientMenuItems}
      </S.AddAccountMenu>
    </>
  );
};
